import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import {
    getPersonalDateLabel,
    TimeEntryGroupHeader
} from '../common/TimeEntryGroupHeader';
import { getActiveTrackingSeconds } from '../time';
import { DayTimeEntries } from './useRecentTimeEntries';

export const RecentTimeEntriesDayGroupHeader = ({
    group,
    size
}: {
    group: DayTimeEntries;
    size?: 'small' | 'medium' | 'large';
}) => {
    const activeTracking = group.entries.find(entry => !entry.endDate);
    const [totalSeconds, setTotalSeconds] = useState(group.totalSeconds);

    useEffect(() => {
        let interval;
        if (activeTracking) {
            const startDate = DateTime.fromISO(activeTracking.startDate!);
            interval = setInterval(() => {
                const totalSecondsActiveTracking =
                    getActiveTrackingSeconds(startDate);
                setTotalSeconds(
                    group.totalSeconds + totalSecondsActiveTracking
                );
            }, 40);
        } else {
            setTotalSeconds(group.totalSeconds);
        }

        return () => (interval ? clearInterval(interval) : undefined);
    }, [activeTracking, group.totalSeconds]);

    // Render
    return (
        <TimeEntryGroupHeader
            size={size}
            totalSeconds={totalSeconds}
            label={getPersonalDateLabel(group.startOfDayUnix)}
        />
    );
};
