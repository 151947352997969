import { Button, ButtonProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export const SecondaryButton = ({
    children,
    ...props
}: PropsWithChildren<ButtonProps>) => {
    return (
        <Button variant="outlined" color="secondary" {...props}>
            {children}
        </Button>
    );
};
