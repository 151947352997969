import { ApolloError, useMutation } from '@apollo/client';
import { Alert, FormControl, LinearProgress } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../../../../common/components/button/PrimaryButton';
import { InputLabel } from '../../../../common/components/form/InputLabel';
import { PublicPageCardForm } from '../../../../common/components/page/PublicPageCardForm';
import { PublicPageCardFormError } from '../../../../common/components/page/PublicPageCardFormError';
import { PublicPageCardFormSubmitButton } from '../../../../common/components/page/PublicPageCardFormSubmitButton';
import { NewPasswordField } from '../../../../user/password/NewPasswordField';
import { PasswordField } from '../../../../user/password/PasswordField';
import { ApiErrorCode, getResponseErrorMessage } from '../../../api/error';
import { Paths } from '../../../navigation/paths';
import { Colors } from '../../../theme/Colors';
import { MUTATION_RESET_PASSWORD } from '../../queries';

interface Values {
    newPassword: string;
    newPasswordRepeat: string;
}

export const ResetPasswordForm = ({
    token,
    onSuccess
}: {
    token: string;
    onSuccess?: () => void;
}) => {
    const [resetPasswordMutation, { loading }] = useMutation(
        MUTATION_RESET_PASSWORD
    );
    const [error, setError] = useState<string | undefined>();
    const [successful, setSuccessful] = useState<boolean>(false);

    function onSubmit(values, { setSubmitting }) {
        if (loading) {
            return;
        }

        resetPasswordMutation({
            variables: { token, newPassword: values.newPassword }
        })
            .then(() => setSuccessful(true))
            .catch(e => {
                setError(getErrorMessage(e));
            })
            .finally(() => setSubmitting(false));
    }

    useEffect(() => {
        if (successful && onSuccess) {
            onSuccess();
        }
    }, [successful]);

    if (successful) {
        return (
            <>
                <Alert severity={'success'} style={{ marginTop: 16 }}>
                    <span
                        style={{
                            fontSize: 16,
                            lineHeight: '20px'
                        }}>
                        Ihr neues Passwort wurde erfolgreich gesetzt
                    </span>
                </Alert>

                <PrimaryButton style={{ marginTop: '16px' }} href={Paths.Login}>
                    Zum Login
                </PrimaryButton>
            </>
        );
    }

    return (
        <>
            {!!error && (
                <PublicPageCardFormError>{error}</PublicPageCardFormError>
            )}

            <Formik
                initialValues={{
                    newPassword: '',
                    newPasswordRepeat: ''
                }}
                validate={values => {
                    const errors: Partial<Values> = {};

                    if (!values.newPassword) {
                        errors.newPassword =
                            'Bitte geben Sie Ihr neues Passwort ein.';
                    }

                    if (!values.newPasswordRepeat) {
                        errors.newPasswordRepeat =
                            'Bitte geben Sie Ihr neues Passwort erneut ein.';
                    } else {
                        if (values.newPassword !== values.newPasswordRepeat) {
                            errors.newPasswordRepeat =
                                'Die Passwörter stimmen nicht überein';
                        }
                    }

                    return errors;
                }}
                onSubmit={onSubmit}>
                {({ submitForm, isSubmitting }) => (
                    <PublicPageCardForm>
                        <FormControl fullWidth>
                            <InputLabel
                                htmlFor="newPassword"
                                style={{ color: Colors.TextMedium }}>
                                Neues Passwort
                            </InputLabel>
                            <NewPasswordField
                                id="newPassword"
                                name="newPassword"
                                placeholder="Neues Passwort eingeben*"
                                disabled={isSubmitting}
                                labelPosition={'inside'}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ marginTop: '12px' }}>
                            <PasswordField
                                id="newPasswordRepeat"
                                name="newPasswordRepeat"
                                placeholder="Neues Passwort erneut eingeben*"
                                disabled={isSubmitting}
                            />
                        </FormControl>

                        <PublicPageCardFormSubmitButton
                            disabled={isSubmitting}
                            onClick={submitForm}>
                            Passwort setzen
                        </PublicPageCardFormSubmitButton>

                        {isSubmitting && <LinearProgress />}
                    </PublicPageCardForm>
                )}
            </Formik>
        </>
    );
};

const getErrorMessage = (error: ApolloError) => {
    return getResponseErrorMessage(error, apiError => {
        const code = apiError.getCode();

        if (code === ApiErrorCode.USER_INACTIVE) {
            return 'Ihr Nutzerkonto wurde zwischenzeitlich deaktiviert. Bitte kontaktieren Sie den Administrator Ihres Unternehmens, um wieder Zugriff zu erhalten.';
        }

        if (code === 'password_reset_token_already_utilized') {
            return 'Dieser Link wurde bereits genutzt. Bitte fordern Sie einen neuen Link an.';
        }

        if (code === 'password_too_weak') {
            return 'Das eingegebene Passwort ist zu schwach. Bitte beachten Sie die Tipps unterhalb des Eingabefeldes. Das Passwort muss mindestens die Sicherheitsstufe "mittel" besitzen.';
        }
    });
};
