import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export function useWindowInnerWidth() {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    const onResize = debounce(event => {
        setInnerWidth(event.target.innerWidth);
    }, 200);

    useEffect(() => {
        addEventListener('resize', onResize);

        return () => {
            removeEventListener('resize', onResize);
        };
    }, []);

    return innerWidth;
}
