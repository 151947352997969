import {
    useApolloClient,
    useLazyQuery,
    useMutation,
    useQuery
} from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingPage } from '../../../common/components/LoadingPage';
import { QUERY_ME, SIGN_OUT } from '../../../user/queries';
import { promiseWait } from '../../../utils';
import { ErrorPage } from '../../components/page/ErrorPage';
import { getDefaultPollInterval } from '../../environment';
import { Paths } from '../../navigation/paths';
import { AuthLocalStorage } from '../authLocalStorage';
import { RESIGN_IN } from '../queries';
import { User } from '../user';
import { AuthenticationContext } from './AuthenticationContext';

export const AuthenticationContextProvider = ({ children }) => {
    /*
     * State and hooks
     */
    const [user, setStateUser] = useState(AuthLocalStorage.getUser());
    const [authenticated, setStateAuthenticated] = useState(
        AuthLocalStorage.isAuthenticated()
    );

    /* Activity flags */
    const [signingIn, setSigningIn] = useState(false);
    const [signingOut, setSigningOut] = useState(false);

    const client = useApolloClient();
    const navigate = useNavigate();
    const location = useLocation();

    // This query is used by apollo client function onError
    // Necessary for sign out user after a problem with refreshing access/refresh token
    const {
        data: { reSignIn }
    } = useQuery(RESIGN_IN);

    // Fetch user info
    const [
        fetchUserData,
        {
            error: errorLoadingUserData,
            data: userData,
            startPolling,
            stopPolling
        }
    ] = useLazyQuery(QUERY_ME);

    // Sign out mutation to clear the http only refresh token
    const [signOutMutation] = useMutation(SIGN_OUT);

    /*
     * Functions for the authentication context
     */

    /** updates the store and local storage */
    const setUser = (user: User | null) => {
        // fallback for page reload
        AuthLocalStorage.setUser(user);

        // Update local state
        setStateUser(user);
    };

    /** updates the store and local storage */
    const setAuthenticated = (isAuthenticated: boolean) => {
        // fallback for page reload
        AuthLocalStorage.setAuthenticated(isAuthenticated);

        setStateAuthenticated(isAuthenticated);
    };

    /** updates the store and local storage */
    const setAuthToken = (token: string | null) => {
        // token is only saved on local storage because of the refresh behaviour and  SSOT. Also we don't need to re-render if the token changes
        AuthLocalStorage.setAuthToken(token);
    };

    const getAuthToken = () => {
        return AuthLocalStorage.getAuthToken();
    };

    // Get path to redirect to after a sign in
    const redirectPathAfterSignIn =
        location.state &&
        typeof location.state === 'object' &&
        'redirectPathAfterSignIn' in location.state
            ? location.state['redirectPathAfterSignIn']
            : undefined;

    const signIn = (token: string): void => {
        setSigningIn(true);
        setAuthToken(token);
        setAuthenticated(true);
        Promise.all([
            fetchUserData().then(result => {
                const user = result?.data?.me;
                if (isEmpty(user)) {
                    console.error('No user could be fetched');
                    return;
                }
                setUser(user);

                let signInPath = Paths.Home;
                if (redirectPathAfterSignIn) {
                    signInPath = redirectPathAfterSignIn || Paths.Home;
                }
                navigate(signInPath, { replace: !!redirectPathAfterSignIn });
            }),
            promiseWait(1000)
        ]).finally(() => {
            setSigningIn(false);
        });
    };

    const signOut = async (): Promise<void> => {
        setSigningOut(true);

        // Cache sign in email for later
        const email = AuthLocalStorage.getSignInEmail();
        stopPolling();

        await Promise.all([
            signOutMutation()
                .catch(_ => {
                    console.warn(
                        'server sign out not possible at the moment. skipping.'
                    );
                })
                .then(() => {
                    // Reset local storage
                    localStorage.clear();

                    // Set sign in email
                    AuthLocalStorage.setSignInEmail(email);

                    // Reset auth state
                    setUser(null);
                    setAuthenticated(false);

                    // apollo client has to be cleared, because of preventing access on cached resources of user who logged in before
                    client.clearStore();

                    navigate(Paths.Login);
                }),
            promiseWait(1000)
        ]).finally(() => {
            setSigningOut(false);
        });
    };

    // Construction authentication context
    const authenticationContext = useCallback(
        () => ({
            user,
            authenticated,
            getAuthToken,
            setAuthToken,
            signIn,
            signOut
        }),
        [user, authenticated, redirectPathAfterSignIn]
    );

    /*
     * Effects
     */

    // Update internal user data when API fetch was successful
    useEffect(() => {
        if (userData?.me) {
            setUser(userData.me);
        }
    }, [userData]);

    // Start/stop polling depending on the `authenticated` flag
    useEffect(() => {
        if (authenticated) {
            startPolling(getDefaultPollInterval());
        } else {
            stopPolling();
            setUser(null);
        }
        return () => stopPolling();
    }, [authenticated]);

    // case is for user who needs a re-sign in
    useEffect(() => {
        if (authenticated && reSignIn) {
            signOut();
        }
    }, [authenticated, reSignIn]);

    if (signingIn) {
        return <LoadingPage title="Lade Konto" />;
    }

    if (signingOut) {
        return <LoadingPage title="Abmelden" />;
    }

    if (authenticated && isEmpty(user)) {
        if (errorLoadingUserData) {
            return (
                <ErrorPage title="Nutzerdaten konnten nicht geladen werden">
                    Bitte laden Sie die Seite neu oder versuchen es später noch
                    einmal.
                </ErrorPage>
            );
        }

        return <LoadingPage title="Lade Konto" />;
    }

    return (
        <AuthenticationContext.Provider value={authenticationContext()}>
            {children}
        </AuthenticationContext.Provider>
    );
};
