import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../../../navigation/paths';
import { AuthBasePage } from '../AuthBasePage';
import { AuthBox } from '../AuthBox';
import { RequestPasswordResetForm } from './RequestPasswordResetForm';

const AdditionalSupportText = styled.p`
    margin-top: 32px;
    color: #86868a;
    font-size: 14px;
    line-height: 20px;
`;

const BackToLoginLink = styled(Link)`
    display: block;
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
`;

export const RequestPasswordResetPage = () => {
    const [hideInfoBoxes, setHideInfoBoxes] = useState(false);

    const hideStyles = {
        transition: 'opacity 0.8s',
        opacity: hideInfoBoxes ? 0.25 : 1
    };

    return (
        <AuthBasePage title="Passwort zurücksetzen">
            <AuthBox title="Passwort zurücksetzen">
                <p style={hideStyles}>
                    Geben Sie die <strong>E-Mail-Adresse</strong> an, mit der
                    Sie registriert sind. Wir senden Ihnen dann eine E-Mail mit
                    einem Link, mit der Sie Ihr Passwort zurücksetzen können.
                </p>

                <RequestPasswordResetForm
                    onSuccess={() => setHideInfoBoxes(true)}
                />

                <AdditionalSupportText style={hideStyles}>
                    Benötigen Sie noch immer Hilfe? Dann wenden Sie sich an den
                    Dajeh Support.
                </AdditionalSupportText>

                <BackToLoginLink to={Paths.Login}>
                    Zurück zum Login
                </BackToLoginLink>
            </AuthBox>
        </AuthBasePage>
    );
};
