import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuthenticatedContext } from '../hooks/useAuthenticatedContext';
import { Colors } from '../theme/Colors';

const StyledNavLink = styled(NavLink)`
    display: flex;
    border-radius: 15px;
    height: 30px;
    margin: 0 10px;
    padding: 4px 12px;
    text-decoration: none;
    color: ${Colors.Text};

    svg {
        display: block;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
        background: ${Colors.Primary};
        color: white;

        svg {
            fill: white;
        }
    }
`;

const DummyNavLink = styled.span`
    position: relative;
    z-index: 1;
    display: block;
    border-radius: 15px;
    height: 30px;
    margin: 0 10px;
    padding: 4px 12px;
    text-decoration: none;
    color: ${Colors.Text};
    opacity: 0.5;
    cursor: default;
    background: white;
    color: ${Colors.Text};

    svg {
        fill: #c4c4c6;
    }
`;

const IconCt = styled.span`
    float: left;
    padding-top: 1px;
    margin-left: -2px;

    svg {
        fill: #c4c4c6;
    }
`;

const Label = styled.span`
    margin-left: 6px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    font-weight: 600;
    margin-top: 1px;
`;

const TeaserBadge = styled.span`
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    margin-top: -7px;
    border-radius: 15px;
    padding: 2px 8px;
    background: #fa5aa3;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    text-align: center;
`;

type MainNavigationLinkProps = {
    icon: ReactElement;
    label: string | ReactElement;
    to: string;
    permissions?: string[];
    teaser?: boolean;
};

const canAccessLink = (permissions, user) => {
    return permissions
        ? permissions.some(permission =>
              user?.permissions?.includes(permission)
          )
        : true;
};

export const MainNavigationLink = ({
    icon,
    label,
    to,
    permissions = [],
    teaser = false
}: MainNavigationLinkProps) => {
    const authContext = useAuthenticatedContext();
    const { user } = authContext;
    if (!user) {
        return null;
    }

    if (
        permissions &&
        permissions.length > 0 &&
        !canAccessLink(permissions, user)
    ) {
        return null;
    }

    if (teaser) {
        return (
            <DummyNavLink>
                <IconCt>{icon}</IconCt>
                <Label>{label}</Label>
                <TeaserBadge>Bald</TeaserBadge>
            </DummyNavLink>
        );
    }

    return (
        <StyledNavLink to={to}>
            <IconCt>{icon}</IconCt>
            <Label>{label}</Label>
        </StyledNavLink>
    );
};
