import styled from '@emotion/styled';
import React from 'react';
import { LoadingSpinner } from './LoadingSpinner';

const Wrapper = styled.div`
    position: relative;
    z-index: 1;
`;

const LoadingOverlay = styled.div`
    position: absolute;
    z-index: 1000;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
`;

const LoadingSpinnerCenter = styled.div`
    margin: auto;
`;

export const LoadingWrapper = ({ loading, label, children }) => (
    <Wrapper>
        {children}
        {loading && (
            <LoadingOverlay>
                <LoadingSpinnerCenter>
                    <LoadingSpinner label={label} />
                </LoadingSpinnerCenter>
            </LoadingOverlay>
        )}
    </Wrapper>
);
