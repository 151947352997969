import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import { Colors } from '../../../core/theme/Colors';

export const SkeletonText = styled(Skeleton)`
    display: inline-block;
    font-size: inherit;
`;

SkeletonText.defaultProps = {
    variant: 'text',
    sx: {
        backgroundColor: Colors.TextSkeleton
    }
};
