import { DateTime } from 'luxon';
import { User } from '../../core/auth/user';
import { toDateTime } from '../time';
import { TimeEntryAuditEntry } from '../TimeEntry';

export type ValueChange<T> = {
    old?: T;
    new?: T;
};

export type TimeEntryDiff = {
    startDate?: ValueChange<DateTime>;
    endDate?: ValueChange<DateTime>;
    description?: ValueChange<string>;
};

export type TimeEntryValues = {
    startDate?: DateTime;
    endDate?: DateTime;
    description?: string;
};

export type TimeEntryAuditLogChange = {
    id: string;
    date: DateTime;
    action: 'start' | 'stop' | 'add' | 'edit' | 'remove' | 'restore';
    isSync: boolean;
    old: TimeEntryValues;
    new: TimeEntryValues;
    diff: TimeEntryDiff;
    actor: User;
    actorName: string;
};

export function convertLogToChanges(
    log: TimeEntryAuditEntry[]
): TimeEntryAuditLogChange[] {
    if (!log || log.length === 0) {
        return [];
    }

    let lastEntry: TimeEntryAuditEntry | undefined;

    return [...log]
        .reverse()
        .map(entry => {
            const change = convertLogEntryToChange(entry, lastEntry);
            lastEntry = entry;
            return change;
        })
        .filter(c => c)
        .reverse();
}

function getChanges(
    entry: TimeEntryAuditEntry,
    lastEntry?: TimeEntryAuditEntry | undefined
): TimeEntryDiff {
    const diff: TimeEntryDiff = {};

    if (entry.startDate != lastEntry?.startDate) {
        diff.startDate = {
            old: toDateTime(lastEntry?.startDate),
            new: toDateTime(entry.startDate)
        };
    }

    if (entry.endDate != lastEntry?.endDate) {
        diff.endDate = {
            old: toDateTime(lastEntry?.endDate),
            new: toDateTime(entry.endDate)
        };
    }

    if (entry.description !== lastEntry?.description) {
        diff.description = {
            old: lastEntry?.description,
            new: entry.description
        };
    }

    return diff;
}

function convertLogEntryToChange(
    entry: TimeEntryAuditEntry,
    lastEntry?: TimeEntryAuditEntry
): TimeEntryAuditLogChange {
    return {
        id: entry.id,
        date: toDateTime(entry.createdAt)!,
        action: getPublicActionFromEntry(entry, lastEntry),
        isSync: getIsSync(entry),
        old: {
            startDate: toDateTime(lastEntry?.startDate),
            endDate: toDateTime(lastEntry?.endDate),
            description: lastEntry?.description
        },
        new: {
            startDate: toDateTime(entry.startDate),
            endDate: toDateTime(entry.endDate),
            description: entry.description
        },
        diff: getChanges(entry, lastEntry),
        actor: entry.actor,
        actorName: entry.actorName
    };
}

function getPublicActionFromEntry(
    entry: TimeEntryAuditEntry,
    lastEntry?: TimeEntryAuditEntry
): 'start' | 'stop' | 'add' | 'edit' | 'remove' | 'restore' {
    if (entry.operationType === 'REMOVE') {
        return 'remove';
    }

    if (entry.operationType === 'RESTORE') {
        return 'restore';
    }

    if (entry.operationType === 'CREATE') {
        if (!entry.endDate) {
            return 'start';
        }

        return 'add';
    }

    if (entry.operationType === 'MODIFY') {
        if (entry.endDate && lastEntry && !lastEntry.endDate) {
            return 'stop';
        }

        return 'edit';
    }

    if (entry.action === 'start' || entry.action === 'stop') {
        return entry.action;
    }

    return entry.operationType;
}

function getIsSync(entry: TimeEntryAuditEntry): boolean {
    return entry.action.indexOf('SYNC') >= 0;
}
