import styled from '@emotion/styled';
import { Card, FormControl } from '@mui/material';
import React from 'react';
import { SkeletonText } from '../../common/components/skeleton/SkeletonText';
import { Colors } from '../../core/theme/Colors';
import {
    getFromTo,
    getRangeUnit,
    TimeReportRangeSelectOption
} from '../../timeTracking/report/timeReportRangeSelectOption';
import { TimeReportTimeRangeSelect } from '../../timeTracking/report/TimeReportTimeRangeSelect';

const StyledSelectCt = styled.div`
    display: flex;
    align-items: center;

    label {
        display: inline-block;
        margin-right: 12px;
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        color: ${Colors.Secondary};
    }
`;

const StyledEmployeeWorkTimeReportRangeSelect = styled(
    TimeReportTimeRangeSelect
)`
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;

    .MuiSelect-select {
        padding-bottom: 1px;
    }

    .MuiInputBase-input {
        padding-right: 36px !important;
    }
`;

const Ct = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
`;

const SelectedTimeLabel = styled.div`
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    color: ${Colors.TextLighter};
`;

function getSelectedTimeRangeLabel(
    selectedDateRange: TimeReportRangeSelectOption
) {
    const range = getRangeUnit(selectedDateRange);
    const fromTo = getFromTo(selectedDateRange);

    if (range === 'week') {
        return `KW ${fromTo.from.weekNumber} / ${fromTo.from.toFormat(
            'dd.MM.'
        )} - ${fromTo.to.toFormat('dd.MM.')}`;
    }

    return fromTo.from.toFormat('MMMM yyyy');
}

export const EmployeeWorkTimeReportPageHeader = ({
    initialDateRange,
    onDateRangeChange
}: {
    initialDateRange: TimeReportRangeSelectOption;
    onDateRangeChange: (newDateRange: TimeReportRangeSelectOption) => void;
}) => {
    const [selectedDateRange, setSelectedDateRange] =
        React.useState<TimeReportRangeSelectOption>(initialDateRange);

    return (
        <Card>
            <Ct>
                <StyledSelectCt>
                    <label
                        htmlFor={
                            'employee-time-tracking-summary-dat-range-select'
                        }>
                        Zeitraum
                    </label>
                    <FormControl>
                        <StyledEmployeeWorkTimeReportRangeSelect
                            variant="standard"
                            id="employee-time-tracking-summary-dat-range-select"
                            value={selectedDateRange}
                            onChange={e => {
                                setSelectedDateRange(
                                    e.target
                                        .value as TimeReportRangeSelectOption
                                );
                                onDateRangeChange(
                                    e.target
                                        .value as TimeReportRangeSelectOption
                                );
                            }}
                        />
                    </FormControl>
                </StyledSelectCt>
                <SelectedTimeLabel>
                    {getSelectedTimeRangeLabel(selectedDateRange)}
                </SelectedTimeLabel>
            </Ct>
        </Card>
    );
};

export const EmployeeWorkTimeReportPageHeaderSkeleton = () => {
    return (
        <Card>
            <Ct>
                <StyledSelectCt>
                    <label>
                        <SkeletonText width={60} />
                    </label>
                    <FormControl>
                        <SkeletonText width={80} />
                    </FormControl>
                </StyledSelectCt>
                <SelectedTimeLabel>
                    <SkeletonText width={60} />
                </SelectedTimeLabel>
            </Ct>
        </Card>
    );
};
