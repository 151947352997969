import { ApolloError, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { TextareaAutosize } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StartTimeTrackingIcon } from '../../common/components/icons/StartTimeTrackingIcon';
import { StopTimeTrackingIcon } from '../../common/components/icons/StopTimeTrackingIcon';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { getResponseErrorMessage } from '../../core/api/error';
import { getShortPollInterval } from '../../core/environment';
import { Colors } from '../../core/theme/Colors';
import { executeSafe, OnNodeChangeFn } from '../../utils';
import { TimeEntryDrawerForm } from '../form/TimeEntryDrawerForm';
import {
    CREATE_TIME_ENTRY_MUTATION,
    GET_MY_ACTIVE_TIME_TRACKING,
    UPDATE_TIME_ENTRY_MUTATION
} from '../queries';
import { toDateTime } from '../time';
import { TimeEntryService } from '../TimeEntryService';
import { ActiveTimeTrackingCounter } from './ActiveTimeTrackingCounter';
import { TimeTrackingBarContainer } from './TimeTrackingBarContainer';

const TrackingButton = styled.button<{ isActive: boolean }>`
    display: block;
    padding: 0;
    margin: 0;
    width: 52px;
    height: 52px;
    background: ${props => (props.isActive ? Colors.Red : Colors.Green)};
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    outline: 0;

    &:focus {
        box-shadow: 0 0 0 3px
            ${props =>
                props.isActive ? Colors.RedDarkest : Colors.GreenDarkest};
    }

    &:hover {
        background: ${props =>
            props.isActive ? Colors.RedDark : Colors.GreenDark};
    }

    &:active {
        background: ${props =>
            props.isActive ? Colors.RedDarkest : Colors.GreenDarkest};
        box-shadow: none;
    }

    &:disabled {
        cursor: default;
        box-shadow: none;
        background: ${props =>
            props.isActive ? Colors.RedLight : Colors.GreenLight};
    }

    svg {
        display: block;
        fill: #fff;
        font-size: 33px;
        margin: auto;
    }
`;

const LabelWrapper = styled.div`
    color: rgb(134, 134, 138);
    font-size: 20px;
    font-weight: 600;
`;

const DescriptionInputWrapper = styled.div`
    width: 50%;
    max-height: 68px;
    margin: 0 16px;

    @media all and (min-width: 0) and (max-width: 500px) {
        display: none;
    }
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
    display: block;
    resize: none;
    width: 100%;
    font-family: source-sans-pro, sans-serif;
    border: 1px solid #c4c4c6;
    padding: 7px 12px 5px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 18px;
    outline: none !important;

    &:hover {
        box-shadow: 0 0 0 2px ${Colors.Background};
        border-color: ${Colors.Secondary};
    }

    &:focus {
        border-color: ${Colors.Text};
        box-shadow: none;
    }

    &::placeholder {
        font-weight: 600;
        color: ${Colors.TextPlaceholder};
    }

    &:disabled {
        box-shadow: none;
        border-color: #c4c4c6;
    }
`;

const TrackingTimeWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const FormattedTimer = styled.div`
    color: rgb(9, 20, 26);
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0;
    margin-right: 24px;
`;

type TimeTrackingBarProps = {
    onChanged: OnNodeChangeFn;
};

export const TimeTrackingBar = ({ onChanged }: TimeTrackingBarProps) => {
    const [dirty, setDirty] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');

    const [showEditForm, setShowEditForm] = useState(false);

    const updateDescriptionTimeoutRef = useRef<number>();

    const { enqueueSnackbar } = useSnackbar();

    const {
        loading,
        error: loadingError,
        data,
        refetch
    } = useQuery(GET_MY_ACTIVE_TIME_TRACKING, {
        pollInterval: getShortPollInterval()
    });

    const activeTimeTracking = data?.me?.activeTimeTracking;

    const [createTimeEntryMutation, { loading: creating, error: createError }] =
        useMutation(CREATE_TIME_ENTRY_MUTATION);

    const [updateTimeEntryMutation, { loading: updating, error: updateError }] =
        useMutation(UPDATE_TIME_ENTRY_MUTATION);

    const processing = loading || creating || updating;

    function reset() {
        setDescription('');
        setDirty(false);
    }

    function onExternalChange(
        action: 'create' | 'update' | 'delete' | 'restore'
    ) {
        refetch();
        executeSafe(onChanged, action);
    }

    const startTimeTracking = async () => {
        return TimeEntryService.startTimeTracking(
            createTimeEntryMutation,
            description,
            {
                refetchQueries: [GET_MY_ACTIVE_TIME_TRACKING]
            }
        )
            .then(payload => {
                executeSafe(onChanged, 'create', payload.timeEntry);
            })
            .catch(_ => {
                // Error is handled in useEffect (see below)
            });
    };

    const stopTimeTracking = async id => {
        const descriptionSavePending = updateDescriptionTimeoutRef.current;
        if (descriptionSavePending) {
            clearTimeout(updateDescriptionTimeoutRef.current);
        }

        return TimeEntryService.stopTimeTracking(
            updateTimeEntryMutation,
            id,
            description,
            {
                refetchQueries: [GET_MY_ACTIVE_TIME_TRACKING]
            }
        )
            .then(payload => {
                executeSafe(onChanged, 'update', payload.timeEntry);
                reset();
            })
            .catch(_ => {
                // Error is handled in useEffect (see below)
            });
    };

    const toggleTimeTracking = async () => {
        // Prevent double submit
        if (processing) {
            return;
        }

        if (activeTimeTracking) {
            const id = activeTimeTracking.id;
            return stopTimeTracking(id);
        }

        return startTimeTracking();
    };

    function onDescriptionChange(event) {
        const description = event.target.value as string;
        setDirty(true);
        setDescription(description);
    }

    function saveDescription() {
        if (!activeTimeTracking || !dirty) {
            return;
        }

        const id = activeTimeTracking.id;

        // active time entry has no id yet, no update possible
        if (!id) {
            return;
        }

        TimeEntryService.update(
            updateTimeEntryMutation,
            id,
            {
                description
            },
            {
                refetchQueries: [GET_MY_ACTIVE_TIME_TRACKING]
            }
        ).then(payload => {
            setDirty(false);
            executeSafe(onChanged, 'update', payload.timeEntry);
        });
    }

    function stopDescriptionUpdate() {
        clearTimeout(updateDescriptionTimeoutRef.current);
    }

    useEffect(() => {
        stopDescriptionUpdate();

        if (activeTimeTracking) {
            setDescription(activeTimeTracking.description);
        } else {
            reset();
        }
    }, [activeTimeTracking]);

    useEffect(() => {
        stopDescriptionUpdate();
        updateDescriptionTimeoutRef.current = window.setTimeout(
            saveDescription,
            10000
        );
    }, [description]);

    const isActive = !!activeTimeTracking;
    const label = isActive ? 'Zeiterfassung stoppen' : 'Zeiterfassung starten';
    const startDate = activeTimeTracking?.startDate;

    const startDateTime = useMemo(() => {
        return toDateTime(startDate);
    }, [startDate]);

    function editTimeEntry() {
        setShowEditForm(true);
    }

    function onFormClosed() {
        setShowEditForm(false);
    }

    function onDescriptionKeyDown(event) {
        if (
            event.code === 'Enter' &&
            (event.ctrlKey || event.metaKey) &&
            !processing &&
            !activeTimeTracking
        ) {
            event.stopPropagation();
            event.preventDefault();
            startTimeTracking();
        }
    }

    function getErrorReason(error: ApolloError) {
        return getResponseErrorMessage(error);
    }

    useEffect(() => {
        if (!loadingError) {
            return;
        }

        const reason = getErrorReason(loadingError);
        enqueueSnackbar(`Tracking konnte nicht geladen werden: ${reason}`, {
            variant: 'error'
        });
    }, [loadingError]);

    useEffect(() => {
        if (!createError) {
            return;
        }

        const reason = getErrorReason(createError);
        enqueueSnackbar(`Tracking konnte nicht gestartet werden: ${reason}`, {
            variant: 'error'
        });
    }, [createError]);

    useEffect(() => {
        if (!updateError) {
            return;
        }

        const reason = getErrorReason(updateError);
        enqueueSnackbar(
            `Tracking konnte nicht ${
                dirty ? 'aktualisiert' : 'gestoppt'
            } werden: ${reason}`,
            {
                variant: 'error'
            }
        );
    }, [updateError]);

    if (loading) {
        return (
            <TimeTrackingBarContainer>
                <LoadingSpinner label="Lade aktives Tracking" />
            </TimeTrackingBarContainer>
        );
    }

    return (
        <>
            <TimeTrackingBarContainer>
                <LabelWrapper
                    role={activeTimeTracking ? 'button' : undefined}
                    onClick={activeTimeTracking ? editTimeEntry : undefined}
                    style={{
                        cursor: activeTimeTracking ? 'pointer' : 'default'
                    }}>
                    {activeTimeTracking
                        ? `Gestartet: ${
                              startDateTime
                                  ? startDateTime.toFormat('HH:mm')
                                  : '--:--'
                          }`
                        : 'Arbeitszeit starten'}
                </LabelWrapper>

                <DescriptionInputWrapper>
                    <StyledTextareaAutosize
                        value={description || ''}
                        onChange={onDescriptionChange}
                        onKeyDown={onDescriptionKeyDown}
                        onBlur={saveDescription}
                        minRows={1}
                        maxRows={3}
                        placeholder="Beschreibung hinzufügen"
                        disabled={creating}
                    />
                </DescriptionInputWrapper>

                <TrackingTimeWrapper>
                    <FormattedTimer
                        role={activeTimeTracking ? 'button' : undefined}
                        onClick={activeTimeTracking ? editTimeEntry : undefined}
                        style={{
                            cursor: activeTimeTracking ? 'pointer' : 'default'
                        }}>
                        <ActiveTimeTrackingCounter startDate={startDate} />
                    </FormattedTimer>
                    <TrackingButton
                        onClick={toggleTimeTracking}
                        aria-label={label}
                        isActive={!!activeTimeTracking}
                        disabled={processing}>
                        {activeTimeTracking ? (
                            <StopTimeTrackingIcon />
                        ) : (
                            <StartTimeTrackingIcon />
                        )}
                    </TrackingButton>
                </TrackingTimeWrapper>
            </TimeTrackingBarContainer>
            {showEditForm && activeTimeTracking && (
                <TimeEntryDrawerForm
                    entry={activeTimeTracking}
                    onChanged={onExternalChange}
                    onClosed={onFormClosed}
                />
            )}
        </>
    );
};
