import styled from '@emotion/styled';
import { History } from '@mui/icons-material';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { TimeEntryAuditLogDialogContent } from './TimeEntryAuditLogDialogContent';

type TimeEntryChangeLogDialogProps = {
    timeEntryId?: string | null;
    open: boolean;
    onClose: () => void;
};

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        width: 100%;
        max-width: 700px;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    svg {
        width: 24px;
        margin-right: 12px;
        float: left;
    }
`;

export const TimeEntryAuditLogDialog = ({
    timeEntryId,
    open,
    onClose
}: TimeEntryChangeLogDialogProps) => {
    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            keepMounted={false}
            aria-labelledby="te-changelog-title">
            <StyledDialogTitle id="te-changelog-title">
                <History /> Änderungsprotokoll
            </StyledDialogTitle>
            <TimeEntryAuditLogDialogContent timeEntryId={timeEntryId} />
            <DialogActions>
                <PrimaryButton onClick={onClose}>Schließen</PrimaryButton>
            </DialogActions>
        </StyledDialog>
    );
};
