import React from 'react';
import { ProtectedPage } from '../../../core/auth/components/ProtectedPage';
import { Permission } from '../../../core/auth/permission';
import { OrganizationWorkTimeReportPageContent } from './OrganizationWorkTimeReportPageContent';

export const OrganizationWorkTimeReportPage = () => (
    <ProtectedPage permissions={Permission.Read_Team_Time_Report}>
        <OrganizationWorkTimeReportPageContent />
    </ProtectedPage>
);

export default OrganizationWorkTimeReportPage;
