import styled from '@emotion/styled'
import React, { PropsWithChildren } from 'react';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

const BasePageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

type BasePageProps = {
    title?: string;
}

export const BasePage = ({title, children}: PropsWithChildren<BasePageProps>) => {

    useDocumentTitle(title);

    return (
        <BasePageWrapper>
            {children}
        </BasePageWrapper>
    );
};
