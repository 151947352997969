import { useMutation } from '@apollo/client';
import apolloClient from '../api/apolloClient';
import { useAuthenticatedContext } from '../hooks/useAuthenticatedContext';
import { NoAccessTokenReceivedError } from './NoAccessTokenReceivedError';
import { AuthLocalStorage } from './authLocalStorage';
import { QUERY_SIGN_IN, REFRESH_TOKEN } from './queries';
import { User } from './user';

export type AuthData = {
    accessToken: string;
    expiresAt: string;
};

export const useLoginMutation = (): Array<any> => {
    const authContext = useAuthenticatedContext();

    const [signInMutation, { loading }] = useMutation(QUERY_SIGN_IN);

    const signIn = (email: string, password: string): Promise<AuthData> => {
        return signInMutation({
            variables: {
                input: {
                    email,
                    password
                }
            }
        }).then(result => {
            const accessToken = result?.data?.signIn?.accessToken;
            if (!accessToken) {
                throw new NoAccessTokenReceivedError();
            }
            authContext.signIn(accessToken);

            return result.data.signIn as AuthData;
        });
    };

    return [signIn, loading];
};

export const refreshAccessToken = () => {
    return apolloClient
        .mutate({
            mutation: REFRESH_TOKEN
        })
        .then(response => response?.data?.refreshAuth?.accessToken)
        .then(accessToken => {
            if (accessToken) {
                AuthLocalStorage.setAuthToken(accessToken);
            }

            return accessToken;
        });
};

export const getAuthorizationBearer = (token?: string): string => {
    return token ? `Bearer ${token}` : '';
};

export function getRoleLabel(role: string | undefined): string {
    switch (role?.toLowerCase()) {
        case 'admin':
            return 'Administrator';
        case 'standard':
            return 'Standard';
        case undefined:
            return `Unbekannt`;
        default:
            return `Unbekannt (${role})`;
    }
}

export function isAdmin(user: Partial<User> | null | undefined): boolean {
    return 'admin' === user?.role;
}
