import React from 'react';
import ImgAddEmployeeUserMenuOverview from '../../assets/images/help/add-employee/user-menu-overview.png';
import ImgAddEmployeeUserMenuProfile from '../../assets/images/help/add-employee/user-menu-profile.png';
import { ContentGridContainer } from '../../common/components/layout/ContentGridContainer';
import { GridItem } from '../../common/components/layout/GridItem';
import { HelpFigure } from '../common/HelpFigure';
import { HelpLightbox } from '../common/HelpLightbox';

const Images = [
    {
        src: ImgAddEmployeeUserMenuOverview,
        title: '"Einladung erneut senden" im 3-Punkte-Menü auf der Mitarbeiterübersichtsseite',
        description:
            'Klicken Sie auf "Einladung erneut senden" im 3-Punkte-Menü'
    },
    {
        src: ImgAddEmployeeUserMenuProfile,
        title: '"Einladung erneut senden" im 3-Punkte-Menü auf der Profilseite des Mitarbeiters',
        description:
            'Klicken Sie auf "Einladung erneut senden" im 3-Punkte-Menü'
    }
].map((image, index) => ({
    ...image,
    title: `Bild ${index + 1}: ${image.title}`,
    alt: image.title,
    index
}));

const ImageMap = Images.reduce((acc, image) => {
    acc[image.src] = image;
    return acc;
}, {});

export const ResendEmployeeInvitationHelpContent = () => {
    const [open, setOpen] = React.useState(false);
    const [currentImageSrc, setCurrentImageSrc] =
        React.useState<string | undefined>();

    const lightboxImageIndexNum = ImageMap[currentImageSrc || '']?.index || 0;

    function openLightbox(imageSrc: string) {
        setCurrentImageSrc(imageSrc);
        setOpen(true);
    }

    return (
        <>
            <p>
                Um die Einladungs-E-Mail erneut zu versenden können Sie
                jederzeit im 3-Punkte-Menü auf der Mitarbeiterübersichtsseite
                (Bild 1) oder auf der Profilseite des Mitarbeiters (Bild 2) auf
                "Einladung erneut senden" klicken.
            </p>

            <p>
                Wenn die Einladung bereits abgelaufen ist, wird ein Hinweis
                "Einladung abgelaufen" angezeigt. In diesem Fall müssen Sie die{' '}
                <a href="#renew-invitation">Einladung erneuern</a>.
            </p>

            <ContentGridContainer>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeUserMenuOverview}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeUserMenuProfile}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
            </ContentGridContainer>

            <HelpLightbox
                open={open}
                onClose={() => setOpen(false)}
                slides={Images}
                index={lightboxImageIndexNum}
            />
        </>
    );
};
