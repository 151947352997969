import { DateTime } from 'luxon';

export type TimeReportRangeSelectOption =
    | 'this_week'
    | 'this_month'
    | 'last_week'
    | 'last_month';

export function getFromTo(dateRange: TimeReportRangeSelectOption): {
    from: DateTime;
    to: DateTime;
} {
    const range = getRangeUnit(dateRange);
    const startDate = getStart(dateRange);
    const endDate = startDate.endOf(range);

    return {
        from: startDate,
        to: endDate
    };
}

export function getStart(dateRange: TimeReportRangeSelectOption): DateTime {
    const now = DateTime.now();
    if (dateRange === 'last_week') {
        return now.minus({ weeks: 1 }).startOf('week');
    } else if (dateRange === 'last_month') {
        return now.minus({ months: 1 }).startOf('month');
    } else if (dateRange === 'this_month') {
        return now.startOf('month');
    } else {
        return now.startOf('week');
    }
}

export function getRangeUnit(
    dateRange: TimeReportRangeSelectOption
): 'week' | 'month' {
    if (dateRange === 'last_week' || dateRange === 'this_week') {
        return 'week';
    }

    return 'month';
}
