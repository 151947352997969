import React from 'react';

type ContextFunctions = {
    editEmployeeInDrawerForm: (id: string, onChange?: () => void) => void;
    hideEditEmployeeDrawerForm: () => void;
};

export type GlobalDrawerFormContextProps = ContextFunctions & {};

const initialContextFunctions: ContextFunctions = {
    editEmployeeInDrawerForm: (userId: string, onChange?: () => void) => {},
    hideEditEmployeeDrawerForm: () => {}
};

const drawerFormContext: GlobalDrawerFormContextProps = {
    ...initialContextFunctions
};

export const GlobalDrawerFormContext = React.createContext(drawerFormContext);
