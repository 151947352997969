import styled from '@emotion/styled';
import { getScoreClass, getScoreColor } from './utils';

const Label = styled.div`
    margin: 2px 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    white-space: nowrap;
    color: ${getScoreColor(undefined)};

    &.is-insufficient {
        color: ${getScoreColor(0)};
    }

    &.is-weak {
        color: ${getScoreColor(1)};
    }

    &.is-medium {
        color: ${getScoreColor(2)};
    }

    &.is-strong {
        color: ${getScoreColor(3)};
    }

    &.is-very-strong {
        color: ${getScoreColor(4)};
    }
`;

function getStrengthLabel(score: undefined | 0 | 1 | 2 | 3 | 4) {
    switch (score) {
        case 0:
            return 'Sehr schwach';
        case 1:
            return 'Schwach';
        case 2:
            return 'Mittel';
        case 3:
            return 'Stark';
        case 4:
            return 'Sehr stark';
        default:
            return '';
    }
}

export const PasswordStrengthLabel = (props: {
    score: undefined | 0 | 1 | 2 | 3 | 4;
}) => {
    const { score } = props;

    return (
        <Label className={`is-${getScoreClass(score)}`}>
            {getStrengthLabel(score)}
        </Label>
    );
};
