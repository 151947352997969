import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import React from 'react';
import { NotAvailable } from '../../common/components/NotAvailable';
import { Colors } from '../../core/theme/Colors';
import {
    formatDateTime,
    formatSecondsAsTimeDuration
} from '../../timeTracking/time';

type NotificationTimeEntryChange = {
    field: 'start' | 'end' | 'description' | 'duration';
    oldValue: string | number | null;
    newValue: string | number | null;
};

const ChangesCt = styled.div`
    margin: 4px 0 8px;
    padding: 8px 12px;
    background-color: ${Colors.Background};
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 20px;

    .is-notification-page & {
        margin: 12px 0;
    }

    table {
        width: 100%;
        border-collapse: collapse;

        th {
            width: 120px;
            padding: 4px 8px 4px 0;
            vertical-align: top;
            text-align: left;
            font-weight: 600;
            color: ${Colors.TextLight};
            white-space: nowrap;
        }

        td {
            padding: 4px 0 4px 8px;
            color: ${Colors.Text};
            vertical-align: top;
            text-align: left;
        }

        tr + tr {
            td,
            th {
                border-top: 1px solid ${Colors.LineColorLight};
            }
        }
    }

    .newValue {
        color: ${Colors.Text};
    }

    .oldValue {
        color: ${Colors.RedDarkest};
        text-decoration: line-through;
    }
`;

export const TimeEntryUpdatedNotificationDataContent = ({ changes }) => {
    return <ChangesCt>{getChangesTable(changes)}</ChangesCt>;
};

function getChangesTable(changes: Partial<NotificationTimeEntryChange>[]) {
    const rows = changes.map(change => getChangeRow(change));

    if (!rows) {
        return <NotAvailable label={'Keine darstellbaren Änderungen'} />;
    }

    return (
        <table>
            <tbody>{rows}</tbody>
        </table>
    );
}

function getRowHeading(field?: string) {
    if (!field) {
        return 'n.a.';
    }

    switch (field) {
        case 'start':
            return 'Start';
        case 'end':
            return 'Ende';
        case 'description':
            return 'Beschreibung';
        case 'duration':
            return 'Dauer';
        default:
            return field;
    }
}

function getChangeValue(
    field?: string,
    value?: string | number | null
): string {
    if (field === 'start' || field === 'end') {
        return formatDateTime(value);
    }

    if (field === 'duration') {
        return formatSecondsAsTimeDuration(value as number);
    }

    if (value) {
        return value.toString();
    }

    return 'n.a.';
}

function getChangeRow(change: Partial<NotificationTimeEntryChange>) {
    const newValue = getChangeValue(change.field, change.newValue);
    const oldValue = getChangeValue(change.field, change.oldValue);

    return (
        <tr key={change.field}>
            <th>{getRowHeading(change?.field)}</th>
            <td>
                <div className={'newValue'}>{newValue}</div>
                <Tooltip
                    title={
                        <>
                            <strong>Alter Wert:</strong>
                            <br />
                            {change.oldValue
                                ? oldValue
                                : 'Nicht angegeben / Leer'}
                        </>
                    }>
                    <span className={'oldValue'}>{oldValue}</span>
                </Tooltip>
            </td>
        </tr>
    );
}
