import styled from '@emotion/styled';
import React from 'react';
import { ProtectedPage } from '../core/auth/components/ProtectedPage';
import { Permission } from '../core/auth/permission';
import { AddNewTimeEntryButton } from '../timeTracking/AddNewTimeEntryButton';
import {
    RecentEmployeeTimeEntries,
    RecentEmployeeTimeEntriesSkeleton
} from '../timeTracking/recent/RecentEmployeeTimeEntries';
import { EmployeePageContentWrapper } from './EmployeePageContentWrapper';

const AddTimeEntryButtonWrapper = styled.div`
    position: relative;
    z-index: 1;
`;

export const EmployeeTimeTrackingPage = () => {
    const [lastAdd, setLastAdd] = React.useState<Date | undefined>();
    return (
        <ProtectedPage permissions={Permission.Manage_Users}>
            <EmployeePageContentWrapper
                subPageTitle={'Zeiterfassung'}
                renderContent={(user, refetchUser) => (
                    <>
                        <AddTimeEntryButtonWrapper>
                            <AddNewTimeEntryButton
                                label={'Eintrag hinzufügen'}
                                onAdded={() => {
                                    setLastAdd(new Date());
                                    refetchUser();
                                }}
                                owner={user}
                            />
                        </AddTimeEntryButtonWrapper>
                        <RecentEmployeeTimeEntries
                            employeeId={user.id}
                            refetchUser={refetchUser}
                            lastAdd={lastAdd}
                        />
                    </>
                )}
                renderSkeletonContent={() => (
                    <RecentEmployeeTimeEntriesSkeleton />
                )}
            />
        </ProtectedPage>
    );
};
