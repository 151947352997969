import React from 'react';
import ImgAddEmployeeInvitationExpiredOnOverviewPage from '../../assets/images/help/add-employee/invitation-expired-overview.png';
import ImgAddEmployeeInvitationExpiredOnProfilePage from '../../assets/images/help/add-employee/invitation-expired-profile.png';
import ImgRenewInvitationUserMenuOverview from '../../assets/images/help/add-employee/invitation-renew-overview.png';
import ImgRenewInvitationUserMenuProfile from '../../assets/images/help/add-employee/invitation-renew-profile.png';
import { ContentGridContainer } from '../../common/components/layout/ContentGridContainer';
import { GridItem } from '../../common/components/layout/GridItem';
import { HelpFigure } from '../common/HelpFigure';
import { HelpLightbox } from '../common/HelpLightbox';

const Images = [
    {
        src: ImgAddEmployeeInvitationExpiredOnOverviewPage,
        title: 'Hinweis auf Mitarbeiterübersichtsseite',
        description: ''
    },
    {
        src: ImgAddEmployeeInvitationExpiredOnProfilePage,
        title: 'Hinweis auf Profilseite des Mitarbeiters',
        description: ''
    },
    {
        src: ImgRenewInvitationUserMenuOverview,
        title: '3-Punkte-Menü auf der Mitarbeiterübersichtsseite',
        description:
            'Klicken Sie auf "Erneut einladen", um eine neue Einladung zu erstellen und zu versenden.'
    },
    {
        src: ImgRenewInvitationUserMenuProfile,
        title: '3-Punkte-Menü auf der Profilseite des Mitarbeiters',
        description:
            'Klicken Sie auf "Erneut einladen", um eine neue Einladung zu erstellen und zu versenden.'
    }
].map((image, index) => ({
    ...image,
    title: `Bild ${index + 1}: ${image.title}`,
    alt: image.title,
    index
}));

const ImageMap = Images.reduce((acc, image) => {
    acc[image.src] = image;
    return acc;
}, {});

export const RenewEmployeeInvitationHelpContent = () => {
    const [open, setOpen] = React.useState(false);
    const [currentImageSrc, setCurrentImageSrc] =
        React.useState<string | undefined>();

    const lightboxImageIndexNum = ImageMap[currentImageSrc || '']?.index || 0;

    function openLightbox(imageSrc: string) {
        setCurrentImageSrc(imageSrc);
        setOpen(true);
    }

    return (
        <>
            <p style={{ marginTop: 0 }}>
                Ihr neues Teammitglied hat 14 Tage Zeit, mit Hilfe des
                Einladungs-Links in der Einladungs-E-Mail, ihr Dajeh-Konto zu
                aktivieren. Falls das nicht passiert, wird Ihnen der Hinweis
                "Einladung abgelaufen" sowohl in der Mitarbeiterübersicht als
                auch auf der Profilseite des Mitarbeiters angezeigt (Siehe Bild
                1 und 2).
            </p>
            <ContentGridContainer>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeInvitationExpiredOnOverviewPage}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeInvitationExpiredOnProfilePage}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
            </ContentGridContainer>

            <hr />

            <h3 style={{ marginTop: 0 }}>Erneut einladen</h3>

            <p>
                Über das 3-Punkte-Menü und den Hinweis auf der Profilseite kann
                eine neue Einladung erstellt werden, die einen neuen
                Einlade-Link bzw. Code enthält. Die neue Einladung ist dann
                erneut 14 Tage ab Versand gültig.
                <br />
                <br />
                Öffnen Sie dazu das 3-Punkte-Menü auf der
                Mitarbeiterübersichtsseite oder der Profilseite des Mitarbeiters
                und klicken Sie auf den Button "Erneut einladen".
            </p>

            <ContentGridContainer>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgRenewInvitationUserMenuOverview}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgRenewInvitationUserMenuProfile}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
            </ContentGridContainer>

            <HelpLightbox
                open={open}
                onClose={() => setOpen(false)}
                slides={Images}
                index={lightboxImageIndexNum}
            />
        </>
    );
};
