import styled from '@emotion/styled'
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import logoImg from '../../assets/images/dajeh-logo_362.png';
import { LoadingSpinner } from './LoadingSpinner';
import { AppFooter } from '../../core/components/page/AppFooter';
import { useDocumentTitle } from '../../core/hooks/useDocumentTitle';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
`

const ContentWrapper = styled.div`
  margin: auto;
  width: 80%;
  max-width: 400px;
`

const Logo = styled.img`
  height: 75px;
  width: auto;
  display: block;
  margin: 0 auto 32px auto;
`

export type ErrorPageProps = {
    title: string
};
export const LoadingPage = ({title}: ErrorPageProps) => {

    useDocumentTitle(title);

    return (
        <>
            <CssBaseline />
            <Wrapper>
                <ContentWrapper>

                    <Logo
                        src={logoImg}
                        alt="Dajeh"
                    />

                    <LoadingSpinner center label={title} />

                </ContentWrapper>

                <AppFooter />
            </Wrapper>
        </>
    );
};
