import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Card } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { Editor } from '../../common/components/Editor';
import { getResponseErrorMessage } from '../../core/api/error';
import { User } from '../../core/auth/user';
import { usePrompt } from '../../core/navigation/prompt';
import { MUTATION_UPDATE_INTERNAL_NOTE } from '../../user/queries';
import { SubPageSectionButtons } from '../../core/components/page/SubPageSectionButtons';

export const EmployeeNotes = ({ user }: { user: User }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [initialized, setInitialized] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [updating, setUpdating] = useState(false);

    const editorRef = useRef<any>(null);

    usePrompt(
        'Ihre Änderungen wurden noch nicht gespeichert. Möchten Sie die Änderungen verwerfen?',
        dirty
    );

    const [updateUserMutation] = useMutation(MUTATION_UPDATE_INTERNAL_NOTE);

    const save = () => {
        if (!editorRef.current || updating) {
            return;
        }

        const internalNote = getInternalNoteEditorContent();

        setUpdating(true);
        updateUserMutation({
            variables: {
                id: user.id,
                input: {
                    internalNote
                }
            }
        })
            .then(result => {
                enqueueSnackbar('Notizen gespeichert', { variant: 'success' });
                setInternalNoteEditorContent(
                    result?.data?.updateUser?.user?.internalNote
                );
                setDirty(false);
            })
            .catch(error => {
                const message =
                    'Notizen konnten nicht gespeichert werden: ' +
                    getResponseErrorMessage(error);

                enqueueSnackbar(message, { variant: 'error' });
            })
            .finally(() => {
                setUpdating(false);
            });
    };

    const reset = () => {
        editorRef.current.resetContent(user.internalNote || '');
        setDirty(false);
    };

    const getInternalNoteEditorContent = () => {
        return editorRef.current?.getContent();
    };

    const setInternalNoteEditorContent = internalNote => {
        return editorRef.current?.setContent(internalNote);
    };

    useEffect(() => {
        if (!initialized && user?.internalNote) {
            setInitialized(true);
            setInternalNoteEditorContent(user.internalNote);
        }
    }, [user]);

    return (
        <>
            <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={user?.internalNote}
                onDirty={() => setDirty(true)}
            />

            <SubPageSectionButtons>
                <SecondaryButton
                    variant="text"
                    disabled={!dirty || updating}
                    onClick={reset}>
                    Abbrechen
                </SecondaryButton>

                <LoadingButton
                    loading={updating}
                    disabled={!dirty}
                    onClick={save}>
                    Speichern
                </LoadingButton>
            </SubPageSectionButtons>
        </>
    );
};

export const EmployeeNotesSkeleton = () => {
    return <Card sx={{ height: '150px' }}></Card>;
};
