export function getSupportEmailAddress(): string {
    return process.env.REACT_APP_SUPPORT_EMAIL || 'support@dajeh.de';
}

export function getAppVersion(): string {
    return process.env.REACT_APP_VERSION || '1.0.0';
}

export function getAppBaseUrl(): string {
    return process.env.REACT_APP_BASE_URL || 'https://app.dajeh.de';
}

export function getAppClientName(): string {
    return process.env.REACT_APP_CLIENT_NAME || 'dajeh-web-app';
}

export function getServerBaseUrl(): string {
    return process.env.REACT_APP_SERVER_BASE_URL || 'https://server.dajeh.de';
}

export function getDefaultPollInterval(): number {
    const env = process.env.REACT_APP_POLL_INTERVAL_DEFAULT;
    const internalDefault = 30000; // 30s
    if (env) {
        return parseInt(env, 10) || internalDefault;
    }
    return internalDefault;
}

export function getShortPollInterval(): number {
    const env = process.env.REACT_APP_POLL_INTERVAL_SHORT;
    const internalDefault = 10000; // 10s
    if (env) {
        return parseInt(env, 10) || internalDefault;
    }
    return internalDefault;
}
