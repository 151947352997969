import styled from '@emotion/styled';
import { Colors } from '../../../core/theme/Colors';

export const InputLabel = styled.label`
  display: block;
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${Colors.Secondary};
  font-weight: 600;
`;
