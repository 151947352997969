import { Skeleton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

type SkeletonButtonProps = {
    size?: 'medium' | 'small' | 'large'
    sx: SxProps<Theme>
}

export const SkeletonButton = ({size, sx}: SkeletonButtonProps) => (
    <Skeleton variant="rectangular" sx={{borderRadius: 15, height: size === 'small' ? 30 : 32, ...sx}} />
)
