import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Colors } from '../theme/Colors';

const StyledNavLink = styled(NavLink)`
    display: inline-flex;
    align-items: center;
    height: auto;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    font-weight: 600;
    padding: 8px 10px;
    text-decoration: none;
    color: ${Colors.TextLight};
    border-bottom: 2px solid transparent;
    margin-bottom: -1px;
    margin-top: -8px;
    transition: background-color 0.1s, color 0.1s, border-bottom-color 0.1s;

    svg {
        width: 20px;
        height: 20px;
        fill: ${Colors.TextLighter};
        transition: fill 0.1s;
        margin-right: 6px;
    }

    @media (min-width: 600px) {
        font-size: 17px;
        letter-spacing: 0;
        padding: 10px 16px;
        margin-top: -10px;
    }

    @media (min-width: 960px) {
        font-size: 18px;
        line-height: 24px;
        padding: 6px 20px 8px;
        margin-top: -6px;

        svg {
            width: 24px;
            height: 24px;
            margin-right: 7px;
        }
    }

    &:hover,
    &:focus {
        color: ${Colors.TextMedium};

        svg {
            fill: ${Colors.TextMedium};
        }

        border-bottom-color: ${Colors.TextLighter};
    }

    &:active {
        color: ${Colors.Primary};

        svg {
            fill: ${Colors.Primary};
        }

        border-bottom-color: ${Colors.Primary};
    }

    &.active {
        color: ${Colors.PrimaryDark};
        border-bottom-color: ${Colors.PrimaryDark};

        svg {
            fill: ${Colors.PrimaryDark};
        }
    }
`;

type SidebarNavLinkProps = {
    icon?: ReactElement;
    label: string;
    to: string;
};

export const OnPageTabNavLink = ({ icon, label, to }: SidebarNavLinkProps) => {
    return (
        <StyledNavLink to={to}>
            {icon}
            <span>{label}</span>
        </StyledNavLink>
    );
};
