import { ApolloError, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import {
    addSectionListFlags,
    SectionListData,
    SectionListEntry
} from '../../common/components/section/sectionList';
import { ConnectionPageInfo } from '../../common/graphql/ConnectionPageInfo';
import { getShortPollInterval } from '../../core/environment';
import { useLocalDate } from '../../core/hooks/useLocalDate';
import {
    QUERY_MY_RECENT_TIME_ENTRIES,
    QUERY_USER_RECENT_TIME_ENTRIES
} from '../queries';
import { formatDayDate, toDateTime } from '../time';
import { TimeEntry } from '../TimeEntry';

export class DayTimeEntries implements SectionListData<TimeEntry> {
    startOfDayUnix: number = 0;
    label: string = '';
    entries: TimeEntry[] = [];
    totalSeconds: number = 0;
}

type UseRecentTimeEntriesResult = {
    recentTimeEntries: DayTimeEntries[];
    isFetching: boolean;
    hasMore: boolean;
    fetchMore: () => Promise<void>;
    isFetchingMore: boolean;
    error: ApolloError | undefined;
    refetch: () => Promise<void>;
};

function getTimeEntryConnection(data: any, userId?: string) {
    return userId ? data?.node?.timeEntries : data?.me?.timeEntries;
}

export function useRecentTimeEntries(
    userId?: string
): UseRecentTimeEntriesResult {
    const [lastPageInfo, setLastPageInfo] =
        useState<undefined | ConnectionPageInfo>();
    const [isFetchingMore, setFetchingMore] = useState<boolean>(false);

    const {
        data,
        loading,
        fetchMore: fetchNextPage,
        error,
        refetch: refetchQuery
    } = useQuery(
        userId ? QUERY_USER_RECENT_TIME_ENTRIES : QUERY_MY_RECENT_TIME_ENTRIES,
        {
            variables: {
                last: 20,
                after: undefined,
                userId
            },
            pollInterval: getShortPollInterval()
        }
    );

    // We need to trigger an update when the date changes so the sections get updated
    const date = useLocalDate();

    const isFetching = loading;
    const hasMore = lastPageInfo?.hasNextPage || false;

    async function fetchMore(): Promise<void> {
        if (
            loading ||
            !lastPageInfo ||
            !lastPageInfo.hasNextPage ||
            isFetchingMore ||
            !fetchNextPage
        ) {
            return;
        }
        setFetchingMore(true);

        return fetchNextPage({
            variables: {
                last: 10,
                after: lastPageInfo.endCursor
            }
        })
            .then(_ => undefined)
            .finally(() => setFetchingMore(false));
    }

    const recentTimeEntries: DayTimeEntries[] = useMemo(() => {
        if (!data || isFetching) {
            return [];
        }

        const timeEntryConnection = getTimeEntryConnection(data, userId);
        setLastPageInfo(timeEntryConnection?.pageInfo);
        const nodes =
            timeEntryConnection?.edges?.map((edge: any) => edge.node) || [];

        const sectionListData = convertToSectionListData(nodes);
        addSectionListFlags(sectionListData);

        return sectionListData;
    }, [isFetching, data, date]);

    function refetch(): Promise<void> {
        const timeEntryConnection = getTimeEntryConnection(data, userId);
        const loadedTimeEntryCount = timeEntryConnection?.edges?.length || 20;
        const loadCount = Math.min(100, Math.max(loadedTimeEntryCount, 20));

        return refetchQuery({
            last: loadCount,
            after: undefined,
            userId
        }).then();
    }

    return {
        recentTimeEntries,
        isFetching,
        hasMore,
        fetchMore,
        isFetchingMore,
        error,
        refetch
    };
}

function convertToSectionListData(timeEntries: TimeEntry[]): DayTimeEntries[] {
    const dayEntriesMap = new Map<number, DayTimeEntries>();

    timeEntries.forEach(timeEntry => {
        const startDate = toDateTime(timeEntry.startDate);
        if (!startDate) {
            return;
        }

        const startOfDayUnix = startDate.startOf('day').toMillis();

        // Add to group to map if not existing
        if (!dayEntriesMap.has(startOfDayUnix)) {
            const newGroup = new DayTimeEntries();
            newGroup.startOfDayUnix = startOfDayUnix;
            newGroup.label = formatDayDate(startDate);
            dayEntriesMap.set(startOfDayUnix, newGroup);
        }

        const group = dayEntriesMap.get(startOfDayUnix)!;
        group.entries.push({
            isFirst: false,
            isLast: false,
            ...timeEntry
        } as SectionListEntry<TimeEntry>);
        group.totalSeconds += timeEntry.totalSeconds || 0;
    });

    const sectionListData = Array.from(dayEntriesMap.values());

    const startOfToday = DateTime.now().startOf('day');

    if (sectionListData.length > 0) {
        const firstDay = sectionListData[0];

        // If there is no item in 'today' we add a dummy section
        if (firstDay.startOfDayUnix !== startOfToday.toMillis()) {
            const todayGroup = new DayTimeEntries();
            todayGroup.startOfDayUnix = startOfToday.toMillis();
            todayGroup.label = 'Heute';
            sectionListData.unshift(todayGroup);
        }
    }

    return sectionListData;
}
