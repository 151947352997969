import PersonAddIcon from '@mui/icons-material/PersonAdd';
import React, { useState } from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { NewEmployeeDrawerForm } from '../form/NewEmployeeDrawerForm';

export const AddNewEmployeeButton = () => {
    const [showFormDrawer, setShowFormDrawer] = useState(false);

    return (
        <>
            <PrimaryButton
                startIcon={<PersonAddIcon />}
                onClick={() => setShowFormDrawer(true)}>
                Hinzufügen
            </PrimaryButton>
            {showFormDrawer && (
                <NewEmployeeDrawerForm
                    onClosed={() => setShowFormDrawer(false)}
                />
            )}
        </>
    );
};
