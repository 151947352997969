import { Colors } from '../../core/theme/Colors';

export function getScoreClass(score: undefined | 0 | 1 | 2 | 3 | 4) {
    switch (score) {
        case 0:
            return 'insufficient';
        case 1:
            return 'weak';
        case 2:
            return 'medium';
        case 3:
            return 'strong';
        case 4:
            return 'very-strong';
        default:
            return 'unknown';
    }
}

export function getScoreColor(score: undefined | 0 | 1 | 2 | 3 | 4) {
    switch (score) {
        case 0:
            return Colors.Red;
        case 1:
            return Colors.RedOrangeLight;
        case 2:
            return Colors.Orange;
        case 3:
            return Colors.Green;
        case 4:
            return Colors.GreenDark2;
        default:
            return Colors.SecondaryLightest;
    }
}
