import React from 'react';
import ImgAddEmployeeForm1 from '../../assets/images/help/add-employee/add-form-1.png';
import ImgAddEmployeeForm2 from '../../assets/images/help/add-employee/add-form-2.png';
import ImgAddEmployeeOnboardingDoc1 from '../../assets/images/help/add-employee/onboarding-doc-1.jpg';
import ImgAddEmployeeOnboardingDoc2 from '../../assets/images/help/add-employee/onboarding-doc-2.jpg';
import ImgOnboardingEmployeeEmail from '../../assets/images/help/add-employee/onboarding-employee-email.jpg';
import ImgOnboardingEmployeeView1 from '../../assets/images/help/add-employee/onboarding-employee-view-1.png';
import ImgOnboardingEmployeeView2 from '../../assets/images/help/add-employee/onboarding-employee-view-2.png';
import ImgOnboardingEmployeeView3 from '../../assets/images/help/add-employee/onboarding-employee-view-3.png';
import ImgAddEmployeeStart from '../../assets/images/help/add-employee/start.png';
import ImgAddEmployeeSuccess from '../../assets/images/help/add-employee/success.png';
import ImgAddEmployeeUserMenuOverview from '../../assets/images/help/add-employee/user-menu-overview.png';
import ImgAddEmployeeUserMenuProfile from '../../assets/images/help/add-employee/user-menu-profile.png';
import { ContentGridContainer } from '../../common/components/layout/ContentGridContainer';
import { GridItem } from '../../common/components/layout/GridItem';
import { Paths } from '../../core/navigation/paths';
import { HelpFigure } from '../common/HelpFigure';
import { HelpLightbox } from '../common/HelpLightbox';

const Images = [
    {
        src: ImgAddEmployeeStart,
        alt: 'Schritt 1: Button "Hinzufügen" auf Mitarbeiterübersichtsseite klicken',
        title: '"Hinzufügen"-Button auf der Mitarbeiterübersichtsseite',
        description:
            'Klicken Sie auf den "Hinzufügen"-Button auf der Mitarbeiterübersichtsseite'
    },
    {
        src: ImgAddEmployeeForm1,
        alt: 'Schritt 2.1: Formular zum Hinzufügen eines Mitarbeiters',
        title: 'Formular zum Hinzufügen eines Mitarbeiters',
        description:
            'Füllen Sie das Formular mit den Daten Ihres neuen Mitarbeiters aus'
    },
    {
        src: ImgAddEmployeeForm2,
        alt: 'Schritt 2.2: Ausgefülltes Formular zum Hinzufügen eines Mitarbeiters',
        title: 'Ausgefülltes Formular zum Hinzufügen eines Mitarbeiters',
        description:
            'Klicken Sie auf den "Mitarbeiter:in anlegen"-Button rechts unten'
    },
    {
        src: ImgAddEmployeeSuccess,
        alt: 'Schritt 3: Erfolgsmeldung nach dem Hinzufügen eines Mitarbeiters',
        title: 'Mitarbeiter wurde erfolgreich hinzugefügt',
        description: ''
    },
    {
        src: ImgAddEmployeeUserMenuOverview,
        alt: '3-Punkte-Menü auf der Mitarbeiterübersichtsseite',
        title: '3-Punkte-Menü auf der Mitarbeiterübersichtsseite',
        description:
            'Klicken Sie im 3-Punkte-Menü auf "Onboarding-Dokument anzeigen". Das Dokument öffnet sich in einem neuen Fenster.'
    },
    {
        src: ImgAddEmployeeUserMenuProfile,
        alt: '3-Punkte-Menü auf der Profilseite des Mitarbeiters',
        title: '3-Punkte-Menü auf der Profilseite des Mitarbeiters',
        description:
            'Klicken Sie im 3-Punkte-Menü auf "Onboarding-Dokument anzeigen". Das Dokument öffnet sich in einem neuen Fenster.'
    },
    {
        src: ImgAddEmployeeOnboardingDoc1,
        alt: 'Vorschau des Onboarding-Dokuments für Hans Meier',
        title: 'Vorschau des Onboarding-Dokuments',
        description:
            'Klicken Sie auf den "Seite ausdrucken"-Button um den Druck-Dialog Ihres Browsers zu öffnen.'
    },
    {
        src: ImgAddEmployeeOnboardingDoc2,
        alt: 'Druck-Dialog des Onboarding-Dokuments für Hans Meier',
        title: 'Druck-Dialog des Onboarding-Dokuments',
        description:
            'Druck-Dialog des Onboarding-Dokuments für Hans Meier (hier im Browser "Google Chrome")'
    },
    {
        src: ImgOnboardingEmployeeEmail,
        alt: 'E-Mail mit Einladungslink für den neuen Mitarbeiter',
        title: 'E-Mail mit Einladungslink für den neuen Mitarbeiter',
        description: 'Diese E-Mail bekommt ihr neuer Mitarbeiter von uns.'
    },
    {
        src: ImgOnboardingEmployeeView1,
        alt: 'Willkommensseite für den neuen Mitarbeiter',
        title: 'Willkommensseite für den neuen Mitarbeiter',
        description:
            'Auf der Willkommensseite muss ihr neuer Mitarbeiter ein Passwort festlegen.'
    },
    {
        src: ImgOnboardingEmployeeView2,
        alt: 'Ihr neuer Mitarbeiter wählt ein Passwort',
        title: 'Ihr neuer Mitarbeiter wählt ein Passwort'
    },
    {
        src: ImgOnboardingEmployeeView3,
        alt: 'Aktivierung erfolgreich: Der Mitarbeiter kann Dajeh jetzt benutzen',
        title: 'Aktivierung erfolgreich',
        description:
            'Das Konto des Mitarbeiters wurde erfolgreich aktiviert! Ihr neuer Mitarbeiter kann Dajeh jetzt benutzen.'
    }
].map((image, index) => ({
    ...image,
    title: `Bild ${index + 1}: ${image.title}`,
    index
}));

const ImageMap = Images.reduce((acc, image) => {
    acc[image.src] = image;
    return acc;
}, {});

export const AddEmployeeHelpContent = () => {
    const [open, setOpen] = React.useState(false);
    const [currentImageSrc, setCurrentImageSrc] =
        React.useState<string | undefined>();

    const lightboxImageIndexNum = ImageMap[currentImageSrc || '']?.index || 0;

    function openLightbox(imageSrc: string) {
        setCurrentImageSrc(imageSrc);
        setOpen(true);
    }

    return (
        <>
            <ContentGridContainer>
                <GridItem sm={6}>
                    <h3 style={{ marginTop: 0 }}>1 – Start</h3>
                    <p>
                        Um einen neuen Mitarbeiter anzulegen, klicken Sie auf
                        den Button „Hinzufügen“ auf der{' '}
                        <a href={Paths.EmployeeList}>
                            Mitarbeiterübersichtsseite
                        </a>
                        .
                    </p>
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeStart}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
            </ContentGridContainer>
            <hr />

            <h3 style={{ marginTop: 0 }}>2 – Formular ausfüllen</h3>
            <p>
                Tragen Sie die Mitarbeiterinformationen für Ihr neues
                Teammitglied ein und klicken Sie auf den "Mitarbeiter:in
                anlegen" Button.
            </p>
            <ContentGridContainer>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeForm1}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeForm2}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
            </ContentGridContainer>

            <hr />

            <ContentGridContainer>
                <GridItem sm={6}>
                    <h3 style={{ marginTop: 0 }}>3 – Fertig!</h3>
                    <p>
                        Ihr neuer Mitarbeiter bekommt in Kürze eine E-Mail mit
                        einem Link zur Aktivierung des Nutzerkontos und kann
                        sich dann selbst ein Passwort vergeben. <br />
                        <br />
                        Zusätzlich haben wir ein Onboarding-Dokument für ihr
                        neues Team-Mitglied erstellt wenn die angegebene E-Mail
                        Adresse noch nicht aktiv ist (siehe Schritt 4).
                        <br />
                        <br />
                        Bis das Nutzerkonto aktiviert wurde, sehen Sie in der
                        Mitarbeiterübersicht und der Profilseite den Status
                        "Eingeladen".
                    </p>
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeSuccess}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
            </ContentGridContainer>

            <hr />

            <h3 style={{ marginTop: 0 }}>
                (Optional) Onboarding-Dokument ausdrucken oder speichern
            </h3>
            <p>
                Das Onboarding-Dokument für Ihren neuen Mitarbeiter kann im
                3-Punkte-Menü auf der Mitarbeiterübersichtsseite als auch im
                Profil des Mitarbeiters geöffnet werden. Es öffnet sich in einem
                neuen Fenster und kann ausgedruckt, oder im PDF-Format über
                einen anderen Kanal wie WhatsApp oder Signal an den Mitarbeiter
                gesendet werden.
            </p>
            <ContentGridContainer>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeUserMenuOverview}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeUserMenuProfile}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeOnboardingDoc1}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgAddEmployeeOnboardingDoc2}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
            </ContentGridContainer>

            <hr />

            <h3 style={{ marginTop: 0 }}>
                Aktivierung des Nutzerkontos (aus Sicht des neuen Mitarbeiters)
            </h3>
            <p>
                Unten sehen Sie die E-Mail, die Ihr neuer Mitarbeiter erhält und
                die Schritte zur Aktivierung des Kontos.
            </p>

            <ContentGridContainer>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgOnboardingEmployeeEmail}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgOnboardingEmployeeView1}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgOnboardingEmployeeView2}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
                <GridItem sm={6}>
                    <HelpFigure
                        src={ImgOnboardingEmployeeView3}
                        imageMap={ImageMap}
                        onClick={openLightbox}
                    />
                </GridItem>
            </ContentGridContainer>

            <HelpLightbox
                open={open}
                onClose={() => setOpen(false)}
                slides={Images}
                index={lightboxImageIndexNum}
            />
        </>
    );
};
