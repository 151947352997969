import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { SelectProps } from '@mui/material/Select/Select';
import React from 'react';
import { TimeReportRangeSelectOption } from './timeReportRangeSelectOption';

export const TimeReportTimeRangeSelect = (
    props: SelectProps<TimeReportRangeSelectOption>
) => {
    return (
        <Select variant="standard" {...props}>
            <MenuItem value="this_week">Diese Woche</MenuItem>
            <MenuItem value="this_month">Dieser Monat</MenuItem>
            <MenuItem value="last_week">Letzte Woche</MenuItem>
            <MenuItem value="last_month">Letzter Monat</MenuItem>
        </Select>
    );
};
