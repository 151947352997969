import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { ReactElement } from 'react';
import { Colors } from '../../core/theme/Colors';
import {
    formatDayDate,
    formatSecondsAsTimeDuration,
    toDateTime
} from '../time';

const GroupCt = styled.div<{ isEmpty?: boolean }>`
    ${props => (props.isEmpty ? `color: ${Colors.TextLight};` : '')}

    &:after {
        content: ' ';
        display: table;
        clear: both;
    }
`;

const fontCss = css`
    font-weight: 700;
    letter-spacing: 0;
    font-size: 16px;
    line-height: 22px;

    @media (min-width: 600px) {
        font-size: 20px;
        line-height: 26px;
    }

    @media (min-width: 960px) {
        font-size: 24px;
        line-height: 28px;
    }
`;

const mediumFontCss = css`
    @media (min-width: 600px) {
        font-size: 18px;
        line-height: 24px;
    }

    @media (min-width: 960px) {
        font-size: 20px;
        line-height: 26px;
    }
`;

const smallFontCss = css`
    @media (min-width: 600px) {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
    }

    @media (min-width: 960px) {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
    }
`;

const GroupLabel = styled.h3<{ size?: 'small' | 'medium' | 'large' }>`
    float: left;
    margin: 0;
    ${fontCss};
    ${props =>
        props.size === 'small'
            ? smallFontCss
            : props.size === 'medium'
            ? mediumFontCss
            : ''}
`;

const GroupTotalTime = styled.div<{ size?: 'small' | 'medium' | 'large' }>`
    float: right;
    ${fontCss};
    ${props =>
        props.size === 'small'
            ? smallFontCss
            : props.size === 'medium'
            ? mediumFontCss
            : ''}
`;

export function getOfficialDateLabel(date: string | number | Date | DateTime) {
    return formatDayDate(toDateTime(date), true);
}

export function getPersonalDateLabel(date: string | number | Date | DateTime) {
    return formatDayDate(toDateTime(date));
}

export const TimeEntryGroupHeader = ({
    label,
    totalSeconds,
    size,
    isEmpty
}: {
    label: string | ReactElement;
    totalSeconds: number | false | null | undefined | (() => ReactElement);
    size?: 'small' | 'medium' | 'large';
    isEmpty?: boolean;
}) => {
    let renderedTotalSeconds =
        typeof totalSeconds === 'function'
            ? totalSeconds()
            : formatSecondsAsTimeDuration(totalSeconds);
    // Render
    return (
        <GroupCt isEmpty={isEmpty}>
            <GroupLabel size={size}>{label}</GroupLabel>
            <GroupTotalTime size={size}>{renderedTotalSeconds}</GroupTotalTime>
        </GroupCt>
    );
};
