import styled from '@emotion/styled';
import { Box, Card, CardProps } from '@mui/material';

const StyledBox = styled(Box)`
    > p:first-child {
        margin-top: 0;
    }

    > p:last-child {
        margin-bottom: 0;
    }
`;

export const ContentCard = ({ children, ...otherProps }: CardProps) => (
    <Card {...otherProps}>
        <StyledBox sx={{ padding: '24px' }}>{children}</StyledBox>
    </Card>
);
