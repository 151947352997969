import styled from '@emotion/styled';
import { ContentCard } from '../layout/ContentCard';

export const OnPageSidebar = styled(ContentCard)`
    .MuiBox-root {
        padding: 24px;
    }

    .MuiButton-root {
        display: block;
        width: 100%;
    }

    .MuiButton-root + .MuiButton-root {
        margin-top: 8px;
    }

    a + a {
        margin-top: 8px;
    }

    ul {
        padding-left: 1.1em;
    }
`;
export const OnPageSidebarNav = styled.nav`
    margin: -6px -12px;
`;
