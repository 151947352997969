import { Breadcrumbs, Link } from '@mui/material';
import React from 'react';
import { Paths } from '../core/navigation/paths';
import { SecondaryAppBar } from '../core/navigation/SecondaryNavBarWrapper';

export const EmployeePageWrapper = ({ breadcrumbs, children }) => (
    <>
        <SecondaryAppBar paddings="small" initialHeight={45}>
            <Breadcrumbs aria-label="breadcrumb">
                {[
                    <Link
                        key={'0'}
                        underline="hover"
                        color="inherit"
                        href={Paths.EmployeeList}>
                        Mitarbeiter
                    </Link>,
                    ...breadcrumbs
                ]}
            </Breadcrumbs>
        </SecondaryAppBar>

        {children}
    </>
);
