import styled from '@emotion/styled';
import {
    AxisConfig,
    BarChartProps,
    BarPlot,
    ChartsGrid,
    ChartsTooltip,
    ChartsXAxis,
    ChartsYAxis,
    ResponsiveChartContainer
} from '@mui/x-charts';
import { ResponsiveChartContainerProps } from '@mui/x-charts/ResponsiveChartContainer/ResponsiveChartContainer';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import { useComponentDimensions } from '../../core/hooks/useComponentDimensions';
import { Colors } from '../../core/theme/Colors';
import {
    getFromTo,
    getRangeUnit,
    TimeReportRangeSelectOption
} from '../../timeTracking/report/timeReportRangeSelectOption';
import {
    formatSecondsAsTimeDuration,
    toDateTime
} from '../../timeTracking/time';

const StyledBarChartCt = styled.div<{ isSkeleton?: boolean }>`
    padding-bottom: 16px;

    .MuiBarElement-root {
        fill: ${props =>
            props.isSkeleton ? Colors.SecondaryLightest : Colors.Primary};
    }

    .MuiChartsGrid-horizontalLine {
        fill: #c6c6c8;
    }

    .MuiChartsAxis-tickLabel {
        fill: ${Colors.TextLighter};
    }

    .MuiChartsAxis-tick {
        display: none;
    }

    .MuiChartsAxis-line {
        display: none;
    }

    .MuiChartsLegend-series {
        display: none;
    }

    .MuiChartsAxisHighlight-root {
        ${props => (props.isSkeleton ? 'display: none;' : '')}
    }
`;

const BarChartTooltipCt = styled.div`
    padding: 8px 16px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid ${Colors.LineColor};
`;

function getRandomHours() {
    return 4 + 4 * Math.random();
}

function getFakeData(dateRange: TimeReportRangeSelectOption) {
    const range = getFromTo(dateRange);
    const unit = getRangeUnit(dateRange);
    return Array.from(
        { length: unit === 'week' ? 5 : range.from.daysInMonth! },
        (_, i) => {
            const day = range.from.plus({ days: i });
            return {
                day: day.toJSDate(),
                hours: getRandomHours()
            };
        }
    );
}

function getChartConfig(
    data: { day: Date; hours: number }[],
    dateRange: TimeReportRangeSelectOption,
    isSkeleton: boolean
): ResponsiveChartContainerProps & BarChartProps {
    const rangeUnit = getRangeUnit(dateRange);

    return {
        xAxis: [
            {
                scaleType: 'band',
                id: 'day',
                dataKey: 'day',
                categoryGapRatio: rangeUnit === 'week' ? 0.35 : 0.4,
                valueFormatter: day => {
                    const dateTime = DateTime.fromJSDate(day);
                    if (rangeUnit === 'week') {
                        return `${dateTime.toFormat(
                            'ccc'
                        )}\n${dateTime.toFormat('dd.MM.')}`;
                    }

                    return dateTime.toFormat('d');
                }
            } as AxisConfig<'band'>
        ],
        yAxis: [
            {
                id: 'hours',
                scaleType: 'linear',
                min: 0,
                max: 8,
                position: 'right',
                tickMinStep: 1,
                tickMaxStep: 2,
                dataKey: 'hours',
                valueFormatter: v => `${v}h`
            }
        ],
        series: [
            {
                type: 'bar',
                dataKey: 'hours',
                label: 'Arbeitszeit',
                color: Colors.PrimaryDark,
                valueFormatter: v => formatSecondsAsTimeDuration(v! * 3600)
            }
        ],
        margin: {
            top: 16,
            left: 44,
            right: 24
        },
        height: 256,
        dataset: data
    };
}

export const EmployeeWorkTimeReportSummaryChart = ({
    chartData,
    dateRange
}: {
    chartData?: { day: Date; hours: number }[];
    dateRange: TimeReportRangeSelectOption;
}) => {
    const componentRef = useRef(null);
    const innerWidth = useComponentDimensions(componentRef);

    const isSkeleton = !chartData;
    const _chartData = isSkeleton ? getFakeData(dateRange) : chartData;

    return (
        <StyledBarChartCt isSkeleton={isSkeleton} ref={componentRef}>
            <ResponsiveChartContainer
                {...getChartConfig(_chartData, dateRange, isSkeleton)}
                width={innerWidth.width || 300}
                disableAxisListener={isSkeleton}>
                <ChartsGrid horizontal={true} />
                <ChartsXAxis disableTicks={true} tickFontSize={14} />
                <ChartsYAxis disableTicks={true} tickFontSize={14} />
                {/* TODO render calendar week line */}
                {/*<ChartsReferenceLine*/}
                {/*    x={new Date(2023, 8, 2, 9)}*/}
                {/*    lineStyle={{ strokeDasharray: '10 5' }}*/}
                {/*    labelStyle={{ fontSize: '10' }}*/}
                {/*    label={`Wake up\n9AM`}*/}
                {/*    labelAlign="start"*/}
                {/*/>*/}
                {/*<ChartsReferenceLine x={new Date(2023, 8, 2, 9)} />*/}
                <BarPlot />
                {!isSkeleton && (
                    <ChartsTooltip
                        slots={{
                            axisContent: (props, index) => {
                                const { series, dataIndex, axisData } = props;
                                const date = axisData?.x?.value;
                                const hours =
                                    (series[0].data[dataIndex!] as number) || 0;

                                return (
                                    <BarChartTooltipCt>
                                        <div>
                                            {toDateTime(date)?.toFormat(
                                                'cccc, d. MMMM yyyy'
                                            )}
                                        </div>
                                        <div>
                                            <strong>
                                                {formatSecondsAsTimeDuration(
                                                    hours * 3600
                                                )}
                                            </strong>
                                        </div>
                                    </BarChartTooltipCt>
                                );
                            }
                        }}
                    />
                )}
            </ResponsiveChartContainer>
        </StyledBarChartCt>
    );
};
