import React, { ReactElement } from 'react';
import { useGlobalEmployeeEditForm } from '../core/hooks/useGlobalDrawerContext';

type ActivateUserButtonWrapperProps = {
    renderButton: (
        edit: (userId: string, onChange?: () => void) => void
    ) => ReactElement;
};

export const EditUserButtonWrapper = ({
    renderButton
}: ActivateUserButtonWrapperProps) => {
    const { edit } = useGlobalEmployeeEditForm();
    return <>{renderButton(edit)}</>;
};
