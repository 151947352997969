import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../common/components/button/PrimaryButton';
import { getShortPollInterval } from '../core/environment';
import { useLocalDate } from '../core/hooks/useLocalDate';
import { Paths } from '../core/navigation/paths';
import { Colors } from '../core/theme/Colors';
import { MarkAllNotificationsAsReadButtonWrapper } from './MarkAllNotificationsAsReadButtonWrapper';
import { NotificationsPopoverItems } from './NotificationsPopoverItems';
import { useNotifications } from './useNotifications';

const NotificationsHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid #e0e0e0;
`;

const NotificationsFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border-top: 1px solid #e0e0e0;
`;

const NotificationsHeadline = styled.h3`
    margin: 0;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
`;

const NotificationsItemCt = styled.div`
    max-height: 600px;
    width: 90vw;
    max-width: 550px;
    overflow-y: auto;
`;

const MarkAllAsReadButton = styled.button`
    background: none;
    border: 0;
    color: ${Colors.Link};
    cursor: pointer;
    font-size: 14px;
    outline: 0;
    padding: 4px;
    margin: 0 -4px;
    border-radius: 2px;
    text-decoration: underline;

    svg {
        float: left;
        margin-right: 3px;
        width: 16px;
        height: 16px;
    }

    &:hover,
    &:focus {
        color: ${Colors.LinkHover};
    }

    &:focus {
        outline: 2px solid ${Colors.LinkHover};
    }

    &:active {
        color: ${Colors.LinkActive};
    }
`;

const ClosePopupButton = styled.button`
    border: 0;
    outline: 0;
    margin: -4px 0;
    padding: 4px;
    transition: background-color 0.2s;
    background-color: white;
    border-radius: 16px;

    svg {
        display: block;
        width: 24px;
        height: 24px;
        fill: ${Colors.SecondaryDark};
    }

    &:hover,
    &:focus,
    &:active {
        svg {
            fill: ${Colors.SecondaryDarker};
        }

        background: ${Colors.SecondaryLightest};
    }
`;

export const NotificationsPopoverContent = ({
    hasUnreadNotifications,
    onClose,
    open
}: {
    hasUnreadNotifications: boolean;
    onClose: () => void;
    open: boolean;
}) => {
    const localDate = useLocalDate();

    const {
        notifications,
        error,
        loadingInitialPage,
        hasMore,
        loadNextPage,
        loadingNextPage,
        refresh,
        startPolling,
        stopPolling
    } = useNotifications(5, 5);

    const navigate = useNavigate();

    useEffect(() => {
        if (!loadingInitialPage && open) {
            refresh();
        }
        if (open) {
            startPolling(getShortPollInterval());
        } else {
            stopPolling();
        }
    }, [loadingInitialPage, open]);

    return (
        <Fragment key={`notifications-${localDate}`}>
            <NotificationsHeader>
                <NotificationsHeadline>
                    Benachrichtigungen
                </NotificationsHeadline>
                <ClosePopupButton onClick={onClose}>
                    <CloseIcon />
                </ClosePopupButton>
            </NotificationsHeader>
            <NotificationsItemCt>
                <NotificationsPopoverItems
                    notifications={notifications}
                    error={error}
                    isFetching={loadingInitialPage}
                    hasMore={hasMore}
                    loadNextPage={loadNextPage}
                    loadingNextPage={loadingNextPage}
                />
            </NotificationsItemCt>
            {!loadingInitialPage &&
                !error &&
                notifications &&
                notifications.length > 0 && (
                    <NotificationsFooter>
                        <PrimaryButton
                            onClick={() => {
                                navigate(Paths.UserNotifications);
                                onClose();
                            }}
                            size={'small'}>
                            Alle anzeigen
                        </PrimaryButton>
                        {hasUnreadNotifications && (
                            <MarkAllNotificationsAsReadButtonWrapper
                                renderButton={(markAllAsRead, isBusy) => (
                                    <MarkAllAsReadButton
                                        onClick={markAllAsRead}
                                        disabled={isBusy}>
                                        <DoneAllIcon /> Alle als gelesen
                                        markieren
                                    </MarkAllAsReadButton>
                                )}
                            />
                        )}
                    </NotificationsFooter>
                )}
        </Fragment>
    );
};
