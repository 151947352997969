import { useApolloClient } from '@apollo/client';
import styled from '@emotion/styled';
import React from 'react';
import { ProtectedPage } from '../core/auth/components/ProtectedPage';
import { PageBody } from '../core/components/page/PageBody';
import { TimeTrackingBar } from './active/TimeTrackingBar';
import { AddNewTimeEntryButton } from './AddNewTimeEntryButton';
import {
    GET_MY_ACTIVE_TIME_TRACKING,
    QUERY_MY_RECENT_TIME_ENTRIES
} from './queries';
import { RecentTimeEntries } from './recent/RecentTimeEntries';

const AddTimeEntryButtonWrapper = styled.div`
    position: relative;
    z-index: 1;
`;

export const TimeTrackingPage = () => {
    const client = useApolloClient();

    // TODO This is crap. Replace by an event bus

    function onTimeEntryAdded() {
        client.refetchQueries({ include: [QUERY_MY_RECENT_TIME_ENTRIES] });
    }

    function onActiveTimeTrackingChanged() {
        client.refetchQueries({ include: [QUERY_MY_RECENT_TIME_ENTRIES] });
    }

    function onTimeEntryChanged() {
        client.refetchQueries({
            include: [QUERY_MY_RECENT_TIME_ENTRIES, GET_MY_ACTIVE_TIME_TRACKING]
        });
    }

    return (
        <ProtectedPage title="Zeiterfassung">
            <TimeTrackingBar onChanged={onActiveTimeTrackingChanged} />

            <PageBody>
                <AddTimeEntryButtonWrapper>
                    <AddNewTimeEntryButton onAdded={onTimeEntryAdded} />
                </AddTimeEntryButtonWrapper>
                <RecentTimeEntries onChanged={onTimeEntryChanged} />
            </PageBody>
        </ProtectedPage>
    );
};
