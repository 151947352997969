import styled from '@emotion/styled'
import { FieldInputProps } from 'formik/dist/types';
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { Colors } from '../../core/theme/Colors';

const DescriptionInputField = styled(TextareaAutosize)`
  display: block;
  outline: none;
  width: 100%;
  height: 5em;
  overflow: auto;
  border: 0;
  margin: 0;
  padding: 26px 24px;
  resize: none;
  font-family: source-sans-pro, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.Text};
  
  &:disabled {
    background: white;
    color: ${Colors.TextLight}
  }
  
  &::placeholder {
    color: ${Colors.TextPlaceholder};
  }
`;

export const DescriptionField = (props: FieldInputProps<string>) => (
    <DescriptionInputField placeholder="＋ Beschreibung hinzufügen" {...props} />
);
