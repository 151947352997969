import React from 'react';
import { GridContainer } from '../../common/components/layout/GridContainer';
import { GridItem } from '../../common/components/layout/GridItem';
import {
    OnPageSidebar,
    OnPageSidebarNav
} from '../../common/components/sidebar/OnPageSidebar';
import { Paths } from '../../core/navigation/paths';
import { SidebarNavLink } from '../../core/navigation/SidebarNavLink';

export const UserSettingsPageContentWrapper = ({ children }) => {
    return (
        <GridContainer>
            <GridItem md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                <OnPageSidebar>
                    <OnPageSidebarNav>
                        <SidebarNavLink
                            label="Allgemein"
                            to={Paths.UserSettings}
                        />
                    </OnPageSidebarNav>
                </OnPageSidebar>
            </GridItem>
            <GridItem md={9}>{children}</GridItem>
        </GridContainer>
    );
};
