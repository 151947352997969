import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { InternalRefetchQueryDescriptor } from '@apollo/client/core/types';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    LinearProgress,
    Menu,
    MenuItem
} from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';

import {
    FormDrawer,
    FormDrawerBody,
    FormDrawerBodyCt,
    FormDrawerCloseButton,
    FormDrawerFooter,
    FormDrawerHead,
    FormDrawerLabel,
    FormDrawerOptionsMenuCt,
    FormDrawerSection,
    FormDrawerTitle
} from '../../common/components/form/FormDrawer';
import { StopTimeTrackingCircledIcon } from '../../common/components/icons/StopTimeTrackingCircledIcon';
import { getResponseErrorMessage } from '../../core/api/error';
import { User } from '../../core/auth/user';
import { useUser } from '../../core/auth/useUser';
import { Colors } from '../../core/theme/Colors';
import { executeSafe, OnNodeChangeFn } from '../../utils';
import { TimeEntryAuditLogDialog } from '../auditLog/TimeEntryAuditLogDialog';
import { showTimeEntryDeletionSuccessNotification } from '../notification/UndoDeletionNotification';
import {
    CREATE_TIME_ENTRY_MUTATION,
    DELETE_TIME_ENTRY_MUTATION,
    GET_MY_ACTIVE_TIME_TRACKING,
    GET_TIME_ENTRY_DETAILS,
    UPDATE_TIME_ENTRY_MUTATION
} from '../queries';
import {
    formatSecondsAsTimeDuration,
    getTimeEntrySummary,
    toDateTime
} from '../time';
import { TimeEntry, TimeEntryPayload } from '../TimeEntry';
import { TimeEntryService } from '../TimeEntryService';
import { DescriptionField } from './DescriptionField';
import { DurationField } from './DurationField';
import { EndDateErrorHelper } from './EndDateErrorHelper';
import { StartDateField } from './StartDateField';
import { TimeEntryFormDayBarrierBadge } from './TimeEntryFormDayBarrierBadge';
import { TimeEntryFormService } from './TimeEntryFormService';
import { TimeEntryFormValues } from './TimeEntryFormValues';
import { TimeField } from './TimeField';

const TimeInputContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 -8px;
`;

const TimeFieldContainer = styled.div`
    position: relative;
    z-index: 1;
    width: 50%;
    margin: 0 8px;
    flex-grow: 1;
`;

const TimeFieldLabel = styled.label`
    position: absolute;
    z-index: 2;
    top: 9px;
    left: 12px;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.TextLight};
`;

const StyledOptionsMenu = styled(Menu)`
    svg {
        fill: ${Colors.Secondary};
        margin-right: 12px;
        width: 20px;
        height: 20px;
    }

    .MuiDivider-root {
        margin: 8px 0;
        border-color: ${Colors.LineColor};
    }
`;

const DeleteMenuItem = styled(MenuItem)`
    font-weight: 600;
    color: ${Colors.Red};

    svg {
        fill: ${Colors.Red};
    }
`;

function getErrorMessage(error: ApolloError): string {
    return getResponseErrorMessage(error, apiError => {
        const code = apiError.getCode();
        const extensions = apiError.getExtensions();

        if (code === 'time_entry_overlap') {
            if (extensions.overlappedTimeEntry) {
                return `Der Eintrag überschneidet sich mit folgendem Eintrag: ${getTimeEntrySummary(
                    extensions.overlappedTimeEntry
                )}`;
            } else {
                return `Die Eintrag überschneidet sich mit einem anderen Eintrag.`;
            }
        }
        if (code === 'time_entry_already_deleted') {
            return `Der Eintrag wurde bereits gelöscht.`;
        }
    });
}

function getNewInitialFormValues(): TimeEntryFormValues {
    const now = DateTime.now();

    const nowDate = now.toISODate();
    const nowTime = now
        .set({ millisecond: 0 })
        .set({ second: 0 })
        .toISOTime({ includeOffset: false });

    return {
        isActiveTracking: false,
        startDate: nowDate,
        startTime: nowTime,
        endDate: nowDate,
        endTime: nowTime,
        duration: '0:00:00',
        description: ''
    };
}

function getEditInitialFormValues(timeEntry: TimeEntry): TimeEntryFormValues {
    const start = toDateTime(timeEntry.startDate)!.toLocal();
    const end = toDateTime(timeEntry.endDate)?.toLocal();

    return {
        isActiveTracking: !end,
        startDate: start.toISODate()!,
        startTime: start.toISOTime({ includeOffset: false })!,
        endDate: end ? end.toISODate()! : undefined,
        endTime: end
            ? end.toISOTime({
                  includeOffset: false
              })!
            : undefined,
        duration: end
            ? formatSecondsAsTimeDuration(timeEntry.totalSeconds)
            : undefined,
        description: timeEntry.description || ''
    };
}

function getInitialFormValues(timeEntry?: TimeEntry) {
    return !!timeEntry
        ? getEditInitialFormValues(timeEntry!)
        : getNewInitialFormValues();
}

const StyledLinearProgress = styled(LinearProgress)`
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
`;

type TimeEntryDrawerFormProps = {
    onClosed: () => void;
    onChanged?: OnNodeChangeFn;
    entry?: TimeEntry;
    owner?: User;
};

export const TimeEntryDrawerForm = ({
    owner,
    entry,
    onChanged,
    onClosed
}: TimeEntryDrawerFormProps) => {
    const [visible, setVisible] = useState(false);
    const [isPushing, setPushing] = useState(false);
    const [remoteError, setRemoteError] = useState('');
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
    const [optionsAnchorEl, setOptionsAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const [showChangeLog, setShowChangeLog] = useState(false);

    const formikRef = React.useRef<FormikProps<any>>(null);

    const { enqueueSnackbar } = useSnackbar();

    const actingUser = useUser();

    const {
        data: timeEntryData,
        refetch: refetchTimeEntryData,
        loading: fetchingFromServer
    } = useQuery(GET_TIME_ENTRY_DETAILS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            timeEntryId: entry?.id
        },
        skip: !entry?.id
    });

    const serverTimeEntry = timeEntryData?.node || entry;
    const isBusy = fetchingFromServer || isPushing;

    const actingUserId = actingUser?.id;
    const timeEntryId = entry?.id || serverTimeEntry?.id;

    const timeEntryOwnerId =
        serverTimeEntry?.owner?.id ||
        entry?.owner?.id ||
        owner?.id ||
        actingUserId;
    if (owner && timeEntryOwnerId && timeEntryOwnerId !== owner.id) {
        console.error(
            `Owner mismatch - Owner ${owner.id} given but entry owner is ${timeEntryOwnerId}`
        );
        return null;
    }

    const isEdit = !!entry;
    const isActiveTracking = isEdit && !serverTimeEntry?.endDate;
    const isActingUsersTimeEntry = timeEntryOwnerId === actingUserId;

    const isActiveTrackingOfCurrentUser =
        isActiveTracking && isActingUsersTimeEntry;

    const optionsMenuOpen = Boolean(optionsAnchorEl);

    const refetchQueries: InternalRefetchQueryDescriptor[] = [];
    if (isActiveTrackingOfCurrentUser) {
        refetchQueries.push(GET_MY_ACTIVE_TIME_TRACKING);
    }

    /* Create */
    const [createTimeEntryMutation] = useMutation(CREATE_TIME_ENTRY_MUTATION);

    /* Update */
    const [updateTimeEntryMutation] = useMutation(UPDATE_TIME_ENTRY_MUTATION, {
        refetchQueries: refetchQueries
    });

    /* Delete */
    const [deleteTimeEntryMutation] = useMutation(DELETE_TIME_ENTRY_MUTATION, {
        refetchQueries: refetchQueries
    });

    const initialValues = useMemo(() => {
        return getInitialFormValues(serverTimeEntry);
    }, [serverTimeEntry]);

    function create(
        start: string,
        end: string | undefined,
        description: string | undefined | null
    ): Promise<TimeEntryPayload> {
        return TimeEntryService.create(createTimeEntryMutation, {
            startDate: start,
            endDate: end,
            description,
            ownerId: timeEntryOwnerId
        });
    }

    function update(
        start: string,
        end: string | undefined,
        description: string | undefined | null
    ): Promise<TimeEntryPayload> {
        return TimeEntryService.update(
            updateTimeEntryMutation,
            serverTimeEntry!.id!,
            {
                startDate: start,
                endDate: end || undefined,
                description
            },
            {
                fetchPolicy: isActiveTracking ? 'network-only' : undefined
            }
        );
    }

    function stop() {
        if (isBusy) {
            return;
        }

        setPushing(true);

        return TimeEntryService.stopTimeTracking(
            updateTimeEntryMutation,
            serverTimeEntry!.id!,
            undefined,
            {
                fetchPolicy: 'network-only',
                refetchQueries: refetchQueries
            }
        )
            .then(payload => {
                refetchTimeEntryData();
                handleCloseOptionsMenu();
                executeSafe(onChanged, 'update', payload.timeEntry);
            })
            .finally(() => {
                setPushing(false);
            });
    }

    function submit(values, actions) {
        if (isBusy) {
            return;
        }

        setPushing(true);

        const timeEntryInput = TimeEntryFormService.convertToInput(values);
        (isEdit ? update : create)(
            timeEntryInput.startDate,
            timeEntryInput.endDate,
            timeEntryInput.description
        )
            .then(payload => {
                enqueueSnackbar(
                    `Eintrag ${isEdit ? 'aktualisiert' : 'erstellt'}`,
                    { variant: 'success' }
                );
                closeDrawer();

                executeSafe(
                    onChanged,
                    isEdit ? 'update' : 'create',
                    payload.timeEntry
                );
            })
            .catch(e => {
                setRemoteError(getErrorMessage(e));
            })
            .finally(() => {
                actions.setSubmitting(false);
                setPushing(false);
            });
    }

    function onDelete() {
        if (isBusy) {
            return;
        }

        handleCloseOptionsMenu();

        TimeEntryService.delete(deleteTimeEntryMutation, serverTimeEntry!.id!, {
            fetchPolicy: isActiveTracking ? 'network-only' : undefined
        })
            .then(payload => {
                closeDrawer();
                executeSafe(onChanged, 'delete', payload.timeEntry);
                showTimeEntryDeletionSuccessNotification(
                    enqueueSnackbar,
                    payload.timeEntry!,
                    (action, timeEntry) =>
                        executeSafe(onChanged, action, timeEntry)
                );
            })
            .catch(e => {
                setRemoteError(getErrorMessage(e));
            });
    }

    function closeDrawer() {
        setVisible(false);
        setTimeout(() => {
            onClosed();
        }, 200);
    }

    function requestClose() {
        if (!formikRef?.current?.dirty) {
            closeDrawer();
            return;
        }

        setShowCloseConfirmation(true);
    }

    const handleOptionsMenuToggleClick = (
        event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => {
        setOptionsAnchorEl(event.currentTarget);
    };
    const handleCloseOptionsMenu = () => {
        setOptionsAnchorEl(null);
    };

    const onShowChangeLog = () => {
        setShowChangeLog(true);
        handleCloseOptionsMenu();
    };

    useEffect(() => {
        setVisible(true);
    }, []);

    return (
        <>
            <FormDrawer
                anchor="right"
                open={visible}
                onClose={requestClose}
                variant="temporary">
                <Formik<TimeEntryFormValues>
                    initialValues={initialValues}
                    validate={TimeEntryFormService.validate}
                    onSubmit={submit}
                    enableReinitialize={true}
                    innerRef={formikRef}>
                    <Form>
                        {/* For debugging */}
                        {/*<FormDebuggerCt>*/}
                        {/*    <FormDebugger />*/}
                        {/*</FormDebuggerCt>*/}

                        <FormDrawerHead>
                            <FormDrawerTitle>
                                {isEdit
                                    ? 'Eintrag bearbeiten'
                                    : 'Eintrag erstellen'}
                            </FormDrawerTitle>
                            <FormDrawerCloseButton
                                type="button"
                                onClick={requestClose}>
                                <CloseIcon />
                            </FormDrawerCloseButton>
                            {fetchingFromServer && <StyledLinearProgress />}
                        </FormDrawerHead>

                        <FormDrawerBodyCt>
                            <FormDrawerBody>
                                {/* Hint for adding / editing time entries for/of another user */}
                                {!isActingUsersTimeEntry && (
                                    <>
                                        <Alert severity="info">
                                            {isEdit
                                                ? 'Eintrag von Mitarbeiter'
                                                : 'Eintrag wird für Mitarbeiter'}{' '}
                                            <strong>
                                                {owner?.name ||
                                                    entry?.owner?.name}
                                            </strong>
                                            {isEdit ? '' : ' hinzugefügt'}
                                        </Alert>
                                        {isEdit && (
                                            <Alert severity="warning">
                                                <strong>Hinweis:</strong>{' '}
                                                Änderungen können im
                                                Änderungsprotokoll eingesehen
                                                werden.
                                            </Alert>
                                        )}
                                    </>
                                )}

                                {remoteError && (
                                    <Alert severity="error">
                                        {remoteError}
                                    </Alert>
                                )}

                                {isEdit && (
                                    <FormDrawerOptionsMenuCt>
                                        <Button
                                            variant={'text'}
                                            onClick={
                                                handleOptionsMenuToggleClick
                                            }
                                            endIcon={<ExpandMoreIcon />}
                                            disableRipple>
                                            Optionen
                                        </Button>
                                        <StyledOptionsMenu
                                            id="options-menu"
                                            anchorEl={optionsAnchorEl}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: -12
                                            }}
                                            open={optionsMenuOpen}
                                            onClose={handleCloseOptionsMenu}>
                                            {isActiveTracking && (
                                                <MenuItem onClick={stop}>
                                                    <StopTimeTrackingCircledIcon />
                                                    Zeiterfassung stoppen
                                                </MenuItem>
                                            )}
                                            <MenuItem onClick={onShowChangeLog}>
                                                <HistoryIcon />
                                                Änderungsprotokoll anzeigen
                                            </MenuItem>
                                            <Divider />
                                            <DeleteMenuItem onClick={onDelete}>
                                                Löschen
                                            </DeleteMenuItem>
                                        </StyledOptionsMenu>
                                    </FormDrawerOptionsMenuCt>
                                )}

                                <FormDrawerSection>
                                    <FormDrawerLabel htmlFor="aef_startDate">
                                        Datum
                                    </FormDrawerLabel>
                                    <StartDateField
                                        id="aef_startDate"
                                        name="startDate"
                                        disabled={isBusy}
                                    />
                                </FormDrawerSection>

                                <FormDrawerSection>
                                    <FormDrawerLabel>Uhrzeit</FormDrawerLabel>

                                    <TimeInputContainer>
                                        <TimeFieldContainer>
                                            <TimeFieldLabel htmlFor="aef_startTime">
                                                Start
                                            </TimeFieldLabel>
                                            <TimeField
                                                id="aef_startTime"
                                                name="startTime"
                                                disabled={isBusy}
                                            />
                                        </TimeFieldContainer>

                                        {!isActiveTracking && (
                                            <TimeFieldContainer>
                                                <TimeEntryFormDayBarrierBadge>
                                                    <TimeFieldLabel htmlFor="aef_endTime">
                                                        Ende
                                                    </TimeFieldLabel>
                                                    <TimeField
                                                        id="aef_endTime"
                                                        name="endTime"
                                                        disabled={isBusy}
                                                    />
                                                    <EndDateErrorHelper />
                                                </TimeEntryFormDayBarrierBadge>
                                            </TimeFieldContainer>
                                        )}
                                    </TimeInputContainer>
                                </FormDrawerSection>

                                {!isActiveTracking && (
                                    <FormDrawerSection>
                                        <FormDrawerLabel htmlFor="aef_duration">
                                            Dauer
                                        </FormDrawerLabel>
                                        <DurationField
                                            id="aef_duration"
                                            name="duration"
                                            disabled={isBusy}
                                        />
                                    </FormDrawerSection>
                                )}

                                <Field
                                    id="aef_description"
                                    name="description"
                                    as={DescriptionField}
                                    disabled={isBusy}
                                />
                            </FormDrawerBody>
                        </FormDrawerBodyCt>

                        <FormDrawerFooter>
                            <SecondaryButton
                                onClick={requestClose}
                                style={{ float: 'left' }}
                                type="button"
                                disabled={isBusy}>
                                Abbrechen
                            </SecondaryButton>
                            <PrimaryButton type="submit" disabled={isBusy}>
                                Eintrag {isEdit ? 'aktualisieren' : 'erstellen'}
                            </PrimaryButton>
                        </FormDrawerFooter>
                    </Form>
                </Formik>
            </FormDrawer>

            <Dialog
                open={showCloseConfirmation}
                onClose={() => setShowCloseConfirmation(false)}
                aria-labelledby="aef-dirty-close-title"
                aria-describedby="aef-dirty-close-description">
                <DialogTitle id="aef-dirty-close-title">
                    {isEdit ? 'Änderungen' : 'Eintrag'} verwerfen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="aef-dirty-close-description">
                        Sie haben bereits {isEdit ? 'Änderungen' : 'Daten'} für
                        Ihren {isEdit ? '' : 'neuen'} Zeiteintrag hinterlegt.
                        Möchten Sie {isEdit ? 'die Änderungen' : 'den Eintrag'}{' '}
                        wirklich verwerfen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SecondaryButton
                        onClick={() => setShowCloseConfirmation(false)}>
                        Abbrechen
                    </SecondaryButton>
                    <PrimaryButton onClick={closeDrawer} autoFocus>
                        Fortfahren und verwerfen
                    </PrimaryButton>
                </DialogActions>
            </Dialog>

            <TimeEntryAuditLogDialog
                key={`${timeEntryId}-${serverTimeEntry?.updatedAt}`}
                timeEntryId={timeEntryId}
                open={showChangeLog}
                onClose={() => setShowChangeLog(false)}
            />
        </>
    );
};
