import { User } from './user';

const LS_KEY_AUTH_TOKEN = 'auth_token';
const LS_KEY_USER = 'user';
const LS_KEY_AUTHENTICATED = 'is_authenticated';
const LS_KEY_REDIRECT_TO = 'redirect_to';
const LS_KEY_SIGN_IN_EMAIL = 'auth_email';

const setAuthToken = (token: string | null) => {
    if (!token) {
        localStorage.removeItem(LS_KEY_AUTH_TOKEN);
        return;
    }

    localStorage.setItem(LS_KEY_AUTH_TOKEN, token);
};

const getAuthToken = (): string | null => {
    return localStorage.getItem(LS_KEY_AUTH_TOKEN);
};

const setUser = (user: User | null | undefined) => {
    if (!user) {
        localStorage.removeItem(LS_KEY_USER);
        return;
    }

    localStorage.setItem(LS_KEY_USER, JSON.stringify(user));
};

const getUser = (): User | null => {
    const userJson = localStorage.getItem(LS_KEY_USER);
    return userJson ? JSON.parse(userJson) : null;
};

const setAuthenticated = (isAuthenticated: boolean) => {
    if (!isAuthenticated) {
        localStorage.removeItem(LS_KEY_AUTHENTICATED);
        return;
    }

    localStorage.setItem(
        LS_KEY_AUTHENTICATED,
        Boolean(isAuthenticated).toString()
    );
};

const isAuthenticated = (): boolean => {
    return Boolean(localStorage.getItem(LS_KEY_AUTHENTICATED)).valueOf();
};

export const actingUserIsOwner = () => {
    const user = getUser();
    return user?.isOwner || false;
};

const setRedirectTo = (route: string | null) => {
    if (!route) {
        localStorage.removeItem(LS_KEY_REDIRECT_TO);
        return;
    }

    localStorage.setItem(LS_KEY_REDIRECT_TO, route);
};

const getRedirectTo = (): string | null => {
    return localStorage.getItem(LS_KEY_REDIRECT_TO);
};

export const setSignInEmail = (email: string) => {
    localStorage.setItem(LS_KEY_SIGN_IN_EMAIL, email);
};

export const getSignInEmail = (): string => {
    return localStorage.getItem(LS_KEY_SIGN_IN_EMAIL) || '';
};

export const AuthLocalStorage = {
    setAuthToken,
    getAuthToken,
    setAuthenticated,
    isAuthenticated,
    setUser,
    getUser,
    setRedirectTo,
    getRedirectTo,
    setSignInEmail,
    getSignInEmail
};
