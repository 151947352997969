import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    Observable
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { getAuthorizationBearer, refreshAccessToken } from '../auth';
import { AuthLocalStorage } from '../auth/authLocalStorage';
import {
    getAppClientName,
    getAppVersion,
    getServerBaseUrl
} from '../environment';
import { reSignIn } from './cache';
import { typeDefs } from './resolvers';

const GRAPHQL_API_URL = `${getServerBaseUrl()}/graphql`;

const httpLink = new HttpLink({
    uri: GRAPHQL_API_URL,
    credentials: 'include'
});

const authLink = setContext((_, { headers }) => {
    // add the authorization token to the headers
    const authToken = AuthLocalStorage.getAuthToken() || undefined;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: getAuthorizationBearer(authToken),
            'X-Client-Version': getAppVersion(),
            'X-Client': getAppClientName()
        }
    };
});

const refreshTokenLink = onError(
    ({ response, graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            for (let error of graphQLErrors) {
                const { extensions } = error;
                // @ts-ignore
                switch (extensions.code) {
                    case 'auth_access_token_expired':
                        // https://stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request/51321068#51321068
                        return new Observable(observer => {
                            refreshAccessToken()
                                .then(accessToken => {
                                    const oldHeaders =
                                        operation.getContext().headers;
                                    operation.setContext({
                                        headers: {
                                            ...oldHeaders,
                                            authorization:
                                                getAuthorizationBearer(
                                                    accessToken
                                                )
                                        }
                                    });
                                })
                                .then(() => {
                                    const subscriber = {
                                        next: observer.next.bind(observer),
                                        error: observer.error.bind(observer),
                                        complete:
                                            observer.complete.bind(observer)
                                    };

                                    // Retry last failed request
                                    forward(operation).subscribe(subscriber);
                                })
                                .catch(error => {
                                    observer.error(error);
                                });
                        });
                    case 'auth_access_token_invalid':
                    case 'auth_refresh_token_invalid':
                    case 'auth_refresh_token_expired':
                        // remove errors of response to prevent further errors on component lvl
                        // @ts-ignore
                        response.errors = null;
                        reSignIn(true);
                        break;
                }
            }
        }

        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
            // if you would also like to retry automatically on
            // network errors, we recommend that you use
            // @apollo/client/link/retry
        }
    }
);
const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    reSignIn: {
                        read() {
                            return reSignIn();
                        }
                    }
                }
            },
            User: {
                fields: {
                    timeEntries: relayStylePagination()
                }
            }
        }
    }),
    link: ApolloLink.from([authLink, refreshTokenLink, httpLink]),
    typeDefs
});

export default apolloClient;
