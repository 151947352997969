import styled from '@emotion/styled';
import { BarChart, WatchLater } from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import React from 'react';
import { generatePath } from 'react-router';
import { useParams } from 'react-router-dom';
import { User } from '../core/auth/user';
import { OnPageTabNavigation } from '../core/navigation/OnPageTabNavigation';
import { OnPageTabNavLink } from '../core/navigation/OnPageTabNavLink';
import { Paths } from '../core/navigation/paths';
import { Colors } from '../core/theme/Colors';

const ActiveTimeTrackingDotCt = styled.span`
    display: inline-block;
    position: relative;
    z-index: 1;
    height: 1em;
    margin-left: 0;

    .activity-dot {
        position: absolute;
        z-index: 2;
        top: -4px;
        left: -12px;
        width: 9px;
        height: 9px;
        background-color: ${Colors.Red};
        border-radius: 50%;
        opacity: 1;
        transition: opacity 0.2s, transform 0.2s;
        transform: scale(1);
        border: 1.5px solid ${Colors.Background};

        .active & {
            opacity: 0;
            transform: scale(0);
        }

        @media (min-width: 960px) {
            border-width: 2px;
            width: 12px;
            height: 12px;
            top: -6px;
            left: -16px;
        }
    }
`;

const ActiveTimeTrackingDot = () => {
    return (
        <ActiveTimeTrackingDotCt role="presentation">
            <span className="activity-dot"></span>
        </ActiveTimeTrackingDotCt>
    );
};

export const EmployeePageTabNavigation = ({ user }: { user?: User }) => {
    const params = useParams();
    const employeeId = user?.id || params?.employeeId || '#';

    return (
        <OnPageTabNavigation>
            <OnPageTabNavLink
                label="Profil"
                to={generatePath(Paths.EmployeeProfile, {
                    employeeId
                })}
                icon={<AccountBoxIcon />}
            />

            <OnPageTabNavLink
                label="Zeiterfassung"
                to={generatePath(Paths.EmployeeTimeTracking, {
                    employeeId
                })}
                icon={
                    <>
                        <WatchLater />
                        {user?.activeTimeTracking?.id && (
                            <ActiveTimeTrackingDot />
                        )}
                    </>
                }
            />

            <OnPageTabNavLink
                label="Bericht"
                to={generatePath(Paths.EmployeeReports, {
                    employeeId
                })}
                icon={<BarChart />}
            />
        </OnPageTabNavigation>
    );
};
