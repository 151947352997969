import { Breadcrumbs, Link } from '@mui/material';
import React from 'react';
import 'yet-another-react-lightbox/styles.css';
import { ContentCard } from '../common/components/layout/ContentCard';
import { GridContainer } from '../common/components/layout/GridContainer';
import { GridItem } from '../common/components/layout/GridItem';
import { HelpSectionHeadline } from './components/HelpSectionHeadline';
import { OnPageSidebar } from '../common/components/sidebar/OnPageSidebar';
import { PageBody } from '../core/components/page/PageBody';
import { PageHeader } from '../core/components/page/PageHeader';
import { PageSubHeadline } from '../core/components/page/PageSubHeadline';
import { PageHeadline } from '../core/components/page/PageHeadline';
import { Paths } from '../core/navigation/paths';
import { SecondaryAppBar } from '../core/navigation/SecondaryNavBarWrapper';
import { AddEmployeeHelpContent } from './content/AddEmployeeHelpConent';
import { RenewEmployeeInvitationHelpContent } from './content/RenewEmployeeInvitationHelpContent';
import { ResendEmployeeInvitationHelpContent } from './content/ResendEmployeeInvitationHelpContent';
import { HelpPageBase } from './HelpPageBase';

export const EmployeesHelpPage = () => {
    return (
        <HelpPageBase title={'Hilfe: Mitarbeiter- und Nutzerverwaltung'}>
            <SecondaryAppBar paddings="small" initialHeight={45}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href={Paths.Help}>
                        Hilfe
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href={Paths.HelpEmployees}>
                        Mitarbeiter- und Nutzerverwaltung
                    </Link>
                </Breadcrumbs>
            </SecondaryAppBar>

            <PageHeader>
                <PageHeadline>
                    Hilfe: Mitarbeiter- und Nutzerverwaltung
                </PageHeadline>
                <PageSubHeadline>
                    Hier finden Sie Hilfe zu allen Mitarbeiter- und
                    Nutzerfunktionen.
                </PageSubHeadline>
            </PageHeader>

            <PageBody>
                <GridContainer>
                    <GridItem md={3}>
                        <OnPageSidebar>
                            <h3 style={{ margin: 0 }}>Inhalt</h3>
                            <ul>
                                <li>
                                    <a href="#add">Hinzufügen</a>
                                </li>
                                <li>
                                    <a href="#edit">Bearbeiten</a>
                                </li>
                                <li>
                                    <a href="#resend-invitation">
                                        Einladung nochmal senden
                                    </a>
                                </li>
                                <li>
                                    <a href="#renew-invitation">
                                        Erneut einladen
                                    </a>
                                </li>
                                <li>
                                    <a href="#deactivate">Deaktivieren</a>
                                </li>
                                <li>
                                    <a href="#reactivate">Reaktivieren</a>
                                </li>
                            </ul>
                        </OnPageSidebar>
                    </GridItem>
                    <GridItem md={9}>
                        <HelpSectionHeadline first>
                            <a className={'section-anchor'} id={'add'}></a>
                            Mitarbeiter:in hinzufügen
                        </HelpSectionHeadline>
                        <ContentCard>
                            <AddEmployeeHelpContent />
                        </ContentCard>

                        <HelpSectionHeadline>
                            <a className={'section-anchor'} id={'edit'} />
                            Mitarbeiterdaten bearbeiten
                        </HelpSectionHeadline>
                        <ContentCard>Folgt in Kürze</ContentCard>

                        <HelpSectionHeadline>
                            <a
                                className={'section-anchor'}
                                id={'resend-invitation'}></a>
                            Einladung erneut versenden
                        </HelpSectionHeadline>
                        <ContentCard>
                            <ResendEmployeeInvitationHelpContent />
                        </ContentCard>

                        <HelpSectionHeadline>
                            <a
                                className={'section-anchor'}
                                id={'renew-invitation'}></a>
                            Mitarbeiter:in erneut einladen / Einladung
                            abgelaufen
                        </HelpSectionHeadline>
                        <ContentCard>
                            <RenewEmployeeInvitationHelpContent />
                        </ContentCard>

                        <HelpSectionHeadline>
                            <a className={'section-anchor'} id={'deactivate'} />
                            Mitarbeiter:in deaktivieren
                        </HelpSectionHeadline>
                        <ContentCard>Folgt in Kürze</ContentCard>

                        <HelpSectionHeadline>
                            <a className={'section-anchor'} id={'reactivate'} />
                            Mitarbeiter:in reaktivieren
                        </HelpSectionHeadline>
                        <ContentCard>Folgt in Kürze</ContentCard>
                    </GridItem>
                </GridContainer>
            </PageBody>
        </HelpPageBase>
    );
};
