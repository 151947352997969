import { DateTime } from 'luxon';
import { UserInvitation } from '../../core/auth/user';

/**
 * Checks if the invitation is expired.
 *
 * Better safe than sorry - returns false only if the valid until date is present and in the future, true otherwise
 * @param invitation
 */
export function isInvitationExpired(
    invitation: UserInvitation | null | undefined
): boolean {
    if (!invitation) {
        return true;
    }

    const { validUntil } = invitation;
    if (!validUntil) {
        return true;
    }

    const parsedDateTime = DateTime.fromISO(validUntil);
    if (!parsedDateTime.isValid) {
        return true;
    }

    return parsedDateTime.diffNow().valueOf() < 0;
}
