import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import SupervisorAccountIcon from '@mui/icons-material/PeopleAltOutlined';
import React from 'react';
import { Colors } from '../../core/theme/Colors';
import { getUserName } from '../../user';
import { formatDateTime } from '../time';
import { TimeEntry } from '../TimeEntry';
import { ItemSummaryItem } from './TimeEntry';

const ItemEditedByAdmin = styled.div`
    float: left;
    margin-top: -1px;

    svg {
        display: block;
        fill: ${Colors.SecondaryLight};
        width: 20px;
        height: 24px;
        transition: fill 0.2s;
    }

    &:hover {
        svg {
            fill: ${Colors.Secondary};
        }
    }
`;

export const TimeEntryEditedByAdminIcon = ({
    timeEntry
}: {
    timeEntry: TimeEntry;
}) => {
    const createdByAdmin = timeEntry.owner?.id !== timeEntry.createdBy?.id;
    const updatedByAdmin = timeEntry.owner?.id !== timeEntry.updatedBy?.id;

    if (!createdByAdmin && !updatedByAdmin) {
        return null;
    }

    const createdByDescription = !createdByAdmin ? null : (
        <>
            <strong>
                Erstellt von {getUserName(timeEntry.createdBy)} (Admin):
            </strong>
            <br />
            {formatDateTime(timeEntry.createdAt)}
        </>
    );

    const updatedIsCreated =
        timeEntry.updatedAt === timeEntry.createdAt &&
        timeEntry.updatedBy?.id === timeEntry.createdBy?.id;

    const updatedByDescription =
        !updatedByAdmin || updatedIsCreated ? null : (
            <>
                <strong>
                    Geändert von {getUserName(timeEntry.updatedBy)} (Admin):
                </strong>
                <br />
                {formatDateTime(timeEntry.updatedAt)}
            </>
        );

    return (
        <ItemSummaryItem>
            <Tooltip
                title={
                    <>
                        {createdByAdmin ? createdByDescription : null}
                        {createdByAdmin &&
                            updatedByAdmin &&
                            !updatedIsCreated && <hr />}
                        {updatedByAdmin ? updatedByDescription : null}
                    </>
                }>
                <ItemEditedByAdmin>
                    <SupervisorAccountIcon />
                </ItemEditedByAdmin>
            </Tooltip>
        </ItemSummaryItem>
    );
};
