import React from 'react';
import { ProtectedPage } from '../core/auth/components/ProtectedPage';
import { Permission } from '../core/auth/permission';
import { PageBody } from '../core/components/page/PageBody';
import { PageHeader } from '../core/components/page/PageHeader';
import { PageHeadline } from '../core/components/page/PageHeadline';
import { PageHeadlineWithActions } from '../core/components/page/PageHeadlineWithActions';
import { PageTitleActions } from '../core/components/page/PageTitleActions';
import { AddNewEmployeeButton } from './components/AddNewEmployeeButton';
import { EmployeeList } from './components/EmployeeList';

export const EmployeesPage = () => {
    return (
        <ProtectedPage
            title="Mitarbeiter"
            permissions={Permission.Manage_Users}>
            <PageHeader>
                <PageHeadlineWithActions>
                    <PageHeadline>Mitarbeiter</PageHeadline>
                    <PageTitleActions>
                        <AddNewEmployeeButton />
                    </PageTitleActions>
                </PageHeadlineWithActions>
            </PageHeader>

            <PageBody>
                <EmployeeList />
            </PageBody>
        </ProtectedPage>
    );
};
