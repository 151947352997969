import styled from '@emotion/styled';
import React from 'react';
import { getScoreClass, getScoreColor } from './utils';

const PasswordStrengthBarCt = styled.div`
  display: flex;
  gap: 6px;
  margin: 4px 0;

  &.is-insufficient :first-child {
    background-color: ${getScoreColor(0)};
  }

  &.is-weak :nth-child(-n+2) {
    background-color: ${getScoreColor(1)};
  }

  &.is-medium :nth-child(-n+3) {
    background-color: ${getScoreColor(2)};
  }

  &.is-strong :nth-child(-n+4) {
    background-color: ${getScoreColor(3)};
  }

  &.is-very-strong :nth-child(-n+5) {
    background-color: ${getScoreColor(4)};
  }
`;

const PasswordStrengthBarLine = styled.div`
  flex: 1;
  height: 6px;
  background-color: ${getScoreColor(undefined)};
  border-radius: 3px;
`;

export const PasswordStrengthBar = (props: { score: undefined | 0 | 1 | 2 | 3 | 4 }) => {
    const { score } = props;

    return (
        <PasswordStrengthBarCt className={`is-${getScoreClass(score)}`}>
            <PasswordStrengthBarLine />
            <PasswordStrengthBarLine />
            <PasswordStrengthBarLine />
            <PasswordStrengthBarLine />
            <PasswordStrengthBarLine />
        </PasswordStrengthBarCt>
    );
}
