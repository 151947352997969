import { Container } from '@mui/material';
import React from 'react';
import { PageBody } from '../../components/page/PageBody';
import { PublicPageHeader } from '../../components/page/PublicPageHeader';
import { PageHeadline } from '../../components/page/PageHeadline';
import { ProtectedPage } from './ProtectedPage';

export const InsufficientPrivilegesPage = ({ title }: { title?: string }) => (
    <ProtectedPage title={title || 'Fehlende Berechtigungen'}>
        <Container>
            <PublicPageHeader>
                <PageHeadline>Fehlende Berechtigungen</PageHeadline>
            </PublicPageHeader>
        </Container>

        <PageBody>
            Diese Seite benötigt zusätzliche Rechte. Bitte kontaktieren Sie
            Ihren Administrator um Ihnen die erforderlichen Rechte einzuräumen.
        </PageBody>
    </ProtectedPage>
);
