import styled from '@emotion/styled';
import { Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppDownloadChoices } from '../../common/components/app/AppDownloadChoices';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { PublicPageCard } from '../../common/components/page/PublicPageCard';
import { PublicPageCardTitle } from '../../common/components/page/PublicPageCardTitle';
import { Colors } from '../../core/theme/Colors';

const ActionChoiceWrapper = styled.div`
    margin-top: 36px;
    padding-top: 36px;
    border-top: 1px solid ${Colors.LineColor};
`;

const AppDownloadTitle = styled.div`
    margin: 32px 0;
    text-align: center;
    color: ${Colors.Secondary};
`;

export const OrganizationAlreadyActivated = () => {
    const navigate = useNavigate();

    return (
        <PublicPageCard>
            <PublicPageCardTitle>Ihr Konto ist aktiv!</PublicPageCardTitle>

            <div style={{ marginTop: 32 }}>
                <Alert severity="success">
                    Ihr Konto wurde bereits aktiviert.
                </Alert>
            </div>

            <Typography paragraph sx={{ mt: 4, mb: 2 }}>
                Ab sofort können Sie sich mit ihrer E-Mail-Adresse und ihrem
                gewählten Passwort bei Dajeh anmelden. Hier auf der
                Web-Plattform und in der Android und iOS-App.
            </Typography>

            {/* App-Links */}
            <ActionChoiceWrapper>
                <PrimaryButton
                    onClick={() => {
                        navigate('/login');
                    }}>
                    Jetzt anmelden
                </PrimaryButton>

                <AppDownloadTitle>
                    <i>–</i> oder App herunterladen <i>–</i>
                </AppDownloadTitle>

                <AppDownloadChoices />
            </ActionChoiceWrapper>
        </PublicPageCard>
    );
};
