import styled from '@emotion/styled';
import { Container } from '@mui/material';

const PageHeaderInnerCt = styled.header`
    margin-top: 16px;

    h1 {
        margin-bottom: 0;
        margin-top: 0;
    }

    @media (min-width: 600px) {
        margin-top: 24px;
    }

    @media (min-width: 960px) {
        margin-top: 32px;
    }

    @media (min-width: 1280px) {
        margin-top: 48px;
    }
`;

export const PageHeader = ({ children }) => (
    <Container>
        <PageHeaderInnerCt>{children}</PageHeaderInnerCt>
    </Container>
);
