import styled from '@emotion/styled';
import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import CheckIcon from '../../assets/images/check.svg';
import { AppDownloadChoices } from '../../common/components/app/AppDownloadChoices';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { PublicPageCard } from '../../common/components/page/PublicPageCard';
import { PublicPageCardTitle } from '../../common/components/page/PublicPageCardTitle';
import { Colors } from '../../core/theme/Colors';
import { OrganizationActivationPublicData_Beta } from './OrganizationActivationPublicData';

const ActionChoiceWrapper = styled.div`
    margin-top: 36px;
    padding-top: 36px;
    border-top: 1px solid ${Colors.LineColor};
`;

const AppDownloadTitle = styled.div`
    margin: 32px 0;
    text-align: center;
    color: ${Colors.Secondary};
`;

type OrganizationActivationSuccessPageProps = {
    data: OrganizationActivationPublicData_Beta;
};

const StyledImage = styled.img`
    display: block;
    margin: 24px auto;
    width: 64px;
    height: 64px;
`;

const StyledParagraph = styled.p`
    color: ${Colors.TextMedium};
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    strong {
        color: ${Colors.Text};
    }
`;

export const OrganizationActivationSuccessPage = ({
    data
}: OrganizationActivationSuccessPageProps) => {
    const navigate = useNavigate();
    return (
        <PublicPageCard>
            <PublicPageCardTitle>Konto aktiviert</PublicPageCardTitle>
            <StyledImage alt={''} src={CheckIcon} role={'presentation'} />

            <StyledParagraph>
                Ihr Konto wurde aktiviert und steht ab sofort zur Verfügung.{' '}
            </StyledParagraph>

            <StyledParagraph>
                Mit Ihrer E-Mail-Adresse <strong>{data.owner_email}</strong> und
                Ihrem gewählten Passwort können Sie sich hier auf der
                Web-Plattform und in der Android und iOS-App anmelden.
            </StyledParagraph>

            {/* App-Links */}
            <ActionChoiceWrapper>
                <PrimaryButton
                    onClick={() =>
                        navigate({
                            pathname: `/login`,
                            search: createSearchParams({
                                email: data.owner_email
                            }).toString()
                        })
                    }>
                    Jetzt anmelden
                </PrimaryButton>

                <AppDownloadTitle>
                    <i>–</i> oder App herunterladen <i>–</i>
                </AppDownloadTitle>

                <AppDownloadChoices />
            </ActionChoiceWrapper>
        </PublicPageCard>
    );
};
