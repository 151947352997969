import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

export function useLocalDate() {
    const getDate = () => DateTime.now().toFormat('y-MM-c');

    const [date, setDate] = useState(getDate());

    // We need to add a repaint timeout so the view is re-rendered when the day changes
    useEffect(() => {
        const refreshTimer = setTimeout(() => {
            setDate(getDate());
        }, Math.min(60 * 1000, Math.abs(DateTime.now().endOf('day').diff(DateTime.now()).milliseconds)));

        return () => {
            clearTimeout(refreshTimer);
        };
    }, [date]);

    return date;
}
