import { ApolloClient, gql } from '@apollo/client';

export const QUERY_MY_NOTIFICATIONS = gql`
    query MyNotifications($last: Int, $before: String) {
        me {
            id
            unreadNotificationsCount
            notifications(last: $last, before: $before) {
                totalCount
                nodes {
                    id
                    createdAt
                    type
                    message
                    data
                    readAt
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;

export const MUTATION_MARK_NOTIFICATION_AS_READ = gql`
    mutation MarkNotificationAsRead($id: ID!) {
        markNotificationAsRead(id: $id) {
            notification {
                id
                readAt
            }
        }
    }
`;

export const MUTATION_MARK_ALL_NOTIFICATIONS_AS_READ = gql`
    mutation MarkAllNotificationsAsRead {
        markAllNotificationsAsRead {
            notifications {
                id
                readAt
            }
        }
    }
`;

export function setNotificationReadAtInApolloCache(
    client: ApolloClient<any>,
    notificationId: string,
    readAt: string
) {
    client.writeFragment({
        id: `Notification:${notificationId}`,
        fragment: gql`
            fragment MyNotification on Notification {
                readAt
            }
        `,
        data: {
            readAt: readAt
        }
    });
}

export function decreaseUnreadNotificationsCountInInApolloCache(
    client: ApolloClient<any>
) {
    const result = client.cache.readQuery({
        query: gql`
            query Me {
                me {
                    id
                }
            }
        `
    });

    if (!result || typeof result !== 'object') {
        console.error('Cannot update unreadNotificationsCount in cache');
        return;
    }

    const id = (result as any)?.me?.id;
    client.cache.updateFragment(
        {
            id: `User:${id}`,
            // options object
            fragment: gql`
                fragment CurrentUser on User {
                    unreadNotificationsCount
                }
            `
        },
        data => ({
            unreadNotificationsCount: Math.max(
                0,
                data.unreadNotificationsCount - 1
            )
        })
    );
}

export function resetUnreadNotificationsCountInInApolloCache(
    client: ApolloClient<any>
) {
    const result = client.cache.readQuery({
        query: gql`
            query Me {
                me {
                    id
                }
            }
        `
    });

    if (!result || typeof result !== 'object') {
        console.error('Cannot update unreadNotificationsCount in cache');
        return;
    }

    const id = (result as any)?.me?.id;
    client.cache.writeFragment({
        id: `User:${id}`,
        // options object
        fragment: gql`
            fragment CurrentUser on User {
                unreadNotificationsCount
            }
        `,
        data: {
            unreadNotificationsCount: 0
        }
    });
}
