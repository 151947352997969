import { FormHelperText } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

export const EndDateErrorHelper = () => {
    const [_, meta] = useField('endDate');

    if (meta.touched && meta.error) {
        return (
            <FormHelperText error>{meta.error}</FormHelperText>
        )
    }

    return null;
}
