import React, { Fragment } from 'react';

export const NewLineToBreak = ({value}) => {
    if (!value) {
        return '';
    }

    if (typeof value !== 'string') {
        return value;
    }

    return value
        .split('\n')
        .map((value, i) => (
            <Fragment key={i}>
                {i > 0 && <br />}
                {value}
            </Fragment>
        ));
}
