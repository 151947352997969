import styled from '@emotion/styled';
import { Card } from '../../../core/theme/Base';

export const PublicPageCard = styled(Card)`
    text-align: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

    @media (max-width: 449px) {
        border-radius: 0;
    }

    label {
        text-align: left;
    }

    @media (min-width: 450px) {
        width: 80%;
        max-width: 500px;
        margin: auto;
        border-radius: 10px;
    }

    @media (min-width: 600px) {
        padding: 32px 24px;
    }
`;
