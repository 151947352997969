import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { deDE } from '@mui/x-date-pickers/locales';
import { Info, Settings as LuxonSettings } from 'luxon';
import React, { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import apolloClient from './core/api/apolloClient';
import { AppUpdateNotifier } from './core/AppUpdateNotifier';
import { AuthenticationContextProvider } from './core/auth/components/AuthenticationContextProvider';
import { AppErrorBoundary } from './core/components/AppErrorBoundary';
import AppRoutes from './core/navigation/AppRoutes';
import { GlobalStyle } from './core/theme/globalStyle';
import theme from './core/theme/MaterialUITheme';
import { SnackbarProvider } from './core/theme/SnackbarProvider';

const LOCALE = 'de';

LuxonSettings.defaultLocale = LOCALE;
LuxonSettings.defaultWeekSettings = {
    firstDay: 1,
    minimalDays: Info.getMinimumDaysInFirstWeek(),
    weekend: Info.getWeekendWeekdays()
};

export default function App() {
    return (
        <StrictMode>
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <GlobalStyle />
                    <AppErrorBoundary>
                        <BrowserRouter>
                            <ApolloProvider client={apolloClient}>
                                <AuthenticationContextProvider>
                                    <LocalizationProvider
                                        dateAdapter={AdapterLuxon}
                                        adapterLocale={LOCALE}
                                        localeText={
                                            deDE.components
                                                .MuiLocalizationProvider
                                                .defaultProps.localeText
                                        }>
                                        <SnackbarProvider>
                                            <AppRoutes />
                                            <AppUpdateNotifier />
                                        </SnackbarProvider>
                                    </LocalizationProvider>
                                </AuthenticationContextProvider>
                            </ApolloProvider>
                        </BrowserRouter>
                    </AppErrorBoundary>
                </ThemeProvider>
            </MuiThemeProvider>
        </StrictMode>
    );
}
