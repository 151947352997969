import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import {
    generatePath,
    NavigateFunction,
    useNavigate,
    useParams
} from 'react-router-dom';
import { PageBody } from '../../../core/components/page/PageBody';
import { useDocumentTitle } from '../../../core/hooks/useDocumentTitle';
import { Paths } from '../../../core/navigation/paths';
import { SecondaryAppBar } from '../../../core/navigation/SecondaryNavBarWrapper';
import { OrganizationWorkTimeSummaryReport } from './OrganizationWorkTimeSummaryReport';

function setPathMonth(
    navigate: NavigateFunction,
    date: DateTime,
    push = false
) {
    const path = generatePath(Paths.OrganizationWorkTimeReport, {
        month: date.toFormat('yyyy-MM')
    });
    push ? navigate(path) : navigate(path, { replace: true });
}

const FilterBarWrapper = styled.nav`
    display: flex;
    align-items: center;
`;

const FilterTitle = styled.h1`
    color: rgb(134, 134, 138);
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    margin: 0 48px 0 0;
`;

export const OrganizationWorkTimeReportPageContent = () => {
    let { month } = useParams();
    const navigate = useNavigate();

    const [selectedMonth, setSelectedMonth] = useState(DateTime.now());
    const [initialized, setInitialized] = useState(false);

    // const datePickerRef = useRef<DatePicker>();

    useDocumentTitle(
        `Arbeitszeit-Bericht ${selectedMonth.toFormat('MMMM yyyy')}`
    );

    // Initialize page
    useEffect(() => {
        let chosenMonth;

        // Happens when user clicks on the nav bar link
        if (!month) {
            chosenMonth = DateTime.now();
            setSelectedMonth(chosenMonth);
            setPathMonth(navigate, chosenMonth);
            return;
        }

        // Happens when user uses the browser back and forth buttons
        chosenMonth = DateTime.fromFormat(month, 'yyyy-MM');
        if (!chosenMonth.isValid) {
            chosenMonth = DateTime.now();
        }

        setSelectedMonth(chosenMonth);

        if (!initialized) {
            setPathMonth(navigate, chosenMonth);
        }

        setInitialized(true);
    }, [month]);

    // Effect from the chosen date
    function onDateChance(date: DateTime | null) {
        const _date = date || DateTime.now();
        if (!_date.isValid) {
            return;
        }
        setSelectedMonth(_date);
        setPathMonth(navigate, _date, true);
    }

    return (
        <>
            <SecondaryAppBar initialHeight={62}>
                <FilterBarWrapper>
                    <FilterTitle>Zeitraum</FilterTitle>
                    <DatePicker<DateTime>
                        views={['year', 'month']}
                        openTo={'month'}
                        format="MMMM yyyy"
                        disableFuture={true}
                        value={selectedMonth}
                        onAccept={onDateChance}
                        sx={{
                            '& .MuiInputBase-input': {
                                color: 'rgb(9, 20, 26)',
                                fontWeight: 600,
                                pr: 0
                            }
                        }}
                        slots={{
                            openPickerIcon: props => (
                                <KeyboardArrowDownIcon
                                    {...props}
                                    sx={{ fontSize: 20 }}
                                />
                            )
                        }}
                        slotProps={{
                            openPickerButton: {
                                disableRipple: true
                            }
                        }}
                    />
                </FilterBarWrapper>
            </SecondaryAppBar>

            <PageBody>
                {initialized && (
                    <OrganizationWorkTimeSummaryReport month={selectedMonth} />
                )}
            </PageBody>
        </>
    );
};
