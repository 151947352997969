import { ApolloError, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { FormControl, FormHelperText } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { InputLabel } from '../../common/components/form/InputLabel';
import { ContentCard } from '../../common/components/layout/ContentCard';
import {
    toApiError,
    getCommonCoreErrorMessage,
    getUnknownErrorFeedbackMessage,
    getUnknownGraphQlErrorFeedbackMessage,
    GraphQLError,
    isCommonCoreError
} from '../../core/api/error';
import { MUTATION_UPDATE_PASSWORD } from '../queries';
import { NewPasswordField } from './NewPasswordField';
import { PasswordField } from './PasswordField';

const Buttons = styled.div`
    margin-top: 24px;

    .MuiButton-root + .MuiButton-root {
        margin-left: 16px;
    }
`;

type ChangePasswordFormValues = {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
};

type FormRemoteError<FormValues> = {
    error: Error;
    formErrorMessage?: string;
    fieldErrors: FormikErrors<FormValues>;
};

function validateFormValues(
    values: ChangePasswordFormValues
): FormikErrors<ChangePasswordFormValues> {
    let errors = {} as Partial<ChangePasswordFormValues>;

    if (!values.oldPassword) {
        errors.oldPassword = 'Bitte geben Sie Ihr aktuelles Passwort ein.';
    }

    if (!values.newPassword) {
        errors.newPassword = 'Bitte geben Sie Ihr neues Passwort ein.';
    }

    if (!values.newPasswordRepeat) {
        errors.newPasswordRepeat =
            'Bitte geben Sie Ihr neues Passwort erneut ein.';
    } else {
        if (values.newPassword !== values.newPasswordRepeat) {
            errors.newPasswordRepeat = 'Die Passwörter stimmen nicht überein';
        }
    }

    return errors;
}

/**
 * Processes the error returned by the server
 *
 * @param requestError
 */
function processRequestError(
    requestError: ApolloError
): FormRemoteError<ChangePasswordFormValues> {
    const formRemoteError: FormRemoteError<ChangePasswordFormValues> = {
        error: requestError,
        fieldErrors: {
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: ''
        }
    };

    const apiError = toApiError(requestError);
    if (!apiError) {
        formRemoteError.formErrorMessage =
            getUnknownErrorFeedbackMessage(requestError);
        return formRemoteError;
    }

    if (isCommonCoreError(apiError)) {
        formRemoteError.formErrorMessage = getCommonCoreErrorMessage(apiError);
        return formRemoteError;
    }

    if (apiError instanceof GraphQLError) {
        /* Application error */
        const code = apiError.getCode();

        if (code === 'password_too_weak') {
            formRemoteError.fieldErrors.newPassword =
                'Das Passwort ist zu schwach';
        } else if (code === 'password_incorrect') {
            formRemoteError.fieldErrors.oldPassword =
                'Ihr eingegebenes Passwort ist falsch. Bitte geben Sie Ihr aktuelles Passwort erneut ein.';
        } else {
            formRemoteError.formErrorMessage =
                getUnknownGraphQlErrorFeedbackMessage(apiError);
        }
    } else {
        formRemoteError.formErrorMessage =
            getUnknownErrorFeedbackMessage(apiError);
    }

    return formRemoteError;
}

export const UpdateUserPassword = () => {
    const initialValues: ChangePasswordFormValues = {
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
    };

    const [formKey, setFormKey] = useState(Date.now());
    const formikRef = useRef<FormikProps<any>>(null);
    const [busy, setBusy] = useState(false);
    const [formError, setFormError] = useState<string | undefined>();
    const { enqueueSnackbar } = useSnackbar();

    const [updatePasswordMutation] = useMutation(MUTATION_UPDATE_PASSWORD);

    const submit = async (values: ChangePasswordFormValues, actions) => {
        console.log(values);
        if (busy) {
            return;
        }

        setBusy(true);
        return updatePasswordMutation({
            variables: {
                input: {
                    currentPassword: values.oldPassword,
                    newPassword: values.newPassword
                }
            }
        })
            .then(() => {
                enqueueSnackbar(`Ihr Passwort wurde erfolgreich geändert`, {
                    variant: 'success'
                });
                reset();
            })
            .catch(e => {
                const formRemoteError = processRequestError(e);
                setFormError(formRemoteError.formErrorMessage);
                if (formikRef.current) {
                    formikRef.current?.setErrors(formRemoteError.fieldErrors);
                } else {
                    setFormError('Bitte Seite neu laden. (formik_ref_no_set)');
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
                setBusy(false);
            });
    };

    const reset = () => {
        formikRef.current?.resetForm();
        setFormKey(Date.now());
    };

    const buttonsDisabled = busy;

    return (
        <Formik<ChangePasswordFormValues>
            initialValues={initialValues}
            onSubmit={submit}
            validate={validateFormValues}
            innerRef={formikRef}
            key={formKey}>
            <Form>
                <ContentCard>
                    {formError && (
                        <FormHelperText error>{formError}</FormHelperText>
                    )}
                    <div style={{ maxWidth: '360px' }}>
                        <FormControl fullWidth>
                            <InputLabel
                                htmlFor="oldPassword"
                                style={{ marginTop: 0 }}>
                                Aktuelles Passwort*
                            </InputLabel>
                            <PasswordField
                                id="oldPassword"
                                name="oldPassword"
                                placeholder="Aktuelles Passwort eingeben*"
                                disabled={busy}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="newPassword">
                                Neues Passwort*
                            </InputLabel>
                            <NewPasswordField
                                id="newPassword"
                                name="newPassword"
                                placeholder="Neues Passwort eingeben*"
                                disabled={busy}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ marginTop: '12px' }}>
                            <PasswordField
                                id="newPasswordRepeat"
                                name="newPasswordRepeat"
                                placeholder="Neues Passwort erneut eingeben*"
                                disabled={busy}
                            />
                        </FormControl>
                    </div>
                </ContentCard>

                <Buttons>
                    <SecondaryButton disabled={buttonsDisabled} onClick={reset}>
                        Abbrechen
                    </SecondaryButton>
                    <PrimaryButton disabled={buttonsDisabled} type="submit">
                        Änderungen speichern
                    </PrimaryButton>
                </Buttons>
            </Form>
        </Formik>
    );
};
