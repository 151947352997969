import styled from '@emotion/styled';

export const ContentSection = styled.section`
    margin-bottom: 16px;

    @media (min-width: 600px) {
        margin-bottom: 24px;
    }

    @media (min-width: 960px) {
        margin-bottom: 32px;
    }
`;
