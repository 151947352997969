export async function promiseWait(milliseconds: number): Promise<void> {
    return new Promise(resolve => window.setTimeout(resolve, milliseconds));
}

export function b64toBlob(
    b64Data: string,
    contentType = '',
    sliceSize = 512
): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        byteArrays.push(new Uint8Array(byteNumbers));
    }

    return new Blob(byteArrays, { type: contentType });
}

export function execute<ARGS extends any[], RETURN>(
    func: ((...args: ARGS) => RETURN) | undefined,
    ...args: ARGS
): RETURN | undefined {
    if (typeof func === 'function') {
        return func(...args);
    }

    if (typeof func !== 'undefined') {
        console.error('func is not a function', func);
    }

    return undefined;
}

export function executeSafe<ARGS extends any[], RETURN>(
    func: ((...args: ARGS) => RETURN) | undefined,
    ...args: ARGS
): RETURN | undefined {
    try {
        return execute(func, ...args);
    } catch (e) {
        console.error('Error while executing function', e);
    }
}

export type OnNodeChangeFn = (
    action: 'create' | 'update' | 'delete' | 'restore',
    data?: undefined | null | Record<string, any>
) => void;
