import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { getAppVersion, getDefaultPollInterval } from './environment';

export function AppUpdateNotifier() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [updateDetected, setUpdateDetected] = useState(false);

    function checkForUpdate() {
        if (window.location.hostname === 'localhost') {
            return;
        }

        fetch('/version.json', { cache: 'no-cache' })
            .then(response => response.json())
            .then(responseJson => {
                if (typeof responseJson !== 'object') {
                    return;
                }

                const sourceVersion = responseJson?.version;
                if (!sourceVersion) {
                    return;
                }

                if (sourceVersion === getAppVersion()) {
                    return;
                }

                console.info(
                    `Dajeh update detected. New version: '${
                        responseJson.version
                    }', currently loaded version: '${getAppVersion()}'`
                );
                setUpdateDetected(true);
            })
            .catch(error => {
                console.error('Cannot check for updates', error);
            });
    }

    function showUpdateNotification() {
        enqueueSnackbar('Ein Update der Web-Plattform ist verfügbar', {
            variant: 'info',
            persist: true,
            action: key => (
                <Button
                    variant="contained"
                    size={'small'}
                    style={{ textTransform: 'uppercase' }}
                    onClick={() => {
                        closeSnackbar(key);
                        window.location.reload();
                    }}>
                    Jetzt neu laden
                </Button>
            )
        });
    }

    useEffect(() => {
        if (updateDetected) {
            showUpdateNotification();
            return;
        } else {
            const intervalHandle = setInterval(
                checkForUpdate,
                getDefaultPollInterval()
            );
            return () => clearInterval(intervalHandle);
        }
    }, [updateDetected]);

    return null;
}
