import { useContext } from 'react';
import {
    GlobalDrawerFormContext,
    GlobalDrawerFormContextProps
} from '../components/form/GlobalDrawerFormContext';

export function useGlobalDrawerContext(): GlobalDrawerFormContextProps {
    return useContext(GlobalDrawerFormContext);
}

export function useGlobalEmployeeEditForm(): {
    edit: (id: string, onChange?: () => void) => void;
    hide: () => void;
} {
    const context = useGlobalDrawerContext();

    return {
        edit: context.editEmployeeInDrawerForm,
        hide: context.hideEditEmployeeDrawerForm
    };
}
