import { useApolloClient, useMutation } from '@apollo/client';
import { isApiErrorCode } from '../core/api/error';
import { NotificationDto } from './notification.dto';
import {
    decreaseUnreadNotificationsCountInInApolloCache,
    MUTATION_MARK_NOTIFICATION_AS_READ,
    setNotificationReadAtInApolloCache
} from './queries';

type Result = [
    (id: string) => Promise<Pick<NotificationDto, 'id' | 'readAt'> | null>
];

export function useMarkNotificationAsReadMutation(): Result {
    const apolloClient = useApolloClient();
    const [markAsReadMutation] = useMutation(
        MUTATION_MARK_NOTIFICATION_AS_READ
    );

    function markAsRead(
        id: string
    ): Promise<Pick<NotificationDto, 'id' | 'readAt'> | null> {
        return markAsReadMutation({
            variables: {
                id
            }
        })
            .then(result => {
                try {
                    setNotificationReadAtInApolloCache(
                        apolloClient,
                        id,
                        result.data?.markNotificationAsRead?.notification
                            ?.readAt || new Date().toISOString()
                    );
                    decreaseUnreadNotificationsCountInInApolloCache(
                        apolloClient
                    );
                } catch (e) {
                    console.error(e);
                }

                return result.data?.markNotificationAsRead?.notification;
            })
            .catch(e => {
                if (isApiErrorCode('notification_already_marked_as_read', e)) {
                    setNotificationReadAtInApolloCache(
                        apolloClient,
                        id,
                        new Date().toISOString()
                    );
                    return null;
                }
                throw e;
            });
    }

    return [markAsRead];
}
