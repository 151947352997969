import { DateTime } from 'luxon';
import { User } from '../core/auth/user';

export const getUserName = (user: User | Partial<User> | null | undefined) => {
    if (!user) {
        return 'n.a.';
    }

    if (user.name) {
        return user.name;
    }

    return `${user.firstName} ${user.lastName}`;
};

export const userHasSomePermissions = (
    user: User | null | undefined,
    permissionsNeeded?: string | string[]
) => {
    if (!permissionsNeeded || permissionsNeeded.length === 0) {
        return true;
    }

    const userPermissions = user?.permissions;

    if (!userPermissions || userPermissions.length === 0) {
        return false;
    }

    const requiredPermissions =
        typeof permissionsNeeded === 'string'
            ? [permissionsNeeded]
            : permissionsNeeded;

    return requiredPermissions.some(permission =>
        userPermissions.includes(permission)
    );
};

export function getGreeting(firstName?: string | null): string {
    const now = DateTime.now();
    const hour = now.hour;

    const genericGreeting = (firstName?: string | null) => {
        return `Hallo${firstName ? ` ${firstName}` : ''}!`;
    };

    if (hour <= 4 || hour > 20) {
        return genericGreeting(firstName);
    } else if (hour <= 10) {
        return `Guten Morgen${firstName ? `, ${firstName}` : ''}!`;
    } else if (hour <= 17) {
        return `Guten Tag${firstName ? `, ${firstName}` : ''}!`;
    } else if (hour <= 20) {
        return `Guten Abend${firstName ? `, ${firstName}` : ''}!`;
    } else {
        return genericGreeting(firstName);
    }
}

export function isStrassenUser(user: User | null | undefined) {
    if (!user) {
        return false;
    }

    if (!user.email) {
        return false;
    }

    return user.email.indexOf('@transporte-strassen.de') > 0;
}
