import styled from '@emotion/styled';
import { Colors } from '../../core/theme/Colors';

export const NoMoreEntries = styled.div`
    margin-top: 32px;
    border-top: 1px solid ${Colors.LineColor};
    padding-top: 32px;
    font-size: 14px;
    color: ${Colors.SecondaryLight};
    font-weight: 600;
`;
