import { css, Global } from '@emotion/react';
import { Colors } from './Colors';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';

const globalStyles = css`
    h1 {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        letter-spacing: 0;
        margin-bottom: 32px;
    }

    h2 {
        font-size: 20px;
        line-height: 25px;
        font-weight: bold;
        letter-spacing: 0;

        margin-top: 32px;
        margin-bottom: 15px;
    }

    h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        letter-spacing: 0;
    }

    hr {
        margin: 32px 0;
        border: none;
        border-top: 1px solid ${Colors.LineColor};
    }

    a {
        color: ${Colors.PrimaryDarker};
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
            color: ${Colors.Primary};
        }
    }

    .section-anchor {
        position: relative;
        top: -120px;
    }

    .SnackbarItem-variantSuccess {
        background-color: ${Colors.Green} !important;
    }

    .SnackbarItem-variantInfo {
        background-color: ${Colors.Primary} !important;
    }

    .SnackbarItem-variantError {
        background-color: ${Colors.Red} !important;
    }

    .SnackbarItem-message {
        max-width: 25em;

        svg {
            align-self: flex-start;
        }

        .actions {
            margin-top: 12px;
        }
    }

    .tox.tox {
        font-family: 'source-sans-pro', sans-serif !important;

        .tox-dialog {
            border-radius: 10px;
        }

        .tox-dialog__header {
            background: ${Colors.Primary};
            color: #fff;
            padding: 14px 24px;

            button:hover {
                background: ${Colors.PrimaryDark} !important;
                border-color: transparent !important;
            }

            .tox-icon > svg {
                fill: white !important;
            }
        }

        .tox-dialog__body-content {
            padding: 14px 24px;
        }

        .tox-dialog__title {
            font-family: 'source-sans-pro', sans-serif;
            font-weight: 700;
            font-size: 24px;
            letter-spacing: 0;
            line-height: 29px;
        }

        .tox-form__group {
            margin-bottom: 16px;

            iframe {
                border: 1px solid ${Colors.LineColor};
                border-radius: 10px;
            }
        }

        .tox-label {
            margin-bottom: 2px;
            font-size: 16px;
            line-height: 22px;
        }

        .tox-selectfield {
            select {
                padding-left: 12px;
            }
        }

        .tox-button {
            background-color: ${Colors.Primary};
            border-color: ${Colors.Primary};
            color: white;
            border-radius: 20px;

            &:hover,
            &:active,
            &:focus {
                background-color: ${Colors.PrimaryDark};
                border-color: ${Colors.PrimaryDark};
                color: white;
            }
        }

        .tox-button--secondary {
            background-color: white;
            border-color: ${Colors.Secondary};
            color: ${Colors.Secondary};

            &:hover,
            &:active,
            &:focus {
                background-color: white !important;
                border-color: ${Colors.Text} !important;
                color: ${Colors.Text} !important;
            }
        }
    }

    .MuiFormControl-fullWidth + .MuiFormControl-fullWidth {
        margin-top: 12px;
    }

    .MuiCheckbox-root {
        svg {
            fill: ${Colors.SecondaryLight};
        }

        &.Mui-checked {
            svg {
                fill: ${Colors.Primary};
            }
        }
    }

    .MuiTooltip-tooltip {
        hr {
            display: block;
            height: 1px;
            background: rgba(255, 255, 255, 0.5);
            border: none;
            margin: 6px 0;
        }
    }
`;

export const GlobalStyle = () => <Global styles={globalStyles} />;
