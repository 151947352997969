import styled from '@emotion/styled'
import React from 'react';
import WelcomePng from '../../assets/images/welcome.png';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { getGreeting } from '../../user';
import { useAuthenticatedContext } from '../hooks/useAuthenticatedContext';

const WelcomeCt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66vh;
  width: 100%;
`

const WelcomeBox = styled.div`
  padding: 24px;
  width: 100%;
`

const WelcomeImg = styled.img`
  display: block;
  width: 50%;
  max-width: 250px;
  margin: auto;
`;

const WelcomeGreetingCt = styled.div`
  margin-top: 18px
`;

const WelcomeGreeting = styled.div`
  color: #7b7b80;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
`;

const WelcomeLoadingCt = styled.div`
  margin-top: 24px
`;

const WelcomeTextCt = styled.div`
  margin: 10px auto 0;
  max-width: 343px;
`;

const WelcomeText = styled.div`
  color: #7b7b80;
  text-align: center;
  font-size: 17px;
  letter-spacing: 0.1px;
  line-height: 23px;
`

type WelcomeProps = {
    isFetching?: boolean;
};

export const WelcomePanel = ({isFetching = false}: WelcomeProps) => {
    const {user} = useAuthenticatedContext();

    return (
        <WelcomeCt>
            <WelcomeBox>
                <WelcomeImg
                    src={WelcomePng}
                    alt="Willkommen zurück"
                />
                <WelcomeGreetingCt>
                    <WelcomeGreeting>
                        {getGreeting(user?.firstName)}
                    </WelcomeGreeting>
                </WelcomeGreetingCt>
                {isFetching ? (
                    <WelcomeLoadingCt>
                        <LoadingSpinner label={null} center={true} />
                    </WelcomeLoadingCt>
                ) : (
                    <WelcomeTextCt>
                        <WelcomeText>
                            Starte die Zeiterfassung, indem du auf den grünen Button tippst.
                        </WelcomeText>
                    </WelcomeTextCt>
                )}
            </WelcomeBox>
        </WelcomeCt>
    );
};

WelcomePanel.defaultProps = {
    isFetching: false
};
