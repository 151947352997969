import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { InternalRefetchQueryDescriptor } from '@apollo/client/core/types';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem
} from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Select, TextField } from 'formik-mui';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { ContactSupportLink } from '../../common/components/ContactSupportLink';
import {
    FormDrawer,
    FormDrawerBody,
    FormDrawerBodyCt,
    FormDrawerCloseButton,
    FormDrawerFooter,
    FormDrawerHead,
    FormDrawerLabel,
    FormDrawerSection,
    FormDrawerTitle
} from '../../common/components/form/FormDrawer';
import { getResponseErrorMessage } from '../../core/api/error';
import { User } from '../../core/auth/user';
import { useUser } from '../../core/auth/useUser';
import {
    MUTATION_UPDATE_USER,
    QUERY_ME,
    QUERY_USER_DETAILS
} from '../../user/queries';
import { executeSafe } from '../../utils';
import { EmployeeFormInvitationEmailChangedHint } from './EmployeeFormInvitationEmailChangedHint';
import { EmployeeFormValues } from './EmployeeFormValues';

const FullWidthSelectContainer = styled.div`
    > .MuiFormControl-root {
        width: 100%;
    }
`;

const EmployeeSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Bitte gib den vollen Vornamen ein')
        .max(
            100,
            'Vornamen können bei uns nicht mehr als 100 Zeichen haben. Bitte kürzen!'
        )
        .required('Bitte gib einen Vornamen an'),
    lastName: Yup.string()
        .min(2, 'Bitte gib den vollen Nachnamen ein')
        .max(
            100,
            'Nachnamen können bei uns nicht mehr als 100 Zeichen haben. Bitte kürzen!'
        )
        .required('Bitte gib einen Nachnamen an'),
    email: Yup.string()
        .email('Bitte gib eine gültige E-Mail-Adresse ein')
        .required('Bitte gib eine E-Mail-Adresse an'),
    role: Yup.string().oneOf(['standard', 'admin'])
});

function getErrorMessage(error: ApolloError): string {
    return getResponseErrorMessage(error, apiError => {
        const code = apiError.getCode();

        if (code === 'user_email_already_taken') {
            return `Diese E-Mail-Adresse ist bereits vergeben.`;
        }
    });
}

function getInitialFormValues(
    user?: Partial<User> | User | null
): EmployeeFormValues {
    return {
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        email: user?.email || '',
        role: user?.role || 'standard'
    };
}

type EditEmployeeDrawerFormProps = {
    userId: string;
    onClosed: () => void;
    onChange?: () => void;
};

export const EditEmployeeDrawerForm = ({
    userId,
    onClosed,
    onChange
}: EditEmployeeDrawerFormProps) => {
    const [initialized, setInitialized] = useState(false);
    const [visible, setVisible] = useState(true);
    const [isBusy, setBusy] = useState(false);
    const [remoteError, setRemoteError] = useState('');
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

    const formikRef = React.useRef<FormikProps<any>>(null);

    const { enqueueSnackbar } = useSnackbar();
    const user = useUser();

    const { data: userQueryData, loading: loadingUserData } = useQuery(
        QUERY_USER_DETAILS,
        {
            fetchPolicy: 'no-cache',
            variables: {
                id: userId
            }
        }
    );

    const userData = userQueryData?.node;

    /* Update */
    const [updateUserMutation] = useMutation(MUTATION_UPDATE_USER, {
        fetchPolicy: 'no-cache'
    });

    const isInvited = userData?.state === 'INVITED';
    const isSelf = userData?.id === user?.id;

    function submit(values, actions) {
        setBusy(true);

        const input = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            role: values.role
        };

        if (isSelf) {
            delete input.email;
            delete input.role;
        }

        const updateRefetchQueries: InternalRefetchQueryDescriptor[] = [];

        if (isSelf) {
            updateRefetchQueries.push(QUERY_ME);
        }

        updateUserMutation({
            variables: {
                id: userId,
                input
            },
            refetchQueries: updateRefetchQueries
        })
            .then(payload => {
                enqueueSnackbar(`Mitarbeiterdaten aktualisiert`, {
                    variant: 'success'
                });
                closeDrawer();
                executeSafe(onChange);
            })
            .catch(e => {
                setRemoteError(getErrorMessage(e));
            })
            .finally(() => {
                actions.setSubmitting(false);
                setBusy(false);
            });
    }

    function closeDrawer() {
        setVisible(false);
        setTimeout(() => {
            onClosed();
        }, 200);
    }

    function requestClose() {
        if (!formikRef?.current?.dirty) {
            closeDrawer();
            return;
        }

        setShowCloseConfirmation(true);
    }

    useEffect(() => {
        if (userData && !loadingUserData) {
            setInitialized(true);
        }
    }, [userData, loadingUserData]);

    return (
        <>
            <FormDrawer
                anchor="right"
                open={visible}
                onClose={requestClose}
                variant="temporary">
                <Formik<EmployeeFormValues>
                    initialValues={getInitialFormValues(userData)}
                    validationSchema={EmployeeSchema}
                    enableReinitialize={true}
                    onSubmit={submit}
                    innerRef={formikRef}>
                    <Form>
                        {/* For debugging */}
                        {/*<FormDebuggerCt>*/}
                        {/*    <FormDebugger />*/}
                        {/*</FormDebuggerCt>*/}

                        <FormDrawerHead>
                            <FormDrawerTitle>
                                Mitarbeiter bearbeiten
                            </FormDrawerTitle>
                            <FormDrawerCloseButton
                                type="button"
                                onClick={requestClose}>
                                <CloseIcon />
                            </FormDrawerCloseButton>
                        </FormDrawerHead>

                        <FormDrawerBodyCt>
                            <FormDrawerBody>
                                {remoteError && (
                                    <Alert severity="error">
                                        {remoteError}
                                    </Alert>
                                )}

                                <FormDrawerSection>
                                    <FormDrawerLabel htmlFor="firstName">
                                        Vorname
                                    </FormDrawerLabel>
                                    <Field
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Vorname eingeben"
                                        disabled={!initialized || isBusy}
                                        component={TextField}
                                        fullWidth
                                    />

                                    <FormDrawerLabel htmlFor="lastName">
                                        Nachname
                                    </FormDrawerLabel>
                                    <Field
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Nachname eingeben"
                                        disabled={!initialized || isBusy}
                                        component={TextField}
                                        fullWidth
                                    />
                                </FormDrawerSection>

                                <FormDrawerSection>
                                    <FormDrawerLabel htmlFor="email">
                                        E-Mail-Adresse
                                    </FormDrawerLabel>
                                    <Field
                                        id="email"
                                        name="email"
                                        type="email"
                                        disabled={
                                            !initialized || isBusy || isSelf
                                        }
                                        component={TextField}
                                        placeholder={'E-Mail-Adresse eingeben'}
                                        fullWidth
                                    />
                                    {isInvited && (
                                        <EmployeeFormInvitationEmailChangedHint />
                                    )}
                                    {isSelf && (
                                        <Alert
                                            severity="info"
                                            style={{
                                                marginTop: 8,
                                                fontWeight: 400
                                            }}>
                                            Sie können Ihre eigene
                                            E-Mail-Adresse nicht ändern.{' '}
                                            <ContactSupportLink
                                                subject={`Anfrage: Bitte meine E-Mail-Adresse ändern [${user?.id}]`}>
                                                Support kontaktieren
                                            </ContactSupportLink>
                                        </Alert>
                                    )}
                                </FormDrawerSection>

                                <FormDrawerSection>
                                    <FormDrawerLabel htmlFor="role">
                                        Berechtigung
                                    </FormDrawerLabel>
                                    <FullWidthSelectContainer>
                                        <Field
                                            id="role"
                                            name="role"
                                            disabled={
                                                !initialized || isBusy || isSelf
                                            }
                                            component={Select}>
                                            <MenuItem value={'standard'}>
                                                Standard
                                            </MenuItem>
                                            <MenuItem value={'admin'}>
                                                Administrator
                                            </MenuItem>
                                        </Field>
                                    </FullWidthSelectContainer>
                                    {isSelf && (
                                        <Alert
                                            severity="info"
                                            style={{
                                                marginTop: 8,
                                                fontWeight: 400
                                            }}>
                                            Sie können Ihre eigene Berechtigung
                                            nicht ändern.
                                        </Alert>
                                    )}
                                </FormDrawerSection>
                            </FormDrawerBody>
                        </FormDrawerBodyCt>

                        <FormDrawerFooter>
                            <SecondaryButton
                                onClick={requestClose}
                                style={{ float: 'left' }}
                                type="button"
                                disabled={isBusy}>
                                Abbrechen
                            </SecondaryButton>
                            <PrimaryButton type="submit" disabled={isBusy}>
                                Änderungen speichern
                            </PrimaryButton>
                        </FormDrawerFooter>
                    </Form>
                </Formik>
            </FormDrawer>

            <Dialog
                open={showCloseConfirmation}
                onClose={() => setShowCloseConfirmation(false)}
                aria-labelledby="aef-dirty-close-title"
                aria-describedby="aef-dirty-close-description">
                <DialogTitle id="aef-dirty-close-title">
                    Mitarbeiterdaten verwerfen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="aef-dirty-close-description">
                        Sie haben bereits Daten geändert. Möchten Sie die Daten
                        wirklich verwerfen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SecondaryButton
                        onClick={() => setShowCloseConfirmation(false)}>
                        Weiter bearbeiten
                    </SecondaryButton>
                    <PrimaryButton onClick={closeDrawer} autoFocus>
                        Verwerfen
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
