import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { LoadingSpinner } from '../common/components/LoadingSpinner';
import { NoMoreEntries } from '../common/components/NoMoreEntries';
import { NothingHereToSee } from '../core/components/NothingHereToSee';
import { Notification } from './Notification';
import { useMarkNotificationAsReadMutation } from './useMarkNotificationAsReadMutation';
import { useNotifications } from './useNotifications';

const NotificationListItemCt = styled.ol`
    list-style-type: none;
    margin: 0;
    padding: 8px 0;
`;

const StyledNoMoreEntries = styled(NoMoreEntries)`
    text-align: center;
`;

const NotificationListFooter = styled.footer`
    margin-top: 24px;
    text-align: center;
`;

export const NotificationsList = () => {
    const [markNotificationAsReadMutation] =
        useMarkNotificationAsReadMutation();

    const { enqueueSnackbar } = useSnackbar();

    const {
        notifications,
        error,
        loadingInitialPage,
        hasMore,
        loadNextPage,
        loadingNextPage
    } = useNotifications(15, 10);

    function markAsRead(id: string): Promise<void> {
        return markNotificationAsReadMutation(id)
            .then(_ => {})
            .catch(e => {
                enqueueSnackbar(
                    `Benachrichtigung konnte nicht als gelesen markiert werden: ${e.message}`,
                    {
                        variant: 'error'
                    }
                );
            });
    }

    if (loadingInitialPage) {
        return (
            <LoadingSpinner
                label={'Lade Benachrichtigungen'}
                center
                size={20}
            />
        );
    }

    if (error) {
        return (
            <Alert color={'error'}>
                Benachrichtigungen können zur Zeit nicht geladen werden. <br />
                <small>({error.message})</small>
            </Alert>
        );
    }

    if (!notifications || notifications.length === 0) {
        return (
            <NothingHereToSee label={'Keine Benachrichtigungen vorhanden'} />
        );
    }

    return (
        <>
            <NotificationListItemCt className={'is-notification-page'}>
                {notifications.map(notification => (
                    <Notification
                        key={notification.id}
                        markAsRead={markAsRead}
                        {...notification}
                    />
                ))}
            </NotificationListItemCt>
            <NotificationListFooter>
                {hasMore ? (
                    <LoadingButton
                        onClick={loadNextPage}
                        loading={loadingNextPage}
                        sx={{ whiteSpace: 'nowrap' }}
                        loadingIndicator="Lade Benachrichtigungen">
                        Ältere Benachrichtigungen laden
                    </LoadingButton>
                ) : (
                    <StyledNoMoreEntries>
                        Keine älteren Benachrichtigungen vorhanden
                    </StyledNoMoreEntries>
                )}
            </NotificationListFooter>
        </>
    );
};
