import React from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { GlobalAlert } from '../../common/components/GlobalAlert';
import { WelcomePanel } from '../../core/components/WelcomePanel';
import { executeSafe, OnNodeChangeFn } from '../../utils';
import { NoEntriesInGroupHintLabel } from '../common/NoEntriesInGroupHintLabel';
import { TimeEntryGroup } from '../common/TimeEntryGroup';
import { TimeEntryGroupBody } from '../common/TimeEntryGroupBody';
import { TimeEntryListItem } from '../TimeEntryListItem';
import { RecentTimeEntriesDayGroupHeader } from './RecentTimeEntriesDayGroupHeader';
import { RecentTimeEntriesFooter } from './RecentTimeEntriesFooter';
import { RecentTimeEntriesNoMoreEntries } from './RecentTimeEntriesNoMore';
import { useRecentTimeEntries } from './useRecentTimeEntries';

export const RecentTimeEntries = ({
    onChanged
}: {
    onChanged?: OnNodeChangeFn;
}) => {
    const {
        recentTimeEntries,
        isFetching,
        hasMore,
        fetchMore,
        isFetchingMore,
        error,
        refetch
    } = useRecentTimeEntries();

    function onTimeEntryChanged(action, data) {
        refetch();
        executeSafe(onChanged, action, data);
    }

    if (isFetching || !recentTimeEntries) {
        return <WelcomePanel isFetching={true} />;
    }

    if (error && !recentTimeEntries) {
        return (
            <GlobalAlert severity="error">
                Fehler beim Laden der Arbeitszeiteinträge{' '}
                <small>({error.message})</small>
            </GlobalAlert>
        );
    }

    if (!recentTimeEntries || recentTimeEntries.length === 0) {
        return <WelcomePanel />;
    }

    // Render
    return (
        <>
            {recentTimeEntries.map((group, index) => {
                // We show the divider for groups that are more than one day apart from the last group
                const showDivider =
                    index > 0 &&
                    Math.abs(
                        recentTimeEntries[index - 1].startOfDayUnix -
                            group.startOfDayUnix
                    ) >
                        24 * 60 * 60 * 1000;

                return (
                    <TimeEntryGroup key={group.label} showDivider={showDivider}>
                        <RecentTimeEntriesDayGroupHeader group={group} />
                        <TimeEntryGroupBody>
                            {group.entries.map(entry => (
                                <TimeEntryListItem
                                    key={entry.id}
                                    entry={entry}
                                    onChanged={onTimeEntryChanged}
                                />
                            ))}
                            {group.entries.length === 0 && (
                                <NoEntriesInGroupHintLabel>
                                    Keine Einträge vorhanden
                                </NoEntriesInGroupHintLabel>
                            )}
                        </TimeEntryGroupBody>
                    </TimeEntryGroup>
                );
            })}

            <RecentTimeEntriesFooter>
                {hasMore ? (
                    <PrimaryButton
                        onClick={() => fetchMore()}
                        disabled={isFetchingMore}
                        aria-busy={isFetchingMore}
                        startIcon={null}>
                        Ältere Einträge laden
                    </PrimaryButton>
                ) : (
                    <RecentTimeEntriesNoMoreEntries>
                        Keine älteren Einträge vorhanden
                    </RecentTimeEntriesNoMoreEntries>
                )}
            </RecentTimeEntriesFooter>
        </>
    );
};
