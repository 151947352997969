import styled from '@emotion/styled'
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { NavLink as RouterLink, NavLinkProps as RouterLinkProps } from 'react-router-dom';


const TeaserBadge = styled.span`
  position: absolute;
  z-index: 2;
  left: 6px;
  bottom: 8px;
  margin-bottom: -7px;
  border-radius: 15px;
  padding: 2px 8px;
  background: #FA5AA3;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  text-align: center;
`

const StyledLI = styled.li`
  position: relative;
  z-index: 1;
`;


const StyledRouterLink = styled(RouterLink)`
  color: rgba(255, 255, 255, 0.7);

  &:active {
    background: #0D161D;
  }

  &[disabled] {
    background: transparent !important;
  }
`;

interface AppDrawerListItemLinkProps {
    to: string;
    primary: string;
    icon?: React.ReactElement;
    teaser?: boolean
}

export const AppDrawerListItemLink = (props: AppDrawerListItemLinkProps) => {
    const {icon, primary, to, teaser} = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(
                itemProps,
                ref,
            ) {
                return <StyledRouterLink to={teaser ? '#' : to} ref={ref} {...itemProps} role={undefined} />;
            }),
        [to],
    );

    return (
        <StyledLI>
            <ListItem component={renderLink}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
                {teaser && <TeaserBadge>Bald</TeaserBadge>}
            </ListItem>
        </StyledLI>
    );
}
