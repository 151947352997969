import styled from '@emotion/styled'
import React from 'react';
import { SecondaryAppBar } from '../../core/navigation/SecondaryNavBarWrapper';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
`;

export const TimeTrackingBarContainer = ({children}) => (
    <SecondaryAppBar initialHeight={83}>
        <Wrapper>
            {children}
        </Wrapper>
    </SecondaryAppBar>
);
