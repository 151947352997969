import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import React, { PropsWithChildren } from 'react';

export const OrganizationMembersTableWrapper = ({
    children
}: PropsWithChildren<any>) => (
    <Table>
        <TableHead>
            <TableRow>
                <TableCell
                    style={{ width: '45px', paddingLeft: 0, paddingRight: 0 }}
                    component="th"></TableCell>
                <TableCell
                    component="th"
                    style={{ paddingLeft: 0, width: '33%' }}>
                    Mitarbeiter
                </TableCell>
                <TableCell component="th">Berechtigung</TableCell>
                <TableCell component="th" sx={{ width: '150px' }} />
            </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
    </Table>
);
