import TableRow from '@mui/material/TableRow';
import React, { PropsWithChildren } from 'react';

type StyledTableRowProps = {
    index: number;
    isEmpty?: boolean;
};

export const ReportTableRow = ({
    index,
    isEmpty,
    children
}: PropsWithChildren<StyledTableRowProps>) => (
    <TableRow
        style={{
            background: index % 2 ? 'none' : '#ececec',
            opacity: !!isEmpty ? 0.5 : 1
        }}>
        {children}
    </TableRow>
);
