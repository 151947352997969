import styled from '@emotion/styled';

export const RecentTimeEntriesNoMoreEntries = styled.div`
    margin-top: 64px;
    border-top: 1px solid #c6c6c8;
    padding-top: 48px;
    font-size: 14px;
    color: #7b7b80;
    font-weight: 600;
`;
