import React, { Component, ErrorInfo } from 'react';
import { ErrorPage } from './page/ErrorPage';

type AppErrorState = {
    error?: Error;
    errorInfo?: ErrorInfo;
};

export class AppErrorBoundary extends Component<any, AppErrorState> {
    state: AppErrorState = {
        error: undefined,
        errorInfo: undefined
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <ErrorPage title={'Hoppla.'}>
                    Da ist wohl etwas schief gegangen.
                </ErrorPage>
            );
        }

        // Normally, just render children
        return this.props.children;
    }
}
