import { PropsWithChildren } from 'react';
import { getSupportEmailAddress } from '../../core/environment';

export const ContactSupportLink = ({
    children,
    subject
}: PropsWithChildren<any>) => {
    const supportEmailAddress = getSupportEmailAddress();
    let href = `mailto:${supportEmailAddress}`;
    if (subject) {
        href += `?subject=${encodeURIComponent(subject)}`;
    }

    if (!children) {
        children = supportEmailAddress;
    }

    return (
        <a href={href} className={'link link--contact-support'}>
            {children}
        </a>
    );
};
