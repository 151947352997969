import { TextField } from '@mui/material';
import { FieldConfig, useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { TimeEntryFormService } from './TimeEntryFormService';
import { TimeEntryFormValues } from './TimeEntryFormValues';

function validateDuration(input: string): string | undefined {
    const parsedDuration = TimeEntryFormService.parseDurationInput(input);
    if (!parsedDuration) {
        return 'Ungültige Zeitspanne';
    }
    return;
}

export const DurationField = (props: FieldConfig & { id?: string, disabled?: boolean }) => {

    const {
        values,
        setValues,
        setFieldTouched,
        setFieldError
    } = useFormikContext<TimeEntryFormValues>();

    const [field, meta] = useField({
        ...props,
        validate: validateDuration
    });
    const fieldName = field.name;
    const fieldValue = field.value;

    const [displayValue, setDisplayValue] = useState('');
    const [hasFocus, setFocussed] = useState(false);

    /**
     * Set display value on change
     */
    const onChange = useCallback(event => {
        const {value} = event.target;
        setFieldTouched(fieldName, true, false);
        setDisplayValue(value);

        const durationValue = TimeEntryFormService.parseDurationInput(value);
        if (durationValue) {
            const updatedValues = TimeEntryFormService.update(values, 'duration', durationValue.as('seconds'))
            setValues(updatedValues, true);
        } else {
            setFieldError(fieldName, 'Ungültige Zeitangabe');
        }
    }, [fieldName, values]);

    /**
     * Set form state on blur
     */
    const onBlur = useCallback(event => {
        const {value} = event.target;
        setDisplayValue(TimeEntryFormService.autoFormatDurationInput(value));
        setFocussed(false)
    }, [setFocussed]);

    /*
     * Update display value when form value changes
     */
    useEffect(() => {
        if (!hasFocus) {
            setDisplayValue(TimeEntryFormService.autoFormatDurationInput(fieldValue));
        }
    }, [fieldValue, hasFocus])

    const hasError = meta.touched && !!meta.error;
    const errorText = hasError ? meta.error : undefined;

    return (
        <TextField
            id={props.id}
            value={displayValue}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={() => setFocussed(true)}
            error={meta.touched && !!meta.error}
            helperText={errorText}
            disabled={props.disabled}
            placeholder="0:00:00"
            fullWidth
        />
    );
}
