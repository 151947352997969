import styled from '@emotion/styled';
import React from 'react';

const Title = styled.h1`
    margin-top: 0;
    margin-bottom: 26px;
    font-size: 20px;
    line-height: 1.3;
    font-weight: 700;

    @media (min-width: 600px) {
        font-size: 24px;
    }

    @media (min-width: 900px) {
        font-size: 32px;
    }
`;

export const PageHeadline = ({ children }) => <Title>{children}</Title>;
