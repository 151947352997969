import { DateTime } from 'luxon';
import { TimeEntry } from './TimeEntry';

export type DateTimeInput =
    | DateTime
    | Date
    | string
    | number
    | null
    | undefined;

export function getDiff(
    startDateInput: DateTimeInput,
    endDateInput?: DateTimeInput,
    unitOfTime:
        | 'days'
        | 'hours'
        | 'minutes'
        | 'seconds'
        | 'milliseconds' = 'milliseconds'
): number {
    if (!startDateInput) {
        return 0;
    }

    const startDate = toDateTime(startDateInput);
    if (!startDate) {
        return 0;
    }

    const endDate = toDateTime(endDateInput) || DateTime.now();
    const duration = endDate.diff(startDate);
    if (!duration || !duration.isValid) {
        return 0;
    }

    return duration.as(unitOfTime);
}

export function formatInterval(
    from: DateTimeInput,
    to: DateTimeInput,
    long: boolean = false
): string {
    return `${formatTime(from, long)} - ${formatTime(to, long)}`;
}

export function formatTime(dateTime: DateTimeInput, long: boolean = false) {
    const _dateTime = toDateTime(dateTime);
    if (!_dateTime) {
        return long ? '00:00:00' : '00:00';
    }

    return _dateTime.toFormat(long ? 'HH:mm:ss' : 'HH:mm');
}

export function formatIntervalAsTimeDuration(
    intervalStart: DateTimeInput,
    intervalEnd: DateTimeInput
): string {
    const totalSeconds = getSecondsBetween(intervalStart, intervalEnd);
    return formatSecondsAsTimeDuration(totalSeconds);
}

export function formatSecondsAsTimeDuration(
    _seconds: number | false | null | undefined,
    discardSeconds: boolean = false
): string {
    if (
        _seconds === false ||
        typeof _seconds === 'undefined' ||
        _seconds === null ||
        _seconds <= 0
    ) {
        if (discardSeconds) {
            return '0:00';
        }

        return '0:00:00';
    }

    const value = Math.floor(_seconds);

    // Values
    const fullSeconds = value % 60;
    const fullMinutes = Math.floor(value / 60) % 60;
    const fullHours = Math.floor(value / 3600);

    // Formatting
    const secondsLabel = addLeadingZeroUnderTen(fullSeconds);
    const minutesLabel = addLeadingZeroUnderTen(fullMinutes);
    const hoursLabel = `${fullHours}`;

    if (discardSeconds) {
        return `${hoursLabel}:${minutesLabel}`;
    }

    return `${hoursLabel}:${minutesLabel}:${secondsLabel}`;
}

function addLeadingZeroUnderTen(number: number) {
    if (number < 10) {
        return `0${number}`;
    }

    return `${number}`;
}

function getDayLabel(date: DateTime, referenceDate: DateTime = DateTime.now()) {
    if (date.hasSame(referenceDate, 'day')) {
        return 'Heute';
    }

    if (date.hasSame(referenceDate.minus({ day: 1 }), 'day')) {
        return 'Gestern';
    }

    return date.toFormat('cccc');
}

export function formatDayDate(date?: DateTime, long?: boolean) {
    if (!date) {
        return 'n.a.';
    }

    const now = DateTime.now();

    let dayLabel = '';
    if (date.hasSame(now, 'day')) {
        dayLabel = 'Heute';
    } else if (date.hasSame(now.minus({ day: 1 }), 'day')) {
        dayLabel = 'Gestern';
    }

    if (!long && dayLabel) {
        return dayLabel;
    }

    if (!long) {
    }

    const dateLabel = date.toFormat('cccc, d. MMMM');
    if (date.hasSame(now, 'year')) {
        return dateLabel;
    }

    return `${dateLabel} ${date.toFormat('yyyy')}`;
}

export function getSecondsBetween(
    start: DateTimeInput,
    end: DateTimeInput
): number | undefined {
    const _start = toDateTime(start);
    const _end = toDateTime(end);

    if (!_start || !_start.isValid || !_end || !_end.isValid) {
        return undefined;
    }

    return (
        Math.floor(_end.toMillis() / 1000) -
        Math.floor(_start.toMillis() / 1000)
    );
}

export function getActiveTrackingSeconds(startDate: DateTime) {
    return (
        Math.floor(Date.now() / 1000) - Math.floor(startDate.toMillis() / 1000)
    );
}

export function getTimeEntrySummary(
    timeEntry: TimeEntry,
    long = false
): string {
    const start = toDateTime(timeEntry.startDate);
    const end = toDateTime(timeEntry.endDate);

    // Active time tracking
    if (!end) {
        return `Aktive Zeiterfassung, gestartet ${formatDayDate(
            start
        )}, ${formatTime(start)} Uhr`;
    }

    // Complete time entry
    const startDayLabel = formatDayDate(start, long);
    let endDayLabel = '';
    // @ts-ignore
    if (!start.hasSame(end, 'day')) {
        endDayLabel = formatDayDate(end, long);
    }

    return `${startDayLabel}, ${formatTime(start)}${
        endDayLabel ? ' Uhr' : ''
    } - ${endDayLabel ? endDayLabel + ', ' : ''}${formatTime(end)} Uhr`;
}

/**
 * Converts the given input into a luxon DateTime object
 *
 * @param dateTimeInput
 */
export function toDateTime(dateTimeInput: DateTimeInput): DateTime | undefined {
    // Nothing given, nothing returned
    if (typeof dateTimeInput === 'undefined' || dateTimeInput === null) {
        return;
    }

    // String given => try to parse iso date
    if (typeof dateTimeInput === 'string') {
        const _dateTime = DateTime.fromISO(dateTimeInput);
        if (!_dateTime.isValid) {
            console.error(
                `Given date time '${dateTimeInput}' is not in ISO format`
            );
            return;
        }
        return _dateTime;
    }

    // Native date given, convert and return
    if (dateTimeInput instanceof Date) {
        return DateTime.fromJSDate(dateTimeInput);
    }

    // Number given => convert from unix epoch milliseconds
    if (typeof dateTimeInput === 'number') {
        return DateTime.fromMillis(dateTimeInput);
    }

    // dateTimeInput is already a DateTime object, return it
    if (DateTime.isDateTime(dateTimeInput)) {
        return dateTimeInput;
    }

    return;
}

export function formatDateTime(dateTime: DateTimeInput) {
    const _dateTime = toDateTime(dateTime);
    if (!_dateTime) {
        return 'n.a.';
    }

    return `${formatDayDate(_dateTime)}, ${formatTime(_dateTime)} Uhr`;
}
