import styled from '@emotion/styled';
import React from 'react';
import Lightbox from 'yet-another-react-lightbox';
import { Captions } from 'yet-another-react-lightbox/plugins';

const StyledLightbox = styled(Lightbox)`
    .yarl__container {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .yarl__slide_description {
        font-size: 18px;
        line-height: 24px;
    }
`;

export const HelpLightbox = ({ open, onClose, slides, index }) => {
    return (
        <StyledLightbox
            open={open}
            close={onClose}
            slides={slides}
            index={index}
            plugins={[Captions]}
            controller={{
                closeOnBackdropClick: true
            }}
            carousel={{
                finite: true
            }}
            animation={{
                swipe: 0
            }}
            captions={{
                descriptionTextAlign: 'center',
                showToggle: true
            }}
        />
    );
};
