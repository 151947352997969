import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { QUERY_PASSWORD_STRENGTH } from '../queries';

export type PasswordStrength = {
    score: 0 | 1 | 2 | 3 | 4;
    feedback: {
        warning: string;
        suggestions: string[];
    }
}

export function usePasswordStrength(password: string | undefined): [PasswordStrength | undefined, boolean] {
    const [debouncedInput, setDebouncedInput] = useState(password);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const handler = setTimeout(() => {
            setDebouncedInput(password);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [password]);

    const result = useQuery(QUERY_PASSWORD_STRENGTH, {
        variables: {
            password: debouncedInput
        }
    });

    useEffect(() => {
        setLoading(false);
    }, [result.data]);

    return [result.data?.passwordStrength, loading];
}

