import { SnackbarProvider as MuiSnackbarProvider } from 'notistack';
import React from 'react';

export const SnackbarProvider = ({children}) => {
    return (
        <MuiSnackbarProvider
            maxSnack={5}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {children}
        </MuiSnackbarProvider>
    )
}
