import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { LoadingSpinner } from '../common/components/LoadingSpinner';
import { NoMoreEntries } from '../common/components/NoMoreEntries';
import { NotAvailable } from '../common/components/NotAvailable';
import { Colors } from '../core/theme/Colors';
import { Notification } from './Notification';
import { NotificationDto } from './notification.dto';
import { useMarkNotificationAsReadMutation } from './useMarkNotificationAsReadMutation';

const DummyContentCt = styled.div`
    padding: 32px 24px;
`;

const NotificationPopoverItemsCt = styled.ol`
    list-style-type: none;
    margin: 0;
    padding: 8px 0 0;
`;

const ScrollCt = styled.div`
    max-height: 490px;
    overflow-y: auto;
`;

const NotificationPopoverItemsFooter = styled.footer`
    margin: 0 24px 0;
    padding-top: 32px;
    padding-bottom: 32px;
    text-align: center;
    border-top: 1px solid ${Colors.LineColorLight};
`;

const StyledNoMoreEntries = styled(NoMoreEntries)`
    text-align: center;
    margin-top: 0;
    border-top: 0;
    padding-top: 0;
`;

type NotificationsPopoverContentProps = {
    isFetching: boolean;
    error: any;
    notifications: null | NotificationDto[];
    hasMore: boolean;
    loadNextPage: () => Promise<void>;
    loadingNextPage: boolean;
};

export const NotificationsPopoverItems = ({
    isFetching,
    error,
    notifications,
    hasMore,
    loadNextPage,
    loadingNextPage
}: NotificationsPopoverContentProps) => {
    const [markNotificationAsReadMutation] =
        useMarkNotificationAsReadMutation();

    const { enqueueSnackbar } = useSnackbar();

    function markAsRead(id: string): Promise<void> {
        return markNotificationAsReadMutation(id)
            .then(_ => {})
            .catch(e => {
                enqueueSnackbar(
                    `Benachrichtigung konnte nicht als gelesen markiert werden: ${e.message}`,
                    {
                        variant: 'error'
                    }
                );
            });
    }

    if (isFetching) {
        return (
            <DummyContentCt>
                <LoadingSpinner label={''} center />
            </DummyContentCt>
        );
    }

    if (error) {
        return (
            <DummyContentCt>
                <Alert severity={'error'}>
                    Benachrichtigungen können zur Zeit nicht geladen werden.{' '}
                    <br />
                    <small>({error?.message})</small>
                </Alert>
            </DummyContentCt>
        );
    }

    if (!notifications || notifications.length === 0) {
        return (
            <DummyContentCt>
                <NotAvailable
                    label={'Keine Benachrichtigungen vorhanden'}
                    showTooltip={false}
                />
            </DummyContentCt>
        );
    }

    return (
        <ScrollCt>
            <NotificationPopoverItemsCt>
                {notifications.map(notification => (
                    <Notification
                        key={notification.id}
                        markAsRead={markAsRead}
                        collapseDetails
                        {...notification}
                    />
                ))}
            </NotificationPopoverItemsCt>
            <NotificationPopoverItemsFooter>
                {hasMore ? (
                    <LoadingButton
                        onClick={loadNextPage}
                        loading={loadingNextPage}
                        sx={{ whiteSpace: 'nowrap' }}
                        loadingIndicator="Lade Benachrichtigungen"
                        variant={'outlined'}
                        size={'small'}>
                        Ältere Benachrichtigungen laden
                    </LoadingButton>
                ) : (
                    <StyledNoMoreEntries>
                        Keine älteren Benachrichtigungen vorhanden
                    </StyledNoMoreEntries>
                )}
            </NotificationPopoverItemsFooter>
        </ScrollCt>
    );
};
