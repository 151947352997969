import { gql } from '@apollo/client';

export const QUERY_GET_ORGANIZATION_WORK_TIME_REPORT_SUMMARY = gql`
    query OrganizationWorkTimeSummaryReport($month: Month!) {
        me {
            organization {
                workTimeSummaryReport(month: $month) {
                    from
                    to
                    totalSeconds
                    memberReports {
                        user {
                            id
                            firstName
                            lastName
                        }
                        totalSeconds
                        hasInsights
                    }
                }
            }
        }
    }
`;

export const QUERY_GET_ORGANIZATION_MEMBER_WORK_TIME_REPORT_SUMMARY = gql`
    query OrganizationMemberWorkTimeReport($userId: ID!, $month: Month!) {
        me {
            organization {
                memberWorkTimeReport(userId: $userId, month: $month) {
                    from
                    to
                    totalSeconds
                    user {
                        id
                        firstName
                        lastName
                    }
                    days {
                        date
                        totalSeconds
                        entries {
                            from
                            to
                            totalSeconds
                            prorated
                            node {
                                id
                                description
                            }
                        }
                    }
                    insights {
                        finding
                        timeEntry {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const QUERY_GET_USER_WORK_TIME_REPORT = gql`
    query UserWorkTimeReport(
        $userId: ID!
        $from: DateTime!
        $to: DateTime!
        $grouping: WorkTimeReportGrouping
    ) {
        node(id: $userId) {
            ... on User {
                id
                workTimeReport(from: $from, to: $to, grouping: $grouping) {
                    from
                    to
                    totalSeconds
                    grouping
                    groups {
                        from
                        to
                        totalSeconds
                        entries {
                            from
                            to
                            totalSeconds
                            prorated
                            node {
                                id
                                startDate
                                endDate
                                description
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const QUERY_GET_ORGANIZATION_WORK_TIME_REPORT_EXCEL_FILE = gql`
    query OrganizationWorkTimeReport($month: Month!) {
        me {
            organization {
                workTimeReport(month: $month) {
                    asExcelFile
                }
            }
        }
    }
`;
