import { gql } from '@apollo/client';

export const QUERY_ORGANIZATION_ACTIVATION_PUBLIC_DATA_BETA = gql`
    query GetAccountActivationPublicDataBeta($token: String!) {
        publicOrganizationActivationData_Beta(token: $token) {
            owner_firstName
            owner_lastName
            owner_name
            owner_email
            organization_name
            subscription_isTrial
            subscription_trialPeriodDays
        }
    }
`;
