import styled from '@emotion/styled';
import React from 'react';
import { Colors } from '../core/theme/Colors';
import { formatDateTime } from '../timeTracking/time';
import { NotificationDto } from './notification.dto';
import CheckIcon from '@mui/icons-material/Check';
import { NotificationData } from './NotificationData';
import { NotificationHeader } from './NotificationHeader';
import { NotificationMessage } from './NotificationMessage';

const ItemCt = styled.li`
    padding: 0 24px;

    + li article {
        border-top: 1px solid ${Colors.LineColorLight};
    }

    &:hover,
    &:focus,
    &:active {
        background-color: ${Colors.BackgroundLight};

        * {
            visibility: visible;
        }
    }

    // Notification page style overrides

    .is-notification-page & {
        padding: 0;
        margin: 0;
        background-color: transparent !important;

        + li {
            margin-top: 16px;

            article {
                border-top: 0;
            }
        }
    }
`;

const NotificationCt = styled.article`
    padding: 20px 0;

    &.is-unread {
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            top: 24px;
            right: 0;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: ${Colors.Red};
        }
    }

    // Notification page style overrides

    .is-notification-page & {
        padding: 20px 24px;
        background: white;
        border-radius: 15px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

        &:hover {
            background-color: ${Colors.BackgroundLight};
        }

        &.is-unread {
            border-left: 5px solid ${Colors.RedLightest};
            padding-left: 19px;

            &:before {
                top: 24px;
                right: 24px;
            }
        }
    }
`;

const NotificationBodyCt = styled.div`
    margin-left: 36px;
`;

const NotificationMeta = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;

    .is-notification-page & {
        margin-top: 4px;
    }
`;

const NotificationDateTime = styled.span`
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.Secondary};
    padding: 2px 0;
`;

const MarkAsReadButton = styled.button`
    background: none;
    padding: 2px 4px;
    margin-right: -4px;
    border: 0;
    outline: 0;
    color: ${Colors.Link};
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;

    svg {
        float: left;
        margin-right: 1px;
        margin-top: 1px;
        width: 16px;
        height: 16px;
    }

    &:hover,
    &:focus {
        color: ${Colors.LinkHover};
    }

    &:focus {
        outline: 2px solid ${Colors.LinkHover};
    }

    &:active {
        color: ${Colors.LinkActive};
        text-decoration: none;
    }
`;

const ReadAtCt = styled.div`
    visibility: hidden;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.Secondary};

    span {
        padding: 2px 0;
    }

    .is-notification-page & {
        visibility: visible;
    }
`;

type NotificationsPopoverItemProps = NotificationDto & {
    collapseDetails?: boolean;
    markAsRead: (id: string) => Promise<void>;
};

export const Notification = ({
    id,
    createdAt,
    type,
    message,
    data,
    readAt,
    collapseDetails = false,
    markAsRead
}: NotificationsPopoverItemProps) => {
    return (
        <ItemCt>
            <NotificationCt className={!readAt ? 'is-unread' : undefined}>
                <NotificationHeader type={type} />
                <NotificationBodyCt>
                    <NotificationMessage message={message} />
                    <NotificationData
                        type={type}
                        data={data}
                        collapsed={collapseDetails}
                    />
                    <NotificationMeta>
                        <NotificationDateTime>
                            {formatDateTime(createdAt)}
                        </NotificationDateTime>
                        <ReadAtCt>
                            {!readAt ? (
                                <MarkAsReadButton
                                    onClick={() => markAsRead(id)}>
                                    <CheckIcon /> Als gelesen markieren
                                </MarkAsReadButton>
                            ) : (
                                <span>
                                    Als gelesen markiert:{' '}
                                    {formatDateTime(readAt)}
                                </span>
                            )}
                        </ReadAtCt>
                    </NotificationMeta>
                </NotificationBodyCt>
            </NotificationCt>
        </ItemCt>
    );
};
