import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import { CreateTimeTrackingCircledIcon } from '../common/components/icons/CreateTimeTrackingCircledIcon';
import { EditTimeTrackingIcon } from '../common/components/icons/EditTimeTrackingIcon';
import { RemoveTimeTrackingIcon } from '../common/components/icons/RemoveTimeTrackingIcon';
import { RestoreTimeTrackingIcon } from '../common/components/icons/RestoreTimeTrackingIcon';
import { Colors } from '../core/theme/Colors';
import { NotificationDto } from './notification.dto';

const Header = styled.header`
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    svg {
        width: 24px;
        height: 24px;
        fill: ${Colors.Primary};
        margin-right: 12px;
    }

    &.is-type-time-entry-created-by-admin svg {
        fill: ${Colors.Primary};
    }
    &.is-type-time-entry-updated-by-admin svg {
        fill: ${Colors.SecondaryDark};
    }
    &.is-type-time-entry-deleted-by-admin svg {
        fill: ${Colors.RedDark};
    }
    &.is-type-time-entry-recovered-by-admin svg {
        fill: ${Colors.Primary};
    }

    .is-notification-page & {
        margin-bottom: 6px;
    }
`;

const Headline = styled.h4`
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
`;

type NotificationsPopoverItemProps = Pick<NotificationDto, 'type'>;

function getHeadline(type: string) {
    switch (type) {
        case 'TIME_ENTRY_CREATED_BY_ADMIN':
            return 'Eintrag hinzugefügt';
        case 'TIME_ENTRY_UPDATED_BY_ADMIN':
            return 'Eintrag geändert';
        case 'TIME_ENTRY_DELETED_BY_ADMIN':
            return 'Eintrag gelöscht';
        case 'TIME_ENTRY_RECOVERED_BY_ADMIN':
            return 'Eintrag wiederhergestellt';
        default:
            return 'Hinweis';
    }
}

function getIconComponent(type: string) {
    switch (type) {
        case 'TIME_ENTRY_CREATED_BY_ADMIN':
            return CreateTimeTrackingCircledIcon;
        case 'TIME_ENTRY_UPDATED_BY_ADMIN':
            return EditTimeTrackingIcon;
        case 'TIME_ENTRY_DELETED_BY_ADMIN':
            return RemoveTimeTrackingIcon;
        case 'TIME_ENTRY_RECOVERED_BY_ADMIN':
            return RestoreTimeTrackingIcon;
        default:
            return InfoIcon;
    }
}

function toLowerAndMinus(type: string) {
    return type.toLowerCase().replaceAll('_', '-');
}

export const NotificationHeader = ({ type }: NotificationsPopoverItemProps) => {
    const headline = getHeadline(type);
    const Icon = getIconComponent(type);
    return (
        <Header className={'is-type-' + toLowerAndMinus(type)}>
            <Icon />
            <Headline>{headline}</Headline>
        </Header>
    );
};
