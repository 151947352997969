import styled from '@emotion/styled';

const Ct = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const PageHeadlineWithActions = ({ children }) => <Ct>{children}</Ct>;
