import { Box, Container } from '@mui/material';
import { PropsWithChildren } from 'react';

export const PageBody = ({
    children,
    ...otherProps
}: PropsWithChildren<any>) => (
    <Container {...otherProps}>
        <Box
            sx={{
                padding: {
                    xs: '32px 0',
                    md: '32px 0 48px'
                }
            }}>
            {children}
        </Box>
    </Container>
);
