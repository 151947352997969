import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import PrintIcon from '@mui/icons-material/Print';
import { Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import appStoreQrCodeSrc from '../assets/images/dajeh-app-app-store-qr-code.png';
import googlePlayStoreQrCodeSrc from '../assets/images/dajeh-app-google-play-store-qr-code.png';
import logoImgSrc from '../assets/images/dajeh-logo_362.png';
import { PrimaryButton } from '../common/components/button/PrimaryButton';
import { LoadingSpinner } from '../common/components/LoadingSpinner';
import { User, UserInvitation } from '../core/auth/user';
import { getReadableDateTime } from '../core/datetime';
import { getAppBaseUrl } from '../core/environment';
import { useDocumentTitle } from '../core/hooks/useDocumentTitle';
import { Colors } from '../core/theme/Colors';
import { getUserName } from '../user';
import { QUERY_USER_ONBOARDING_DOCUMENT_DATA } from '../user/queries';

const Wrapper = styled.div`
    max-width: 900px;
    margin: 24px auto;

    @media print {
        max-width: none;
        margin: 0;
    }
`;

const PrintArea = styled.div`
    padding: 32px;
    background: white;

    @media print {
        padding: 0;
        font-size: 10pt;
        line-height: 1.3;

        a,
        a:hover,
        a:focus,
        a:visited {
            color: #0000ee;
        }
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 48px;

    @media print {
        margin-bottom: 32pt;
    }
`;

const Body = styled.div``;

const Footer = styled.div`
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #eee;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    color: ${Colors.TextLight};

    @media print {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-top: 16pt;
        padding-top: 16pt;
        border-top-width: 1pt;
        font-size: 8pt;
        line-height: 10pt;
    }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px auto;

    @media print {
        display: none;
        margin-top: 16pt;
    }
`;

const Logo = styled.img`
    height: 60px;
    padding-right: 24px;
    border-right: 2px solid #eee;
    margin-right: 24px;
    margin-left: -13px;

    @media print {
        height: 40pt;
        padding-right: 16pt;
        border-right-width: 1.6pt;
        margin-right: 16pt;
        margin-left: -8.6pt;
    }
`;

const OrgName = styled.div`
    font-size: 24px;
    line-height: 40px;
    color: #86868a;
    font-weight: 600;

    @media print {
        font-size: 16pt;
        line-height: 24pt;
    }
`;

const Section = styled.section`
    margin-bottom: 40px;

    @media print {
        margin-bottom: 26.6pt;
    }
`;

const SectionTitle = styled.h2`
    font-size: 30px;
    line-height: 42px;
    margin-top: 0;
    margin-bottom: 24px;

    @media print {
        font-size: 20pt;
        line-height: 28pt;
        margin-bottom: 16pt;
    }
`;

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;

    @media print {
        gap: 16pt;
    }
`;

const Column = styled.div`
    flex: 1;
    width: calc(50% - 12px);

    @media print {
        width: calc(50% - 8pt);
    }

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
`;

const EmployeeProp = styled.div`
    & + & {
        margin-top: 12px;
    }

    @media print {
        & + & {
            margin-top: 8pt;
        }
    }
`;

const EmployeePropLabel = styled.div`
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;

    @media print {
        font-size: 10pt;
        line-height: 13.3pt;
    }
`;

const EmployeePropValue = styled.div`
    margin-top: 4px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    padding-bottom: 18px;
    border-bottom: 2px solid #dddedf;

    @media print {
        margin-top: 2.6pt;
        font-size: 13.3pt;
        line-height: 16pt;
        padding-bottom: 12pt;
        border-bottom-width: 1.6pt;
    }
`;

const QRCodeContainer = styled.figure`
    margin: 0;
    padding: 0;
`;

const QRCodeImage = styled.img`
    display: block;
    width: 100%;
    height: auto;
    max-width: 180px;
    margin: -2% auto 0;

    @media print {
        max-width: 120pt;
    }
`;

const QRCodeLabel = styled.figcaption`
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: rgb(134, 134, 138);
    font-weight: bold;
    margin-top: 4px;

    @media print {
        margin-top: 2.6pt;
        font-size: 8pt;
        line-height: 13.3pt;
    }
`;

export const EmployeeOnboardingDocumentPageContent = () => {
    let params = useParams();

    const [pageTitle, setPageTitle] = useState(
        'Erstelle Onboarding-Dokument ...'
    );

    useDocumentTitle(pageTitle);

    const { data, loading, error } = useQuery(
        QUERY_USER_ONBOARDING_DOCUMENT_DATA,
        {
            variables: {
                id: params.employeeId
            }
        }
    );

    const userData: Partial<User> | null | undefined = data?.node;
    const invitation: Partial<UserInvitation> | null | undefined =
        userData?.pendingInvitation;

    useEffect(() => {
        if (data) {
            setPageTitle(`Onboarding-Dokument für ${getUserName(userData)}`);
        }
    }, [data]);

    if (loading) {
        return (
            <Wrapper>
                <PrintArea>
                    <LoadingSpinner
                        label="Erstelle Onboarding-Dokument ..."
                        center
                    />
                </PrintArea>
            </Wrapper>
        );
    }

    if (error) {
        return (
            <Wrapper>
                <PrintArea>
                    <Alert severity="error">
                        <AlertTitle>
                            Fehler beim Laden der Onboarding-Daten
                        </AlertTitle>
                        {error.message}
                    </Alert>
                </PrintArea>
            </Wrapper>
        );
    }

    if (!data) {
        return (
            <Wrapper>
                <PrintArea>
                    <Alert severity="error">
                        <AlertTitle>
                            Fehler beim Laden der Onboarding-Daten
                        </AlertTitle>
                        Die Daten für Ihren Mitarbeiter konnten nicht geladen
                        werden. Bitte kontaktieren Sie den Dajeh-Support.
                    </Alert>
                </PrintArea>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Actions>
                <PrimaryButton
                    startIcon={<PrintIcon />}
                    onClick={() => window.print()}>
                    Seite ausdrucken
                </PrimaryButton>
            </Actions>
            <PrintArea>
                <Header>
                    <Logo src={logoImgSrc} alt="Dajeh" />
                    <OrgName>{userData?.organization?.name}</OrgName>
                </Header>
                <Body>
                    <Section>
                        <SectionTitle>Ihre Zugangsdaten</SectionTitle>
                        <EmployeeProp>
                            <EmployeePropLabel>Name</EmployeePropLabel>
                            <EmployeePropValue>
                                {getUserName(userData)}
                            </EmployeePropValue>
                        </EmployeeProp>
                        <EmployeeProp>
                            <EmployeePropLabel>E-Mail</EmployeePropLabel>
                            <EmployeePropValue>
                                {userData?.email || '-'}
                            </EmployeePropValue>
                        </EmployeeProp>
                        <EmployeeProp>
                            <EmployeePropLabel>Passwort</EmployeePropLabel>
                            <EmployeePropValue>
                                <span
                                    style={{
                                        color: 'grey',
                                        fontWeight: 'normal'
                                    }}>
                                    - Wird bei der ersten Anmeldung festgelegt -
                                </span>
                            </EmployeePropValue>
                        </EmployeeProp>
                    </Section>
                    <Section>
                        <SectionTitle>Ihre erste Anmeldung</SectionTitle>
                        <Row>
                            <Column>
                                <p>
                                    Sie können sich mit Ihrem Smartphone oder
                                    Desktop-PC anmelden. Scannen Sie dazu den
                                    QR-Code rechts mit Ihrem Smartphone oder
                                    öffnen Sie den folgenden Link in Ihrem
                                    Browser: <br />
                                    <br />
                                    <a href={invitation?.acceptUrl}>
                                        {invitation?.acceptUrl}
                                    </a>
                                </p>
                            </Column>
                            <Column>
                                {invitation?.acceptUrlQrCode && (
                                    <QRCodeContainer>
                                        <QRCodeImage
                                            src={invitation!.acceptUrlQrCode}
                                            alt={''}
                                        />
                                        <QRCodeLabel>
                                            Erste Anmeldung
                                        </QRCodeLabel>
                                    </QRCodeContainer>
                                )}
                            </Column>
                        </Row>
                    </Section>
                    <Section>
                        <SectionTitle>
                            Dajeh als App und im Browser
                        </SectionTitle>
                        <Row>
                            <Column>
                                <p>
                                    Sobald Sie Ihre erste Anmeldung
                                    abgeschlossen haben, können Sie sich sowohl
                                    mit der App als auch über die Web-Plattform
                                    anmelden.
                                </p>
                                <p>
                                    <strong>App</strong>
                                    <br />
                                    Laden Sie sich die App für Android oder iOS
                                    herunter und melden Sie sich mit Ihren
                                    Zugangsdaten an. Nach Öffnen der App können
                                    Sie auf “Los geht’s” tippen und anschließend
                                    “Unternehmenskonto” auswählen, um sich
                                    anzumelden.
                                </p>
                                <p>
                                    <strong>Web</strong>
                                    <br />
                                    Die Web-Plattform finden Sie unter{' '}
                                    <a href={getAppBaseUrl()}>
                                        {getAppBaseUrl()}
                                    </a>
                                    .
                                    <br />
                                    Hier können Sie sich ebenfalls mit Ihren
                                    Zugangsdaten anmelden und loslegen.
                                </p>
                            </Column>
                            <Column>
                                <Row>
                                    <Column>
                                        <QRCodeContainer>
                                            <QRCodeImage
                                                src={googlePlayStoreQrCodeSrc}
                                                alt="Dajeh App im Goole Play Store"
                                            />
                                            <QRCodeLabel>
                                                Android Play Store
                                            </QRCodeLabel>
                                        </QRCodeContainer>
                                    </Column>
                                    <Column>
                                        <QRCodeContainer>
                                            <QRCodeImage
                                                src={appStoreQrCodeSrc}
                                                alt="Dajeh App im Apple App Store"
                                            />
                                            <QRCodeLabel>
                                                Apple App Store
                                            </QRCodeLabel>
                                        </QRCodeContainer>
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                    </Section>
                </Body>
                <Footer>
                    {invitation?.validUntil &&
                        `Einladung gültig bis ${getReadableDateTime(
                            invitation.validUntil
                        )}.`}{' '}
                    {invitation?.createdAt &&
                        `Erstellt am ${getReadableDateTime(
                            invitation.createdAt
                        )}.`}{' '}
                    {invitation?.updatedAt &&
                        invitation?.updatedAt !== invitation?.createdAt &&
                        `Zuletzt aktualisiert am ${getReadableDateTime(
                            invitation.updatedAt
                        )}.`}
                </Footer>
            </PrintArea>
            <Actions>
                <PrimaryButton
                    startIcon={<PrintIcon />}
                    onClick={() => window.print()}>
                    Seite ausdrucken
                </PrimaryButton>
            </Actions>
        </Wrapper>
    );
};
