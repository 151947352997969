import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Colors } from '../theme/Colors';

const StyledNavLink = styled(NavLink)`
    display: block;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 600;
    height: 30px;
    padding: 4px 12px;
    text-decoration: none;
    color: ${Colors.Text};
    letter-spacing: 0;
    line-height: 22px;

    &:hover,
    &:focus,
    &:active,
    &.active {
        background: ${Colors.Primary};
        color: white;

        svg {
            fill: white;
        }
    }
`;

const IconCt = styled.span`
    float: left;
    padding-top: 1px;
    margin-left: -2px;

    svg {
        fill: #c4c4c6;
    }
`;

const Label = styled.span`
    margin-left: 7px;
`;

type SidebarNavLinkProps = {
    icon?: ReactElement;
    label: string;
    to: string;
};

export const SidebarNavLink = ({ icon, label, to }: SidebarNavLinkProps) => {
    return (
        <StyledNavLink to={to}>
            {icon && <IconCt>{icon}</IconCt>}
            <Label>{label}</Label>
        </StyledNavLink>
    );
};
