import styled from '@emotion/styled';
import { Card, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { SkeletonText } from '../../common/components/skeleton/SkeletonText';
import { Colors } from '../../core/theme/Colors';
import { TimeReportDuration } from '../../timeTracking/report/TimeReportDuration';
import {
    getRangeUnit,
    TimeReportRangeSelectOption
} from '../../timeTracking/report/timeReportRangeSelectOption';
import { WorkTimeReportDto } from '../../timeTracking/report/userWorkTimeReport/userWorkTimeReport.types';
import { formatSecondsAsTimeDuration } from '../../timeTracking/time';
import { EmployeeWorkTimeReportSummaryChart } from './EmployeeWorkTimeReportSummaryChart';

const BarChartHeadlineCt = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 0;
    margin-bottom: 12px;
`;

const BarChartHeadline = styled.h3`
    margin: 0;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
`;

const BarChartHeadlineLike = styled.div`
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    letter-spacing: 0.15px;
`;

function getDayAverageTotalSeconds(workTimeReport: WorkTimeReportDto) {
    const daysWithTrackings = workTimeReport.groups.filter(
        group => group.entries.length > 0
    ).length;
    if (daysWithTrackings === 0) return 0;
    return workTimeReport.totalSeconds / daysWithTrackings;
}

export const EmployeeWorkTimeReportSummary = ({
    workTimeReport,
    dateRange
}: {
    workTimeReport?: WorkTimeReportDto;
    dateRange: TimeReportRangeSelectOption;
}) => {
    if (!workTimeReport) {
        return <EmployeeWorkTimeReportSummarySkeleton dateRange={dateRange} />;
    }

    let barChartData = workTimeReport.groups.map(group => {
        const date = DateTime.fromISO(group.from);
        return {
            day: date.toJSDate(),
            isSaturday: date.weekday === 6,
            isSunday: date.weekday === 7,
            hours: group.totalSeconds / 3600,
            numEntries: group.entries.length
        };
    });

    // If the date range is a week, we may remove sunday and saturday if there are no entries
    if (getRangeUnit(dateRange) === 'week') {
        const removeSunday = barChartData[6].numEntries === 0;
        const removeSaturday = removeSunday && barChartData[5].numEntries === 0;
        if (removeSunday || removeSaturday) {
            barChartData = barChartData.filter(
                data =>
                    !(
                        (removeSunday && data.isSunday) ||
                        (removeSaturday && data.isSaturday)
                    )
            );
        }
    }

    const dayAverageTotalSeconds = getDayAverageTotalSeconds(workTimeReport);

    return (
        <Card>
            <BarChartHeadlineCt>
                <BarChartHeadline>
                    <TimeReportDuration
                        timeDuration={formatSecondsAsTimeDuration(
                            workTimeReport.totalSeconds
                        )}
                    />
                </BarChartHeadline>
                <Tooltip
                    title={
                        'Durchschnittliche Arbeitszeit bezieht sich auf anwesende Arbeitstage'
                    }>
                    <BarChartHeadlineLike>
                        <span style={{ color: Colors.Secondary }}>Ø Tag</span>{' '}
                        {formatSecondsAsTimeDuration(
                            dayAverageTotalSeconds,
                            true
                        )}
                        h
                    </BarChartHeadlineLike>
                </Tooltip>
            </BarChartHeadlineCt>
            <EmployeeWorkTimeReportSummaryChart
                dateRange={dateRange}
                chartData={barChartData}
            />
        </Card>
    );
};

export const EmployeeWorkTimeReportSummarySkeleton = ({
    dateRange
}: {
    dateRange: TimeReportRangeSelectOption;
}) => (
    <Card>
        <BarChartHeadlineCt>
            <BarChartHeadline>
                <SkeletonText width={60} style={{ fontSize: 20 }} />
            </BarChartHeadline>
            <BarChartHeadlineLike>
                <span style={{ color: Colors.Secondary }}>Ø Tag</span>{' '}
                <SkeletonText width={35} style={{ fontSize: 20 }} />
            </BarChartHeadlineLike>
        </BarChartHeadlineCt>
        <EmployeeWorkTimeReportSummaryChart dateRange={dateRange} />
    </Card>
);
