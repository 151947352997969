import { MutationFunction } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';
import { MutationOptions } from '@apollo/client/core/watchQueryOptions';
import { DateTime } from 'luxon';
import { InvariantError } from '../common/InvariantErrors';
import { TimeEntry, TimeEntryPayload } from './TimeEntry';

export type TimeEntryUpdateInput = {
    startDate?: string;
    endDate?: string;
    description?: string | null;
};

export type TimeEntryCreateInput = {
    startDate: string;
    endDate?: string;
    description?: string | null;
    ownerId?: string | null;
};

export type TimeEntryInput = TimeEntryCreateInput | TimeEntryUpdateInput;

export class TimeEntryService {
    /**
     * Starts a new time tracking
     */
    static async startTimeTracking(
        createTimeEntryMutation: MutationFunction,
        description?: string,
        mutationOptions: Omit<MutationOptions, 'variables' | 'mutation'> = {}
    ): Promise<TimeEntryPayload> {
        return createTimeEntryMutation({
            variables: {
                input: {
                    startDate: DateTime.now().toUTC().toISO(),
                    description
                }
            },
            ...mutationOptions
        }).then(result => {
            return result?.data?.createTimeEntry;
        });
    }

    /**
     * Stops the active time tracking
     */
    static async stopTimeTracking(
        updateTimeEntryMutation: MutationFunction,
        timeEntryId: string,
        description?: string,
        mutationOptions: Omit<OperationVariables, 'variables'> = {}
    ): Promise<TimeEntryPayload> {
        return updateTimeEntryMutation({
            variables: {
                id: timeEntryId,
                input: {
                    description,
                    endDate: DateTime.now().toUTC().toISO()
                }
            },
            ...mutationOptions
        }).then(result => {
            return result?.data?.updateTimeEntry;
        });
    }

    /**
     * Creates a new time entry
     */
    static async create(
        createTimeEntryMutation: MutationFunction,
        input: TimeEntryCreateInput,
        mutationOptions: Omit<MutationOptions, 'variables' | 'mutation'> = {}
    ): Promise<TimeEntryPayload> {
        return createTimeEntryMutation({
            variables: {
                input
            },
            ...mutationOptions
        }).then(result => {
            return result?.data?.createTimeEntry;
        });
    }

    /**
     * Updates the time entry
     * @param updateTimeEntryMutation
     * @param timeEntryId
     * @param input
     * @param mutationOptions
     */
    static async update(
        updateTimeEntryMutation: MutationFunction,
        timeEntryId: string,
        input: Partial<TimeEntryUpdateInput>,
        mutationOptions: Omit<MutationOptions, 'variables' | 'mutation'> = {}
    ): Promise<TimeEntryPayload> {
        await TimeEntryService.validate(input, timeEntryId);

        return updateTimeEntryMutation({
            variables: {
                id: timeEntryId,
                input
            },
            ...mutationOptions
        }).then(result => {
            return result?.data?.updateTimeEntry;
        });
    }

    /**
     * (Soft)Deletes the time entry
     *
     * @param deleteTimeEntryMutation
     * @param timeEntryId
     * @param mutationOptions
     */
    static async delete(
        deleteTimeEntryMutation: MutationFunction,
        timeEntryId: string,
        mutationOptions: Omit<MutationOptions, 'variables' | 'mutation'> = {}
    ): Promise<TimeEntryPayload> {
        return deleteTimeEntryMutation({
            variables: {
                id: timeEntryId
            },
            ...mutationOptions
        }).then(result => {
            return result?.data?.deleteTimeEntry;
        });
    }

    /**
     * Recovers a soft-deleted time entry
     *
     * @param recoverTimeEntryMutation
     * @param timeEntryId
     * @param mutationOptions
     */
    static async recover(
        recoverTimeEntryMutation: MutationFunction,
        timeEntryId: string,
        mutationOptions: Omit<MutationOptions, 'variables' | 'mutation'> = {}
    ): Promise<TimeEntryPayload> {
        return recoverTimeEntryMutation({
            variables: {
                id: timeEntryId
            },
            ...mutationOptions
        }).then(result => {
            return result?.data?.recoverTimeEntry;
        });
    }

    /**
     * Checks only the invariants, which can violated by user input
     * @param entry
     * @param existingEntryId
     */
    public static async validate(
        entry: TimeEntry | TimeEntryInput,
        existingEntryId?: string
    ): Promise<void> {
        TimeEntryService.checkStartDateIsLessThanEndDateInvariant(entry);
        TimeEntryService.checkNoDistantFutureDatesInvariant(entry);
    }

    private static checkStartDateIsLessThanEndDateInvariant(
        entry: TimeEntry | TimeEntryInput
    ): void {
        if (!entry.endDate) {
            return;
        }

        if (
            DateTime.fromISO(entry.startDate as string) >
            DateTime.fromISO(entry.endDate)
        ) {
            throw new InvariantError(
                'Die Startzeit muss vor der Endzeit liegen',
                'startDate'
            );
        }
    }

    private static checkNoDistantFutureDatesInvariant(
        entry: TimeEntry | TimeEntryInput
    ) {
        TimeEntryService.validateNoFutureDate(entry, 'startDate');
        TimeEntryService.validateNoFutureDate(entry, 'endDate');
    }

    private static validateNoFutureDate(
        entry: TimeEntry | TimeEntryInput,
        fieldName: 'startDate' | 'endDate'
    ) {
        const date = entry[fieldName];
        // @ts-ignore
        if (date && DateTime.fromISO(date as string) > DateTime.now()) {
            throw new InvariantError(
                `Die ${
                    fieldName === 'startDate' ? 'Startzeit' : 'Endzeit'
                } liegt in der Zukunft`,
                fieldName
            );
        }
    }
}
