import { gql } from '@apollo/client';

export const QUERY_ORGANIZATION_MEMBERS = gql`
    query OrganizationMembers($states: [UserState]) {
        me {
            id
            organization {
                id
                members(states: $states) {
                    nodes {
                        id
                        name
                        email
                        role
                        state
                        isOwner
                        pendingInvitation {
                            id
                            createdAt
                            createdBy {
                                id
                                firstName
                                lastName
                                email
                            }
                            validUntil
                        }
                    }
                }
            }
        }
    }
`;

export const MUTATION_ACTIVATE_ORGANIZATION = gql`
    mutation ActivateOrganization(
        $token: String!
        $input: ActivateOrganizationInput!
    ) {
        activateOrganization(token: $token, input: $input)
    }
`;
