import styled from '@emotion/styled';
import { Drawer as MuiDrawer } from '@mui/material';
import { Colors } from '../../../core/theme/Colors';

export const FormDrawer = styled(MuiDrawer)`
    .MuiDrawer-paper {
        width: 100%;
        max-width: 400px;
        background: #fff;
        overflow: visible;
    }

    form {
        height: 100%;
    }
`;

export const FormDrawerHead = styled.header`
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    background: ${Colors.Primary};
    padding: 14px 24px 13px;
`;

export const FormDrawerTitle = styled.h2`
    font-size: 24px;
    line-height: 29px;
    padding-right: 64px;
    margin: 0;
    font-weight: bold;
    color: #fff;
`;

export const FormDrawerCloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px 16px 11px;
    background: transparent;
    outline: none;
    border: 0;
    color: white;
`;

export const FormDrawerBodyCt = styled.div`
    position: relative;
    z-index: 1;
    height: 100%;
    padding-top: 56px;
    padding-bottom: 65px;
`;

export const FormDrawerBody = styled.div`
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const FormDrawerSection = styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid #c6c6c8;
`;

export const FormDrawerOptionsMenuCt = styled.div`
    padding: 12px 24px 4px 0;
`;

export const FormDrawerFooter = styled.div`
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px 24px;
    border-top: 1px solid #dddedf;
    text-align: right;
`;

export const FormDrawerLabel = styled.label`
    display: block;
    color: #86868a;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;

    .MuiFormControl-root + & {
        margin-top: 16px;
    }
`;
