import {
    OrganizationWorkTimeReportInsightDto,
    TimeEntryInsightDto
} from './organizationWorkTime/organizationWorkTimeReport.types';

export function toTimeEntryInsightsMap(
    insights: OrganizationWorkTimeReportInsightDto[]
): Map<string, TimeEntryInsightDto[]> {
    const timeEntryIdToInsightsMap = new Map<string, TimeEntryInsightDto[]>();
    if (!insights) {
        return timeEntryIdToInsightsMap;
    }

    insights.forEach(insight => {
        const timeEntryId = insight?.timeEntry?.id;
        if (!timeEntryId) {
            return;
        }

        if (!timeEntryIdToInsightsMap.has(timeEntryId)) {
            timeEntryIdToInsightsMap.set(timeEntryId, []);
        }

        timeEntryIdToInsightsMap
            .get(timeEntryId)!
            .push(insight as TimeEntryInsightDto);
    });

    return timeEntryIdToInsightsMap;
}

export function insightFindingToText(insight: TimeEntryInsightDto): string {
    if (!insight?.finding) {
        return 'n.a.';
    }

    const { finding } = insight;
    switch (finding) {
        case 'over_10_hours':
            return 'Eintrag > 10h';
        default:
            return finding;
    }
}
