import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { GroupedTimeEntriesSkeleton } from '../../common/GroupedTimeEntriesSkeleton';
import { NoEntriesInGroupHintLabel } from '../../common/NoEntriesInGroupHintLabel';
import { TimeEntryGroup } from '../../common/TimeEntryGroup';
import { TimeEntryGroupBody } from '../../common/TimeEntryGroupBody';
import {
    getOfficialDateLabel,
    TimeEntryGroupHeader
} from '../../common/TimeEntryGroupHeader';
import { formatSecondsAsTimeDuration } from '../../time';
import { TimeReportDuration } from '../TimeReportDuration';
import {
    getRangeUnit,
    TimeReportRangeSelectOption
} from '../timeReportRangeSelectOption';
import { WorkTimeReportEntry } from '../WorkTimeReportEntry';
import { WorkTimeReportEntryDto } from '../workTimeReportEntryDto';
import {
    WorkTimeReportDto,
    WorkTimeReportGroupDto
} from './userWorkTimeReport.types';

const UserWorkTimeReportGroup = ({
    group
}: {
    group: WorkTimeReportGroupDto;
}) => {
    const isMonday = DateTime.fromISO(group.from).weekday === 1;
    return (
        <TimeEntryGroup key={group.from} size={'small'} showDivider={isMonday}>
            <TimeEntryGroupHeader
                label={getOfficialDateLabel(group.from)}
                totalSeconds={() => (
                    <TimeReportDuration
                        timeDuration={formatSecondsAsTimeDuration(
                            group.totalSeconds
                        )}
                    />
                )}
                isEmpty={!group.entries.length}
                size={'small'}
            />
            <TimeEntryGroupBody size={'small'}>
                {group.entries.map(entry => (
                    <WorkTimeReportEntry key={entry.from} entry={entry} />
                ))}
                {group.entries.length === 0 && (
                    <NoEntriesInGroupHintLabel>
                        Keine Einträge vorhanden
                    </NoEntriesInGroupHintLabel>
                )}
            </TimeEntryGroupBody>
        </TimeEntryGroup>
    );
};

const CollapsedWorkTimeReportGroup = ({
    group
}: {
    group: EnhancedWorkTimeReportGroupDto;
}) => {
    let endOfRange = DateTime.fromISO(group.to).toLocal();
    if (
        endOfRange.hour === 0 &&
        endOfRange.minute === 0 &&
        endOfRange.second === 0
    ) {
        endOfRange = endOfRange.minus({ days: 1 });
    }

    const isMonday = DateTime.fromISO(group.from).weekday === 1;

    return (
        <TimeEntryGroup key={group.from} size={'small'} showDivider={isMonday}>
            <TimeEntryGroupHeader
                label={`${getOfficialDateLabel(
                    group.from
                )} - ${getOfficialDateLabel(endOfRange)}`}
                totalSeconds={() => (
                    <TimeReportDuration
                        timeDuration={formatSecondsAsTimeDuration(0)}
                    />
                )}
                isEmpty={true}
                size={'small'}
            />
            <TimeEntryGroupBody size={'small'}>
                <NoEntriesInGroupHintLabel>
                    Keine Einträge vorhanden
                </NoEntriesInGroupHintLabel>
            </TimeEntryGroupBody>
        </TimeEntryGroup>
    );
};

type EnhancedWorkTimeReportGroupDto = WorkTimeReportGroupDto & {
    isCollapsed?: boolean;
};

function collapseConsecutiveEmptyDaysInWeek(
    groups: WorkTimeReportGroupDto[]
): EnhancedWorkTimeReportGroupDto[] {
    return groups.reduce((acc, group, currentIndex) => {
        const lastGroup = acc.length > 0 ? acc[acc.length - 1] : null;
        if (
            lastGroup &&
            lastGroup.entries.length === 0 &&
            group.entries.length === 0 &&
            DateTime.fromISO(group.from).weekNumber ===
                DateTime.fromISO(lastGroup.from).weekNumber
        ) {
            return [
                ...acc.slice(0, -1),
                {
                    from: lastGroup.from,
                    to: group.to,
                    isCollapsed: true,
                    entries: [] as WorkTimeReportEntryDto[]
                } as EnhancedWorkTimeReportGroupDto
            ];
        } else {
            acc.push(group as EnhancedWorkTimeReportGroupDto);
        }

        return acc;
    }, [] as EnhancedWorkTimeReportGroupDto[]);
}

export const UserWorkTimeReport = ({
    workTimeReport
}: {
    workTimeReport: WorkTimeReportDto;
}) => {
    const collapsedWorkTimeReportGroups = useMemo(
        () => collapseConsecutiveEmptyDaysInWeek(workTimeReport.groups),
        [workTimeReport.groups]
    );
    return (
        <>
            {collapsedWorkTimeReportGroups.map((group, i) =>
                group.isCollapsed ? (
                    <CollapsedWorkTimeReportGroup group={group} key={i} />
                ) : (
                    <UserWorkTimeReportGroup group={group} key={i} />
                )
            )}
        </>
    );
};

export const UserWorkTimeReportSkeleton = ({
    selectedReportRange
}: {
    selectedReportRange: TimeReportRangeSelectOption;
}) => {
    const numGroups = getRangeUnit(selectedReportRange) === 'week' ? 5 : 20;
    return <GroupedTimeEntriesSkeleton numGroups={numGroups} size={'small'} />;
};
