import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../../navigation/paths';
import { AuthBasePage } from './AuthBasePage';
import { AuthBox } from './AuthBox';
import { LoginForm } from './LoginForm';

const ResetPasswordLink = styled(Link)`
    margin-top: 16px;
    text-align: center;
    display: block;
`;

export const LoginPage = () => {
    return (
        <AuthBasePage title="Anmelden">
            <AuthBox title="Anmelden">
                <LoginForm />
                <ResetPasswordLink to={Paths.RequestPasswordReset}>
                    Passwort vergessen?
                </ResetPasswordLink>
            </AuthBox>
        </AuthBasePage>
    );
};
