import React, { PropsWithChildren } from 'react';
import { ProtectedPage } from '../core/auth/components/ProtectedPage';
import { PageBody } from '../core/components/page/PageBody';

export const HelpPageBase = ({
    title,
    children
}: PropsWithChildren<{ title: string }>) => {
    return <ProtectedPage title={title}>{children}</ProtectedPage>;
};
