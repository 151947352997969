import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Alert } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { LoadingSpinner } from '../../../common/components/LoadingSpinner';
import { LoadingWrapper } from '../../../common/components/LoadingWrapper';
import {
    getResponseErrorMessage,
    isMissingPermissionError
} from '../../../core/api/error';
import { MissingPermissionError } from '../../../core/auth/components/MissingPermissionError';
import { useUser } from '../../../core/auth/useUser';
import { Colors } from '../../../core/theme/Colors';
import { formatSecondsAsTimeDuration } from '../../time';
import { QUERY_GET_ORGANIZATION_WORK_TIME_REPORT_SUMMARY } from '../queries';
import { OrganizationMemberWorkTimeSummaryReport } from './OrganizationMemberWorkTimeSummaryReport';
import { OrganizationWorkTimeReportExport } from './OrganizationWorkTimeReportExport';
import {
    OrganizationWorkTimeReportDummyTable,
    OrganizationWorkTimeSummaryReportTable
} from './OrganizationWorkTimeSummaryReportTable';

type MonthlyTimeReportProps = {
    month: DateTime;
};

const HeadWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0;
`;

const Month = styled.span`
    display: inline-block;
    color: rgb(9, 20, 26);
`;

const TotalTime = styled.span`
    display: inline-block;
    color: ${Colors.TextLighter};
    margin-left: 16px;
`;

export const OrganizationWorkTimeSummaryReport = ({
    month
}: MonthlyTimeReportProps) => {
    const { loading, error, data } = useQuery(
        QUERY_GET_ORGANIZATION_WORK_TIME_REPORT_SUMMARY,
        {
            variables: {
                month: `${month.toFormat('yyyy-LL')}`
            },
            pollInterval: 5 * 60 * 1000
        }
    );

    const user = useUser();

    if (isMissingPermissionError(error)) {
        return <MissingPermissionError />;
    }

    if (loading && !data) {
        return (
            <OrganizationWorkTimeReportDummyTable>
                <LoadingSpinner label="Lade Monatsbericht ..." />
            </OrganizationWorkTimeReportDummyTable>
        );
    }

    if (error) {
        const reason = getResponseErrorMessage(error);
        return (
            <OrganizationWorkTimeReportDummyTable>
                <Alert severity="error">
                    Fehler beim Laden des Monatsberichts <br />
                    <small>{reason}</small>
                </Alert>
            </OrganizationWorkTimeReportDummyTable>
        );
    }

    const report = data?.me?.organization?.workTimeSummaryReport;
    if (!report) {
        return (
            <OrganizationWorkTimeReportDummyTable>
                <Alert severity="error">
                    Monatsbericht konnte nicht geladen werden
                </Alert>
            </OrganizationWorkTimeReportDummyTable>
        );
    }

    if (report.memberReports.length === 0) {
        return (
            <OrganizationWorkTimeReportDummyTable>
                Für diesen Monat liegen keine Daten vor
            </OrganizationWorkTimeReportDummyTable>
        );
    }

    return (
        <LoadingWrapper loading={loading} label="Lade Monatsbericht ...">
            <HeadWrapper>
                <Title>
                    <Month>{month.toFormat('MMMM yyyy')}</Month>
                    <TotalTime>
                        {formatSecondsAsTimeDuration(report.totalSeconds)}
                    </TotalTime>
                </Title>
                <OrganizationWorkTimeReportExport
                    month={month}
                    deactivated={loading}
                />
            </HeadWrapper>
            <OrganizationWorkTimeSummaryReportTable>
                {report.memberReports.map(memberReport => (
                    <OrganizationMemberWorkTimeSummaryReport
                        month={month}
                        data={memberReport}
                        key={memberReport.user.id}
                    />
                ))}
            </OrganizationWorkTimeSummaryReportTable>
        </LoadingWrapper>
    );
};
