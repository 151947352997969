import styled from '@emotion/styled';
import WarningIcon from '@mui/icons-material/Warning';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    TextField
} from '@mui/material';
import { FieldConfig, useField } from 'formik';
import React, { useState } from 'react';
import { InputLabel } from '../../common/components/form/InputLabel';
import { Colors } from '../../core/theme/Colors';
import { PasswordStrengthBar } from './PasswordStrengthBar';
import { PasswordStrengthLabel } from './PasswordStrengthLabel';
import { usePasswordStrength } from './usePasswordStrength';

const PasswordStrengthWarning = styled.div`
    margin: 6px 0;
    color: ${Colors.OrangeDark};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    .MuiSvgIcon-root {
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 4px;
        margin-top: -2px;
    }
`;

const PasswordStrengthSuggestions = styled.div`
    margin: 4px 0;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.Secondary};
`;

export const NewPasswordField = (
    props: FieldConfig & {
        id?: string;
        disabled?: boolean;
        label?: string;
        placeholder?: string;
        labelPosition?: 'inside' | 'outside';
    }
) => {
    const [field, meta] = useField(props);
    const [showPassword, setShowPassword] = useState(false);
    const [wasFocussed, setWasFocussed] = useState(false);

    const handleClickShowPassword = () => setShowPassword(show => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const [passwordStrength, loadingPasswordStrength] = usePasswordStrength(
        field.value
    );

    const { score, feedback } = passwordStrength || {};
    const { warning, suggestions } = feedback || {};

    const hasSuggestions = suggestions && suggestions.length;

    const showStrengthHints = meta.touched || !!field.value;
    const touchedError = meta.touched && !!meta.error;

    return (
        <>
            <FormControl fullWidth>
                {props.label && (
                    <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
                )}
                <div style={{ position: 'relative', zIndex: 1 }}>
                    <TextField
                        {...field}
                        placeholder={props.placeholder}
                        type={showPassword ? 'text' : 'password'}
                        error={
                            touchedError ||
                            (meta.touched && (!score || score < 2))
                        }
                        onFocus={() => setWasFocussed(true)}
                        sx={{ width: '100%' }}
                        size="medium"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end">
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    {/* Strength hint */}
                    {showStrengthHints && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '6px',
                                ...(props.labelPosition === 'inside'
                                    ? { right: 48 }
                                    : { left: '100%', marginLeft: '8px' })
                            }}>
                            {loadingPasswordStrength ? (
                                <CircularProgress
                                    size={16}
                                    style={{ marginTop: 3 }}
                                />
                            ) : (
                                <PasswordStrengthLabel
                                    score={
                                        loadingPasswordStrength || !field.value
                                            ? undefined
                                            : score
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>

                <div style={{ margin: '0 12px' }}>
                    {wasFocussed && (
                        <PasswordStrengthBar
                            score={
                                loadingPasswordStrength || !field.value
                                    ? undefined
                                    : score
                            }
                        />
                    )}
                </div>

                {touchedError && (
                    <FormHelperText error>{meta.error}</FormHelperText>
                )}

                <div style={{ margin: '0 12px' }}>
                    {wasFocussed && (warning || hasSuggestions) ? (
                        <div style={{ marginTop: '8px' }}>
                            {warning ? (
                                <PasswordStrengthWarning>
                                    <WarningIcon /> {warning}
                                </PasswordStrengthWarning>
                            ) : null}
                            {hasSuggestions ? (
                                <PasswordStrengthSuggestions>
                                    <strong>Tipp:</strong>{' '}
                                    {suggestions.join(' ')}
                                </PasswordStrengthSuggestions>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </FormControl>
        </>
    );
};
