export type SectionListData<T> = {
    label: string,
    entries: T[],
    [key: string]: any;
}

export type SectionListEntry<T> = T & {
    isFirst: boolean
    isLast: boolean
    isFirstSection: boolean
    isLastSection: boolean
};

export function addSectionListFlags<T>(sectionData: SectionListData<T>[]): SectionListData<SectionListEntry<T>>[] {
    sectionData.forEach((section, index) => {
        const isFirstSection = index === 0;
        const isLastSection = index === sectionData.length;

        section.entries.forEach((value, index, data) => {
            // @ts-ignore
            value.isFirst = index === 0;

            // @ts-ignore
            value.isLast = index === data.length - 1;

            // @ts-ignore
            value.isFirstSection = isFirstSection;

            // @ts-ignore
            value.isLastSection = isLastSection;
        });

        return section;
    });

    return sectionData as unknown as SectionListData<SectionListEntry<T>>[];
}
