import styled from '@emotion/styled';
import { Table, TableBody, TableContainer, TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import { CreateTimeTrackingCircledIcon } from '../../common/components/icons/CreateTimeTrackingCircledIcon';
import { EditTimeTrackingIcon } from '../../common/components/icons/EditTimeTrackingIcon';
import { RemoveTimeTrackingIcon } from '../../common/components/icons/RemoveTimeTrackingIcon';
import { RestoreTimeTrackingIcon } from '../../common/components/icons/RestoreTimeTrackingIcon';
import { StartTimeTrackingCircledIcon } from '../../common/components/icons/StartTimeTrackingCircledIcon';
import { StopTimeTrackingCircledIcon } from '../../common/components/icons/StopTimeTrackingCircledIcon';
import { SyncIcon } from '../../common/components/icons/SyncIcon';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { NotAvailable } from '../../common/components/NotAvailable';
import { Colors } from '../../core/theme/Colors';
import {
    formatDateTime,
    formatDayDate,
    formatIntervalAsTimeDuration,
    formatTime
} from '../time';
import { TimeEntryAuditEntry } from '../TimeEntry';
import { convertLogToChanges, TimeEntryAuditLogChange } from './auditLog';

const StyledTableContainer = styled(TableContainer)`
    padding: 12px 0;

    > table {
        min-width: 400px;
        border-bottom: 0;

        > tbody {
            > tr {
                > td {
                    padding: 20px 8px;
                    vertical-align: top;

                    &:last-of-type {
                        padding-right: 24px;
                    }
                }
            }
        }
    }

    tr:last-of-type td.timeline:after {
        content: none;
    }

    td.timeline {
        position: relative;
        z-index: 1;
        padding: 16px 2px 16px 18px;

        &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 20px;
            left: 37px;
            width: 1px;
            height: 100%;
            background-color: ${Colors.LineColorLight};
        }
    }
`;

const ChangeHeadline = styled.header`
    @media screen and (min-width: 500px) {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        align-items: flex-start;
    }
`;

const ChangeTitle = styled.h3`
    margin: 0;

    @media screen and (min-width: 500px) {
        min-width: 200px;
    }
`;

const ChangeIconCt = styled.div`
    position: relative;
    z-index: 2;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 16px;
    top: -5px;

    svg {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
    }

    .icon-time-tracking-edit {
        width: 22px;
        height: 22px;
    }

    .icon-time-tracking-add,
    .icon-time-tracking-start,
    .icon-time-tracking-stop {
        width: 26px;
        height: 26px;
    }
`;

const ChangesMeta = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.TextLight};
    font-weight: 300;
    margin: 4px 0 8px;

    @media screen and (min-width: 500px) {
        font-size: 16px;
        line-height: 22px;
        margin: 0;
        flex: 1;
        text-align: right;
    }

    &:hover {
        color: ${Colors.Text};
    }

    a,
    strong {
        font-weight: 600;
    }

    a {
        color: ${Colors.TextLight};

        &:hover {
            color: ${Colors.Text};
        }
    }
`;

const ChangeDetails = styled.div`
    margin-top: 12px;
    padding: 8px 16px;
    background-color: ${Colors.BackgroundLight};
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 20px;

    table {
        width: 100%;
        border-collapse: collapse;

        th {
            width: 120px;
            padding: 4px 8px 4px 0;
            vertical-align: top;
            text-align: left;
            font-weight: 600;
            color: ${Colors.TextLight};
            white-space: nowrap;
        }

        td {
            padding: 4px 0 4px 8px;
            color: ${Colors.Text};
            vertical-align: top;
            text-align: left;
        }

        tr + tr {
            td,
            th {
                border-top: 1px solid ${Colors.LineColorLight};
            }
        }
    }

    .newValue {
        color: ${Colors.Text};
    }

    .oldValue {
        color: ${Colors.RedDarkest};
        text-decoration: line-through;
    }
`;

function getChangeIcon(auditLogChange: TimeEntryAuditLogChange) {
    if (auditLogChange.isSync) {
        return <SyncIcon />;
    }

    switch (auditLogChange.action) {
        case 'start':
            return (
                <StartTimeTrackingCircledIcon
                    sx={{ fill: Colors.Green }}
                    className={'icon-time-tracking-start'}
                />
            );
        case 'stop':
            return (
                <StopTimeTrackingCircledIcon
                    sx={{ fill: Colors.Red }}
                    className={'icon-time-tracking-stop'}
                />
            );
        case 'add':
            return (
                <CreateTimeTrackingCircledIcon
                    sx={{ fill: Colors.Primary }}
                    className={'icon-time-tracking-add'}
                />
            );
        case 'edit':
            return (
                <EditTimeTrackingIcon
                    sx={{ fill: Colors.Secondary }}
                    className={'icon-time-tracking-edit'}
                />
            );
        case 'remove':
            return (
                <RemoveTimeTrackingIcon
                    sx={{ fill: Colors.Secondary }}
                    className={'icon-time-tracking-remove'}
                />
            );
        case 'restore':
            return (
                <RestoreTimeTrackingIcon
                    sx={{ fill: Colors.Primary }}
                    className={'icon-time-tracking-restore'}
                />
            );
        default:
            return '';
    }
}

function getActionLabel(auditLogChange: TimeEntryAuditLogChange) {
    switch (auditLogChange.action) {
        case 'start':
            return 'Zeiterfassung gestartet';
        case 'stop':
            return 'Zeiterfassung gestoppt';
        case 'add':
            return 'Eintrag hinzugefügt';
        case 'edit':
            if (
                auditLogChange.diff.startDate &&
                !auditLogChange.diff.endDate &&
                !auditLogChange.diff.description
            ) {
                return 'Startzeit geändert';
            }
            if (
                !auditLogChange.diff.startDate &&
                auditLogChange.diff.endDate &&
                !auditLogChange.diff.description
            ) {
                return 'Endzeit geändert';
            }
            if (
                !auditLogChange.diff.startDate &&
                !auditLogChange.diff.endDate &&
                auditLogChange.diff.description
            ) {
                return 'Beschreibung geändert';
            }
            return 'Eintrag bearbeitet';
        case 'remove':
            return 'Eintrag gelöscht';
        case 'restore':
            return 'Eintrag wiederhergestellt';
        default:
            return `Eintrag geändert (${auditLogChange.action})`;
    }
}

function getTitleAction(auditLogChange: TimeEntryAuditLogChange) {
    if (auditLogChange.isSync) {
        return `Eintrag synchronisiert (${getActionLabel(auditLogChange)})`;
    }

    return getActionLabel(auditLogChange);
}

export const TimeEntryAuditLog = ({
    log,
    loading
}: {
    log?: TimeEntryAuditEntry[];
    loading: boolean;
}) => {
    const changeLog = useMemo(() => {
        if (!log) {
            return [];
        }
        return convertLogToChanges(log);
    }, [log]);

    if (loading) {
        // TODO add skeleton loader
        return (
            <div style={{ padding: '48px 0' }}>
                <LoadingSpinner label={'Lade Änderungsprotokoll'} center />
            </div>
        );
    }

    if (!changeLog || changeLog.length === 0) {
        return (
            <div
                style={{
                    padding: '48px 0',
                    color: Colors.Secondary,
                    textAlign: 'center'
                }}>
                Keine Änderungen protokolliert
            </div>
        );
    }

    return (
        <div style={{ overflow: 'hidden' }}>
            <StyledTableContainer>
                <Table>
                    <colgroup>
                        <col style={{ width: '32px' }} />
                        <col style={{ width: 'auto' }} />
                    </colgroup>
                    <TableBody>
                        {changeLog.map(change => {
                            const changeDetails = getChangeDetails(change);
                            return (
                                <TableRow key={change.id}>
                                    <td className={'timeline'}>
                                        <ChangeIconCt>
                                            {getChangeIcon(change)}
                                        </ChangeIconCt>
                                    </td>
                                    <td>
                                        <ChangeHeadline>
                                            <ChangeTitle>
                                                {getTitleAction(change)}
                                            </ChangeTitle>
                                            <ChangesMeta>
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap'
                                                    }}>
                                                    <strong>
                                                        {formatDayDate(
                                                            change.date
                                                        )}
                                                    </strong>
                                                    ,{' '}
                                                    <strong>
                                                        {formatTime(
                                                            change.date
                                                        )}{' '}
                                                        Uhr
                                                    </strong>
                                                </span>{' '}
                                                von{' '}
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap'
                                                    }}>
                                                    <a
                                                        href={`/employees/${change.actor.id}`}>
                                                        {change.actorName}
                                                    </a>
                                                </span>
                                            </ChangesMeta>
                                        </ChangeHeadline>

                                        {changeDetails && (
                                            <ChangeDetails>
                                                {changeDetails}
                                            </ChangeDetails>
                                        )}
                                    </td>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </div>
    );
};

function getChangeDetails(change: TimeEntryAuditLogChange) {
    if (change.action === 'restore' || change.action === 'remove') {
        return;
    }

    if (change.action === 'start') {
        return (
            <table>
                <tbody>
                    <tr>
                        <th>Start</th>
                        <td>{formatDateTime(change.diff.startDate?.new)}</td>
                    </tr>
                    {change.new.description ? (
                        <tr>
                            <th>Beschreibung</th>
                            <td>{change.new.description}</td>
                        </tr>
                    ) : null}
                </tbody>
            </table>
        );
    }

    if (change.action === 'stop') {
        return (
            <table>
                <tbody>
                    <tr>
                        <th>Ende</th>
                        <td>{formatDateTime(change.new.endDate)}</td>
                    </tr>
                    <tr>
                        <th>Dauer</th>
                        <td>
                            {formatIntervalAsTimeDuration(
                                change.old.startDate,
                                change.new.endDate
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    if (change.action === 'add') {
        return (
            <table>
                <tbody>
                    <tr>
                        <th>Start</th>
                        <td>{formatDateTime(change.new.startDate)}</td>
                    </tr>
                    <tr>
                        <th>Ende</th>
                        <td>{formatDateTime(change.new.endDate)}</td>
                    </tr>
                    <tr>
                        <th>Dauer</th>
                        <td>
                            {formatIntervalAsTimeDuration(
                                change.new.startDate,
                                change.new.endDate
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th>Beschreibung</th>
                        <td>
                            {change.new.description || (
                                <NotAvailable
                                    tooltipTitle={'Nicht angegeben / Leer'}
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    return (
        <table>
            <tbody>
                {change.diff.startDate ? (
                    <tr>
                        <th>Start</th>
                        <td>
                            <div className="newValue">
                                {formatDateTime(change.new.startDate)}
                            </div>
                            <div className="oldValue">
                                {formatDateTime(change.old.startDate)}
                            </div>
                        </td>
                    </tr>
                ) : null}
                {change.diff.endDate ? (
                    <tr>
                        <th>Ende</th>
                        <td>
                            <div className="newValue">
                                {formatDateTime(change.new.endDate)}
                            </div>
                            <div className="oldValue">
                                {formatDateTime(change.old.endDate)}
                            </div>
                        </td>
                    </tr>
                ) : null}
                {change.new.endDate &&
                (change.diff.startDate || change.diff.endDate) ? (
                    <tr>
                        <th>Dauer</th>
                        <td>
                            <div className="newValue">
                                {formatIntervalAsTimeDuration(
                                    change.new.startDate,
                                    change.new.endDate
                                )}
                            </div>
                            <div className="oldValue">
                                {formatIntervalAsTimeDuration(
                                    change.old.startDate,
                                    change.old.endDate
                                )}
                            </div>
                        </td>
                    </tr>
                ) : null}
                {change.diff.description ? (
                    <tr>
                        <th>Beschreibung</th>
                        <td>
                            <div className="newValue">
                                {change.new.description || (
                                    <NotAvailable
                                        tooltipTitle={'Nicht angegeben / Leer'}
                                    />
                                )}
                            </div>
                            <div className="oldValue">
                                {change.old.description || (
                                    <NotAvailable
                                        tooltipTitle={'Nicht angegeben / Leer'}
                                    />
                                )}
                            </div>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </table>
    );
}
