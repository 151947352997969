import styled from '@emotion/styled';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import { Colors } from '../core/theme/Colors';
import { TimeEntryUpdatedNotificationDataContent } from './dataContent/TimeEntryUpdatedNotificationDataContent';

const Ct = styled.div`
    padding: 4px 1px;

    .is-notification-page & {
        padding: 0 1px;
    }
`;

const CollapseToggle = styled.button`
    background: none;
    border: 0;
    color: ${Colors.Link};
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    outline: 0;
    padding: 4px 2px;
    margin: -2px -2px -2px -5px;

    svg {
        float: left;
        margin-right: 0;
        margin-top: 1px;
        width: 16px;
        height: 16px;
    }

    &:hover,
    &:focus {
        color: ${Colors.LinkHover};
    }

    &:active {
        color: ${Colors.LinkActive};
    }
`;

type NotificationDataProps = {
    data?: Record<string, any> | null;
    type: string;
    collapsed?: boolean;
};

const CollapsedNotificationData = ({
    children
}: PropsWithChildren<unknown>) => {
    const [open, setOpen] = useState(false);
    return (
        <Ct>
            <Collapse in={open} timeout="auto">
                {children}
            </Collapse>
            <CollapseToggle onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                Details {open ? 'ausblenden' : 'einblenden'}
            </CollapseToggle>
        </Ct>
    );
};

export const NotificationData = ({
    data,
    type,
    collapsed = true
}: NotificationDataProps) => {
    if (!data) {
        return null;
    }

    // We only show data of time entry updates for the moment
    if (type !== 'TIME_ENTRY_UPDATED_BY_ADMIN') {
        return null;
    }

    const { nodeId, auditId, changes } = data;
    if (!changes || !changes.length) {
        return null;
    }

    if (collapsed) {
        return (
            <CollapsedNotificationData>
                <TimeEntryUpdatedNotificationDataContent changes={changes} />
            </CollapsedNotificationData>
        );
    }

    return (
        <Ct>
            <TimeEntryUpdatedNotificationDataContent changes={changes} />
        </Ct>
    );
};
