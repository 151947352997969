import { FormikErrors } from 'formik/dist/types';
import { DateTime, Duration, Interval } from 'luxon';
import {
    formatIntervalAsTimeDuration,
    formatSecondsAsTimeDuration,
    getDiff
} from '../time';
import { TimeEntryFormValues } from './TimeEntryFormValues';

export type TimeEntryUpdateInput = {
    startDate: string;
    endDate?: string;
    description?: string | null;
};

export type TimeEntryCreateInput = {
    startDate: string;
    endDate: string;
    description?: string | null;
};

export type TimeEntryInput = TimeEntryCreateInput | TimeEntryUpdateInput;

const DurationRegex = /^([0-9]{0,4})?(:[0-9]*)?(:[0-9]*)?$/;

export class TimeEntryFormService {
    static update(
        values: TimeEntryFormValues,
        key: 'date' | 'from' | 'to' | 'duration' | 'description',
        value: string | number
    ): TimeEntryFormValues {
        const input = TimeEntryFormService.convertToInput(values);
        const updatedInput = TimeEntryFormService.updateFormInput(
            input,
            key,
            value
        );
        return {
            ...values,
            ...TimeEntryFormService.convertToValues(updatedInput)
        };
    }

    static validate(
        values: TimeEntryFormValues
    ): FormikErrors<TimeEntryFormValues & { other: string }> | undefined {
        const { startDate, startTime, endDate, endTime, isActiveTracking } =
            values;

        /*
         * Check start date
         */
        if (!startDate) {
            return {
                startDate: 'Bitte gib ein Datum an'
            };
        }

        const startDateAsDateTime = DateTime.fromISO(startDate);
        if (!startDateAsDateTime.isValid) {
            return {
                startDate: 'Ungültiges Datum'
            };
        }
        if (startDateAsDateTime.startOf('day') > DateTime.now()) {
            return {
                startDate: 'Datum liegt in der Zukunft'
            };
        }

        /*
         * Check start time
         */
        if (!startTime) {
            return {
                startTime: 'Bitte gib eine Uhrzeit an'
            };
        }

        const startDuration = Duration.fromISOTime(startTime);
        if (!startDuration.isValid) {
            return {
                startTime: 'Ungültige Uhrzeit'
            };
        }

        /*
         * Check start
         */
        const start = startDateAsDateTime.plus(startDuration);
        if (start > DateTime.now()) {
            return {
                startTime: 'Uhrzeit liegt in der Zukunft'
            };
        }

        // If this is an active tracking we can ignore end date and time
        if (isActiveTracking) {
            return;
        }

        if (!endDate) {
            return {
                endDate: 'Bitte Enddatum angeben'
            };
        }

        const endDateAsDateTime = DateTime.fromISO(endDate);
        if (!endDateAsDateTime.isValid) {
            return {
                endDate: 'Ungültiges Enddatum'
            };
        }
        if (endDateAsDateTime.startOf('day') > DateTime.now()) {
            return {
                endDate: 'Endzeitpunkt liegt in der Zukunft'
            };
        }

        /*
         * Check end time
         */
        if (!endTime) {
            return {
                endTime: 'Bitte gib eine Uhrzeit an'
            };
        }

        const endDuration = Duration.fromISOTime(endTime);
        if (!endDuration.isValid) {
            return {
                endTime: 'Ungültige Uhrzeit'
            };
        }

        /*
         * Check end
         */
        const end = endDateAsDateTime.plus(endDuration);
        if (end > DateTime.now()) {
            return {
                endTime: 'Uhrzeit liegt in der Zukunft'
            };
        }

        if (end < start) {
            return {
                endDate: 'Endzeit liegt vor Startzeit',
                endTime: 'Endzeit liegt vor Startzeit'
            };
        }

        /*
         * Duration
         */
        const interval = Interval.fromDateTimes(start, end);
        if (interval.toDuration().as('hours') >= 24) {
            return {
                duration: 'Dauer kann maximal 24 Stunden betragen'
            };
        }

        return;
    }

    static convertDateAndTimeToDateTime(date: string, time: string): DateTime {
        const parsedDate = DateTime.fromISO(date).toISODate();
        const parsedTime = Duration.fromISOTime(time).toISOTime();
        return DateTime.fromISO(`${parsedDate}T${parsedTime}`);
    }

    public static convertToInput(
        formValues: TimeEntryFormValues
    ): TimeEntryInput {
        const startDate = TimeEntryFormService.convertDateAndTimeToDateTime(
            formValues.startDate,
            formValues.startTime
        ).toISO()!;
        const endDate =
            formValues.isActiveTracking ||
            !formValues.endDate ||
            !formValues.endTime
                ? undefined
                : TimeEntryFormService.convertDateAndTimeToDateTime(
                      formValues.endDate,
                      formValues.endTime
                  ).toISO()!;

        return {
            startDate,
            endDate,
            description: formValues.description
        };
    }

    private static convertToValues(
        input: TimeEntryInput
    ): Partial<TimeEntryFormValues> {
        const startDateTime = DateTime.fromISO(input.startDate!);
        const endDateTime = input.endDate
            ? DateTime.fromISO(input.endDate)
            : undefined;

        return {
            startDate: startDateTime.toISODate()!,
            startTime: startDateTime.set({ millisecond: 0 }).toISOTime({
                suppressMilliseconds: true,
                includeOffset: false
            })!,
            endDate: endDateTime ? endDateTime.toISODate()! : undefined,
            endTime: endDateTime
                ? endDateTime.set({ millisecond: 0 }).toISOTime({
                      suppressMilliseconds: true,
                      includeOffset: false
                  })!
                : undefined,
            duration: !endDateTime
                ? undefined
                : formatIntervalAsTimeDuration(startDateTime, endDateTime)
        };
    }

    /**
     * Tries to parse the user value into duration
     * @param displayValue
     */
    public static parseDurationInput(
        displayValue?: string
    ): Duration | undefined {
        if (!displayValue) {
            return;
        }

        // Do some cleanup
        let cleanedValue = displayValue.replaceAll(' ', '');

        const matches = cleanedValue.match(DurationRegex);
        if (!matches || matches.length === 0) {
            return;
        }

        if (matches.length > 1) {
            const hours = matches[1];
            const minutes = TimeEntryFormService.sanitizeDurationInput(
                matches[2]
            );
            const seconds = TimeEntryFormService.sanitizeDurationInput(
                matches[3]
            );

            return Duration.fromObject({
                hours: hours ? parseInt(hours, 10) : 0,
                minutes: minutes ? parseInt(minutes, 10) : 0,
                seconds: seconds ? parseInt(seconds, 10) : 0
            });
        }

        return;
    }

    /**
     * Converts the users input a valid iso timestamp
     * @param displayValue
     */
    public static autoFormatDurationInput(displayValue: string): string {
        const duration = TimeEntryFormService.parseDurationInput(displayValue);
        if (!duration || !duration.isValid) {
            return displayValue;
        }

        return formatSecondsAsTimeDuration(Math.floor(duration.as('seconds')));
    }

    private static sanitizeDurationInput(
        value: string | undefined
    ): string | undefined {
        if (!value) {
            return undefined;
        }

        return value.replaceAll(':', '');
    }

    /**
     * Updates the time entry input based on the given key/value pair
     *
     * @param input
     * @param key
     * @param value
     */
    private static updateFormInput(
        input: TimeEntryInput,
        key: 'date' | 'from' | 'to' | 'duration' | 'description',
        value: string | number
    ): TimeEntryInput {
        const workingCopy = { ...input };

        switch (key) {
            case 'date':
                TimeEntryFormService.setFormInputDate(
                    workingCopy,
                    value as string
                );
                break;
            case 'from':
                TimeEntryFormService.setFormInputStartTime(
                    workingCopy,
                    value as string,
                    false
                );
                break;
            case 'to':
                TimeEntryFormService.setFormInputEndTime(
                    workingCopy,
                    value as string,
                    false
                );
                break;
            case 'duration':
                TimeEntryFormService.setFormInputDuration(
                    workingCopy,
                    value as number
                );
                break;
            case 'description':
                workingCopy.description = value as string;
                break;
            default:
                console.error(
                    `Unknown time entry form input update key ${key}`
                );
        }

        /*
         * Apply all helpers to ensure a valid time entry
         */
        TimeEntryFormService.applyHelpers(workingCopy, key);

        return workingCopy;
    }

    /**
     * Apply some helpers:
     *
     * 1) user changed start time s.t. start time > now ? set date to yesterday
     * 2) user change end time:
     *   2.1) s.t. duration(start, end) > 1 day ? set end date s.t. duration < 1day and time remains unchanged
     *   2.2) s.t. duration(start, end) < 0 ? increase end day by one
     *
     * @param input
     * @param changedField
     */
    private static applyHelpers(
        input: TimeEntryInput,
        changedField: 'date' | 'from' | 'to' | 'duration' | 'description'
    ) {
        // We apply the helper only for specific field changes
        if (['date', 'from', 'to', 'duration'].indexOf(changedField) < 0) {
            return;
        }

        const now = DateTime.now();

        let startDateTime = DateTime.fromISO(input.startDate);
        let endDateTime = input.endDate ? DateTime.fromISO(input.endDate) : now;
        const isActiveTimeTracking = !input.endDate;

        /*
         * Helper 1: Changed from time and now the start is in the future -> set start date to yesterday
         */
        if (changedField === 'from' && startDateTime > now) {
            startDateTime = startDateTime.minus({ days: 1 });
        }

        /*
         * Helper 2: Changed to time and ...
         */
        if (changedField === 'to') {
            const diff = endDateTime.diff(startDateTime).as('days');
            if (diff > 1) {
                // ... now the end is more than 24 hours after start -> set end date s.t. duration < 24h and time remains unchanged
                endDateTime = startDateTime.plus({ days: diff % 1 });
            } else if (diff < 0) {
                // ... now the end is before the start -> increase day by one
                endDateTime = endDateTime.plus({ days: 1 });
            }
        }

        /*
         * Update input / form state
         */
        input.startDate = startDateTime.toISO()!;
        if (!isActiveTimeTracking) {
            input.endDate = endDateTime.toISO()!;
        }
    }

    /**
     * Sets a new base date for the time entry
     *
     * @param input
     * @param datetime
     */
    private static setFormInputDate(input: TimeEntryInput, datetime: string) {
        const baseDate = DateTime.fromISO(datetime);

        // 1) Get end to start diff
        const msDiff = getDiff(input.startDate, input.endDate, 'milliseconds');

        // 2) Set year, month and day of the start date
        const startDate = DateTime.fromISO(input.startDate).set({
            year: baseDate.year,
            month: baseDate.month,
            day: baseDate.day
        });
        input.startDate = startDate.toISO()!;

        // Not the active tracking - update end date as well
        if (input.endDate) {
            // Updated end date: startDate + msDiff
            const endDate = startDate.plus({ milliseconds: msDiff });
            input.endDate = endDate.toISO()!;
        }
    }

    /**
     * Set the startDate of the time entry
     * @param input
     * @param timeIso
     * @param withSeconds
     */
    private static setFormInputStartTime(
        input: TimeEntryInput,
        timeIso: string,
        withSeconds: boolean = true
    ) {
        input.startDate = TimeEntryFormService.updateTime(
            input.startDate,
            timeIso,
            withSeconds
        );
    }

    /**
     * Set the endDate of the time entry
     * @param input
     * @param timeIso
     * @param withSeconds
     */
    private static setFormInputEndTime(
        input: TimeEntryInput,
        timeIso: string,
        withSeconds: boolean = true
    ) {
        input.endDate = TimeEntryFormService.updateTime(
            input.endDate!,
            timeIso,
            withSeconds
        );
    }

    private static updateTime(
        dateTimeIso: string,
        timeIso: string,
        withSeconds: boolean = true
    ): string {
        let start = DateTime.fromISO(dateTimeIso);
        const time = DateTime.fromISO(timeIso);

        start = start.set({
            hour: time.hour,
            minute: time.minute,
            second: withSeconds ? time.second : 0,
            millisecond: 0
        });

        return start.toISO()!;
    }

    /**
     * Takes the startDate of the time entry and add the duration to get the new endDate
     * @param input
     * @param durationInSeconds
     */
    private static setFormInputDuration(
        input: TimeEntryInput,
        durationInSeconds: number
    ) {
        if (durationInSeconds < 0) {
            input.endDate = input.startDate;
            return;
        }

        const endDateTime = DateTime.fromISO(input.endDate!);
        if (Math.abs(endDateTime.diff(DateTime.now()).as('seconds')) < 2 * 60) {
            /*
             * Mode 1: End is equal to now -> start  = end - duration
             */
            input.startDate = endDateTime
                .minus({ seconds: durationInSeconds })
                .toISO()!;
        } else {
            /*
             * Mode 2: End is not equal to now: end = start + duration
             */
            input.endDate = DateTime.fromISO(input.startDate)
                .plus({ seconds: durationInSeconds })
                .toISO()!;
        }
    }
}
