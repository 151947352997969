import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { NewLineToBreak } from '../common/components/NewLineToBreak';
import { SkeletonText } from '../common/components/skeleton/SkeletonText';
import { Colors } from '../core/theme/Colors';
import { OnNodeChangeFn } from '../utils';
import {
    Item,
    ItemActiveDot,
    ItemFromTo,
    ItemLabel,
    ItemSummaryCt,
    ItemSummaryItem,
    ItemTotalTime
} from './entry/TimeEntry';
import { TimeEntryEditedByAdminIcon } from './entry/TimeEntryEditedByAdminIcon';
import { TimeEntryDrawerForm } from './form/TimeEntryDrawerForm';
import {
    formatSecondsAsTimeDuration,
    formatTime,
    getActiveTrackingSeconds
} from './time';
import { TimeEntry } from './TimeEntry';

type TimeEntryListItemProps = {
    entry: TimeEntry;
    showActivityIndicators?: boolean;
    size?: 'small' | 'medium' | 'large';
    onChanged?: OnNodeChangeFn;
};

export const TimeEntryListItem = ({
    entry,
    showActivityIndicators,
    size,
    onChanged
}: TimeEntryListItemProps) => {
    const isActive = !entry.endDate;
    const startTime = formatTime(entry.startDate);
    const endTime = isActive ? '' : formatTime(entry.endDate);

    const { description } = entry;

    const [showEditForm, setShowEditForm] = useState(false);

    let unmounted = false;

    useEffect(() => {
        return () => {
            unmounted = true;
        };
    }, []);

    const [totalSeconds, setTotalSeconds] = useState(entry.totalSeconds || 0);

    useEffect(() => {
        let interval;
        if (isActive) {
            const startDate = DateTime.fromISO(entry.startDate!);
            interval = setInterval(() => {
                setTotalSeconds(getActiveTrackingSeconds(startDate));
            }, 40);
        } else {
            setTotalSeconds(entry.totalSeconds || 0);
        }

        return () => (interval ? clearInterval(interval) : undefined);
    }, [entry, isActive]);

    function editTimeEntry() {
        setShowEditForm(true);
    }

    function onFormClosed() {
        if (!unmounted) {
            setShowEditForm(false);
        }
    }

    const totalTime = formatSecondsAsTimeDuration(totalSeconds);

    return (
        <>
            <Item role="button" onClick={editTimeEntry} size={size}>
                <ItemFromTo>
                    {startTime}
                    {' - '}
                    {isActive ? (
                        <SkeletonText
                            width={36}
                            animation={showActivityIndicators ? 'pulse' : false}
                            sx={{ backgroundColor: Colors.TextSkeletonLight }}
                        />
                    ) : (
                        endTime
                    )}
                </ItemFromTo>
                <ItemSummaryCt>
                    {isActive && showActivityIndicators && (
                        <ItemSummaryItem>
                            <ItemActiveDot role="presentation" />
                        </ItemSummaryItem>
                    )}
                    <TimeEntryEditedByAdminIcon timeEntry={entry} />
                    <ItemSummaryItem>
                        <ItemTotalTime>{totalTime}</ItemTotalTime>
                    </ItemSummaryItem>
                </ItemSummaryCt>
                <ItemLabel hasDescription={!!description}>
                    <NewLineToBreak
                        value={description ? description : 'Keine Beschreibung'}
                    />
                </ItemLabel>
            </Item>
            {showEditForm && (
                <TimeEntryDrawerForm
                    entry={entry}
                    onClosed={onFormClosed}
                    onChanged={onChanged}
                />
            )}
        </>
    );
};
