import styled from '@emotion/styled';
import { BarChart, WatchLater } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { DrawerProps } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React, { ReactElement, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import logoImg from '../../assets/images/logo_24h.png';
import logoImg2x from '../../assets/images/logo_24h@2x.png';
import { SignOutLink } from '../auth/components/SignOutLink';
import { User } from '../auth/user';
import { useAuthenticatedContext } from '../hooks/useAuthenticatedContext';
import { AppDrawerListItemLink } from './AppDrawerListItemLink';
import { PathPermissions, Paths } from './paths';

type CategoryLink = {
    id: string;
    label: string;
    icon: ReactElement;
    path: string;
    teaser?: boolean;
    permissions?: string[];
};

type Category = {
    id: string;
    label: string;
    links: CategoryLink[];
};

function getCategories(user: User): Category[] {
    return [
        {
            id: 'main',
            label: '',
            links: [
                {
                    id: 'tracking',
                    label: 'Zeiterfassung',
                    icon: <WatchLater />,
                    path: Paths.WorkTimeTracking
                },
                {
                    id: 'reports',
                    label: 'Bericht',
                    icon: <BarChart />,
                    path: Paths.OrganizationWorkTimeReportWOP,
                    permissions: PathPermissions.OrganizationWorkTimeReport
                }
            ]
        },
        {
            id: 'company',
            label: 'Unternehmen',
            links: [
                {
                    id: 'empoloyees',
                    label: 'Mitarbeiter',
                    icon: <GroupIcon />,
                    path: Paths.EmployeeList,
                    permissions: PathPermissions.Employees
                }
            ]
        }
    ];
}

const CategoryHeader = styled(ListItem)`
    padding-top: 16px;
    padding-bottom: 0;
    border-top: 1px solid #404854;
`;

const CategoryHeaderListItemText = styled(ListItemText)`
    font-size: 16px;
    color: #aaa;
    font-weight: 400;
    letter-spacing: 0.3px;
`;

const LogoContainer = styled.div`
    display: flex;
    height: 56px;
    background: white;
    border-right: 1px solid #f5f5f5;
`;

const Logo = styled.img`
    height: 24px;
    width: 87px;
    display: block;
    margin: auto 0 auto 16px;
`;

const StyledDrawer = styled(Drawer)`
    width: 100%;
    max-width: 300px;
`;

const AccountMenuWrapper = styled.div`
    margin-top: auto;
    padding: 16px;
    border-top: 1px solid #404854;
    font-size: 16px;
    color: #aaa;
    letter-spacing: 0.3px;
`;

const StyledSignOutLink = styled(SignOutLink)`
    display: block;
    color: white;
    margin: 16px -16px 0;
    padding: 16px;
    line-height: 20px;
    cursor: pointer;

    svg {
        float: left;
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
`;

const StyledSettingsLink = styled(NavLink)`
    display: block;
    text-decoration: none;
    color: #aaa;
`;

export const AppDrawerNavigation = (props: DrawerProps) => {
    const { user } = useAuthenticatedContext();

    const categories = useMemo(() => {
        return getCategories(user as User);
    }, [user]);

    return (
        <StyledDrawer variant="temporary" {...props}>
            <LogoContainer>
                <Logo
                    src={logoImg}
                    width={87}
                    height={24}
                    srcSet={`${logoImg} 1x, ${logoImg2x} 2x`}
                    alt="Dajeh - So geht Zeiterfassung"
                />
            </LogoContainer>
            <List>
                {categories
                    .filter(category => canAccessCategory(category, user))
                    .map(({ id, label, links }) => {
                        const catHeader = !label ? null : (
                            <CategoryHeader>
                                <CategoryHeaderListItemText>
                                    {label}
                                </CategoryHeaderListItemText>
                            </CategoryHeader>
                        );

                        return (
                            <React.Fragment key={id ? id : 'main'}>
                                {catHeader}
                                {links
                                    .filter(link => canAccessLink(link, user))
                                    .map(
                                        ({
                                            id: childId,
                                            label,
                                            teaser,
                                            icon,
                                            path
                                        }) => (
                                            <AppDrawerListItemLink
                                                key={childId}
                                                to={path}
                                                icon={icon}
                                                primary={label}
                                                teaser={!!teaser}
                                            />
                                        )
                                    )}
                            </React.Fragment>
                        );
                    })}
            </List>

            {/* Personal */}
            <AccountMenuWrapper>
                <StyledSettingsLink to={Paths.User}>
                    <strong>
                        {user?.firstName} {user?.lastName}
                    </strong>
                    <br />
                    {user?.organization?.name}
                </StyledSettingsLink>

                <StyledSignOutLink>
                    <PowerSettingsNewIcon />
                    Abmelden
                </StyledSignOutLink>
            </AccountMenuWrapper>
        </StyledDrawer>
    );
};

const canAccessLink = (link, user) => {
    return link.permissions
        ? link.permissions.some(permission =>
              user?.permissions?.includes(permission)
          )
        : true;
};

const canAccessCategory = (category, user) => {
    return user
        ? category.links.some(link => canAccessLink(link, user))
        : false;
};
