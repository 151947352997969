import { Alert } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { EmployeeFormValues } from './EmployeeFormValues';

export const EmployeeFormInvitationEmailChangedHint = () => {
    // Grab values and submitForm from context
    const { values, initialValues } = useFormikContext<EmployeeFormValues>();

    if (!values.email) {
        return null;
    }

    if (initialValues.email === values.email.trim()) {
        return null;
    }

    return (
        <Alert severity={'info'} style={{ marginTop: 8 }}>
            <strong>Hinweis:</strong> Durch die Änderung der E-Mail-Adresse wird
            automatisch eine neue Einladungs-E-Mail an die aktualisierte Adresse
            versendet. Die bestehende Einladung wird ungültig.
        </Alert>
    );
};
