import styled from '@emotion/styled';
import { Card, CircularProgress } from '@mui/material';
import { Editor as TinyMceEditor } from '@tinymce/tinymce-react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useUser } from '../../core/auth/useUser';
import { getAppBaseUrl } from '../../core/environment';
import { Colors } from '../../core/theme/Colors';
import { isStrassenUser } from '../../user';

const EditorCard = styled(Card)`
    margin-top: 20px;
    position: relative;
    z-index: 1;
    overflow: visible;
    padding-bottom: 20px;

    .tox-tinymce {
        border: 0;
    }

    .tox-toolbar-overlord {
        background: transparent !important;
        padding: 7px;
        border-bottom: 1px solid ${Colors.SecondaryLight};
    }

    .tox-toolbar__primary {
        background: transparent !important;
        border: 0;
    }

    .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
        border-color: ${Colors.SecondaryLightest};
    }
`;

const PoweredByTinyMceLink = styled.a`
    position: absolute;
    z-index: 2;
    bottom: 5px;
    right: 5px;
    text-decoration: none !important;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.SecondaryLight};

    &:hover {
        color: ${Colors.Secondary};
        text-decoration: underline;
    }
`;

const EditorStyle = `
    @import url('https://use.typekit.net/css4xrj.css');
    body { 
        font-family: source-sans-pro, sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: rgb(9, 20, 26);
        margin: 24px; 
    }
    
    h1 {
        font-size: 22px;
        line-height: 28px;
        font-weight: bold;
        margin: 40px 0 18px;
    }
    
    h2 {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin: 32px 0 16px;
    }
    
    h3 {
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        margin: 24px 0 12px;
    }
    
    hr {
        margin: 40px 0;
        height: 1px;
        border: 0;
        background-color: #09141a;
    }
    
    strong {
        font-weight: 600;
    }
    
    table, tr, td, th {
        border-color: #dddedf;
    }
    
    td {
        vertical-align: top;
    }
    
    :first-child,
    *:first-child {
      margin-top: 0;
    }
`;

const EditorCardOverlay = styled.div`
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
`;

const EditorCardLoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: auto;
`;

export const Editor = ({ onInit, ...otherProps }: any) => {
    const [isInitialized, setInitialized] = useState(false);

    function internalOnInit(evt, editor) {
        setInitialized(true);
        if (typeof onInit === 'function') {
            onInit(evt, editor);
        }
    }

    const user = useUser();
    const showMonthTemplate = isStrassenUser(user);

    return (
        <EditorCard sx={{ minHeight: isInitialized ? 0 : '400px' }}>
            <TinyMceEditor
                tinymceScriptSrc={getAppBaseUrl() + '/tinymce/tinymce.min.js'}
                init={{
                    height: 400,
                    menubar: false,
                    auto_focus: false,
                    language: 'de',
                    plugins: [
                        `advlist lists table paste help autoresize hr emoticons noneditable`,
                        showMonthTemplate ? 'template' : null
                    ],
                    toolbar: `formatselect | bold italic | align | forecolor | table | bullist numlist ${
                        showMonthTemplate ? '| template' : ''
                    }`,
                    content_style: EditorStyle,
                    statusbar: false,
                    noneditable_noneditable_class: 'is-non-editable',
                    block_formats:
                        'Absatz=p; Überschrift 1=h1; Überschrift 2=h2; Überschrift 3=h3',
                    table_appearance_options: false,
                    entity_encoding: 'raw',
                    table_default_attributes: {
                        border: 1
                    },
                    table_default_styles: {
                        width: '100%'
                    },
                    template_replace_values: {
                        date: DateTime.now().toFormat('MMMM yyyy')
                    },
                    template_preview_replace_values: {
                        date: DateTime.now().toFormat('MMMM yyyy')
                    },
                    templates: [
                        {
                            title: `Monatsübersicht`,
                            description:
                                'Fügt eine neue Monatsübersicht in die internen Notizen ein.',
                            url:
                                getAppBaseUrl() +
                                '/templates/internal-note-month-template-strassen.html'
                        }
                    ]
                }}
                onInit={internalOnInit}
                {...otherProps}
            />
            <PoweredByTinyMceLink
                href="https://www.tiny.cloud"
                target="_blank"
                rel="noopener noreferrer">
                Powered by Tiny
            </PoweredByTinyMceLink>
            {!isInitialized && (
                <EditorCardOverlay>
                    <EditorCardLoadingWrapper>
                        <CircularProgress sx={{ marginRight: '16px' }} />
                        Lade Editor
                    </EditorCardLoadingWrapper>
                </EditorCardOverlay>
            )}
        </EditorCard>
    );
};
