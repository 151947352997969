import React, { useMemo, useState } from 'react';
import { EditEmployeeDrawerForm } from '../../../employee/form/EditEmployeeDrawerForm';
import { executeSafe } from '../../../utils';
import {
    GlobalDrawerFormContext,
    GlobalDrawerFormContextProps
} from './GlobalDrawerFormContext';

export const GlobalDrawerFormContextProvider = ({ children }) => {
    const [showEditEmployeeDrawer, setShowEditEmployeeDrawer] = useState(false);
    const [editUserId, setEditUserId] = useState<string | undefined>(undefined);
    const [onChangeCallback, setOnChangeCallback] =
        useState<undefined | (() => void)>(undefined);

    function editEmployeeInDrawerForm(userId: string, onChange?: () => void) {
        setEditUserId(userId);
        setShowEditEmployeeDrawer(true);
        setOnChangeCallback(() => onChange);
    }

    function hideEditEmployeeDrawerForm() {
        setShowEditEmployeeDrawer(false);
    }

    // Construction authentication context
    const globalDrawerFormContext = useMemo<GlobalDrawerFormContextProps>(
        () => ({
            editEmployeeInDrawerForm: editEmployeeInDrawerForm,
            hideEditEmployeeDrawerForm: hideEditEmployeeDrawerForm
        }),
        [showEditEmployeeDrawer]
    );

    return (
        <GlobalDrawerFormContext.Provider value={globalDrawerFormContext}>
            {children}
            {showEditEmployeeDrawer && editUserId && (
                <EditEmployeeDrawerForm
                    key={editUserId}
                    userId={editUserId}
                    onClosed={() => hideEditEmployeeDrawerForm()}
                    onChange={() => {
                        executeSafe(onChangeCallback);
                    }}
                />
            )}
        </GlobalDrawerFormContext.Provider>
    );
};
