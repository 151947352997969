import styled from '@emotion/styled';
import { Card, Container } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import logoImg from '../../assets/images/dajeh-logo_362.png';
import { ContentCard } from '../../common/components/layout/ContentCard';
import { PageBody } from '../components/page/PageBody';
import { PublicPageHeader } from '../components/page/PublicPageHeader';
import { PageHeadline } from '../components/page/PageHeadline';
import { ProtectedPage } from '../auth/components/ProtectedPage';
import { PublicPage } from '../auth/components/PublicPage';
import { useAuthenticatedContext } from '../hooks/useAuthenticatedContext';

const PublicPageWrapper = styled.div`
    flex: 1;
    margin-top: 15vh;
    height: 100%;
    width: 100%;
    text-align: center;
`;

const Logo = styled.img`
    height: 100px;
    width: auto;
    display: block;
    margin: 0 auto 32px auto;
`;

const FoFErrorMessage = styled.div`
    margin: 24px 16px;
`;

type FourOFourPageProps = PropsWithChildren<{
    title?: string;
}>;

export const FourOFourPage = ({ title, children }: FourOFourPageProps) => {
    const { user } = useAuthenticatedContext();

    const _title = title || 'Seite nicht gefunden';
    const _children = children ? (
        children
    ) : (
        <FoFErrorMessage>
            Unter dem eingegebenen Pfad konnten wir nichts finden :-(
        </FoFErrorMessage>
    );

    if (user) {
        return (
            <ProtectedPage title={_title}>
                <PageBody>
                    <ContentCard
                        style={{
                            maxWidth: 600,
                            margin: '0 auto',
                            textAlign: 'center'
                        }}>
                        <PageHeadline>{_title}</PageHeadline>
                        {_children}
                    </ContentCard>
                </PageBody>
            </ProtectedPage>
        );
    }

    return (
        <PublicPage title={_title}>
            <PublicPageWrapper>
                <Logo src={logoImg} alt="Dajeh" />

                <Container>
                    <PageBody>
                        <ContentCard
                            style={{
                                maxWidth: 600,
                                margin: '0 auto',
                                textAlign: 'center'
                            }}>
                            <PageHeadline>{_title}</PageHeadline>
                            {_children}
                        </ContentCard>
                    </PageBody>
                </Container>
            </PublicPageWrapper>
        </PublicPage>
    );
};
