import styled from '@emotion/styled';
import {
    Alert,
    AlertTitle,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { getMailToSupport } from '../../common/mailto';
import { getUserName } from '../../user';
import { useUser } from '../auth/useUser';
import { Colors } from '../theme/Colors';

const PROLONG_TRIAL_EMAIL_SUBJECT = 'Testphase verlängern';
const PROLONG_TRIAL_EMAIL_BODY = `Hallo Dajeh-Team,

ich möchte die Testphase für $1 ($2) verlängern.

Mit freundlichen Grüßen

`;

const CONVERT_TO_SUBSCRIPTION_EMAIL_SUBJECT = 'Abo abschließen';
const CONVERT_TO_SUBSCRIPTION_EMAIL_BODY = `Hallo Dajeh-Team,

ich möchte für $1 ($2) in ein bezahltes Abonnement wechseln. Bitte melden Sie sich bei mir zwecks weiterer Schritte.

[Hier meine Telefonnummer: ...]

Mit freundlichen Grüßen

`;

const RENEW_SUBSCRIPTION_EMAIL_SUBJECT = 'Abonnement erneuern';
const RENEW_SUBSCRIPTION_EMAIL_BODY = `Hallo Dajeh-Team,

ich möchte mein Abonnement für $1 ($2) erneuern. Bitte melden Sie sich bei mir zwecks weiterer Schritte.

Mit freundlichen Grüßen

`;

function getProlongTrialMailToLink(
    organizationName: string,
    organizationId: string
) {
    return getMailToSupport(
        PROLONG_TRIAL_EMAIL_SUBJECT,
        PROLONG_TRIAL_EMAIL_BODY.replace('$1', organizationName).replace(
            '$2',
            organizationId
        )
    );
}

function getConvertToSubscriptionMailToLink(
    organizationName: string,
    organizationId: string
) {
    return getMailToSupport(
        CONVERT_TO_SUBSCRIPTION_EMAIL_SUBJECT,
        CONVERT_TO_SUBSCRIPTION_EMAIL_BODY.replace(
            '$1',
            organizationName
        ).replace('$2', organizationId)
    );
}

function getRenewSubscriptionMailToLink(
    organizationName: string,
    organizationId: string
) {
    return getMailToSupport(
        RENEW_SUBSCRIPTION_EMAIL_SUBJECT,
        RENEW_SUBSCRIPTION_EMAIL_BODY.replace('$1', organizationName).replace(
            '$2',
            organizationId
        )
    );
}

const TrailPeriodIndicatorBadge = styled.span`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    background-color: #fa9f5a;
    border-radius: 15px;
    padding: 2px 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    cursor: pointer;
    margin: 0 10px;
    text-align: center;
    color: white;
`;

function getTrialEndDurationLeftMessage(
    trialEndDateTime: DateTime,
    nochOrIn: 'noch' | 'in' = 'in'
) {
    const isNoch = nochOrIn === 'noch';
    const durationUntilEndOfTrial = trialEndDateTime.diffNow();

    const daysLeft = durationUntilEndOfTrial.as('days');
    if (daysLeft >= 2) {
        return `${Math.ceil(daysLeft)} Tage${isNoch ? '' : 'n'}`;
    }

    const hoursLeft = durationUntilEndOfTrial.as('hours');

    if (daysLeft >= 1) {
        if (hoursLeft > 24 && !isNoch) {
            return `1 Tag und ${Math.ceil(hoursLeft) - 24} Stunden`;
        }
        return `eine${isNoch ? 'n' : 'm'} Tag`;
    }

    if (hoursLeft >= 2) {
        return `${Math.ceil(hoursLeft)} Stunden`;
    }

    if (isNoch) {
        return durationUntilEndOfTrial.toFormat('h:mm') + 'h';
    }

    const minutesLeft = durationUntilEndOfTrial.as('minutes');
    if (hoursLeft >= 1) {
        return `einer Stunde und ${Math.ceil(minutesLeft) - 60} Minuten`;
    }

    return `${Math.ceil(minutesLeft)} Minuten`;
}

export const MainNavigationTrialPeriodIndicator = () => {
    const [trialModalOpen, setSubscriptionModalOpen] = useState(false);

    const user = useUser();
    if (!user) {
        return null;
    }

    const { organization } = user;
    if (!organization) {
        return null;
    }

    const { activeSubscription_BETA } = organization || {};
    if (!activeSubscription_BETA) {
        return null;
    }

    const isTrial = activeSubscription_BETA.isTrial;
    const endOfSubscription = DateTime.fromISO(
        activeSubscription_BETA.validUntil
    );
    const isExpired = endOfSubscription < DateTime.now();
    const endsSoon = endOfSubscription.diffNow().as('days') < 3;

    // We don't show anything for non-trial subscriptions that are neither expired nor ending soon
    if (!isTrial && !isExpired && !endsSoon) {
        return null;
    }

    function openSubscriptionModal() {
        setSubscriptionModalOpen(true);
    }

    function closeSubscriptionModal() {
        setSubscriptionModalOpen(false);
    }

    const endOfSubscriptionFormatted = `${endOfSubscription.toLocaleString(
        DateTime.DATE_HUGE
    )} um ${endOfSubscription.toLocaleString(DateTime.TIME_SIMPLE)} Uhr`;
    const subscriptionEndsInLabel = getTrialEndDurationLeftMessage(
        endOfSubscription,
        'in'
    );
    const subscriptionDurationLeft = getTrialEndDurationLeftMessage(
        endOfSubscription,
        'noch'
    );

    const tooltipTitle = isExpired
        ? isTrial
            ? 'Ihre Testphase is beendet'
            : 'Ihr Abo ist beendet'
        : (endsSoon
              ? isTrial
                  ? `Ihre Testphase endet bald`
                  : 'Ihr Abo endet bald'
              : `Ihre Testphase endet in ${subscriptionEndsInLabel}, am ${endOfSubscriptionFormatted}`) +
          ` – jetzt ${isTrial ? 'Abo abschließen' : 'erneuern'}${
              isTrial && endsSoon ? ' oder verlängern' : ''
          }`;

    return (
        <>
            <Tooltip title={tooltipTitle}>
                <TrailPeriodIndicatorBadge
                    role="button"
                    onClick={openSubscriptionModal}
                    style={{
                        backgroundColor: isExpired
                            ? Colors.Red
                            : endsSoon
                            ? Colors.Orange
                            : Colors.Background,
                        color:
                            isExpired || endsSoon ? 'white' : Colors.TextLight
                    }}>
                    {isExpired
                        ? isTrial
                            ? 'Testphase beendet'
                            : 'Abo beendet'
                        : `Noch ${subscriptionDurationLeft}`}
                </TrailPeriodIndicatorBadge>
            </Tooltip>
            {trialModalOpen && (
                <Dialog
                    open={true}
                    onClose={closeSubscriptionModal}
                    aria-labelledby={`subscription-dialog-title`}
                    aria-describedby={`subscription-dialog-description`}>
                    <DialogTitle id={`subscription-dialog-title`}>
                        {isExpired
                            ? isTrial
                                ? 'Testphase beendet'
                                : 'Abo beendet'
                            : isTrial
                            ? `Testphase endet ${
                                  endsSoon
                                      ? 'bald'
                                      : `in ${subscriptionEndsInLabel}`
                              }`
                            : 'Abo endet bald'}
                    </DialogTitle>
                    <DialogContent
                        style={{ fontSize: '1.1em', lineHeight: 1.3 }}>
                        <DialogContentText
                            id={`subscription-dialog-description`}
                            style={{
                                fontSize: '20px',
                                lineHeight: 1.5,
                                marginTop: '16px'
                            }}>
                            {isTrial
                                ? 'Ihre kostenfreie Testphase mit Dajeh '
                                : 'Ihr Dajeh-Abo '}
                            {isExpired ? (
                                `ist ${isTrial ? 'vorüber' : 'beendet'}.`
                            ) : (
                                <>
                                    endet in{' '}
                                    <strong
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}>
                                        {subscriptionEndsInLabel}
                                    </strong>
                                    , am {endOfSubscriptionFormatted}.
                                </>
                            )}
                        </DialogContentText>

                        {!isTrial && isExpired && (
                            <Alert
                                severity="warning"
                                style={{ margin: '16px 0 32px' }}>
                                Als Eigentümer haben Sie weiterhin Zugriff auf
                                sämtliche Daten aus Ihrem Unternehmen, können
                                aber keine Zeiteinträge oder Mitarbeiter
                                hinzufügen, bestehende ändern oder löschen.{' '}
                                <br />
                                <br />
                                Ihre Mitarbeiter haben keinen Zugriff mehr auf
                                das System.
                            </Alert>
                        )}

                        {isTrial && (endsSoon || isExpired) ? (
                            <>
                                <hr />
                                <p>
                                    Benötigen Sie <strong>mehr Zeit</strong>
                                    ? Dann kontaktieren Sie uns: <br />
                                    <SecondaryButton
                                        variant={'contained'}
                                        sx={{ mt: 2 }}
                                        href={getProlongTrialMailToLink(
                                            organization!.name,
                                            organization!.id
                                        )}>
                                        Testphase verlängern
                                    </SecondaryButton>
                                </p>
                            </>
                        ) : null}
                        {isTrial && <hr />}
                        <p>
                            {isTrial && endsSoon ? 'Oder jetzt' : 'Jetzt'}{' '}
                            <strong>
                                Abo {isTrial ? 'abschließen' : 'erneuern'}
                            </strong>{' '}
                            und {!isTrial ? 'weiterhin ' : ''}genießen:
                        </p>
                        <ul>
                            <li>Unbegrenzter Zugang zu allen Funktionen</li>
                            <li>Priorisierter Support für schnelle Hilfe</li>
                            <li>
                                Zugriff auf die neuesten Features und Updates
                            </li>
                        </ul>
                        {!isTrial && endsSoon && (
                            <>
                                <p>
                                    Unser exklusives Angebot:
                                    <br />
                                    <strong>
                                        20% Rabatt auf den nächsten
                                        Abrechnungszeitraum
                                    </strong>
                                </p>
                                <p>Jetzt Abo erneuern und sparen!</p>
                            </>
                        )}
                        <PrimaryButton
                            href={(isTrial
                                ? getConvertToSubscriptionMailToLink
                                : getRenewSubscriptionMailToLink)(
                                organization!.name,
                                organization!.id
                            )}>
                            Sales kontaktieren
                        </PrimaryButton>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton onClick={closeSubscriptionModal}>
                            Schließen
                        </SecondaryButton>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
