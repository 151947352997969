import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import {
    Alert,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { LoadingWrapper } from '../../../common/components/LoadingWrapper';
import {
    getResponseErrorMessage,
    isMissingPermissionError
} from '../../../core/api/error';
import { MissingPermissionError } from '../../../core/auth/components/MissingPermissionError';
import { User } from '../../../core/auth/user';
import { toTimeEntryInsightsMap } from '../insights';
import { QUERY_GET_ORGANIZATION_MEMBER_WORK_TIME_REPORT_SUMMARY } from '../queries';
import { OrganizationMemberWorkTimeDayReport } from './OrganizationMemberWorkTimeDayReport';
import { OrganizationMemberWorkTimeReportDetailsSkeleton } from './OrganizationMemberWorkTimeReportDetailsSkeleton';
import { TimeEntryInsightDto } from './organizationWorkTimeReport.types';

const Container = styled.div`
    padding: 8px 24px 16px 16px;
`;

type OrgMemberWorkTimeReportDetailsProps = {
    user: User;
    month: DateTime;
    shown: boolean;
};

export const OrganizationMemberWorkTimeReportDetails = ({
    user,
    month,
    shown
}: OrgMemberWorkTimeReportDetailsProps) => {
    if (!shown) {
        return null;
    }

    return (
        <InternalOrganizationMemberWorkTimeReportDetails
            user={user}
            month={month}
        />
    );
};

const InternalOrganizationMemberWorkTimeReportDetails = ({
    user,
    month
}: Omit<OrgMemberWorkTimeReportDetailsProps, 'shown'>) => {
    const { loading, error, data } = useQuery(
        QUERY_GET_ORGANIZATION_MEMBER_WORK_TIME_REPORT_SUMMARY,
        {
            variables: {
                userId: user.id,
                month: `${month.toFormat('yyyy-LL')}`
            },
            pollInterval: 5 * 60 * 1000
        }
    );

    const _insights = data?.me?.organization?.memberWorkTimeReport?.insights;
    const timeEntryInsights = useMemo(() => {
        if (_insights) {
            return toTimeEntryInsightsMap(_insights);
        }

        return new Map<string, TimeEntryInsightDto[]>();
    }, [_insights]);

    if (isMissingPermissionError(error)) {
        return <MissingPermissionError />;
    }

    if (loading && !data) {
        return (
            <OrganizationMemberWorkTimeReportDetailsSkeleton
                month={month}
                user={user}
            />
        );
    }

    if (error) {
        const reason = getResponseErrorMessage(error);
        return (
            <Container>
                <Alert severity="error">
                    Fehler beim Laden des Monatsberichts für Mitarbeiter{' '}
                    {user.firstName} {user.lastName}: <br />
                    <small>{reason}</small>
                </Alert>
            </Container>
        );
    }

    const report = data?.me?.organization?.memberWorkTimeReport;
    if (!report) {
        return (
            <Container>
                <Alert severity="warning">
                    Uns liegen keine Daten für den Monat{' '}
                    {month.toFormat('MMMM yyyy')} von Mitarbeiter{' '}
                    {user.firstName} {user.lastName} vor
                </Alert>
            </Container>
        );
    }

    return (
        <LoadingWrapper
            loading={loading}
            label={`Lade Monatsbericht für Mitarbeiter ${user.firstName} ${user.lastName} ...`}>
            <Box sx={{ pb: '32px' }}>
                <Table
                    size="small"
                    style={{
                        borderTop: '1px solid rgba(224, 224, 224, 1)',
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        tableLayout: 'fixed'
                    }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width="100px" colSpan={2}>
                                Tag
                            </TableCell>
                            <TableCell width="100px" align="right">
                                Arbeitszeit
                            </TableCell>
                            <TableCell width="120px" align="center">
                                Von - Bis
                            </TableCell>
                            <TableCell width="90px" align="right">
                                Stunden
                            </TableCell>
                            <TableCell>Beschreibung</TableCell>
                            <TableCell width="135" align="center">
                                Hinweis
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {report.days.map((dayData, dayIndex) => (
                            <OrganizationMemberWorkTimeDayReport
                                key={dayData.date}
                                dayIndex={dayIndex}
                                data={dayData}
                                insights={timeEntryInsights}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </LoadingWrapper>
    );
};
