import React, { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import { useAuthenticatedContext } from '../../hooks/useAuthenticatedContext';

export const SignOutLink = (props: PropsWithChildren<AnchorHTMLAttributes<any>>) => {
    const authContext = useAuthenticatedContext();

    function signOut() {
        authContext.signOut();
    }

    return (
        <a onClick={signOut} role="button" {...props}>
            {props.children}
        </a>
    )
}
