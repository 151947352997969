import React from 'react';
import { ProtectedPage } from '../core/auth/components/ProtectedPage';
import { Permission } from '../core/auth/permission';
import { EmployeePageContentWrapper } from './EmployeePageContentWrapper';
import {
    EmployeeProfilePageContent,
    EmployeeProfilePageContentSkeleton
} from './EmployeeProfilePageContent';

export const EmployeeProfilePage = () => {
    return (
        <ProtectedPage permissions={Permission.Manage_Users}>
            <EmployeePageContentWrapper
                subPageTitle={'Profil'}
                renderContent={(user, refetchUser) => (
                    <EmployeeProfilePageContent
                        user={user}
                        refetchUser={refetchUser}
                    />
                )}
                renderSkeletonContent={() => (
                    <EmployeeProfilePageContentSkeleton />
                )}
            />
        </ProtectedPage>
    );
};
