import styled from '@emotion/styled';
import { Colors } from '../../theme/Colors';

export const ListWithFilters = styled.div`
    background: white;
    border-radius: 6px;
    padding: 4px 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    th {
        background: ${Colors.BackgroundLight};
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: 0.4px;
        border-bottom: 1px solid ${Colors.LineColor};
        color: ${Colors.TextLight};
        padding-top: 14px;
        padding-bottom: 13px;
    }
`;
