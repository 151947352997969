import { ApolloError, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Alert } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import logoImg from '../../assets/images/dajeh-logo_362.png';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { PublicPageCard } from '../../common/components/page/PublicPageCard';
import { PublicPageCardTitle } from '../../common/components/page/PublicPageCardTitle';
import { isApiErrorCode } from '../../core/api/error';
import { PublicPage } from '../../core/auth/components/PublicPage';
import { useUser } from '../../core/auth/useUser';
import { getSupportEmailAddress } from '../../core/environment';
import { useAuthenticatedContext } from '../../core/hooks/useAuthenticatedContext';
import { OrganizationActivationForm } from './OrganizationActivationForm';
import { OrganizationActivationPublicData_Beta } from './OrganizationActivationPublicData';
import { OrganizationAlreadyActivated } from './OrganizationAlreadyActivated';
import { QUERY_ORGANIZATION_ACTIVATION_PUBLIC_DATA_BETA } from './queries';

const Wrapper = styled.div`
    flex: 1;
    margin-top: 15vh;
    height: 100%;
    width: 100%;
`;

const Logo = styled.img`
    height: 100px;
    width: auto;
    display: block;
    margin: 0 auto 32px auto;
`;

const PageWrapper = ({ children }: PropsWithChildren<any>) => (
    <PublicPage title="Konto aktivieren" viewableIfAuthenticated={true}>
        <Wrapper>
            <Logo src={logoImg} alt="Dajeh" />
            {children}
        </Wrapper>
    </PublicPage>
);

const ErrorWrapper = styled.div`
    margin: 24px auto 0;
    max-width: 600px;
    text-align: left;
`;

function getLoadErrorMessage(error: ApolloError | undefined) {
    if (!error) {
        return (
            <>
                Es konnten keine Aktivierungsdaten gefunden werden. Bitte
                kontaktieren Sie den Dajeh-Support unter{' '}
                <a href={`mailto:${getSupportEmailAddress()}`}>
                    {getSupportEmailAddress()}
                </a>
                , um die Kontoaktivierung erneut zu starten.
            </>
        );
    }

    if (error?.message?.indexOf('is not a valid id') >= 0) {
        return `Der Aktivierungs-Code ist ungültig. Bitte kontaktieren Sie den Dajeh-Support unter ${getSupportEmailAddress()}, damit wir Ihnen weiterhelfen können.`;
    }

    return error.message;
}

const ActionWrapper = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

export const OrganizationActivationPage = () => {
    let { token } = useParams();
    const { loading, error, data } = useQuery<{
        publicOrganizationActivationData_Beta: OrganizationActivationPublicData_Beta;
    }>(QUERY_ORGANIZATION_ACTIVATION_PUBLIC_DATA_BETA, {
        variables: {
            token: token
        }
    });

    const authContext = useAuthenticatedContext();

    const user = useUser();

    if (loading) {
        return (
            <PageWrapper>
                <PublicPageCard>
                    <PublicPageCardTitle>Konto aktivieren</PublicPageCardTitle>

                    <div style={{ padding: '32px 0' }}>
                        <LoadingSpinner center label={'Lade Daten'} />
                    </div>
                </PublicPageCard>
            </PageWrapper>
        );
    }

    if (error || !data || !data.publicOrganizationActivationData_Beta) {
        if (isApiErrorCode('organization_already_activated', error)) {
            return (
                <PageWrapper>
                    <OrganizationAlreadyActivated />
                </PageWrapper>
            );
        }

        if (isApiErrorCode('organization_activation_token_expired', error)) {
            return (
                <PageWrapper>
                    <PublicPageCard>
                        <PublicPageCardTitle>
                            Kontoaktivierung abgelaufen
                        </PublicPageCardTitle>

                        <ErrorWrapper>
                            <Alert severity="error">
                                Das Token zur Kontoaktivierung ist nicht mehr
                                gültig. Bitte kontaktieren Sie den{' '}
                                <a href={`mailto:${getSupportEmailAddress()}`}>
                                    Dajeh Support
                                </a>{' '}
                                um eine neue E-Mail zur Kontoaktivierung zu
                                erhalten.
                            </Alert>
                        </ErrorWrapper>

                        <ActionWrapper>
                            <PrimaryButton
                                href={`mailto:${getSupportEmailAddress()}`}>
                                Support kontaktieren
                            </PrimaryButton>
                        </ActionWrapper>
                    </PublicPageCard>
                </PageWrapper>
            );
        }

        const message = getLoadErrorMessage(error);
        return (
            <PageWrapper>
                <PublicPageCard>
                    <PublicPageCardTitle>
                        Daten konnten nicht geladen werden
                    </PublicPageCardTitle>

                    <ErrorWrapper>
                        <Alert severity="error">{message}</Alert>
                    </ErrorWrapper>
                </PublicPageCard>
            </PageWrapper>
        );
    }

    if (user) {
        return (
            <PageWrapper>
                <PublicPageCard>
                    <PublicPageCardTitle>
                        Konto aktivieren - noch angemeldet
                    </PublicPageCardTitle>

                    <ErrorWrapper>
                        <Alert severity="error">
                            Sie sind bereits mit einem anderen Unternehmenskonto
                            im System angemeldet. Bitte melden Sie sich zuerst
                            ab bevor Sie das neue Konto aktivieren.
                        </Alert>
                    </ErrorWrapper>

                    <ActionWrapper>
                        <PrimaryButton href={`/`}>Zur Home-Seite</PrimaryButton>
                        <SecondaryButton onClick={() => authContext.signOut()}>
                            Abmelden
                        </SecondaryButton>
                    </ActionWrapper>
                </PublicPageCard>
            </PageWrapper>
        );
    }

    const { publicOrganizationActivationData_Beta } = data;

    return (
        <PageWrapper>
            <OrganizationActivationForm
                data={publicOrganizationActivationData_Beta}
            />
        </PageWrapper>
    );
};
