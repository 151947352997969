import styled from '@emotion/styled';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Paths } from '../../../navigation/paths';
import { AuthBasePage } from '../AuthBasePage';
import { AuthBox } from '../AuthBox';
import { ResetPasswordForm } from './ResetPasswordForm';

const BackToLoginLink = styled(Link)`
    display: block;
    text-align: center;
    margin-top: 36px;
    font-size: 14px;
`;

export const ResetPasswordPage = () => {
    const { token } = useParams();
    const [hideLoginLink, setHideLoginLink] = React.useState(false);
    return (
        <AuthBasePage title="Passwort zurücksetzen">
            <AuthBox title="Passwort zurücksetzen">
                <ResetPasswordForm
                    token={token!}
                    onSuccess={() => setHideLoginLink(true)}
                />
            </AuthBox>
            <BackToLoginLink
                to={Paths.Login}
                style={{ visibility: hideLoginLink ? 'hidden' : 'visible' }}>
                Zum Login
            </BackToLoginLink>
        </AuthBasePage>
    );
};
