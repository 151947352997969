import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { LoadingWrapper } from '../../../common/components/LoadingWrapper';
import { User } from '../../../core/auth/user';
import { OrganizationMemberWorkTimeDummyEntry } from './OrganizationMemberWorkTimeEntry';

type OrgMemberWorkTimeReportDetailsProps = {
    user: User;
    month: DateTime;
};

export const OrganizationMemberWorkTimeReportDetailsSkeleton = ({
    user,
    month
}: OrgMemberWorkTimeReportDetailsProps) => {
    const days = useMemo(() => {
        const daysInMonth =
            month && month.isValid ? month.daysInMonth || 31 : 31;
        const days: DateTime[] = [];
        for (let i = 1; i <= daysInMonth; i++) {
            days.push(month.set({ day: i }));
        }
        return days;
    }, [month]);

    return (
        <LoadingWrapper
            loading={true}
            label={`Lade Monatsbericht für Mitarbeiter ${user.firstName} ${user.lastName} ...`}>
            <Box sx={{ pb: '32px' }}>
                <Table
                    size="small"
                    style={{
                        borderTop: '1px solid rgba(224, 224, 224, 1)',
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        tableLayout: 'fixed'
                    }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width="100px" colSpan={2}>
                                Tag
                            </TableCell>
                            <TableCell width="100px" align="right">
                                Arbeitszeit
                            </TableCell>
                            <TableCell width="120px" align="center">
                                Von - Bis
                            </TableCell>
                            <TableCell width="90px" align="right">
                                Stunden
                            </TableCell>
                            <TableCell>Beschreibung</TableCell>
                            <TableCell width="135" align="center">
                                Hinweis
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {days.map((dayDate, dayIndex) => (
                            <OrganizationMemberWorkTimeDummyEntry
                                date={dayDate}
                                dayIndex={dayIndex}
                                key={dayDate.valueOf()}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </LoadingWrapper>
    );
};
