import { useApolloClient } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { NewLineToBreak } from '../../common/components/NewLineToBreak';
import { SkeletonText } from '../../common/components/skeleton/SkeletonText';
import { Colors } from '../../core/theme/Colors';
import {
    Item,
    ItemFromTo,
    ItemLabel,
    ItemSummaryCt,
    ItemSummaryItem,
    ItemTotalTime
} from '../entry/TimeEntry';
import { TimeEntryDrawerForm } from '../form/TimeEntryDrawerForm';
import {
    formatSecondsAsTimeDuration,
    formatTime,
    getActiveTrackingSeconds
} from '../time';
import { QUERY_GET_USER_WORK_TIME_REPORT } from './queries';
import { TimeReportDuration } from './TimeReportDuration';
import { WorkTimeReportEntryDto } from './workTimeReportEntryDto';
import { WorkTimeReportEntryProratedHint } from './WorkTimeReportEntryProratedHint';

type WorkTimeReportEntryProps = {
    entry: WorkTimeReportEntryDto;
};

export const WorkTimeReportEntry = ({ entry }: WorkTimeReportEntryProps) => {
    const isActive = !entry.to;
    const startTime = formatTime(entry.from);
    const endTime = isActive ? '' : formatTime(entry.to);

    const { description } = entry.node;

    const [showEditForm, setShowEditForm] = useState(false);

    let unmounted = false;

    const client = useApolloClient();

    useEffect(() => {
        return () => {
            unmounted = true;
        };
    }, []);

    const [totalSeconds, setTotalSeconds] = useState(entry.totalSeconds || 0);

    useEffect(() => {
        let interval;
        if (isActive) {
            const startDate = DateTime.fromISO(entry.from!);
            interval = setInterval(() => {
                setTotalSeconds(getActiveTrackingSeconds(startDate));
            }, 40);
        } else {
            setTotalSeconds(entry.totalSeconds || 0);
        }

        return () => (interval ? clearInterval(interval) : undefined);
    }, [entry, isActive]);

    function editTimeEntry() {
        setShowEditForm(true);
    }

    function onFormClosed() {
        if (!unmounted) {
            setShowEditForm(false);
        }
    }

    function onChanged() {
        client.refetchQueries({
            include: [QUERY_GET_USER_WORK_TIME_REPORT]
        });
    }

    const totalTime = formatSecondsAsTimeDuration(totalSeconds);

    return (
        <>
            <Item role="button" onClick={editTimeEntry} size={'small'}>
                <ItemFromTo>
                    {startTime}
                    {' - '}
                    {isActive ? (
                        <SkeletonText
                            width={36}
                            animation={false}
                            sx={{ backgroundColor: Colors.TextSkeletonLight }}
                        />
                    ) : (
                        endTime
                    )}
                </ItemFromTo>
                <ItemSummaryCt>
                    <ItemSummaryItem>
                        <WorkTimeReportEntryProratedHint entry={entry} />
                    </ItemSummaryItem>
                    <ItemSummaryItem>
                        <ItemTotalTime>
                            <TimeReportDuration timeDuration={totalTime} />
                        </ItemTotalTime>
                    </ItemSummaryItem>
                </ItemSummaryCt>
                <ItemLabel hasDescription={!!description}>
                    <NewLineToBreak
                        value={description ? description : 'Keine Beschreibung'}
                    />
                </ItemLabel>
            </Item>
            {showEditForm && (
                <TimeEntryDrawerForm
                    entry={entry.node}
                    onChanged={onChanged}
                    onClosed={onFormClosed}
                />
            )}
        </>
    );
};
