import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Alert, Skeleton, TableCell, TableRow } from '@mui/material';
import match from 'autosuggest-highlight/match';
import { sortBy, throttle } from 'lodash';
import React, { useMemo } from 'react';
import { SkeletonButton } from '../../common/components/button/SkeletonButton';
import { getResponseErrorMessage } from '../../core/api/error';
import { isAdmin } from '../../core/auth';
import { User } from '../../core/auth/user';
import { useUser } from '../../core/auth/useUser';
import { UserState } from '../../user/userState';
import { QUERY_ORGANIZATION_MEMBERS } from '../queries';
import { OrganizationMembersTableRow } from './OrganizationMembersTableRow';
import { OrganizationMembersTableWrapper } from './OrganizationMembersTableWrapper';

const SkeletonText = styled(Skeleton)`
    font-size: 17px;
`;

const EmptyTable = ({ children }) => (
    <OrganizationMembersTableWrapper>
        <TableRow>
            <TableCell colSpan={2}>{children}</TableCell>
        </TableRow>
    </OrganizationMembersTableWrapper>
);

const SkeletonTable = () => (
    <OrganizationMembersTableWrapper>
        <TableRow>
            <TableCell>
                <SkeletonText variant="text" sx={{ width: '150px' }} />
            </TableCell>
            <TableCell>
                <SkeletonText variant="text" sx={{ width: '100px' }} />
            </TableCell>
            <TableCell align="right">
                <SkeletonButton
                    size="small"
                    sx={{ width: 84, marginLeft: 'auto' }}
                />
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell>
                <SkeletonText variant="text" sx={{ width: '100px' }} />
            </TableCell>
            <TableCell>
                <SkeletonText variant="text" sx={{ width: '120px' }} />
            </TableCell>
            <TableCell align="right">
                <SkeletonButton
                    size="small"
                    sx={{ width: 84, marginLeft: 'auto' }}
                />
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell>
                <SkeletonText variant="text" sx={{ width: '120px' }} />
            </TableCell>
            <TableCell>
                <SkeletonText variant="text" sx={{ width: '110px' }} />
            </TableCell>
            <TableCell align="right">
                <SkeletonButton
                    size="small"
                    sx={{ width: 84, marginLeft: 'auto' }}
                />
            </TableCell>
        </TableRow>
    </OrganizationMembersTableWrapper>
);

type OrganizationMembersTableProps = {
    nameQuery?: string;
    stateFilter?: UserState;
};

function getStateFilter(
    user: User | null | undefined,
    selectedState: UserState | undefined
): UserState[] {
    if (isAdmin(user)) {
        return selectedState === UserState.INACTIVE
            ? [UserState.INACTIVE]
            : [UserState.ACTIVE, UserState.INVITED];
    }

    return [UserState.ACTIVE];
}

export const OrganizationMembersTable = ({
    nameQuery,
    stateFilter
}: OrganizationMembersTableProps) => {
    const getFilteredList = throttle(
        (query: string | undefined, data: Partial<User>[]) => {
            const cleanQuery = (query || '').trim();
            if (!cleanQuery) {
                return data;
            }

            return data.filter(member => {
                if (!member || !member.name) {
                    return false;
                }

                return match(member.name, cleanQuery, {}).length > 0;
            });
        },
        200
    );

    const user = useUser();

    const { loading, error, data, refetch } = useQuery(
        QUERY_ORGANIZATION_MEMBERS,
        {
            fetchPolicy: 'network-only',
            variables: {
                states: getStateFilter(user, stateFilter)
            }
        }
    );

    const _memberNodes = data?.me?.organization?.members?.nodes;

    const members = useMemo(() => {
        return _memberNodes || [];
    }, [_memberNodes]);

    const orderedMembers = useMemo(() => {
        return sortBy(members, ['role', 'name']);
    }, [members]);

    const orderedFilteredMembers = useMemo(
        () => getFilteredList(nameQuery, orderedMembers),
        [orderedMembers, nameQuery]
    );

    function onMemberChange() {
        refetch();
    }

    if (loading) {
        return <SkeletonTable />;
    }

    if (error) {
        const errorMessage = getResponseErrorMessage(error, apiError => {
            if (apiError.getCode() === 'user_not_found') {
                return 'Mitarbeiterdaten konnten nicht geladen werden. Möglicherweise wurde der Mitarbeiter zwischenzeitlich gelöscht.';
            }
        });
        return (
            <EmptyTable>
                <Alert severity="error">
                    Mitarbeiter:in konnten nicht geladen werden :-( <br />
                    <small>{errorMessage}</small>
                </Alert>
            </EmptyTable>
        );
    }

    if (!members || members.length === 0) {
        return (
            <EmptyTable>
                Keine{' '}
                {stateFilter === UserState.INACTIVE ? 'inaktiven' : 'aktiven'}{' '}
                Mitarbeiter
            </EmptyTable>
        );
    }

    const tableData = orderedFilteredMembers || orderedMembers || members;

    if (nameQuery && tableData.length === 0) {
        return (
            <EmptyTable>
                Keine Mitarbeiter:in mit dem Namen „{nameQuery?.trim()}“
                gefunden
            </EmptyTable>
        );
    }

    return (
        <>
            <OrganizationMembersTableWrapper>
                {tableData.map(member => (
                    <OrganizationMembersTableRow
                        key={member.id}
                        member={member}
                        onUserChange={onMemberChange}
                    />
                ))}
            </OrganizationMembersTableWrapper>
        </>
    );
};
