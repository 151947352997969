import styled from '@emotion/styled';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

const ToggleLink = styled.a`
    display: inline-block;

    svg {
        width: 0.75em;
        height: 0.75em;
        margin-right: 0;
        margin-left: -0.2em;
        margin-bottom: -2px;
        vertical-align: top;
    }
`;

export type ToggleLinkProps = {
    expanded: boolean;
    onClick: () => void;
    showMoreLabel: string;
    showLessLabel: string;
};

export const ToggleExpandLink = ({
    expanded,
    showMoreLabel,
    showLessLabel,
    onClick
}: ToggleLinkProps) => {
    return (
        <ToggleLink href={'javascript:'} onClick={onClick} role={'button'}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            {expanded ? showLessLabel : showMoreLabel}
        </ToggleLink>
    );
};
