import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Colors } from '../../core/theme/Colors';

function getOffset(
    viewport: 's' | 'm' | 'l',
    size?: 'small' | 'medium' | 'large'
) {
    // Small viewport
    if (viewport === 's') {
        if (size === 'small') {
            return '20px';
        }
        if (size === 'medium') {
            return '24px';
        }
        return '28px';
    }

    // Medium viewport
    if (viewport === 'm') {
        if (size === 'small') {
            return '20px';
        }
        if (size === 'medium') {
            return '28px';
        }
        return '32px';
    }

    // Large viewport
    if (size === 'small') {
        return '24px';
    }
    if (size === 'medium') {
        return '32px';
    }

    return '40px';
}

const Ct = styled.div<TimeEntryGroupProps>`
    & + & {
        margin-top: ${props => getOffset('s', props.size)};

        @media (min-width: 600px) {
            margin-top: ${props => getOffset('m', props.size)};
        }

        @media (min-width: 960px) {
            margin-top: ${props => getOffset('l', props.size)};
        }
    }

    & + &.show-divider {
        position: relative;
        z-index: 1;
        padding-top: ${props => getOffset('s', props.size)};

        &:before {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 50%;
            width: 60px;
            height: 4px;
            margin-left: -30px;
            margin-top: -2px;
            border-radius: 2px;
            background-color: ${Colors.BackgroundDark};
        }

        @media (min-width: 600px) {
            padding-top: ${props => getOffset('m', props.size)};
        }

        @media (min-width: 960px) {
            padding-top: ${props => getOffset('l', props.size)};
        }
    }
`;

type TimeEntryGroupProps = {
    size?: 'small' | 'medium' | 'large';
    showDivider?: boolean;
};

export const TimeEntryGroup = (
    props: PropsWithChildren<TimeEntryGroupProps>
) => {
    return (
        <Ct
            className={props.showDivider ? 'show-divider' : ''}
            size={props.size}>
            {props.children}
        </Ct>
    );
};
