import styled from '@emotion/styled'
import { Box, Container } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { debounce } from 'lodash';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Colors } from '../theme/Colors';

const StyledAppBar = styled(AppBar)`
  background: #ffffff;
  z-index: 1101;
  border-top: 1px solid #dddedf;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);

  .MuiInputLabel-root {
    transform: none;
    font-size: 13px;
    line-height: 17px;
    color: ${Colors.Text}
  }
  
  .MuiFormControl-root {
   
  }
`;

type SecondaryAppBarWrapperProps = {
    paddings?: 'large' | 'small' | 'default'
    wrapperProps?: React.HTMLAttributes<HTMLDivElement>,
    initialHeight?: number
}

export const SecondaryAppBar = ({wrapperProps, initialHeight, paddings, children}: PropsWithChildren<SecondaryAppBarWrapperProps>) => {
    const appBarRef = useRef<null | HTMLDivElement>(null);
    const [height, setHeight] = useState(initialHeight || 56);

    const updateDummyHeight = debounce(() => {
        if (!appBarRef.current) {
            return;
        }
        setHeight(appBarRef.current?.clientHeight);
    }, 200)

    useEffect(() => {
        updateDummyHeight();
    }, [appBarRef.current])

    useEffect(() => {
        window.addEventListener('resize', updateDummyHeight);

        return () => {
            window.removeEventListener('resize', updateDummyHeight)
        }
    }, []);

    const verticalPadding = paddings === 'small' ? '14px' : paddings === 'large' ? '24px' : '16px';

    return (
        <>
            <StyledAppBar
                ref={appBarRef}
                position="fixed"
                elevation={0}
                sx={{left: 0, right: 0, top: 56 }}
            >
                <Container {...wrapperProps} sx={{ pt: verticalPadding, pb: verticalPadding}}>
                    {children}
                </Container>
            </StyledAppBar>
            <Box sx={{height}} />
        </>
    );
}
