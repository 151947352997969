import { useQuery } from '@apollo/client';
import React from 'react';
import { GlobalAlert } from '../common/components/GlobalAlert';
import { GridContainer } from '../common/components/layout/GridContainer';
import { GridItem } from '../common/components/layout/GridItem';
import { ProtectedPage } from '../core/auth/components/ProtectedPage';
import { Permission } from '../core/auth/permission';
import { User } from '../core/auth/user';
import { QUERY_GET_USER_WORK_TIME_REPORT } from '../timeTracking/report/queries';
import {
    getFromTo,
    TimeReportRangeSelectOption
} from '../timeTracking/report/timeReportRangeSelectOption';
import {
    UserWorkTimeReport,
    UserWorkTimeReportSkeleton
} from '../timeTracking/report/userWorkTimeReport/UserWorkTimeReport';
import { WorkTimeReportDto } from '../timeTracking/report/userWorkTimeReport/userWorkTimeReport.types';
import {
    EmployeeWorkTimeReportPageHeader,
    EmployeeWorkTimeReportPageHeaderSkeleton
} from './components/EmployeeWorkTimeReportPageHeader';
import {
    EmployeeWorkTimeReportSummary,
    EmployeeWorkTimeReportSummarySkeleton
} from './components/EmployeeWorkTimeReportSummary';
import { EmployeePageContentWrapper } from './EmployeePageContentWrapper';

const EmployeeWorkTimeReportPageInnerContentSkeleton = ({
    selectedDateRange
}: {
    user?: User;
    selectedDateRange: TimeReportRangeSelectOption;
}) => {
    return (
        <>
            <GridItem xs={12} md={6} lg={5}>
                <EmployeeWorkTimeReportSummarySkeleton
                    dateRange={selectedDateRange}
                />
            </GridItem>
            <GridItem xs={12} md={6} lg={7}>
                <UserWorkTimeReportSkeleton
                    selectedReportRange={selectedDateRange}
                />
            </GridItem>
        </>
    );
};

function getNumEntriesInWorkTimeReport(userWorkTimeReport?: WorkTimeReportDto) {
    if (!userWorkTimeReport) {
        return 0;
    }

    const { groups } = userWorkTimeReport;
    if (!groups) {
        return 0;
    }

    return groups.reduce((acc, group) => acc + group.entries?.length || 0, 0);
}

const EmployeeWorkTimeReportPageInnerContent = ({
    error,
    userWorkTimeReport,
    selectedDateRange
}: {
    error?: any;
    userWorkTimeReport?: WorkTimeReportDto;
    selectedDateRange: TimeReportRangeSelectOption;
}) => {
    if (error || !userWorkTimeReport) {
        return (
            <>
                <GridItem xs={12} md={6} lg={5}>
                    <EmployeeWorkTimeReportSummarySkeleton
                        dateRange={selectedDateRange}
                    />
                </GridItem>
                <GridItem xs={12} md={6} lg={7}>
                    <GlobalAlert severity="error">
                        Arbeitszeitdaten konnten nicht geladen werden.
                        {error && (
                            <>
                                <br>
                                    <small>({error.message})</small>
                                </br>
                            </>
                        )}
                    </GlobalAlert>
                </GridItem>
            </>
        );
    }

    const hasEntries = getNumEntriesInWorkTimeReport(userWorkTimeReport) > 0;

    return (
        <>
            <GridItem xs={12} md={6} lg={5}>
                <EmployeeWorkTimeReportSummary
                    workTimeReport={userWorkTimeReport}
                    dateRange={selectedDateRange}
                />
            </GridItem>
            <GridItem xs={12} md={6} lg={7}>
                {hasEntries ? (
                    <UserWorkTimeReport workTimeReport={userWorkTimeReport} />
                ) : (
                    <GlobalAlert severity={'info'}>
                        Mitarbeiter hat im ausgewählten Zeitraum keine
                        Arbeitszeit erfasst
                    </GlobalAlert>
                )}
            </GridItem>
        </>
    );
};

const EmployeeWorkTimeReportPageContent = ({ user }: { user: User }) => {
    const [selectedDateRange, setSelectedDateRange] =
        React.useState<TimeReportRangeSelectOption>('this_week');
    const range = getFromTo(selectedDateRange);

    const { loading, error, data } = useQuery(QUERY_GET_USER_WORK_TIME_REPORT, {
        variables: {
            userId: user.id,
            from: range.from.toISO(),
            to: range.to.toISO()
        }
    });

    return (
        <GridContainer>
            <GridItem xs={12}>
                <EmployeeWorkTimeReportPageHeader
                    initialDateRange={selectedDateRange}
                    onDateRangeChange={setSelectedDateRange}
                />
            </GridItem>

            {loading ? (
                <EmployeeWorkTimeReportPageInnerContentSkeleton
                    user={user}
                    selectedDateRange={selectedDateRange}
                />
            ) : (
                <EmployeeWorkTimeReportPageInnerContent
                    userWorkTimeReport={data?.node?.workTimeReport}
                    selectedDateRange={selectedDateRange}
                    error={error}
                />
            )}
        </GridContainer>
    );
};

const EmployeeWorkTimeReportPageContentSkeleton = () => (
    <GridContainer>
        <GridItem xs={12}>
            <EmployeeWorkTimeReportPageHeaderSkeleton />
        </GridItem>
        <EmployeeWorkTimeReportPageInnerContentSkeleton
            selectedDateRange={'this_week'}
        />
    </GridContainer>
);

export const EmployeeWorkTimeReportPage = () => {
    return (
        <ProtectedPage permissions={Permission.Manage_Users}>
            <EmployeePageContentWrapper
                subPageTitle={'Bericht'}
                renderContent={user => (
                    <EmployeeWorkTimeReportPageContent user={user} />
                )}
                renderSkeletonContent={() => (
                    <EmployeeWorkTimeReportPageContentSkeleton />
                )}
            />
        </ProtectedPage>
    );
};
