import { ApolloError, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';
import { PrimaryButton } from '../common/components/button/PrimaryButton';
import { SecondaryButton } from '../common/components/button/SecondaryButton';
import { getApiErrorCode, getResponseErrorMessage } from '../core/api/error';
import { User } from '../core/auth/user';
import { useUser } from '../core/auth/useUser';
import { executeSafe } from '../utils';
import { getUserName } from './index';
import { MUTATION_DEACTIVATE_USER } from './queries';

export const DEACTIVATE_OWN_FORBIDDEN_MSG =
    'Das eigene Nutzerkonto kann nicht deaktiviert werden.';
export const DEACTIVATE_OWNER_FORBIDDEN_MSG =
    'Das Konto des Eigentümers kann nicht deaktiviert werden. Bitte wenden Sie sich an den Support falls Sie einen Eigentümerwechsel durchführen möchten.';

type DeactivateUserButtonWrapperProps = {
    user: Partial<User>;
    onSuccess?: () => void;
    renderButton: (
        user: Partial<User>,
        working: boolean,
        deactivateUser: () => void
    ) => ReactElement;
};

export const DeactivateUserButtonWrapper = ({
    user,
    onSuccess,
    renderButton
}: DeactivateUserButtonWrapperProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [working, setWorking] = useState<boolean>(false);

    const [
        showDeactivationConfirmationDialog,
        setShowDeactivationConfirmationDialog
    ] = useState<boolean>(false);
    const [deactivateUser] = useMutation(MUTATION_DEACTIVATE_USER, {
        variables: {
            id: user.id
        }
    });
    const [deactivationError, setDeactivationError] =
        useState<ApolloError | undefined>();

    const userName = getUserName(user);
    const authUser = useUser();

    const onClickDeactivateUser = () => {
        setShowDeactivationConfirmationDialog(true);
    };

    const onConfirmedDeactivation = () => {
        setWorking(true);
        deactivateUser()
            .then(() => {
                setShowDeactivationConfirmationDialog(false);
                enqueueSnackbar(`Das Konto von ${userName} wurde deaktiviert`, {
                    variant: 'success',
                    autoHideDuration: 5000
                });
                executeSafe(onSuccess);
            })
            .catch(e => {
                const apiErrorCode = getApiErrorCode(e);
                if (apiErrorCode === 'user_already_inactive') {
                    executeSafe(onSuccess);
                    enqueueSnackbar('Das Nutzerkonto ist bereits inaktiv.', {
                        variant: 'success',
                        autoHideDuration: 5000
                    });
                    return;
                }

                setDeactivationError(e);
            })
            .finally(() => {
                setWorking(false);
            });
    };

    const closeDeactivationConfirmationDialog = e => {
        e && e.stopPropagation();
        setShowDeactivationConfirmationDialog(false);
    };

    const renderDeactivationErrorMessage = () => {
        if (!deactivationError) {
            return null;
        }

        const message = getResponseErrorMessage(deactivationError, apiError => {
            const apiErrorCode = apiError.getCode();
            if (apiErrorCode === 'deactivate_self_forbidden') {
                return DEACTIVATE_OWN_FORBIDDEN_MSG;
            } else if (apiErrorCode === 'deactivate_owner_forbidden') {
                return DEACTIVATE_OWNER_FORBIDDEN_MSG;
            } else if (apiErrorCode) {
                return `Nutzer kann nicht deaktiviert werden. Fehler: ${apiErrorCode}`;
            }

            return `Nutzer kann nicht deaktiviert werden. Fehler: ${deactivationError.message}`;
        });

        return (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
                {message}
            </Alert>
        );
    };

    const isOwner = user.isOwner || false;
    const isSelf = user.id === authUser?.id;

    return (
        <>
            {renderButton(user, working, onClickDeactivateUser)}
            <Dialog
                open={showDeactivationConfirmationDialog}
                onClose={
                    working ? () => {} : closeDeactivationConfirmationDialog
                }
                aria-labelledby={`${user.id}-dialog-deactivate-title`}
                aria-describedby={`${user.id}-dialog-deactivate-description`}
                onClick={e => e.stopPropagation()}>
                <DialogTitle id={`${user.id}-dialog-deactivate-title`}>
                    Mitarbeiterkonto deaktivieren
                </DialogTitle>
                <DialogContent>
                    {renderDeactivationErrorMessage()}
                    <DialogContentText
                        id={`${user.id}-dialog-deactivate-description`}>
                        {isOwner && DEACTIVATE_OWNER_FORBIDDEN_MSG}
                        {isSelf && !isOwner && DEACTIVATE_OWN_FORBIDDEN_MSG}
                        {!(isOwner || isSelf) && (
                            <>
                                Möchten Sie das Mitarbeiterkonto von{' '}
                                <strong>{userName}</strong> wirklich
                                deaktivieren?
                            </>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {isOwner || isSelf ? (
                        <PrimaryButton
                            onClick={closeDeactivationConfirmationDialog}
                            autoFocus>
                            Schließen
                        </PrimaryButton>
                    ) : (
                        <>
                            <SecondaryButton
                                onClick={closeDeactivationConfirmationDialog}
                                disabled={working}
                                autoFocus>
                                Abbrechen
                            </SecondaryButton>
                            <LoadingButton
                                onClick={onConfirmedDeactivation}
                                loading={working}
                                sx={{ whiteSpace: 'nowrap' }}>
                                Konto deaktivieren
                            </LoadingButton>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};
