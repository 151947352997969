import styled from '@emotion/styled';
import { Colors } from '../../theme/Colors';

export const SubPageSectionHeadline = styled.h3`
    font-size: 16px;
    line-height: 1.25;
    font-weight: bold;
    margin: 16px 0 12px;

    @media (min-width: 600px) {
        font-size: 18px;
        margin: 20px 0 14px;
    }

    @media (min-width: 960px) {
        font-size: 22px;
        margin: 24px 0 16px;
    }

    .MuiGrid-item > & {
        margin-top: 0;
    }
`;
