import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { DialogContent } from '@mui/material';
import React from 'react';
import { ErrorBoundary } from '../../common/components/ErrorBoundary';
import { useUser } from '../../core/auth/useUser';
import {
    GET_TIME_ENTRY_AUDIT_LOG,
    GET_TIME_ENTRY_AUDIT_LOG_ADMIN
} from '../queries';
import { TimeEntryAuditLog } from './TimeEntryAuditLog';

type TimeEntryAuditLogDialogContentProps = {
    timeEntryId?: string | null;
};

const StyledDialogContent = styled(DialogContent)`
    padding: 0;
    margin: 0;
`;

export const TimeEntryAuditLogDialogContent = ({
    timeEntryId
}: TimeEntryAuditLogDialogContentProps) => {
    if (!timeEntryId) {
        return null;
    }

    const user = useUser();
    const auditQuery =
        user?.role === 'admin'
            ? GET_TIME_ENTRY_AUDIT_LOG_ADMIN
            : GET_TIME_ENTRY_AUDIT_LOG;

    const { data, loading } = useQuery(auditQuery, {
        variables: { timeEntryId },
        fetchPolicy: 'cache-and-network'
    });

    return (
        <StyledDialogContent>
            <ErrorBoundary>
                <TimeEntryAuditLog
                    log={data?.node?.auditLog?.nodes}
                    loading={loading}
                />
            </ErrorBoundary>
        </StyledDialogContent>
    );
};
