import { useMutation } from '@apollo/client';
import { Alert, LinearProgress } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useEffect, useState } from 'react';
import { InputLabel } from '../../../../common/components/form/InputLabel';
import { PublicPageCardForm } from '../../../../common/components/page/PublicPageCardForm';
import { PublicPageCardFormError } from '../../../../common/components/page/PublicPageCardFormError';
import { PublicPageCardFormSubmitButton } from '../../../../common/components/page/PublicPageCardFormSubmitButton';
import { getResponseErrorMessage } from '../../../api/error';
import { AuthLocalStorage } from '../../authLocalStorage';
import { MUTATION_REQUEST_PW_RESET } from '../../queries';

interface Values {
    email: string;
}

export const RequestPasswordResetForm = ({
    onSuccess
}: {
    onSuccess?: () => void;
}) => {
    const [requestPasswordResetMutation, { loading }] = useMutation(
        MUTATION_REQUEST_PW_RESET
    );
    const [error, setError] = useState<string | undefined>();
    const [successful, setSuccessful] = useState<boolean>(false);

    function onSubmit(values, { setSubmitting }) {
        if (loading) {
            return;
        }

        requestPasswordResetMutation({ variables: { email: values.email } })
            .then(() => setSuccessful(true))
            .catch(e => {
                setError(getResponseErrorMessage(e));
            })
            .finally(() => setSubmitting(false));
    }

    useEffect(() => {
        if (successful && onSuccess) {
            onSuccess();
        }
    }, [successful]);

    if (successful) {
        return (
            <Alert severity={'success'}>
                <span
                    style={{
                        fontSize: 16,
                        lineHeight: '20px'
                    }}>
                    Wir haben Ihnen eine E-Mail mit Anleitungen dazu gesendet,
                    wie Sie Ihr Passwort zurücksetzen können.
                </span>
            </Alert>
        );
    }

    return (
        <>
            {!!error && (
                <PublicPageCardFormError>{error}</PublicPageCardFormError>
            )}

            <Formik
                initialValues={{
                    email: AuthLocalStorage.getSignInEmail() || ''
                }}
                validate={values => {
                    const errors: Partial<Values> = {};
                    if (!values.email) {
                        errors.email =
                            'Bitte geben Sie eine E-Mail Adresse ein';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            values.email
                        )
                    ) {
                        errors.email =
                            'Bitte geben Sie eine gültige E-Mail Adresse ein';
                    }

                    return errors;
                }}
                onSubmit={onSubmit}>
                {({ submitForm, isSubmitting }) => (
                    <PublicPageCardForm>
                        <InputLabel htmlFor="email">E-Mail-Adresse</InputLabel>
                        <Field
                            component={TextField}
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            disabled={isSubmitting}
                        />
                        <PublicPageCardFormSubmitButton
                            disabled={isSubmitting}
                            onClick={submitForm}>
                            Senden
                        </PublicPageCardFormSubmitButton>

                        {isSubmitting && <LinearProgress />}
                    </PublicPageCardForm>
                )}
            </Formik>
        </>
    );
};
