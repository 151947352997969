import { ApolloError, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';
import React, { ReactElement, useEffect, useState } from 'react';
import { SecondaryButton } from '../common/components/button/SecondaryButton';
import { getResponseErrorMessage } from '../core/api/error';
import { User } from '../core/auth/user';
import { executeSafe } from '../utils';
import { getUserName } from './index';
import { MUTATION_ACTIVATE_USER } from './queries';

type ActivateUserButtonWrapperProps = {
    user: Partial<User>;
    onSuccess?: () => void;
    renderButton: (
        user: Partial<User>,
        working: boolean,
        activateUser: () => void
    ) => ReactElement;
};

export const ActivateUserButtonWrapper = ({
    user,
    onSuccess,
    renderButton
}: ActivateUserButtonWrapperProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [working, setWorking] = useState<boolean>(false);

    const [
        showActivationConfirmationDialog,
        setShowActivationConfirmationDialog
    ] = useState<boolean>(false);

    const [activateUser] = useMutation(MUTATION_ACTIVATE_USER, {
        variables: {
            id: user.id
        }
    });
    const [activationError, setActivationError] =
        useState<ApolloError | undefined>();

    const userName = getUserName(user);

    const onClickActivateUser = () => {
        setShowActivationConfirmationDialog(true);
    };

    const onConfirmActivateUser = (): Promise<void> => {
        setWorking(true);
        return activateUser()
            .then(() => {
                setShowActivationConfirmationDialog(false);
                enqueueSnackbar(`Das Konto von ${userName} wurde aktiviert`, {
                    variant: 'success',
                    autoHideDuration: 5000
                });
                executeSafe(onSuccess);
            })
            .catch(e => {
                setActivationError(e);
            })
            .finally(() => {
                setWorking(false);
            });
    };

    const closeActivationConfirmationDialog = e => {
        e && e.stopPropagation();
        setShowActivationConfirmationDialog(false);
    };

    const showActivationErrorMessage = () => {
        if (!activationError) {
            return null;
        }

        let variant: VariantType = 'error';
        const message = getResponseErrorMessage(
            activationError,
            graphQlApiError => {
                const apiErrorCode = graphQlApiError.getCode();
                if (apiErrorCode === 'user_already_active') {
                    executeSafe(onSuccess);
                    variant = 'success';
                    return 'Nutzer ist bereits aktiv';
                } else if (apiErrorCode) {
                    return `Nutzer kann nicht aktiviert werden. Fehlercode: ${apiErrorCode}`;
                } else {
                    return `Nutzer kann nicht aktiviert werden. Fehler: ${activationError.message}`;
                }
            }
        );

        enqueueSnackbar(message, {
            variant: variant,
            autoHideDuration: 5000
        });
    };

    const renderActivationErrorMessage = () => {
        if (!activationError) {
            return null;
        }

        let variant: VariantType = 'error';
        const message = getResponseErrorMessage(
            activationError,
            graphQlApiError => {
                const apiErrorCode = graphQlApiError.getCode();
                if (apiErrorCode === 'user_already_active') {
                    executeSafe(onSuccess);
                    variant = 'success';
                    return 'Nutzer ist bereits aktiv';
                } else if (apiErrorCode) {
                    return `Nutzer kann nicht aktiviert werden. Fehlercode: ${apiErrorCode}`;
                } else {
                    return `Nutzer kann nicht aktiviert werden. Fehler: ${activationError.message}`;
                }
            }
        );

        return (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
                {message}
            </Alert>
        );
    };

    useEffect(() => {
        showActivationErrorMessage();
    }, [activationError]);

    return (
        <>
            {renderButton(user, working, onClickActivateUser)}
            <Dialog
                open={showActivationConfirmationDialog}
                onClose={working ? () => {} : closeActivationConfirmationDialog}
                aria-labelledby={`${user.id}-dialog-activate-title`}
                aria-describedby={`${user.id}-dialog-activate-description`}
                onClick={e => e.stopPropagation()}>
                <DialogTitle id={`${user.id}-dialog-activate-title`}>
                    Mitarbeiterkonto aktivieren
                </DialogTitle>
                <DialogContent>
                    {renderActivationErrorMessage()}
                    <DialogContentText
                        id={`${user.id}-dialog-activate-description`}>
                        Möchten Sie das Mitarbeiterkonto von{' '}
                        <strong>{userName}</strong> wirklich aktivieren?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SecondaryButton
                        onClick={closeActivationConfirmationDialog}
                        disabled={working}
                        autoFocus>
                        Abbrechen
                    </SecondaryButton>
                    <LoadingButton
                        onClick={onConfirmActivateUser}
                        loading={working}
                        sx={{ whiteSpace: 'nowrap' }}>
                        Konto aktivieren
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
