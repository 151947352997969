import styled from '@emotion/styled';
import { SvgIcon, Tooltip } from '@mui/material';
import React from 'react';
import { Colors } from '../../core/theme/Colors';
import {
    formatDayDate,
    formatInterval,
    getTimeEntrySummary,
    toDateTime
} from '../time';
import { WorkTimeReportEntryDto } from './workTimeReportEntryDto';
import { ReactComponent as ProratedIcon } from '../../assets/images/prorated.svg';
import { ReactComponent as ProratedStartIcon } from '../../assets/images/prorated_start.svg';
import { ReactComponent as ProratedEndIcon } from '../../assets/images/prorated_end.svg';

const Ct = styled.span`
    float: left;
    margin-right: 4px;
    padding: 6px;
    margin-top: -2px;
    margin-bottom: -2px;

    svg {
        display: block;
        width: 16px;
        height: 16px;
        fill: ${Colors.Secondary};
    }
`;

export const WorkTimeReportEntryProratedHint = ({
    entry
}: {
    entry: WorkTimeReportEntryDto;
}) => {
    if (!entry.prorated) {
        return null;
    }

    // Since we still have the original time entry we can simply check for identity of the time stamps to determine if the cutoff was done at the start or end of the day or both
    const cutOffAtStartOfDay = entry.from !== entry.node.startDate;
    const cutOffAtEndOfDay = entry.to !== entry.node.endDate;

    const tooltipTitle = 'Arbeitszeit anteilig berechnet';
    let tooltipBody = '';
    let icon;
    if (cutOffAtStartOfDay && cutOffAtEndOfDay) {
        tooltipBody =
            'Eintrag wird vom Vortag weitergeführt und läuft am Folgetag weiter';
        icon = ProratedIcon;
    } else if (cutOffAtStartOfDay) {
        tooltipBody = 'Eintrag wird vom Vortag weitergeführt';
        icon = ProratedStartIcon;
    } else {
        tooltipBody = 'Eintrag wird am Folgetag weitergeführt';
        icon = ProratedEndIcon;
    }

    const timeEntrySummary = getTimeEntrySummary(entry.node, true);

    return (
        <Tooltip
            title={
                <>
                    <strong>{tooltipTitle}</strong>
                    <br />
                    {tooltipBody}
                    <span
                        style={{
                            display: 'block',
                            height: 1,
                            background: 'rgba(255,255,255,0.5)',
                            margin: '4px 0'
                        }}
                    />
                    {timeEntrySummary}
                </>
            }>
            <Ct>
                <SvgIcon component={icon} inheritViewBox />
            </Ct>
        </Tooltip>
    );
};
