import { ApolloError } from '@apollo/client';
import { TimeEntry } from '../../timeTracking/TimeEntry';
import { actingUserIsOwner } from '../auth/authLocalStorage';

export const toApiError = (
    error: Error | ApolloError | GraphQLError | undefined
): ApiError | null => {
    if (error instanceof ApiError) {
        return error;
    }

    if (!(error instanceof ApolloError)) {
        return null;
    }

    if (!error || (!error.graphQLErrors && !error.networkError)) {
        return null;
    }

    if (error.networkError) {
        if (
            'response' in error.networkError &&
            'status' in error.networkError.response &&
            error.networkError.response.status === 429
        ) {
            let retryAfterSeconds = -1;
            if (
                'result' in error.networkError &&
                'extensions' in error.networkError.result &&
                'retryAfterSeconds' in error.networkError.result.extensions
            ) {
                retryAfterSeconds =
                    error.networkError.result.extensions.retryAfterSeconds;
            }
            return new TooManyRequestsError(retryAfterSeconds, error);
        }

        if ('message' in error && error.message === 'Failed to fetch') {
            return new NetworkError(error);
        }

        console.error(error.networkError);
        return null;
    }

    // @ts-ignore
    return new GraphQLError(error.graphQLErrors[0].extensions.code, error);
};

export class ApiError extends Error {
    protected code: string;
    protected error: {};

    constructor(code: string, message: string, error: Error) {
        super(message);
        this.code = code;
        this.error = error;
    }

    getCode() {
        return this.code;
    }

    getMessage() {
        return this.message;
    }

    getError() {
        return this.error;
    }
}

type GraphQLExtensions = {
    missingPermission?: string;
    overlappedTimeEntry?: TimeEntry;
} & Record<string, any>;

export class GraphQLError extends ApiError {
    protected inputErrors = {};
    protected extensions: GraphQLExtensions;

    constructor(code: string, error: ApolloError) {
        super(code, error.graphQLErrors[0].message, error);

        // @ts-ignore
        this.extensions = error.graphQLErrors[0].extensions || {};

        if (code === 'BAD_USER_INPUT') {
            // @ts-ignore
            const invalidArgs =
                (error.graphQLErrors[0].extensions.invalidArgs as object) || {};
            Object.keys(invalidArgs).forEach(input => {
                // Name of the graphql argument
                const inputName = input.split('.')[1];
                // take first error and ignore the others, because UI will render only one per time
                // @ts-ignore
                this.inputErrors[inputName] = Object.keys(
                    invalidArgs[input]
                )[0];
            });
        }
    }

    hasInputErrors(): boolean {
        return Object.keys(this.inputErrors).length !== 0;
    }

    getInputErrors(): any {
        return this.inputErrors;
    }

    getExtensions(): GraphQLExtensions {
        return this.extensions;
    }
}

export class NetworkError extends ApiError {
    constructor(error: Error) {
        super('network_error', error.message, error);
    }
}

export class TooManyRequestsError extends ApiError {
    constructor(public retryAfterSeconds: number, error: Error) {
        super('too_many_requests', 'Too many requests', error);
    }
}

export function isApiErrorCode(
    errorCode: string | string[],
    error: ApolloError | GraphQLError | undefined
) {
    const apiErrorCode = getApiErrorCode(error);
    if (!apiErrorCode) {
        return false;
    }

    if (Array.isArray(errorCode)) {
        return errorCode.includes(apiErrorCode);
    }

    return apiErrorCode === errorCode;
}

export function getApiErrorCode(
    error: Error | ApolloError | GraphQLError | undefined
): string | undefined {
    if (!error) {
        return;
    }

    if (!(error instanceof ApolloError || error instanceof GraphQLError)) {
        return;
    }

    const apiError = toApiError(error);
    if (!apiError) {
        return undefined;
    }

    return apiError.getCode();
}

export const isMissingPermissionError = (
    error: ApolloError | undefined
): boolean => {
    return isApiErrorCode(ApiErrorCode.MISSING_PERMISSION, error);
};

export const isAuthUserInactiveError = (
    error: ApolloError | undefined
): boolean => {
    return isApiErrorCode(ApiErrorCode.AUTH_USER_INACTIVE, error);
};

export const isAccountOrSubscriptionError = (error: Error): boolean => {
    if (!error) {
        return false;
    }

    if (!(error instanceof ApolloError || error instanceof GraphQLError)) {
        return false;
    }

    return isApiErrorCode(
        [
            ApiErrorCode.AUTH_USER_NOT_FOUND,
            ApiErrorCode.AUTH_USER_INACTIVE,
            ApiErrorCode.ORGANIZATION_NOT_ACTIVATED,
            ApiErrorCode.ORGANIZATION_SUBSCRIPTION_EXPIRED
        ],
        error
    );
};

export function isCoreNetworkError(error: Error | undefined) {
    if (!error) {
        return false;
    }

    return error instanceof NetworkError;
}

function isTooManyRequestsError(error: Error | undefined): boolean {
    if (!error) {
        return false;
    }

    if (error instanceof TooManyRequestsError) {
        return true;
    }

    if (!(error instanceof ApolloError)) {
        return false;
    }

    return (
        !!error.networkError &&
        'response' in error.networkError &&
        'status' in error.networkError.response &&
        error.networkError.response.status === 429
    );
}

export function isNetworkError(error: Error | undefined): boolean {
    return isCoreNetworkError(error) || isTooManyRequestsError(error);
}

export function getNetworkErrorMessage(error: Error): string {
    if (isTooManyRequestsError(error)) {
        if (error instanceof TooManyRequestsError) {
            let message =
                'Sie haben zu viele Anfragen in kurzer Zeit gestellt.';
            if (error.retryAfterSeconds > 0) {
                message += ` Bitte versuchen Sie es in ${error.retryAfterSeconds} Sekunden erneut.`;
            } else {
                message += ' Bitte versuchen Sie es gleich erneut.';
            }
            return message;
        }

        return 'Sie haben zu viele Anfragen in kurzer Zeit gestellt. Bitte versuchen Sie es gleich erneut.';
    }

    if (isCoreNetworkError(error)) {
        return 'Es konnte keine Verbindung zum Dajeh-Server hergestellt werden.';
    }

    return `Es ist ein unbekannter Netzwerkfehler aufgetreten. Bitte versuchen Sie es gleich erneut. Fehlercode: ${error.message}`;
}

export enum ApiErrorCode {
    AUTH_USER_NOT_FOUND = 'auth_user_not_found',
    AUTH_USER_INACTIVE = 'auth_user_inactive',
    ORGANIZATION_NOT_ACTIVATED = 'organization_not_activated',
    ORGANIZATION_SUBSCRIPTION_EXPIRED = 'organization_subscription_expired',
    MISSING_PERMISSION = 'missing_permission',
    ADMIN_PRIVILEGES_REQUIRED = 'admin_privileges_required',
    USER_INACTIVE = 'user_inactive'
}

export const AUTH_USER_INACTIVE_ERROR_MSG =
    'Ihr Nutzerkonto wurde deaktiviert.  Bitte kontaktieren Sie den Administrator Ihres Unternehmens, um wieder Zugriff zu erhalten.';

export const AUTH_USER_NOT_FOUND_ERROR_MSG =
    'Ihr Nutzerkonto wurde zwischenzeitlich stillgelegt. Bitte kontaktieren Sie den Administrator Ihres Unternehmens.';

export const ORGANIZATION_NOT_ACTIVATED_ERROR_MSG =
    'Ihr Unternehmenskonto wurde noch nicht aktiviert. Bitte schließen Sie zuerst den Registrierungsprozess ab. Bitte kontaktieren Sie den Dajeh-Support wenn Sie Hilfe dabei benötigen.';

export const ORGANIZATION_TRIAL_EXPIRED_OWNER_ERROR_MSG =
    'Ihre Dajeh-Testphase ist vorüber. Sie können weiterhin auf sämtliche Daten zugreifen aber keine Änderungen mehr vornehmen. Möchten Sie in ein bezahltes Abo wechseln? Kontaktieren Sie uns: support@dajeh.de';

export const ORGANIZATION_SUBSCRIPTION_EXPIRED_OWNER_ERROR_MSG =
    'Ihr Dajeh-Abonnement wurde beendet. Sie können weiterhin auf sämtliche Daten zugreifen aber keine Änderungen mehr vornehmen. Möchten Sie ihr Abo wieder aufnehmen? Kontaktieren Sie einfach uns einfach: support@dajeh.de';

export const ORGANIZATION_TRIAL_EXPIRED_ERROR_MSG =
    'Die Dajeh-Testphase für Ihr Unternehmen ist abgelaufen. Bitte wenden Sie sich an die zuständige Person in Ihrem Unternehmen.';

export const ORGANIZATION_SUBSCRIPTION_EXPIRED_ERROR_MSG =
    'Ihr Unternehmen besitzt kein aktives Dajeh-Abonnement mehr. Bitte wenden Sie sich an die zuständige Person in Ihrem Unternehmen.';

export function getAccountOrSubscriptionErrorMessage(
    error: Error | GraphQLError | ApolloError
): string {
    const apiError = toApiError(error);
    if (apiError) {
        const errorCode = apiError.getCode();
        const extensions =
            apiError instanceof GraphQLError ? apiError.getExtensions() : {};
        switch (errorCode) {
            case ApiErrorCode.AUTH_USER_NOT_FOUND:
                return AUTH_USER_NOT_FOUND_ERROR_MSG;
            case ApiErrorCode.AUTH_USER_INACTIVE:
                return AUTH_USER_INACTIVE_ERROR_MSG;
            case ApiErrorCode.ORGANIZATION_NOT_ACTIVATED:
                return ORGANIZATION_NOT_ACTIVATED_ERROR_MSG;
            case ApiErrorCode.ORGANIZATION_SUBSCRIPTION_EXPIRED:
                const isTrial = !!extensions.isTrial;
                if (actingUserIsOwner()) {
                    if (isTrial) {
                        return ORGANIZATION_TRIAL_EXPIRED_OWNER_ERROR_MSG;
                    } else {
                        return ORGANIZATION_SUBSCRIPTION_EXPIRED_OWNER_ERROR_MSG;
                    }
                }
                if (isTrial) {
                    return ORGANIZATION_TRIAL_EXPIRED_ERROR_MSG;
                }

                return ORGANIZATION_SUBSCRIPTION_EXPIRED_ERROR_MSG;
            default:
                return `Das ist zur Zeit leider nicht möglich. Fehler: ${errorCode})`;
        }
    }

    return `Das ist zur Zeit leider nicht möglich. Fehler: ${error.message})`;
}

export function isCommonCoreError(error: Error | undefined) {
    if (!error) {
        return false;
    }

    return isNetworkError(error) || isAccountOrSubscriptionError(error);
}

export function getCommonCoreErrorMessage(error: Error | undefined): string {
    if (!error) {
        return '';
    }

    if (isAccountOrSubscriptionError(error)) {
        return getAccountOrSubscriptionErrorMessage(error);
    }

    if (isNetworkError(error)) {
        return getNetworkErrorMessage(error);
    }

    return `Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut. (${error.message})`;
}

export function getUnknownErrorFeedbackMessage(error: Error) {
    return `Es ist ein unerwarteter Fehler aufgetreten. Bitte wenden Sie sich an unseren Support: hilfe@dajeh.de (${
        error.name || error.message
    })`;
}

export function getUnknownGraphQlErrorFeedbackMessage(error: GraphQLError) {
    return `Es ist ein uns unbekannter Fehler aufgetreten. Bitte wenden Sie sich an unseren Support: hilfe@dajeh.de (${
        error.getCode() || error.name || error.message
    })`;
}

/**
 * Helper function to handle common errors like network, subscription and account errors. Specify a custom error handler for GraphQl errors.
 *
 * @param error
 * @param graphQlApiErrorHandler
 */
export function getResponseErrorMessage(
    error: ApolloError,
    graphQlApiErrorHandler?: (
        graphQlApiError: GraphQLError
    ) => string | undefined
): string {
    const apiError = toApiError(error);
    if (!apiError) {
        return getUnknownErrorFeedbackMessage(error);
    }

    /* Common core errors (network, subscription, user status) */
    if (isCommonCoreError(apiError)) {
        return getCommonCoreErrorMessage(apiError);
    }

    /* GraphQl error */
    if (apiError instanceof GraphQLError) {
        if (graphQlApiErrorHandler) {
            const errorMessage = graphQlApiErrorHandler(apiError);
            if (errorMessage) {
                return errorMessage;
            }
        }

        return getUnknownGraphQlErrorFeedbackMessage(apiError);
    }

    return getUnknownErrorFeedbackMessage(apiError);
}
