import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { GlobalAlert } from '../../common/components/GlobalAlert';
import { GroupedTimeEntriesSkeleton } from '../common/GroupedTimeEntriesSkeleton';
import { NoEntriesInGroupHintLabel } from '../common/NoEntriesInGroupHintLabel';
import { TimeEntryGroup } from '../common/TimeEntryGroup';
import { TimeEntryGroupBody } from '../common/TimeEntryGroupBody';
import { TimeEntryListItem } from '../TimeEntryListItem';
import { RecentTimeEntriesDayGroupHeader } from './RecentTimeEntriesDayGroupHeader';
import { RecentTimeEntriesFooter } from './RecentTimeEntriesFooter';
import { RecentTimeEntriesNoMoreEntries } from './RecentTimeEntriesNoMore';
import { useRecentTimeEntries } from './useRecentTimeEntries';

export const RecentEmployeeTimeEntries = ({
    employeeId,
    refetchUser,
    lastAdd
}: {
    employeeId: string;
    refetchUser?: () => Promise<void>;
    lastAdd?: Date;
}) => {
    const [lastFetch, setLastFetch] = useState(new Date());

    const {
        recentTimeEntries,
        isFetching,
        hasMore,
        fetchMore,
        isFetchingMore,
        error,
        refetch
    } = useRecentTimeEntries(employeeId);

    function onTimeEntryChanged() {
        refetch();
        refetchUser && refetchUser();
    }

    useEffect(() => {
        if (isFetching) {
            setLastFetch(new Date());
        }
    }, [isFetching]);

    useEffect(() => {
        if (lastAdd && !isFetching && lastFetch < lastAdd) {
            refetch();
        }
    }, [lastFetch, lastAdd, isFetching]);

    if (isFetching) {
        return <RecentEmployeeTimeEntriesSkeleton />;
    }

    if (error && !recentTimeEntries) {
        return (
            <GlobalAlert severity="error">
                Fehler beim Laden der Arbeitszeiteinträge{' '}
                <small>({error?.message})</small>
            </GlobalAlert>
        );
    }

    if (!recentTimeEntries || recentTimeEntries.length === 0) {
        return (
            <GlobalAlert severity="info">
                Mitarbeiter:in hat noch keine Arbeitszeit erfasst
            </GlobalAlert>
        );
    }

    // Render
    return (
        <>
            {recentTimeEntries.map((group, i) => {
                // We show the divider for groups that are more than one day apart from the last group
                const showDivider =
                    i > 0 &&
                    Math.abs(
                        recentTimeEntries[i - 1].startOfDayUnix -
                            group.startOfDayUnix
                    ) >
                        24 * 60 * 60 * 1000;

                return (
                    <TimeEntryGroup
                        key={group.label}
                        size={'medium'}
                        showDivider={showDivider}>
                        <RecentTimeEntriesDayGroupHeader
                            group={group}
                            size={'medium'}
                        />
                        <TimeEntryGroupBody size={'medium'}>
                            {group.entries.map(entry => (
                                <TimeEntryListItem
                                    key={entry.id}
                                    entry={entry}
                                    showActivityIndicators={true}
                                    size={'medium'}
                                    onChanged={onTimeEntryChanged}
                                />
                            ))}
                            {group.entries.length === 0 && (
                                <NoEntriesInGroupHintLabel>
                                    Keine Einträge vorhanden
                                </NoEntriesInGroupHintLabel>
                            )}
                        </TimeEntryGroupBody>
                    </TimeEntryGroup>
                );
            })}

            <RecentTimeEntriesFooter>
                {hasMore ? (
                    <PrimaryButton
                        onClick={() => fetchMore()}
                        disabled={isFetchingMore}
                        aria-busy={isFetchingMore}
                        startIcon={null}>
                        Ältere Einträge laden
                    </PrimaryButton>
                ) : (
                    <RecentTimeEntriesNoMoreEntries>
                        Keine älteren Einträge vorhanden
                    </RecentTimeEntriesNoMoreEntries>
                )}
            </RecentTimeEntriesFooter>
        </>
    );
};

export const RecentEmployeeTimeEntriesSkeleton = () => (
    <GroupedTimeEntriesSkeleton numGroups={5} size={'medium'} />
);
