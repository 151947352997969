import DoneAllIcon from '@mui/icons-material/DoneAll';
import React from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { ProtectedPage } from '../../core/auth/components/ProtectedPage';
import { useUser } from '../../core/auth/useUser';
import { PageBody } from '../../core/components/page/PageBody';
import { PageHeader } from '../../core/components/page/PageHeader';
import { PageHeadline } from '../../core/components/page/PageHeadline';
import { PageHeadlineWithActions } from '../../core/components/page/PageHeadlineWithActions';
import { PageTitleActions } from '../../core/components/page/PageTitleActions';
import { MarkAllNotificationsAsReadButtonWrapper } from '../../notification/MarkAllNotificationsAsReadButtonWrapper';
import { NotificationsList } from '../../notification/NotificationsList';

export const UserNotificationsPage = () => {
    const user = useUser();
    const hasUnreadNotifications = (user?.unreadNotificationsCount || 0) > 0;

    return (
        <ProtectedPage title="Benachrichtigungen">
            <PageHeader>
                <PageHeadlineWithActions>
                    <PageHeadline>Benachrichtigungen</PageHeadline>
                    <PageTitleActions>
                        <MarkAllNotificationsAsReadButtonWrapper
                            renderButton={(markAllAsRead, busy) => (
                                <PrimaryButton
                                    onClick={markAllAsRead}
                                    disabled={busy || !hasUnreadNotifications}
                                    startIcon={<DoneAllIcon />}>
                                    Alle als gelesen markieren
                                </PrimaryButton>
                            )}
                        />
                    </PageTitleActions>
                </PageHeadlineWithActions>
            </PageHeader>

            <PageBody>
                <NotificationsList />
            </PageBody>
        </ProtectedPage>
    );
};
