import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import logoImg from '../../../assets/images/dajeh-logo_362.png';
import { PublicPage } from './PublicPage';

const Wrapper = styled.div`
    flex: 1;
    margin-top: 32px;
    height: 100%;
    width: 100%;

    @media (min-width: 600px) {
        margin-top: 15vh;
    }
`;

const Logo = styled.img`
    width: auto;
    display: block;
    height: 50px;
    margin: 0 auto 24px auto;

    @media (min-width: 450px) {
        height: 60px;
        margin-bottom: 32px;
    }

    @media (min-width: 600px) {
        height: 100px;
    }
`;

export const AuthBasePage = ({
    title,
    children
}: PropsWithChildren<{ title: string }>) => {
    return (
        <PublicPage title={title}>
            <Wrapper>
                <Logo src={logoImg} alt="Dajeh" />

                {children}
            </Wrapper>
        </PublicPage>
    );
};
