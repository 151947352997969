import React from 'react';
import { User } from '../user';

type ContextFunctions = {
    getAuthToken: () => string | null | undefined;
    setAuthToken: (token: string) => void;
    signIn: (token: string) => void;
    signOut: () => Promise<void>;
};

export type AuthenticationContextProps = ContextFunctions & {
    user: User | null | undefined
    authenticated: boolean
};

const initialContextFunctions: ContextFunctions = {
    setAuthToken: (token: string) => null,
    getAuthToken: () => null,
    signIn: (token: string) => {
    },
    signOut: (): Promise<void> => Promise.resolve()
};

const authenticationContext: AuthenticationContextProps = {
    ...initialContextFunctions,
    user: undefined,
    authenticated: false
};

export const AuthenticationContext = React.createContext(authenticationContext);
