import { ApolloError, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem
} from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Select, TextField } from 'formik-mui';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { ContactSupportLink } from '../../common/components/ContactSupportLink';
import {
    FormDrawer,
    FormDrawerBody,
    FormDrawerBodyCt,
    FormDrawerCloseButton,
    FormDrawerFooter,
    FormDrawerHead,
    FormDrawerLabel,
    FormDrawerSection,
    FormDrawerTitle
} from '../../common/components/form/FormDrawer';
import { ToggleExpandLink } from '../../common/components/ToggleExpandLink';
import { getResponseErrorMessage } from '../../core/api/error';
import { useUser } from '../../core/auth/useUser';
import {
    getEmployeeOnboardingDocumentUrl,
    Paths
} from '../../core/navigation/paths';
import { Colors } from '../../core/theme/Colors';
import { QUERY_ORGANIZATION_MEMBERS } from '../../organization/queries';
import { isStrassenUser } from '../../user';
import { MUTATION_CREATE_USER } from '../../user/queries';
import { EmployeeFormValues } from './EmployeeFormValues';

const FullWidthSelectContainer = styled.div`
    > .MuiFormControl-root {
        width: 100%;
    }
`;

const NewEmployeeSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Bitte gib den vollen Vornamen ein')
        .max(
            100,
            'Vornamen können bei uns nicht mehr als 100 Zeichen haben. Bitte kürzen!'
        )
        .required('Bitte gib einen Vornamen an'),
    lastName: Yup.string()
        .min(2, 'Bitte gib den vollen Nachnamen ein')
        .max(
            100,
            'Nachnamen können bei uns nicht mehr als 100 Zeichen haben. Bitte kürzen!'
        )
        .required('Bitte gib einen Nachnamen an'),
    email: Yup.string()
        .email('Bitte gib eine gültige E-Mail-Adresse ein')
        .required('Bitte gib eine E-Mail-Adresse an')
});

function getErrorMessage(error: ApolloError): string {
    return getResponseErrorMessage(error, apiError => {
        const code = apiError.getCode();

        if (code === 'user_email_already_taken') {
            return `Diese E-Mail-Adresse ist bereits vergeben.`;
        }
    });
}

function getNewInitialFormValues(): EmployeeFormValues {
    return {
        firstName: '',
        lastName: '',
        email: '',
        role: 'standard'
    };
}

type NewEmployeeDrawerFormProps = {
    onClosed: () => void;
};

const SnackbarContentActions = styled.div`
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .MuiButton-root {
        background: #fff;
        color: ${Colors.GreenDark};

        &:hover,
        &:focus,
        &:active {
            color: ${Colors.GreenDarkest};
            background-color: #eee;
        }
    }
`;

const EmployeeAddedSnackbarContent = ({ userId }: { userId: string }) => (
    <div>
        <strong>Mitarbeiter erfolgreich angelegt</strong>
        <div style={{ height: '0.5em' }} />
        Ihr neues Team-Mitglied erhält in Kürze eine E-Mail mit einem Link zur
        Aktivierung des Kontos.
        <div style={{ height: '0.5em' }} />
        Zusätzlich haben wir Ihnen ein Onboarding-Dokument erstellt, das Sie an
        Ihren Mitarbeiter weiterleiten können.
        <SnackbarContentActions>
            <Button
                size={'small'}
                target="_blank"
                href={getEmployeeOnboardingDocumentUrl(userId)}>
                Onboarding-Dokument öffnen
            </Button>
        </SnackbarContentActions>
    </div>
);

export const NewEmployeeDrawerForm = ({
    onClosed
}: NewEmployeeDrawerFormProps) => {
    const [visible, setVisible] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [remoteError, setRemoteError] = useState('');
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

    const formikRef = React.useRef<FormikProps<any>>(null);

    const { enqueueSnackbar } = useSnackbar();
    const user = useUser();

    /* Create */
    const [createUserMutation] = useMutation(MUTATION_CREATE_USER, {
        fetchPolicy: 'no-cache',
        refetchQueries: [QUERY_ORGANIZATION_MEMBERS]
    });

    function submit(values, actions) {
        setBusy(true);

        createUserMutation({
            variables: {
                input: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    role: values.role
                }
            }
        })
            .then(payload => {
                enqueueSnackbar(
                    <EmployeeAddedSnackbarContent
                        userId={payload.data.createUser.user.id}
                    />,
                    { variant: 'success', autoHideDuration: 10000 }
                );
                closeDrawer();
            })
            .catch(e => {
                setRemoteError(getErrorMessage(e));
            })
            .finally(() => {
                actions.setSubmitting(false);
                setBusy(false);
            });
    }

    function closeDrawer() {
        setVisible(false);
        setTimeout(() => {
            onClosed();
        }, 200);
    }

    function requestClose() {
        if (!formikRef?.current?.dirty) {
            closeDrawer();
            return;
        }

        setShowCloseConfirmation(true);
    }

    useEffect(() => {
        setVisible(true);
    }, []);

    return (
        <>
            <FormDrawer
                anchor="right"
                open={visible}
                onClose={requestClose}
                variant="temporary">
                <Formik<EmployeeFormValues>
                    initialValues={getNewInitialFormValues()}
                    validationSchema={NewEmployeeSchema}
                    onSubmit={submit}
                    innerRef={formikRef}>
                    <Form>
                        {/* For debugging */}
                        {/*<FormDebuggerCt>*/}
                        {/*    <FormDebugger />*/}
                        {/*</FormDebuggerCt>*/}

                        <FormDrawerHead>
                            <FormDrawerTitle>
                                Mitarbeiter:in hinzufügen
                            </FormDrawerTitle>
                            <FormDrawerCloseButton
                                type="button"
                                onClick={requestClose}>
                                <CloseIcon />
                            </FormDrawerCloseButton>
                        </FormDrawerHead>

                        <FormDrawerBodyCt>
                            <FormDrawerBody>
                                <Alert severity="info">
                                    <div
                                        style={{
                                            fontWeight: 'normal',
                                            fontSize: 15
                                        }}>
                                        {!showMoreInfo && (
                                            <>
                                                Ihr neues Teammitglied erhält
                                                einen Einladungs&shy;link per
                                                E-Mail.
                                            </>
                                        )}

                                        {showMoreInfo && (
                                            <>
                                                Ihr neues Teammitglied erhält
                                                einen Einladungs&shy;link per
                                                E-Mail. Mit diesem
                                                Einladungslink kann das
                                                Nutzerkonto aktiviert werden.
                                                <br />
                                                <br />
                                                Zusätzlich steht für Ihr neues
                                                Teammitglied ein
                                                Onboarding-Dokument zur
                                                Verfügung. Das
                                                Onboarding-Dokument finden Sie
                                                im 3-Punkte-Menü.
                                                {isStrassenUser(user) && (
                                                    <>
                                                        <br />
                                                        <br />
                                                        <strong>
                                                            Terminal-Nutzung:
                                                        </strong>{' '}
                                                        Um Nutzern eine
                                                        Terminal-Karte
                                                        zuzuordnen, kontaktieren
                                                        Sie bitte nach dem
                                                        Hinzufügen den{' '}
                                                        <ContactSupportLink
                                                            subject={
                                                                'Anfrage: Zuweisung von Terminal-Karten'
                                                            }>
                                                            Dajeh-Support
                                                        </ContactSupportLink>
                                                        .
                                                    </>
                                                )}
                                                <br />
                                                <br />
                                                <a
                                                    href={`${Paths.HelpEmployees}#add`}
                                                    target={'_blank'}>
                                                    Hier geht's zur detaillierte
                                                    Anleitung
                                                </a>
                                                .
                                            </>
                                        )}
                                        <div style={{ marginTop: '0.5em' }}>
                                            <ToggleExpandLink
                                                onClick={() =>
                                                    setShowMoreInfo(
                                                        !showMoreInfo
                                                    )
                                                }
                                                expanded={showMoreInfo}
                                                showMoreLabel={'Mehr'}
                                                showLessLabel={
                                                    'Information ausblenden'
                                                }
                                            />
                                        </div>
                                    </div>
                                </Alert>

                                {remoteError && (
                                    <Alert severity="error">
                                        {remoteError}
                                    </Alert>
                                )}

                                <FormDrawerSection>
                                    <FormDrawerLabel htmlFor="firstName">
                                        Vorname
                                    </FormDrawerLabel>
                                    <Field
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Vorname eingeben"
                                        disabled={isBusy}
                                        component={TextField}
                                        fullWidth
                                    />

                                    <FormDrawerLabel htmlFor="lastName">
                                        Nachname
                                    </FormDrawerLabel>
                                    <Field
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Nachname eingeben"
                                        disabled={isBusy}
                                        component={TextField}
                                        fullWidth
                                    />
                                </FormDrawerSection>

                                <FormDrawerSection>
                                    <FormDrawerLabel htmlFor="email">
                                        E-Mail-Adresse
                                    </FormDrawerLabel>
                                    <Field
                                        id="email"
                                        name="email"
                                        type="email"
                                        disabled={isBusy}
                                        component={TextField}
                                        placeholder={'E-Mail-Adresse eingeben'}
                                        fullWidth
                                    />
                                </FormDrawerSection>

                                <FormDrawerSection>
                                    <FormDrawerLabel htmlFor="role">
                                        Berechtigung
                                    </FormDrawerLabel>
                                    <FullWidthSelectContainer>
                                        <Field
                                            id="role"
                                            name="role"
                                            disabled={isBusy}
                                            component={Select}>
                                            <MenuItem value={'standard'}>
                                                Standard
                                            </MenuItem>
                                            <MenuItem value={'admin'}>
                                                Administrator
                                            </MenuItem>
                                        </Field>
                                    </FullWidthSelectContainer>
                                </FormDrawerSection>
                            </FormDrawerBody>
                        </FormDrawerBodyCt>

                        <FormDrawerFooter>
                            <SecondaryButton
                                onClick={requestClose}
                                style={{ float: 'left' }}
                                type="button"
                                disabled={isBusy}>
                                Abbrechen
                            </SecondaryButton>
                            <PrimaryButton type="submit" disabled={isBusy}>
                                Mitarbeiter:in anlegen
                            </PrimaryButton>
                        </FormDrawerFooter>
                    </Form>
                </Formik>
            </FormDrawer>

            <Dialog
                open={showCloseConfirmation}
                onClose={() => setShowCloseConfirmation(false)}
                aria-labelledby="aef-dirty-close-title"
                aria-describedby="aef-dirty-close-description">
                <DialogTitle id="aef-dirty-close-title">
                    Mitarbeiterdaten verwerfen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="aef-dirty-close-description">
                        Sie haben bereits Daten für Ihren neuen Mitarbeiter
                        hinterlegt. Möchten Sie die Daten wirklich verwerfen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SecondaryButton
                        onClick={() => setShowCloseConfirmation(false)}>
                        Weiter bearbeiten
                    </SecondaryButton>
                    <PrimaryButton onClick={closeDrawer} autoFocus>
                        Verwerfen
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
