import styled from '@emotion/styled';
import { Colors } from '../../core/theme/Colors';

export const Item = styled.div<{
    size?: 'small' | 'medium' | 'large';
}>`
    padding: ${props => {
        if (props.size === 'small') {
            return '8px 16px';
        }
        if (props.size === 'medium') {
            return '9px 16px';
        }
        return '11px 16px';
    }};
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: box-shadow 200ms, background 200ms;
    cursor: pointer;

    &:after {
        content: ' ';
        display: table;
        clear: both;
    }

    &:hover {
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.5);
    }

    &:active {
        background: ${Colors.BackgroundDark};
    }

    &:focus {
        outline: 1px solid ${Colors.LineColor};
    }

    & + & {
        margin-top: ${props => {
            if (props.size === 'small') {
                return '4px';
            }
            if (props.size === 'medium') {
                return '6px';
            }
            return '8px';
        }};
    }
`;

export const ItemFromTo = styled.div`
    float: left;
    width: 100px;
    text-align: left;
    font-size: 16px;
    color: #86868a;
`;

export const ItemLabel = styled.div<{ hasDescription: boolean }>`
    float: left;
    font-size: 14px;
    line-height: 18px;
    padding-top: 3px;
    color: ${props => (props.hasDescription ? '#86868A' : '#c4c4c6')};
`;

export const ItemSummaryCt = styled.div`
    float: right;
`;

export const ItemSummaryItem = styled.div`
    float: left;

    & + & {
        margin-left: 8px;

        @media (min-width: 600px) {
            margin-left: 10px;
        }

        @media (min-width: 960px) {
            margin-left: 12px;
        }
    }
`;

export const ItemTotalTime = styled.span`
    text-align: right;
`;

export const ItemActiveDot = styled.span`
    float: left;
    width: 8px;
    height: 8px;
    margin-top: 8px;
    border-radius: 50%;
    background-color: ${Colors.Red};
`;
