import styled from '@emotion/styled'
import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { AppFooter } from '../../components/page/AppFooter';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useAuthenticatedContext } from '../../hooks/useAuthenticatedContext';
import { Paths } from '../../navigation/paths';

const PublicPageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const PublicPageContentWrapper = styled.div`
  flex: 1;
  width: 100%;
`

type PublicPageProps = {
    title: string;
    viewableIfAuthenticated?: boolean
}

export const PublicPage = ({title, children, viewableIfAuthenticated = false}: PropsWithChildren<PublicPageProps>) => {

    useDocumentTitle(title);

    const authContext = useAuthenticatedContext();
    if (!viewableIfAuthenticated && authContext.authenticated) {
        return <Navigate to={Paths.Home} />
    }

    return (
        <PublicPageWrapper>
            <PublicPageContentWrapper>
                {children}
            </PublicPageContentWrapper>

            <AppFooter />
        </PublicPageWrapper>
    );
};
