import styled from '@emotion/styled';
import { Container } from '@mui/material';
import Link from '@mui/material/Link';
import React from 'react';
import { getAppVersion } from '../../environment';
import { Colors } from '../../theme/Colors';

const Wrapper = styled.footer`
    margin-top: 48px;
    padding: 16px 0;
`;

const StyledText = styled.div`
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.TextLight};

    a {
        color: ${Colors.TextLight};
        text-decoration-color: ${Colors.TextLight};

        &:hover {
            color: ${Colors.Text};
            text-decoration-color: ${Colors.Text};
        }
    }
`;

export const AppFooter = () => (
    <Wrapper>
        <Container>
            <StyledText>
                Made with passion by{' '}
                <Link
                    href="https://www.kalaflax.com"
                    target="_blank"
                    tabIndex={-1}>
                    Kalaflax
                </Link>{' '}
                – &copy; {new Date().getFullYear()} - Version {getAppVersion()}
            </StyledText>
        </Container>
    </Wrapper>
);
