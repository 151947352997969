import styled from '@emotion/styled';
import { ButtonProps } from '@mui/material';
import { FormSubmitButton } from '../button/PrimaryButton';

const _PublicPageCardFormSubmitButton = (props: ButtonProps) => (
    <FormSubmitButton fullWidth {...props} />
);

export const PublicPageCardFormSubmitButton = styled(
    _PublicPageCardFormSubmitButton
)`
    display: block;
    margin: 32px 0 16px;
`;
