import React from 'react';
import { ProtectedPage } from '../core/auth/components/ProtectedPage';
import { Permission } from '../core/auth/permission';
import { EmployeeOnboardingDocumentPageContent } from './EmployeeOnboardingDocumentPageContent';

export const EmployeeOnboardingDocumentPage = () => {
    return (
        <ProtectedPage permissions={Permission.Manage_Users} noWrapper>
            <EmployeeOnboardingDocumentPageContent />
        </ProtectedPage>
    );
};
