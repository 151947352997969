import styled from '@emotion/styled';
import React, { Fragment, ReactNode } from 'react';
import { NewLineToBreak } from '../common/components/NewLineToBreak';
import { Colors } from '../core/theme/Colors';
import { formatDateTime } from '../timeTracking/time';
import { NotificationDto } from './notification.dto';
import CheckIcon from '@mui/icons-material/Check';

const MessageCt = styled.div`
    color: ${Colors.SecondaryDarker};

    em {
        color: ${Colors.SecondaryDarker};
        font-weight: 600;
        text-decoration: none;
        font-style: normal;
    }

    a {
        color: ${Colors.SecondaryDarker};
        font-weight: 600;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: ${Colors.Primary};
        }
    }
`;

type NotificationsPopoverItemProps = {
    message?: string | null;
};

export const NotificationMessage = ({
    message
}: NotificationsPopoverItemProps) => {
    if (!message) {
        return null;
    }

    const publicEntityRegEx: RegExp = /\[[^|]*\|[^\]]*\]/g;
    const parts: Array<any> = [];
    let lastIndex = 0;
    let match;

    while ((match = publicEntityRegEx.exec(message)) !== null) {
        // Push the text before the match
        if (match.index > lastIndex) {
            parts.push(message.substring(lastIndex, match.index));
        }

        // Add the match
        const publicEntityMatch = match[0];
        const id = publicEntityMatch.split('|')[0].replace('[', '');
        const label = publicEntityMatch.split('|')[1].replace(']', '');
        // TODO add links to public entities
        parts.push(<em>{label}</em>);

        lastIndex = match.index + publicEntityMatch.length;
    }

    // Push the remaining text after the last match
    if (lastIndex < message.length) {
        parts.push(message.substring(lastIndex, message.length));
    }

    const elements = parts.map((part, i) => (
        <Fragment key={i}>{part}</Fragment>
    ));
    return <MessageCt>{elements}</MessageCt>;
};
