import styled from '@emotion/styled';
import EmailIcon from '@mui/icons-material/Email';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import React, { ReactNode, ReactNodeArray } from 'react';
import logoImg from '../../../assets/images/dajeh-logo_362.png';
import { AppFooter } from './AppFooter';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Card } from '../../theme/Base';
import { Colors } from '../../theme/Colors';

const Wrapper = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
`;

const ContentWrapper = styled.div`
    flex: 1;
    width: 100%;
`;

const ErrorWrapper = styled.div`
    flex: 1;
    margin-top: 15vh;
    height: 100%;
    width: 100%;
`;

const ErrorContainer = styled(Card)`
    position: relative;
    z-index: 1;
    width: 80%;
    max-width: 500px;
    margin: auto;
    text-align: center;
`;

const SadEmojiCt = styled.span`
    position: absolute;
    z-index: 2;
    top: -25px;
    right: -25px;
    font-size: 50px;
    cursor: default;
`;

const ErrorMessage = styled.div`
    margin-top: 12px;
    padding-top: 24px;
    border-top: 1px solid ${Colors.LineColor};
`;

const ErrorActions = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    height: 100px;
    width: auto;
    display: block;
    margin: 0 auto 32px auto;
`;

export type ErrorPageProps = {
    title: string;
    children: ReactNode | ReactNodeArray;
};
export const ErrorPage = ({ title, children }: ErrorPageProps) => {
    useDocumentTitle(title);

    return (
        <>
            <CssBaseline />
            <Wrapper>
                <ContentWrapper>
                    <ErrorWrapper>
                        <Logo src={logoImg} alt="Dajeh" />

                        <ErrorContainer>
                            <Typography
                                variant="h4"
                                component="h1"
                                gutterBottom>
                                {title}
                                <SadEmojiCt>😞</SadEmojiCt>
                            </Typography>

                            <ErrorMessage>{children}</ErrorMessage>

                            <ErrorActions>
                                <Button
                                    onClick={() => window.location.reload()}
                                    startIcon={<RefreshIcon />}>
                                    Seite neu laden
                                </Button>
                                <Button
                                    component="a"
                                    href="mailto:hilfe@dajeh.de"
                                    startIcon={<EmailIcon />}>
                                    Hilfe anfragen
                                </Button>
                            </ErrorActions>
                        </ErrorContainer>
                    </ErrorWrapper>
                </ContentWrapper>

                <AppFooter />
            </Wrapper>
        </>
    );
};
