import styled from '@emotion/styled';
import { Colors } from '../../../core/theme/Colors';

export const PublicPageCardFooter = styled.footer`
    padding: 16px 16px 0;
    margin: 32px -24px -8px;
    border-top: 1px solid ${Colors.LineColorLight};
    font-size: 14px;
    line-height: 22px;
    color: ${Colors.TextLightest};

    @media (min-width: 600px) {
        padding-top: 24px;
    }

    a {
        color: ${Colors.TextLightest};
        text-decoration: underline;

        &:hover,
        &:focus {
            color: ${Colors.TextLighter};
            text-decoration: none;
        }

        &:active {
            color: ${Colors.TextLight};
            text-decoration: none;
        }
    }
`;
