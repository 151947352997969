import { getSupportEmailAddress } from '../core/environment';

export const getMailTo = (email, subject, body) => {
    return `mailto:${email}?subject=${encodeURIComponent(
        subject
    )}&body=${encodeURIComponent(body)}`;
};

export const getMailToSupport = (subject: string, body: string) => {
    return getMailTo(getSupportEmailAddress(), subject, body);
};
