import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { PrimaryButton } from '../common/components/button/PrimaryButton';
import { GridContainer } from '../common/components/layout/GridContainer';
import { GridItem } from '../common/components/layout/GridItem';
import { User } from '../core/auth/user';
import { SubPageSectionButtons } from '../core/components/page/SubPageSectionButtons';
import { SubPageSectionHeadline } from '../core/components/page/SubPageSectionHeadline';
import { ActivateUserButtonWrapper } from '../user/ActivateUserButtonWrapper';
import { EditUserButtonWrapper } from '../user/EditUserButtonWrapper';
import { UserState } from '../user/userState';
import {
    EmployeeNotes,
    EmployeeNotesSkeleton
} from './components/EmployeeNotes';
import {
    EmployeeProfileCard,
    EmployeeShortInfoSkeleton
} from './components/EmployeeProfileCard';

export const EmployeeProfilePageContent = ({
    user,
    refetchUser
}: {
    user: User;
    refetchUser: () => Promise<void>;
}) => {
    function onUserChanged() {
        refetchUser();
    }

    return (
        <GridContainer>
            <GridItem xs={12} lg={5}>
                <SubPageSectionHeadline>Stammdaten</SubPageSectionHeadline>

                <EmployeeProfileCard data={user} />

                <SubPageSectionButtons>
                    {/* Activate button */}
                    {user.state === UserState.INACTIVE && (
                        <ActivateUserButtonWrapper
                            user={user}
                            onSuccess={onUserChanged}
                            renderButton={(user, working, activateUser) => (
                                <LoadingButton
                                    color={'secondary'}
                                    loading={working}
                                    startIcon={
                                        <ToggleOnIcon fontSize="small" />
                                    }
                                    onClick={e => {
                                        e.stopPropagation();
                                        activateUser();
                                    }}>
                                    Konto aktivieren
                                </LoadingButton>
                            )}
                        />
                    )}

                    <EditUserButtonWrapper
                        renderButton={showEditForm => (
                            <PrimaryButton
                                startIcon={<EditIcon fontSize="small" />}
                                onClick={e => {
                                    e.stopPropagation();
                                    showEditForm(user.id!, onUserChanged);
                                }}>
                                Bearbeiten
                            </PrimaryButton>
                        )}
                    />
                </SubPageSectionButtons>
            </GridItem>
            <GridItem xs={12} lg={7}>
                <SubPageSectionHeadline>Notizen</SubPageSectionHeadline>

                <EmployeeNotes user={user} />
            </GridItem>
        </GridContainer>
    );
};

export const EmployeeProfilePageContentSkeleton = () => (
    <GridContainer>
        <GridItem xs={12} lg={5}>
            <SubPageSectionHeadline>Stammdaten</SubPageSectionHeadline>
            <EmployeeShortInfoSkeleton />
        </GridItem>
        <GridItem xs={12} lg={7}>
            <SubPageSectionHeadline>Notizen</SubPageSectionHeadline>
            <EmployeeNotesSkeleton />
        </GridItem>
    </GridContainer>
);
