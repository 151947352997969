import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { DateTime } from 'luxon';
import React from 'react';
import { formatSecondsAsTimeDuration } from '../../time';
import { ReportInsightChip } from '../ReportInsightChip';
import { TimeReportDuration } from '../TimeReportDuration';
import { OrganizationMemberWorkTimeReportDetails } from './OrganizationMemberWorkTimeReportDetails';
import { OrganizationMemberWorkTimeSummaryReportDto } from './organizationWorkTimeReport.types';

type OrgMemberWorkTimeSummaryReportTableRowProps = {
    month: DateTime;
    data: OrganizationMemberWorkTimeSummaryReportDto;
};

const StyledTableCell = styled(TableCell)`
    border-top: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 0;
`;

export const OrganizationMemberWorkTimeSummaryReport = ({
    month,
    data
}: OrgMemberWorkTimeSummaryReportTableRowProps) => {
    const { user, totalSeconds, hasInsights } = data;

    const [open, setOpen] = React.useState(false);

    const totalTime = formatSecondsAsTimeDuration(totalSeconds);

    return (
        <>
            <TableRow>
                <StyledTableCell
                    style={{ width: 48, height: 55, padding: '0 0 0 16px' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                    {user.firstName} {user.lastName}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'right' }}>
                    <TimeReportDuration timeDuration={totalTime} />
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'right' }}>
                    {hasInsights ? (
                        <ReportInsightChip
                            label="Hinweise vorhanden"
                            onClick={() => setOpen(!open)}
                        />
                    ) : null}
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} />
                <TableCell style={{ padding: '0 0 0 16px' }} colSpan={4}>
                    <Collapse in={open} timeout="auto">
                        <OrganizationMemberWorkTimeReportDetails
                            user={user}
                            month={month}
                            shown={open}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
