import TodayIcon from '@mui/icons-material/Today';
import { FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FieldConfig, useField, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { TimeEntryFormService } from './TimeEntryFormService';
import { TimeEntryFormValues } from './TimeEntryFormValues';

const DateInputFormat = 'd. MMMM yyyy';

export const StartDateField = (
    props: FieldConfig & { id?: string; disabled?: boolean }
) => {
    const { values, setValues, setFieldTouched, setFieldError } =
        useFormikContext<TimeEntryFormValues>();

    const [field, meta] = useField(props);
    const fieldName = field.name;

    const touchedError = meta.touched && !!meta.error;

    return (
        <FormControl fullWidth>
            <DatePicker<DateTime>
                format={DateInputFormat}
                showDaysOutsideCurrentMonth={true}
                disableFuture={true}
                onChange={newValue => {
                    if (!newValue || !newValue.isValid) {
                        setFieldError(
                            fieldName,
                            'Bitte gültiges Datum eingeben'
                        );
                    }

                    const updatedValues = TimeEntryFormService.update(
                        values,
                        'date',
                        newValue!.toISO()!
                    );
                    setFieldTouched('startDate', true, false);
                    setValues(updatedValues, true);
                }}
                value={DateTime.fromISO(field.value)}
                slots={{
                    openPickerIcon: props => (
                        <TodayIcon {...props} sx={{ fontSize: 20 }} />
                    )
                }}
                slotProps={{
                    field: {
                        id: props.id,
                        disabled: props.disabled
                    },
                    textField: {
                        fullWidth: true,
                        helperText: touchedError && meta.error,
                        error: touchedError
                    },
                    openPickerButton: {
                        disableRipple: true
                    }
                }}
            />
        </FormControl>
    );
};
