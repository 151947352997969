import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Colors } from '../../theme/Colors';

const ListFilterCt = styled.div`
    padding: 4px 12px;
    border-bottom: 1px solid ${Colors.LineColor};

    .MuiInputLabel-root {
        transform: none;
        font-size: 13px;
        line-height: 17px;
        color: ${Colors.Text};
    }

    @media (min-width: 900px) {
        padding: 16px 20px;
    }
`;

export const ListFilterBar = ({
    children
}: PropsWithChildren<{ label?: string }>) => (
    <ListFilterCt>{children}</ListFilterCt>
);
