import { DateTime } from 'luxon';
import React from 'react';
import {
    OrganizationMemberWorkTimeDummyEntry,
    OrganizationMemberWorkTimeEntry
} from './OrganizationMemberWorkTimeEntry';
import {
    OrganizationWorkTimeReportDayDto,
    TimeEntryInsightDto
} from './organizationWorkTimeReport.types';

type WormTimeDayReportProps = {
    data: OrganizationWorkTimeReportDayDto;
    insights: Map<string, TimeEntryInsightDto[]>;
    dayIndex: number;
};

export const OrganizationMemberWorkTimeDayReport = (
    props: WormTimeDayReportProps
) => {
    const { data, dayIndex, insights } = props;

    const { date, entries } = data;
    const totalEntries = entries?.length || 0;

    const dateDateTime = DateTime.fromISO(date);

    if (totalEntries === 0) {
        return (
            <OrganizationMemberWorkTimeDummyEntry
                date={dateDateTime}
                dayIndex={dayIndex}
            />
        );
    }

    return (
        <>
            {entries.map((entry, i) => (
                <OrganizationMemberWorkTimeEntry
                    key={`${date}-${i}`}
                    date={dateDateTime}
                    data={data}
                    entry={entry}
                    entryIndex={i}
                    dayIndex={dayIndex}
                    insights={
                        entry?.node?.id
                            ? insights.get(entry.node.id)
                            : undefined
                    }
                />
            ))}
        </>
    );
};
