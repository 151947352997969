import styled from '@emotion/styled';
import React from 'react';
import { Colors } from '../../core/theme/Colors';

export const HelpImageFigure = styled.figure`
    margin: 0;
    border: 1px solid #e0e0e0;
    cursor: zoom-in;
    border-radius: 6px;
    overflow: hidden;
`;

export const HelpImageFigImage = styled.img`
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
`;

export const HelpImageFigCaption = styled.figcaption`
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.TextLight};
    padding: 8px 16px;
`;

export type HelpImageProps = {
    src: string;
    imageMap: Record<
        string,
        {
            src: string;
            index: number;
            alt?: string;
            title: string;
            description: string;
        }
    >;
    onClick: (src: string) => void;
};

export const HelpFigure = ({ src, imageMap, onClick }: HelpImageProps) => {
    const image = imageMap[src];

    return (
        <HelpImageFigure onClick={() => onClick(src)}>
            <HelpImageFigImage src={src} alt={image.alt || ''} />
            <HelpImageFigCaption>{image.title}</HelpImageFigCaption>
        </HelpImageFigure>
    );
};
