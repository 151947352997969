import { ApolloError, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CheckboxField } from '../../common/components/form/CheckboxField';
import { PublicPageCard } from '../../common/components/page/PublicPageCard';
import { PublicPageCardForm } from '../../common/components/page/PublicPageCardForm';
import { PublicPageCardFormError } from '../../common/components/page/PublicPageCardFormError';
import { PublicPageCardFormSubmitButton } from '../../common/components/page/PublicPageCardFormSubmitButton';
import { PublicPageCardTitle } from '../../common/components/page/PublicPageCardTitle';
import { FormFeedback } from '../../common/FormFeedback';
import { getResponseErrorMessage } from '../../core/api/error';
import { Colors } from '../../core/theme/Colors';
import { NewPasswordField } from '../../user/password/NewPasswordField';
import { PasswordField } from '../../user/password/PasswordField';
import { MUTATION_ACTIVATE_ORGANIZATION } from '../queries';
import { OrganizationActivationPublicData_Beta } from './OrganizationActivationPublicData';
import { OrganizationActivationSuccessPage } from './OrganizationActivationSuccessPage';

const AutomaticSubscriptionEndOnTestPeriodHint = styled.div`
    margin-top: 16px;
    text-align: center;
    color: ${Colors.Secondary};
    font-size: 14px;
    line-height: 20px;
`;

const TermsBox = styled.div`
    margin-top: 32px;

    .MuiFormControl-fullWidth {
        margin-top: 0;
    }
`;

const StyledPublicPageCardForm = styled(PublicPageCardForm)`
    label {
        color: ${Colors.TextMedium};
    }
`;

interface ActivateOrganizationFormValues {
    owner_password: string;
    owner_password_repeat: string;
    accept_terms: boolean;
    accept_privacy_policy: boolean;
}

type AcceptInvitationFormProps = {
    data: OrganizationActivationPublicData_Beta;
};

export const OrganizationActivationForm = ({
    data
}: AcceptInvitationFormProps) => {
    const [hasActivatedOrganization, setHasActivatedOrganization] =
        useState(false);
    const [activateOrganization, { loading }] = useMutation(
        MUTATION_ACTIVATE_ORGANIZATION
    );
    const [error, setError] = useState<string | undefined>();

    const { token } = useParams();

    function onSubmit(values, { setSubmitting }) {
        if (loading) {
            return;
        }

        setError(undefined);

        // Redirect happens in the AuthenticationContextProvider
        activateOrganization({
            variables: {
                token: token,
                input: {
                    owner_password: values.owner_password,
                    acceptTerms: values.accept_terms,
                    acceptPrivacyPolicy: values.accept_privacy_policy
                }
            }
        })
            .then(() => {
                setHasActivatedOrganization(true);
            })
            .catch(e => {
                setError(getRemoteErrorMessage(e));
                setSubmitting(false);
            });
    }

    if (hasActivatedOrganization) {
        return <OrganizationActivationSuccessPage data={data} />;
    }

    return (
        <PublicPageCard>
            <PublicPageCardTitle>Konto aktivieren</PublicPageCardTitle>

            <p style={{ color: Colors.TextMedium }}>
                Noch ein Schritt, dann kann es losgehen:
            </p>

            {!!error && (
                <PublicPageCardFormError>{error}</PublicPageCardFormError>
            )}

            <Formik
                initialValues={{
                    owner_password: '',
                    owner_password_repeat: '',
                    accept_terms: false,
                    accept_privacy_policy: false
                }}
                validate={values => {
                    const errors: FormFeedback<ActivateOrganizationFormValues> =
                        {};

                    if (!values.owner_password) {
                        errors.owner_password =
                            'Bitte geben Sie ein Passwort an';
                    }

                    if (!values.owner_password_repeat) {
                        errors.owner_password_repeat =
                            'Bitte wiederholen Sie Ihr gewähltes Passwort';
                    }

                    if (
                        values.owner_password !== values.owner_password_repeat
                    ) {
                        errors.owner_password_repeat =
                            'Passwörter stimmen nicht überein';
                    }

                    if (!values.accept_terms) {
                        errors.accept_terms =
                            'Bitte akzeptieren Sie die Nutzungsbedingungen';
                    }

                    if (!values.accept_privacy_policy) {
                        errors.accept_privacy_policy =
                            'Bitte akzeptieren Sie die Datenschutzhinweise';
                    }

                    return errors;
                }}
                onSubmit={onSubmit}>
                {({ submitForm, isSubmitting }) => (
                    <StyledPublicPageCardForm>
                        <NewPasswordField
                            id="password"
                            name="owner_password"
                            placeholder="Passwort eingeben"
                            label="Passwort festlegen"
                            disabled={isSubmitting}
                            labelPosition={'inside'}
                        />
                        <PasswordField
                            id="password_repeat"
                            name="owner_password_repeat"
                            placeholder="Passwort wiederholen"
                            disabled={isSubmitting}
                        />

                        <TermsBox>
                            <CheckboxField
                                id="accept_terms"
                                name="accept_terms"
                                label={
                                    <>
                                        Ich akzeptieren die{' '}
                                        <a
                                            href="https://www.dajeh.de/allgemeine-nutzungsbedingungen"
                                            target="_blank">
                                            Nutzungsbedingungen
                                        </a>
                                    </>
                                }
                                disabled={isSubmitting}
                            />

                            <CheckboxField
                                id="accept_privacy_policy"
                                name="accept_privacy_policy"
                                label={
                                    <>
                                        Ich akzeptieren die{' '}
                                        <a
                                            href="https://www.dajeh.de/datenschutz"
                                            target="_blank">
                                            Datenschutzhinweise
                                        </a>
                                    </>
                                }
                                disabled={isSubmitting}
                            />
                        </TermsBox>

                        <PublicPageCardFormSubmitButton
                            disabled={isSubmitting}
                            onClick={submitForm}>
                            Konto aktivieren
                        </PublicPageCardFormSubmitButton>
                        {isSubmitting && <LinearProgress />}

                        {data.subscription_isTrial && (
                            <AutomaticSubscriptionEndOnTestPeriodHint>
                                Die Testphase endet automatisch, es entstehen
                                keine Verpflichtungen.
                            </AutomaticSubscriptionEndOnTestPeriodHint>
                        )}
                    </StyledPublicPageCardForm>
                )}
            </Formik>
        </PublicPageCard>
    );
};

const getRemoteErrorMessage = (error: ApolloError) => {
    return getResponseErrorMessage(error, apiError => {
        const code = apiError.getCode();

        if (code === 'password_too_weak') {
            return 'Das Passwort erfüllt nicht die Mindestanforderungen. Bitte beachten Sie auch die Hinweise unterhalb der Passwort-Eingabe. Das Passwort muss mindestens "mittel" sein.';
        }

        if (code === 'organization_activation_expired') {
            return 'Das Token zur Aktivierung Ihres Unternehmenskontos ist nicht mehr gültig. Bitte kontaktieren Sie den Dajeh-Support.';
        }

        if (code === 'organization_activation_terms_not_accepted') {
            return 'Bitte stimmen Sie den Nutzungsbedingungen zu.';
        }

        if (code === 'organization_activation_privacy_policy_not_accepted') {
            return 'Bitte akzeptieren Sie die Datenschutzhinweise.';
        }
    });
};
