import React from 'react';
import { SkeletonText } from '../../common/components/skeleton/SkeletonText';
import { Colors } from '../../core/theme/Colors';
import {
    Item,
    ItemFromTo,
    ItemLabel,
    ItemSummaryItem,
    ItemTotalTime
} from './TimeEntry';

export const SkeletonTimeEntry = ({
    size,
    animation
}: {
    size?: 'small' | 'medium' | 'large';
    animation?: boolean;
}) => {
    return (
        <Item size={size}>
            <ItemFromTo>
                <SkeletonText
                    width={36}
                    animation={animation ? 'pulse' : false}
                    sx={{ backgroundColor: Colors.TextSkeletonLight }}
                />
                {' - '}
                <SkeletonText
                    width={36}
                    animation={animation ? 'pulse' : false}
                    sx={{ backgroundColor: Colors.TextSkeletonLight }}
                />
            </ItemFromTo>
            <ItemSummaryItem>
                <ItemTotalTime>
                    <SkeletonText
                        width={75}
                        animation={animation ? 'pulse' : false}
                        sx={{ backgroundColor: Colors.TextSkeletonLight }}
                    />
                </ItemTotalTime>
            </ItemSummaryItem>
            <ItemLabel hasDescription={true}>
                <SkeletonText
                    width={100 + Math.ceil(Math.random() * 150)}
                    animation={animation ? 'pulse' : false}
                    sx={{ backgroundColor: Colors.TextSkeletonLight }}
                />
            </ItemLabel>
        </Item>
    );
};
