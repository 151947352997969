import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConnectionPageInfo } from '../common/graphql/ConnectionPageInfo';
import { getShortPollInterval } from '../core/environment';
import { NotificationDto } from './notification.dto';
import { QUERY_MY_NOTIFICATIONS } from './queries';

type UseNotificationsResult = {
    notifications: null | NotificationDto[];
    loadingInitialPage: boolean;
    error: ApolloError | undefined;
    hasMore: boolean;
    loadNextPage: () => Promise<void>;
    loadingNextPage: boolean;
    refresh: () => Promise<void>;
    startPolling: (time: number) => void;
    stopPolling: () => void;
};

export function useNotifications(
    initialPageSize: number,
    subsequentPageSize?: number,
    poll: boolean = false
): UseNotificationsResult {
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [cachedNotifications, setCachedNotifications] =
        useState<null | NotificationDto[]>(null);
    const [lastPageInfo, setLastPageInfo] =
        useState<ConnectionPageInfo | null>(null);

    const {
        data,
        loading: initialLoading,
        error,
        fetchMore,
        refetch,
        startPolling,
        stopPolling
    } = useQuery(QUERY_MY_NOTIFICATIONS, {
        variables: {
            last: initialPageSize || 20
        },
        pollInterval: poll ? getShortPollInterval() : undefined
    });

    const isFetching = initialLoading || loadingPage;
    const notificationConnection = data?.me?.notifications || {};
    const hasMore = lastPageInfo
        ? lastPageInfo.hasNextPage
        : notificationConnection
        ? notificationConnection.pageInfo?.hasNextPage
        : false;
    const endCursor =
        lastPageInfo?.endCursor ||
        notificationConnection?.pageInfo?.endCursor ||
        null;

    const initialNodes = notificationConnection?.nodes || null;

    useEffect(() => {
        if (initialNodes) {
            // Merge refreshed results with cached notifications
            setCachedNotifications(cachedNotifications => {
                if (!cachedNotifications) {
                    return initialNodes;
                }

                const updateIds = initialNodes.map(n => n.id);

                return [
                    ...initialNodes,
                    ...cachedNotifications.filter(
                        n => updateIds.indexOf(n.id) < 0
                    )
                ];
            });
        }
    }, [initialNodes]);

    const loadNextPage = useCallback(async () => {
        if (isFetching || !hasMore) {
            return Promise.resolve();
        }
        setLoadingPage(true);

        return fetchMore({
            variables: {
                last: subsequentPageSize || 10,
                before: endCursor
            }
        })
            .then((result: ApolloQueryResult<any>) => {
                setCachedNotifications(prevValue => [
                    ...(prevValue || []),
                    ...result.data?.me?.notifications?.nodes
                ]);
                setLastPageInfo(result.data?.me?.notifications?.pageInfo);
            })
            .finally(() => setLoadingPage(false));
    }, [subsequentPageSize, isFetching, hasMore, endCursor]);

    const refresh = useCallback(async () => {
        if (cachedNotifications === null) {
            return Promise.resolve();
        }

        return refetch({
            last: Math.min(
                Math.max(cachedNotifications?.length || 0, initialPageSize),
                100
            )
        }).then(result => {
            setCachedNotifications(result.data?.me?.notifications?.nodes);
            setLastPageInfo(result.data?.me?.notifications?.pageInfo);
        });
    }, [refetch, cachedNotifications, initialPageSize]);

    return {
        loadingInitialPage: initialLoading,
        loadingNextPage: loadingPage,
        notifications: cachedNotifications || null,
        error,
        hasMore,
        loadNextPage,
        refresh,
        startPolling,
        stopPolling
    };
}
