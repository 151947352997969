/* The next line must not be removed for the lab overrides to work with typescript */
import type {} from '@mui/lab/themeAugmentation';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { deDE as coreDeDE } from '@mui/material/locale';
import { deDE } from '@mui/x-date-pickers/locales';
import { createTheme } from '@mui/material/styles';
import React from 'react';
import { Breakpoints } from './Breakpoints';
import { Colors } from './Colors';

// A custom theme for this app
let theme = createTheme(
    {
        palette: {
            primary: {
                main: Colors.Primary,
                light: Colors.PrimaryLight,
                dark: Colors.PrimaryDark
            },
            secondary: {
                main: Colors.Secondary,
                light: Colors.SecondaryLight
            },
            error: {
                main: Colors.Red
            },
            success: {
                main: Colors.Green
            },
            info: {
                main: Colors.Primary,
                light: Colors.PrimaryLight,
                dark: Colors.PrimaryDark
            },
            background: {
                default: Colors.Background
            },
            text: {
                primary: Colors.Text,
                secondary: Colors.TextLight
            }
        },
        breakpoints: {
            values: Breakpoints
        },
        typography: {
            fontSize: 16,
            fontFamily: 'source-sans-pro, sans-serif',
            htmlFontSize: 16,
            body1: {
                fontSize: 16
            },
            body2: {
                fontSize: 16
            },
            h6: {
                fontSize: 20,
                lineHeight: '29px',
                fontWeight: 600
            },
            h5: {
                fontSize: 20,
                lineHeight: 1.4,
                fontWeight: 600
            },
            h4: {
                fontSize: 24,
                lineHeight: 1.4,
                fontWeight: 600
            },
            h3: {
                fontSize: 28,
                lineHeight: 1.4,
                fontWeight: 900
            },
            h2: {
                fontSize: 36,
                lineHeight: 1.4,
                fontWeight: 600
            },
            h1: {
                fontSize: 42,
                lineHeight: 1.4,
                fontWeight: 700
            },
            button: {
                textTransform: 'none'
            }
        },
        components: {
            MuiContainer: {
                styleOverrides: {
                    root: {
                        paddingLeft: 16,
                        paddingRight: 16
                    }
                },
                defaultProps: {
                    maxWidth: 'xl'
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: '#18202c'
                    },
                    paperAnchorLeft: {
                        width: '100%',
                        maxWidth: 300
                    },
                    paperAnchorDockedLeft: {
                        overflow: 'hidden',
                        borderRight: 0
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: '#fff'
                    }
                }
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        height: 3,
                        borderTopLeftRadius: 3,
                        borderTopRightRadius: 3
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        margin: '0 16px',
                        minWidth: 0,
                        padding: 0
                    }
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        borderRadius: 4
                    }
                }
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#404854'
                    }
                }
            },
            MuiIcon: {
                styleOverrides: {
                    root: {
                        fontSize: '20px'
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: 'inherit',
                        minWidth: 36,
                        marginRight: 0,
                        '& svg': {
                            fontSize: 20
                        }
                    }
                }
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        width: 32,
                        height: 32
                    }
                }
            },
            MuiBadge: {
                styleOverrides: {
                    root: {
                        display: 'inline',
                        zIndex: 1,
                        verticalAlign: 'inherit'
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    variant: 'contained'
                },
                styleOverrides: {
                    root: {
                        fontSize: 16,
                        lineHeight: '20px',
                        padding: '8px 25px',
                        fontWeight: 600,
                        borderRadius: 18,
                        minWidth: '115px'
                    },
                    sizeSmall: {
                        fontSize: 14,
                        lineHeight: '18px',
                        minWidth: 0,
                        padding: '4px 12px'
                    },
                    sizeLarge: {
                        lineHeight: '22px',
                        borderRadius: 20
                    },
                    text: {
                        backgroundColor: 'transparent',
                        '&:hover, &:active, &:focus': {
                            backgroundColor: 'transparent'
                        }
                    },
                    textPrimary: {
                        color: Colors.Primary,
                        '&:hover, &:active, &:focus': {
                            color: Colors.PrimaryDark
                        }
                    },
                    textSecondary: {
                        color: Colors.Secondary,
                        '&:hover, &:active, &:focus': {
                            color: Colors.Text
                        }
                    },
                    outlined: {
                        padding: '7px 15px'
                    },
                    outlinedPrimary: {
                        borderColor: Colors.Primary,
                        color: Colors.Primary,
                        '&:hover, &:active, &:focus': {
                            borderColor: Colors.PrimaryDark,
                            color: Colors.PrimaryDark
                        },
                        '&:disabled': {
                            opacity: 0.5,
                            borderColor: Colors.PrimaryDark,
                            color: Colors.PrimaryDark
                        }
                    },
                    outlinedSizeSmall: {
                        padding: '3px 11px'
                    },
                    outlinedSecondary: {
                        borderColor: '#86868a',
                        color: '#86868a',
                        '&:hover, &:active, &:focus': {
                            borderColor: '#86868a',
                            color: '#86868a',
                            backgroundColor: '#f2f2f2'
                        },
                        '&:disabled': {
                            opacity: 0.5,
                            borderColor: '#86868a',
                            backgroundColor: '#FFFFFF',
                            color: '#86868a'
                        }
                    },
                    contained: {
                        boxShadow: 'none !important',
                        fontWeight: 600
                    },
                    containedPrimary: {
                        color: '#fff',
                        '&:hover, &:active, &:focus': {
                            color: '#fff'
                        },
                        '&:disabled': {
                            backgroundColor: Colors.PrimaryLight,
                            color: '#fff'
                        }
                    },
                    containedSecondary: {
                        color: '#09141a',
                        backgroundColor: '#dddedf',
                        '&:hover, &:active, &:focus': {
                            color: '#09141a',
                            backgroundColor: '#cacbcc'
                        },
                        '.MuiButton-endIcon': {
                            svg: {
                                fill: '#81858d'
                            }
                        }
                    },
                    endIcon: {
                        marginLeft: '6px'
                    }
                }
            },
            MuiLoadingButton: {
                defaultProps: {
                    variant: 'contained'
                }
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        fontWeight: 600
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            backgroundColor: '#f1f1f1'
                        }
                    },
                    input: {
                        color: Colors.Text,
                        '&:disabled': {
                            color: Colors.TextLight
                        }
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 18,
                        borderColor: '#c4c4c6'
                    },
                    input: {
                        height: 20,
                        padding: '8px 16px'
                    },
                    sizeSmall: {
                        borderRadius: 16
                    },
                    inputSizeSmall: {
                        height: 18,
                        padding: '7px 12px 5px',
                        fontSize: 14,
                        lineHeight: '18px'
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: 14
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    outlined: {
                        transform: 'translate(14px, 7px) scale(1);'
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
                        borderRadius: '10px'
                    }
                }
            },
            /* Dialog */
            MuiDialog: {
                styleOverrides: {
                    root: {},
                    paper: {
                        maxWidth: 480
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        padding: '14px 24px 10px',
                        backgroundColor: Colors.Primary,
                        color: '#fff'
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        padding: '0 24px',
                        marginTop: '24px',
                        marginBottom: '48px'
                    }
                }
            },
            MuiDialogContentText: {
                styleOverrides: {
                    root: {
                        fontSize: 16,
                        color: Colors.Text
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: '12px 24px',
                        borderTop: `1px solid ${Colors.LineColor}`
                    },
                    spacing: {
                        '& > :not(:first-child)': {
                            marginLeft: '24px'
                        }
                    }
                }
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            color: '#fff !important',
                            backgroundColor: Colors.Primary
                        },
                        '&:focus': {
                            color: '#fff',
                            backgroundColor: Colors.Primary
                        },
                        '&.MuiPickersDay-dayOutsideMonth': {
                            color: '#eee'
                        }
                    }
                }
            },
            /* Table */
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: '15px 28px',
                        fontSize: '16px',
                        lineHeight: '20px',
                        borderBottom: '1px solid #dddedf'
                    },
                    head: {
                        color: Colors.Text,
                        fontSize: '13px',
                        lineHeight: '17px',
                        fontWeight: 400
                    },
                    sizeSmall: {
                        padding: '6px 12px'
                    }
                }
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid #dddedf'
                    }
                }
            },
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        paddingBottom: '8px',
                        boxShadow: '0 1px 3px 0px rgba(0, 0, 0, 0.15)',
                        borderRadius: 0
                    }
                }
            },
            MuiBreadcrumbs: {
                styleOverrides: {
                    root: {
                        color: Colors.Text,
                        fontSize: '13px',
                        lineHeight: '17px'
                    },
                    separator: {
                        color: Colors.Secondary
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    sizeSmall: {
                        fontSize: 13,
                        paddingLeft: 4,
                        paddingRight: 4
                    },
                    sizeMedium: {
                        fontSize: 16
                    }
                }
            }
        }
    },
    deDE,
    coreDeDE
);

theme = {
    ...theme,
    components: {
        ...theme.components,
        MuiContainer: {
            ...theme.components?.MuiContainer,
            styleOverrides: {
                root: {
                    [theme.breakpoints.up('sm')]: {
                        paddingLeft: 16,
                        paddingRight: 16
                    },
                    [theme.breakpoints.up('md')]: {
                        paddingLeft: 24,
                        paddingRight: 24
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginLeft: theme.spacing(1)
                },
                indicator: {
                    backgroundColor: theme.palette.common.white
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.up('md')]: {
                        padding: 0,
                        minWidth: 0
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1)
                },
                sizeSmall: {
                    padding: '4px',
                    fontSize: '16px'
                },
                edgeEnd: {
                    marginRight: -13,
                    '&:hover,&:active,&:focus': {
                        background: '#f2f2f2'
                    }
                }
            }
        },
        MuiDesktopTimePicker: {
            defaultProps: {
                viewRenderers: {
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock
                }
            }
        },
        MuiDesktopDateTimePicker: {
            defaultProps: {
                viewRenderers: {
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock
                }
            }
        }
    }
};

export default theme;
