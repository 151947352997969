import { Link } from '@mui/material';
import React from 'react';
import { SkeletonText } from '../common/components/skeleton/SkeletonText';
import { PageBody } from '../core/components/page/PageBody';
import { PageHeader } from '../core/components/page/PageHeader';
import { PageHeadline } from '../core/components/page/PageHeadline';
import { PageSubHeadline } from '../core/components/page/PageSubHeadline';
import { execute } from '../utils';
import { PageHeadlineContainer } from './EmployeePageHeadlineContainer';
import { EmployeePageTabNavigation } from './EmployeePageTabNavigation';
import { EmployeePageWrapper } from './EmployeePageWrapper';

export const EmployeeSkeletonPage = ({
    renderSkeletonContent
}: {
    renderSkeletonContent?: () => any;
}) => (
    <EmployeePageWrapper
        breadcrumbs={[
            <Link key="1" underline="hover" color="inherit" href={'#'}>
                <SkeletonText width={75} />
            </Link>,
            <span key="2">
                <SkeletonText width={60} />
            </span>
        ]}>
        <PageHeader>
            <PageHeadlineContainer>
                <PageHeadline>
                    <SkeletonText width={150} />
                </PageHeadline>
            </PageHeadlineContainer>
            <PageSubHeadline>
                <SkeletonText width={100} />
                {' • '}
                <span>
                    <SkeletonText width={75} />
                </span>
            </PageSubHeadline>

            <EmployeePageTabNavigation />
        </PageHeader>

        <PageBody>{execute(renderSkeletonContent)}</PageBody>
    </EmployeePageWrapper>
);
