import styled from '@emotion/styled';
import { Alert } from '@mui/material';

export const GlobalAlert = styled(Alert)`
    border-radius: 10px;
    box-shadow: 0px 1px 3px 0px rgba(14, 31, 38, 0.15);
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;

    .MuiAlert-icon {
        margin-top: 1px;
    }
`;

export const GlobalAlertActions = styled.div`
    margin-top: 10px;
`;
