import styled from '@emotion/styled';
import { FormControl, FormHelperText } from '@mui/material';
import { Field, FieldConfig, useField } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import React from 'react';

const StyledFormControl = styled(FormControl)`
    .MuiFormHelperText-root {
        margin-left: 0;
    }
`;

export const CheckboxField = (
    props: FieldConfig & {
        id?: string;
        disabled?: boolean;
        label: React.ReactNode;
        placeholder?: string;
    }
) => {
    const [field, meta] = useField(props.name);
    const touchedError = meta.touched && !!meta.error;

    return (
        <StyledFormControl fullWidth>
            <Field
                id={props.id}
                name={props.name}
                component={CheckboxWithLabel}
                type="checkbox"
                Label={{ label: props.label }}
            />
            {touchedError && (
                <FormHelperText error>{meta.error}</FormHelperText>
            )}
        </StyledFormControl>
    );
};
