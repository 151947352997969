import styled from '@emotion/styled';

export const SubPageSectionButtons = styled.div`
    margin-top: 12px;
    text-align: right;

    @media (min-width: 600px) {
        margin-top: 16px;
    }

    .MuiButton-root + .MuiButton-root {
        margin-left: 16px;
    }
`;
