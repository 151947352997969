import styled from '@emotion/styled';
import TableCell from '@mui/material/TableCell';
import { DateTime } from 'luxon';
import React from 'react';
import { NewLineToBreak } from '../../../common/components/NewLineToBreak';
import { formatSecondsAsTimeDuration, formatInterval } from '../../time';
import { insightFindingToText } from '../insights';
import { ReportInsightChip } from '../ReportInsightChip';
import { ReportTableRow } from '../ReportTableRow';
import { TimeReportDuration } from '../TimeReportDuration';
import { WorkTimeReportEntryDto } from '../workTimeReportEntryDto';
import {
    OrganizationWorkTimeReportDayDto,
    TimeEntryInsightDto
} from './organizationWorkTimeReport.types';

type WormTimeEntryProps = {
    date: DateTime;
    data: OrganizationWorkTimeReportDayDto;
    entry: WorkTimeReportEntryDto;
    entryIndex: number;
    dayIndex: number;
    insights: TimeEntryInsightDto[] | undefined;
};

export const OrganizationMemberWorkTimeEntry = (props: WormTimeEntryProps) => {
    const { date, dayIndex, data, entry, entryIndex, insights } = props;

    const totalEntries = data.entries.length;
    const { from, to, totalSeconds: entryTotalSeconds, node } = entry;

    const timeRange = (from && to && formatInterval(from, to)) || '-';
    const description = node?.description || '';

    let totalSeconds = 0;
    if (entryIndex === 0) {
        totalSeconds = data.entries.reduce((acc, entry) => {
            return acc + entry.totalSeconds;
        }, 0);
    }

    return (
        <ReportTableRow index={dayIndex}>
            {entryIndex === 0 && (
                <>
                    <StyledEntryTableCell
                        sx={{ paddingRight: 0 }}
                        rowSpan={totalEntries}>
                        {date.toFormat('ccc.')}
                    </StyledEntryTableCell>

                    <StyledEntryTableCell
                        rowSpan={totalEntries}
                        sx={{ textAlign: 'right', paddingLeft: 0 }}>
                        {date.toFormat('d.L.')}
                    </StyledEntryTableCell>

                    <StyledEntryTableCell rowSpan={totalEntries} align="right">
                        {totalSeconds === 0 ? (
                            '-'
                        ) : (
                            <TimeReportDuration
                                timeDuration={formatSecondsAsTimeDuration(
                                    totalSeconds
                                )}
                            />
                        )}
                    </StyledEntryTableCell>
                </>
            )}

            <StyledEntryTableCell
                style={{
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    borderTop:
                        entryIndex > 0
                            ? `1px solid ${
                                  dayIndex % 2 === 0 ? '#DDD' : '#EEE'
                              }`
                            : 0
                }}>
                {timeRange}
            </StyledEntryTableCell>

            <StyledEntryTableCell
                style={{
                    textAlign: 'right',
                    borderTop:
                        entryIndex > 0
                            ? `1px solid ${
                                  dayIndex % 2 === 0 ? '#DDD' : '#EEE'
                              }`
                            : 0
                }}>
                {entryTotalSeconds === 0 ? (
                    '-'
                ) : (
                    <TimeReportDuration
                        timeDuration={formatSecondsAsTimeDuration(
                            entryTotalSeconds
                        )}
                    />
                )}
            </StyledEntryTableCell>

            <StyledEntryTableCell
                style={{
                    borderTop:
                        entryIndex > 0
                            ? `1px solid ${
                                  dayIndex % 2 === 0 ? '#DDD' : '#EEE'
                              }`
                            : 0
                }}>
                <NewLineToBreak value={description || '-'} />
            </StyledEntryTableCell>

            <StyledEntryTableCell
                style={{
                    borderTop:
                        entryIndex > 0
                            ? `1px solid ${
                                  dayIndex % 2 === 0 ? '#DDD' : '#EEE'
                              }`
                            : 0
                }}
                align="center">
                {insights && insights.length
                    ? insights.map((insight, index) => (
                          <ReportInsightChip
                              label={insightFindingToText(insight)}
                              key={insight.finding + index}
                          />
                      ))
                    : '-'}
            </StyledEntryTableCell>
        </ReportTableRow>
    );
};

export const OrganizationMemberWorkTimeDummyEntry = ({
    date,
    dayIndex
}: {
    date: DateTime;
    dayIndex: number;
}) => (
    <ReportTableRow index={dayIndex} isEmpty>
        <StyledEntryTableCell sx={{ paddingRight: 0 }}>
            {date.toFormat('ccc.')}
        </StyledEntryTableCell>

        <StyledEntryTableCell sx={{ textAlign: 'right', paddingLeft: 0 }}>
            {date.toFormat('d.L.')}
        </StyledEntryTableCell>

        <StyledEntryTableCell>-</StyledEntryTableCell>

        <StyledEntryTableCell>-</StyledEntryTableCell>

        <StyledEntryTableCell>-</StyledEntryTableCell>

        <StyledEntryTableCell>
            <NewLineToBreak value={'-'} />
        </StyledEntryTableCell>

        <StyledEntryTableCell align="center">-</StyledEntryTableCell>
    </ReportTableRow>
);

const StyledEntryTableCell = styled(TableCell)`
    vertical-align: top;
    border-top: 0;
    border-bottom: 0;
`;
