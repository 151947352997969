import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

export const OrganizationWorkTimeSummaryReportTable = ({ children }) => (
    <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: 48, padding: 0 }} />
                    <TableCell style={{ width: '25%' }}>Mitarbeiter</TableCell>
                    <TableCell style={{ width: 125, textAlign: 'right' }}>
                        Arbeitszeit
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
        </Table>
    </TableContainer>
);

export const OrganizationWorkTimeReportDummyTable = ({ children }) => (
    <OrganizationWorkTimeSummaryReportTable>
        <TableRow>
            <TableCell colSpan={4}>{children}</TableCell>
        </TableRow>
    </OrganizationWorkTimeSummaryReportTable>
);
