import { ApolloError, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import {
    Alert,
    AlertTitle,
    FormControl,
    LinearProgress,
    Tooltip
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { AppDownloadChoices } from '../../common/components/app/AppDownloadChoices';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { InputLabel } from '../../common/components/form/InputLabel';
import { PublicPageCard } from '../../common/components/page/PublicPageCard';
import { PublicPageCardFooter } from '../../common/components/page/PublicPageCardFooter';
import { PublicPageCardForm } from '../../common/components/page/PublicPageCardForm';
import { PublicPageCardFormError } from '../../common/components/page/PublicPageCardFormError';
import { PublicPageCardFormSubmitButton } from '../../common/components/page/PublicPageCardFormSubmitButton';
import { PublicPageCardTitle } from '../../common/components/page/PublicPageCardTitle';
import { getResponseErrorMessage } from '../../core/api/error';
import { useUser } from '../../core/auth/useUser';
import { Colors } from '../../core/theme/Colors';
import { getUserName } from '../index';
import { NewPasswordField } from '../password/NewPasswordField';
import { PasswordField } from '../password/PasswordField';
import { MUTATION_ACCEPT_INVITATION } from '../queries';

const StyledPublicPageCard = styled(PublicPageCard)`
    max-width: 400px;
`;

const ActionChoiceWrapper = styled.div`
    margin-top: 36px;
    padding-top: 36px;
    border-top: 1px solid ${Colors.LineColor};
`;

const AppDownloadTitle = styled.div`
    margin: 32px 0;
    text-align: center;
    color: ${Colors.Secondary};
`;
const DateSecurityHint = styled.div`
    margin-top: 24px;
    text-align: center;
    color: ${Colors.TextMedium};
`;

const ActivationMessage = styled.p`
    margin: 32px auto 0;
    max-width: 375px;
    color: ${Colors.TextMedium};

    strong {
        color: ${Colors.Text};
    }
`;

interface ActivateUserAccountFormValues {
    password: string;
    password_repeat: string;
}

type AcceptInvitationFormProps = {
    invitation: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        organizationName: string;
        role: string;
    };
};

export const AcceptInvitationForm = ({
    invitation
}: AcceptInvitationFormProps) => {
    const [acceptedInvitation, setAcceptedInvitation] = useState(false);
    const [acceptInvitation, { loading }] = useMutation(
        MUTATION_ACCEPT_INVITATION
    );
    const [error, setError] = useState<string | undefined>();

    const { token } = useParams();
    const navigate = useNavigate();
    const user = useUser();

    function onSubmit(values, { setSubmitting }) {
        if (user || loading) {
            return;
        }

        setError(undefined);

        // Redirect happens in the AuthenticationContextProvider
        acceptInvitation({
            variables: {
                token: token,
                input: {
                    password: values.password
                }
            }
        })
            .then(() => {
                setAcceptedInvitation(true);
            })
            .catch(e => {
                setError(getRemoteErrorMessage(e));
                setSubmitting(false);
            });
    }

    if (acceptedInvitation) {
        return (
            <PublicPageCard>
                <PublicPageCardTitle>
                    Ihr Konto wurde aktiviert!
                </PublicPageCardTitle>

                <ActivationMessage>
                    Ab sofort können Sie sich mit ihrer E-Mail-Adresse <br />
                    <br />
                    <strong>{invitation.email}</strong> <br />
                    <br />
                    und ihrem gewählten Passwort anmelden. Hier auf der
                    Web-Plattform und in der Android und iOS-App.
                </ActivationMessage>

                {/* App-Links */}
                <ActionChoiceWrapper>
                    <PrimaryButton
                        onClick={() =>
                            navigate({
                                pathname: `/login`,
                                search: createSearchParams({
                                    email: invitation.email
                                }).toString()
                            })
                        }>
                        Jetzt anmelden
                    </PrimaryButton>

                    <AppDownloadTitle>
                        <i>–</i> oder App herunterladen <i>–</i>
                    </AppDownloadTitle>

                    <AppDownloadChoices />
                </ActionChoiceWrapper>
            </PublicPageCard>
        );
    }

    return (
        <PublicPageCard>
            <PublicPageCardTitle>
                Herzlich Willkommen, {invitation.firstName}!
            </PublicPageCardTitle>

            <Typography
                paragraph
                sx={{
                    maxWidth: 375,
                    margin: '24px auto',
                    color: Colors.TextLight
                }}>
                Ein letzter Schritt noch, bevor es losgeht: <br />
                Bitte legen Sie jetzt ein Passwort fest, mit dem Sie sich
                zukünftig bei Dajeh anmelden möchten.
            </Typography>

            {user && (
                <Alert severity="warning" style={{ textAlign: 'left' }}>
                    <AlertTitle>Vorschau - Bereits angemeldet</AlertTitle>
                    Sie sind zur Zeit mit dem Konto{' '}
                    <Tooltip title={user.email}>
                        <span>"{getUserName(user)}"</span>
                    </Tooltip>{' '}
                    angemeldet.
                    <div style={{ height: '0.5em' }} />
                    Um die Einladung annehmen zu können, müssen Sie sich vorher
                    abmelden und auf diese Seite zurückkehren.
                </Alert>
            )}

            {!!error && (
                <PublicPageCardFormError>{error}</PublicPageCardFormError>
            )}

            <Formik
                initialValues={{
                    password: '',
                    password_repeat: ''
                }}
                validate={values => {
                    const errors: Partial<ActivateUserAccountFormValues> = {};

                    if (!values.password) {
                        errors.password = 'Bitte geben Sie ein Passwort an';
                    }

                    if (!values.password_repeat) {
                        errors.password_repeat =
                            'Bitte wiederholen Sie Ihr gewähltes Passwort';
                    }

                    if (values.password !== values.password_repeat) {
                        errors.password_repeat =
                            'Passwörter stimmen nicht überein';
                    }

                    return errors;
                }}
                onSubmit={onSubmit}>
                {({ submitForm, isSubmitting }) => (
                    <PublicPageCardForm>
                        <FormControl fullWidth>
                            <InputLabel
                                htmlFor="password"
                                style={{
                                    marginTop: 0,
                                    color: Colors.TextMedium
                                }}>
                                Passwort festlegen
                            </InputLabel>
                            <NewPasswordField
                                id="password"
                                name="password"
                                placeholder="Passwort eingeben"
                                disabled={isSubmitting}
                                labelPosition={'inside'}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ marginTop: '12px' }}>
                            <InputLabel
                                htmlFor="password"
                                style={{
                                    color: Colors.TextMedium
                                }}>
                                Passwort wiederholen
                            </InputLabel>
                            <PasswordField
                                id="password_repeat"
                                name="password_repeat"
                                placeholder="Passwort erneut eingeben"
                                disabled={isSubmitting}
                            />
                        </FormControl>

                        <PublicPageCardFormSubmitButton
                            disabled={!!user || isSubmitting}
                            onClick={submitForm}>
                            Konto aktivieren
                        </PublicPageCardFormSubmitButton>

                        <DateSecurityHint>
                            Bitte beachten Sie auch unsere{' '}
                            <a
                                href={'https://www.dajeh.de/datenschutz'}
                                target={'_blank'}>
                                Datenschutzhinweise
                            </a>
                            .
                        </DateSecurityHint>

                        {isSubmitting && <LinearProgress />}
                    </PublicPageCardForm>
                )}
            </Formik>

            <PublicPageCardFooter>
                Diese Einladung ist bestimmt für {invitation.firstName}{' '}
                {invitation.lastName} @ {invitation.organizationName}. <br />
                Weitere Informationen zu Dajeh finden Sie unter{' '}
                <a href={'https://www.dajeh.de'} target={'_blank'}>
                    www.dajeh.de
                </a>
                .
            </PublicPageCardFooter>
        </PublicPageCard>
    );
};

const getRemoteErrorMessage = (error: ApolloError) => {
    return getResponseErrorMessage(error, apiError => {
        const code = apiError.getCode();
        if (code === 'password_too_weak') {
            return 'Das Passwort erfüllt nicht die Mindestanforderungen. Bitte beachten Sie auch die Hinweise unterhalb der Passwort-Eingabe. Das Passwort muss mindestens "mittel" sein.';
        }

        if (code === 'user_invitation_not_found') {
            return 'Die Einladung wurde zwischenzeitlich zurückgezogen oder gelöscht. Bitte kontaktieren Sie die für Dajeh zuständige Person in Ihrem Unternehmen und fragen Sie nach einer erneuten Einladung.';
        }

        if (code === 'user_invitation_expired') {
            return 'Die Einladung ist nicht mehr gültig. Bitte kontaktieren Sie die für Dajeh zuständige Person in Ihrem Unternehmen und fragen Sie nach einer erneuten Einladung.';
        }
    });
};
