import styled from '@emotion/styled';

export const TimeEntryGroupBody = styled.div<{
    size?: 'small' | 'medium' | 'large';
}>`
    margin-top: ${props => {
        if (props.size === 'small') {
            return '6px';
        }
        if (props.size === 'medium') {
            return '10px';
        }
        return '16px';
    }};
`;
