import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { PrimaryButton } from '../common/components/button/PrimaryButton';
import { CreateTimeTrackingIcon } from '../common/components/icons/CreateTimeTrackingIcon';
import { User } from '../core/auth/user';
import { Colors } from '../core/theme/Colors';
import { TimeEntryDrawerForm } from './form/TimeEntryDrawerForm';

const Wrapper = styled.div`
    text-align: right;
    margin-bottom: 32px;
`;

const AddTimeEntryIconButton = styled(IconButton)`
    background: ${Colors.Primary};
    color: #fff;
    padding: 2px;

    &:hover {
        background: ${Colors.PrimaryDark};
    }
`;

const AddTimeEntryButton = styled(PrimaryButton)`
    svg {
        width: 28px;
        height: 28px;
        margin: -4px -2px -4px -10px;
    }
`;

export const AddNewTimeEntryButton = ({
    label,
    onAdded,
    owner
}: {
    label?: string;
    onAdded?: () => void;
    owner?: User;
}) => {
    const [showFormDrawer, setShowFormDrawer] = useState(false);

    function toggleDrawer() {
        setShowFormDrawer(!showFormDrawer);
    }

    return (
        <Wrapper>
            {label ? (
                <AddTimeEntryButton
                    startIcon={<CreateTimeTrackingIcon />}
                    onClick={toggleDrawer}>
                    {label}
                </AddTimeEntryButton>
            ) : (
                <AddTimeEntryIconButton onClick={toggleDrawer}>
                    <CreateTimeTrackingIcon />
                </AddTimeEntryIconButton>
            )}

            {showFormDrawer && (
                <TimeEntryDrawerForm
                    onClosed={() => setShowFormDrawer(false)}
                    onChanged={onAdded}
                    owner={owner}
                />
            )}
        </Wrapper>
    );
};
