import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { Colors } from '../../core/theme/Colors';

const Ct = styled.span`
    color: ${Colors.TextLighter};

    .oldValue & {
        color: ${Colors.RedDarkest};
    }
`;

type NotAvailableProps = {
    tooltipTitle?: string;
    label?: string;
    showTooltip?: boolean;
};

export const NotAvailable = ({
    label,
    tooltipTitle,
    showTooltip = true
}: NotAvailableProps) => {
    return (
        <Tooltip
            title={tooltipTitle || label || 'Nicht angegeben'}
            disableHoverListener={!showTooltip}>
            <Ct>{label || 'n.a.'}</Ct>
        </Tooltip>
    );
};
