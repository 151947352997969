import styled from '@emotion/styled';

export const HelpSectionHeadline = styled.h2<{ first?: boolean }>`
    margin-top: ${props => (props.first ? 0 : '24px')};
    font-size: 18px;
    line-height: 1.25;
    font-weight: 600;

    @media (min-width: 600px) {
        font-size: 20px;
        margin-top: ${props => (props.first ? 0 : '32px')};
    }

    @media (min-width: 960px) {
        font-size: 24px;
        margin-top: ${props => (props.first ? 0 : '48px')};
    }
`;
