import { useApolloClient, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import {
    MUTATION_MARK_ALL_NOTIFICATIONS_AS_READ,
    resetUnreadNotificationsCountInInApolloCache
} from './queries';

type Props = {
    renderButton(
        markAllAsRead: () => Promise<void>,
        busy: boolean
    ): React.ReactNode;
};

export const MarkAllNotificationsAsReadButtonWrapper = ({
    renderButton
}: Props) => {
    const [busy, setBusy] = useState(false);

    const apolloClient = useApolloClient();
    const [markAllAsReadMutation] = useMutation(
        MUTATION_MARK_ALL_NOTIFICATIONS_AS_READ
    );

    const { enqueueSnackbar } = useSnackbar();

    function markAllAsRead(): Promise<void> {
        if (busy) {
            return Promise.resolve();
        }

        setBusy(true);
        return markAllAsReadMutation()
            .then(_ => {
                try {
                    resetUnreadNotificationsCountInInApolloCache(apolloClient);
                } catch (e) {
                    console.error(e);
                }
            })
            .catch(e => {
                enqueueSnackbar(
                    `Benachrichtigung konnte nicht als gelesen markiert werden: ${e.message}`,
                    {
                        variant: 'error'
                    }
                );
            })
            .finally(() => {
                setBusy(false);
            });
    }

    return <>{renderButton(markAllAsRead, busy)}</>;
};
