import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';
import { ApiErrorCode, getResponseErrorMessage } from '../core/api/error';
import { User } from '../core/auth/user';
import { executeSafe } from '../utils';
import { getUserName } from './index';
import { MUTATION_RENEW_USER_INVITATION } from './queries';

type RenewUserInvitationButtonWrapperProps = {
    user: User | Partial<User>;
    userInvitationId: string;
    renderButton: (
        working: boolean,
        renewUserInvitation: () => void
    ) => ReactElement;
    onSuccess?: () => void;
};

export const RenewUserInvitationButtonWrapper = ({
    user,
    userInvitationId,
    renderButton,
    onSuccess
}: RenewUserInvitationButtonWrapperProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [working, setWorking] = useState<boolean>(false);

    const [renewUserInvitation] = useMutation(MUTATION_RENEW_USER_INVITATION, {
        variables: {
            id: userInvitationId
        }
    });

    const userName = getUserName(user);

    const onClickRenewUserInvitation = () => {
        setWorking(true);
        renewUserInvitation()
            .then(() => {
                enqueueSnackbar(`${userName} wurde erneut eingeladen`, {
                    variant: 'success',
                    autoHideDuration: 5000
                });
                executeSafe(onSuccess);
            })
            .catch(e => {
                const message = getResponseErrorMessage(e, apiError => {
                    const code = apiError.getCode();
                    if (code === ApiErrorCode.USER_INACTIVE) {
                        return 'Das Nutzerkonto wurde zwischenzeitlich deaktiviert.';
                    }

                    return `Mitarbeiter ${userName} kann nicht erneut eingeladen werden. Fehler: "${e.message}"`;
                });

                enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 5000
                });
            })
            .finally(() => {
                setWorking(false);
            });
    };

    return <>{renderButton(working, onClickRenewUserInvitation)}</>;
};
