import styled from '@emotion/styled';
import EmailIcon from '@mui/icons-material/Email';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { Component, ErrorInfo } from 'react';
import { Colors } from '../../core/theme/Colors';

type ComponentErrorState = {
    error?: Error;
    errorInfo?: ErrorInfo;
};

export class ErrorBoundary extends Component<any, ComponentErrorState> {
    state: ComponentErrorState = {
        error: undefined,
        errorInfo: undefined
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <ErrorPanel
                    onReset={() =>
                        this.setState({
                            error: undefined,
                            errorInfo: undefined
                        })
                    }
                    error={this.state.error}
                    errorInfo={this.state.errorInfo}
                />
            );
        }

        // Normally, just render children
        return this.props.children;
    }
}

const ErrorContainer = styled.div`
    position: relative;
    z-index: 1;
    padding: 120px 24px 64px;
    max-width: 500px;
    margin: auto;
    text-align: center;
`;

const SadEmojiCt = styled.span`
    position: absolute;
    z-index: 2;
    top: 32px;
    right: 50%;
    transform: translateX(50%);
    font-size: 50px;
    cursor: default;
`;

const ErrorMessage = styled.div`
    margin-top: 12px;
    padding-top: 16px;
    border-top: 1px solid ${Colors.LineColor};
`;

const ErrorActions = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
`;

const ErrorDetails = styled.div`
    font-size: 14px;
    line-height: 18px;
    margin-top: 12px;
    color: ${Colors.SecondaryLight};
`;

const ErrorPanel = ({
    error,
    errorInfo,
    onReset
}: {
    onReset: () => void;
    error?: Error;
    errorInfo?: ErrorInfo;
}) => {
    return (
        <ErrorContainer>
            <Typography variant="h4" component="h3" gutterBottom>
                Ups. Da ist wohl etwas schief gegangen.
                <SadEmojiCt>😞</SadEmojiCt>
            </Typography>

            <ErrorMessage>
                Bitte versuche es noch einmal oder kontaktiere uns wenn das
                Problem weiter besteht.
                {error && (
                    <ErrorDetails>
                        Fehler: {error.message || error.name}
                    </ErrorDetails>
                )}
            </ErrorMessage>

            <ErrorActions>
                <Button onClick={onReset} startIcon={<RefreshIcon />}>
                    Nochmal versuchen
                </Button>
                <Button
                    component="a"
                    href="mailto:hilfe@dajeh.de"
                    startIcon={<EmailIcon />}>
                    Hilfe anfragen
                </Button>
            </ErrorActions>
        </ErrorContainer>
    );
};
