import { gql } from '@apollo/client';

export const QUERY_SIGN_IN = gql`
    mutation SignIn($input: SignInInput!) {
        signIn(input: $input) {
            accessToken
            expiresAt
        }
    }
`;

export const REFRESH_TOKEN = gql`
    mutation RefreshAccessToken {
        refreshAuth {
            accessToken
        }
    }
`;

export const RESIGN_IN = gql`
    query ShouldReSignIn {
        reSignIn @client
    }
`;

export const MUTATION_REQUEST_PW_RESET = gql`
    mutation RequestPasswordReset($email: String!) {
        requestPasswordReset(email: $email)
    }
`;

export const MUTATION_RESET_PASSWORD = gql`
    mutation ResetPassword($token: String!, $newPassword: String!) {
        resetPassword(token: $token, newPassword: $newPassword)
    }
`;
