import React from 'react';
import { SkeletonText } from '../../common/components/skeleton/SkeletonText';
import { SkeletonTimeEntry } from '../entry/SkeletonTimeEntry';
import { NoEntriesInGroupHintLabel } from './NoEntriesInGroupHintLabel';
import { TimeEntryGroup } from './TimeEntryGroup';
import { TimeEntryGroupBody } from './TimeEntryGroupBody';
import { TimeEntryGroupHeader } from './TimeEntryGroupHeader';

type FakeGroupData = {
    id: number;
    entries: { id: string }[];
};

export const GroupedTimeEntriesSkeleton = ({
    numGroups,
    size
}: {
    numGroups: number;
    size?: 'small' | 'medium' | 'large';
}) => {
    const fakeGroups: FakeGroupData[] = [];
    for (let i = 0; i < numGroups; i++) {
        const numEntriesInGroup = Math.ceil(1 + Math.random() * 2);
        const entries: { id: string }[] = [];
        for (let j = 0; j < numEntriesInGroup; j++) {
            entries.push({ id: `${i}-${j}` });
        }

        fakeGroups.push({
            id: i,
            entries
        });
    }

    // Render
    return (
        <>
            {fakeGroups.map((group, i) => (
                <TimeEntryGroup key={group.id} size={size}>
                    <TimeEntryGroupHeader
                        label={
                            <SkeletonText
                                animation={false}
                                width={100 + Math.random() * 100}
                            />
                        }
                        totalSeconds={() => (
                            <SkeletonText animation={false} width={90} />
                        )}
                        size={size}
                    />
                    <TimeEntryGroupBody size={size}>
                        {group.entries.map(entry => (
                            <SkeletonTimeEntry key={entry.id} size={size} />
                        ))}
                        {group.entries.length === 0 && (
                            <NoEntriesInGroupHintLabel>
                                Keine Einträge vorhanden
                            </NoEntriesInGroupHintLabel>
                        )}
                    </TimeEntryGroupBody>
                </TimeEntryGroup>
            ))}
        </>
    );
};
