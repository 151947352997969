import { ApolloError, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Alert } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logoImg from '../../assets/images/dajeh-logo_362.png';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { PublicPageCard } from '../../common/components/page/PublicPageCard';
import { PublicPageCardTitle } from '../../common/components/page/PublicPageCardTitle';
import { isApiErrorCode } from '../../core/api/error';
import { PublicPage } from '../../core/auth/components/PublicPage';
import { getSupportEmailAddress } from '../../core/environment';
import { QUERY_USER_INVITATION_PUBLIC_DATA } from '../queries';
import { AcceptInvitationForm } from './AcceptInvitationForm';
import { InvitationAlreadyAccepted } from './InvitationAlreadyAccepted';

const Wrapper = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;

    @media (min-width: 600px) {
        margin-top: 15vh;
    }
`;

const Logo = styled.img`
    height: 70px;
    width: auto;
    display: block;
    margin: 12px auto;

    @media (min-width: 600px) {
        height: 100px;
        margin: 0 auto 32px auto;
    }
`;

type InvitationPublicData = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    organizationName: string;
    role: 'ADMIN' | 'STANDARD';
};

const PageWrapper = ({ children }: PropsWithChildren<any>) => (
    <PublicPage title="Konto aktivieren" viewableIfAuthenticated={true}>
        <Wrapper>
            <Logo src={logoImg} alt="Dajeh" />
            {children}
        </Wrapper>
    </PublicPage>
);

const ErrorWrapper = styled.div`
    margin: 24px auto 0;
    max-width: 600px;
    text-align: left;
`;

function getInvitationLoadErrorMessage(error: ApolloError | undefined) {
    if (!error) {
        return 'Es konnte keine Einladung gefunden werden. Bitte kontaktieren Sie den oder die zuständige Mitarbeiter:in in Ihrem Unternehmen um eine neue Einladung zu erhalten.';
    }

    if (error?.message?.indexOf('is not a valid id') >= 0) {
        return `Der Einladungs-Code ist ungültig. Bitte kontaktieren Sie den Dajeh-Support unter ${getSupportEmailAddress()}, damit wir Ihnen weiterhelfen können.`;
    }

    return `Einladung konnte nicht geladen werden: ${error.message}`;
}

const ActionWrapper = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

export const AcceptInvitationPage = () => {
    let { token } = useParams();
    const { loading, error, data } = useQuery<{
        publicUserInvitationBeta: InvitationPublicData;
    }>(QUERY_USER_INVITATION_PUBLIC_DATA, {
        variables: {
            token: token
        }
    });

    const navigate = useNavigate();

    if (loading) {
        return (
            <PageWrapper>
                <PublicPageCard>
                    <PublicPageCardTitle>
                        Herzlich Willkommen!
                    </PublicPageCardTitle>

                    <div style={{ padding: '32px 0' }}>
                        <LoadingSpinner center label={'Lade Einladung'} />
                    </div>
                </PublicPageCard>
            </PageWrapper>
        );
    }

    if (error || !data || !data.publicUserInvitationBeta) {
        if (isApiErrorCode('user_invitation_already_accepted', error)) {
            return (
                <PageWrapper>
                    <InvitationAlreadyAccepted />
                </PageWrapper>
            );
        }

        if (isApiErrorCode('user_invitation_expired', error)) {
            return (
                <PageWrapper>
                    <PublicPageCard>
                        <PublicPageCardTitle>
                            Einladung nicht mehr gültig
                        </PublicPageCardTitle>

                        <ErrorWrapper>
                            <Alert severity="error">
                                Diese Einladung ist nicht mehr gültig. Bitte
                                kontaktieren Sie den oder die zuständige
                                Mitarbeiter:in in Ihrem Unternehmen um eine neue
                                Einladung zu erhalten.
                            </Alert>
                        </ErrorWrapper>

                        <ActionWrapper>
                            <PrimaryButton onClick={() => navigate('/login')}>
                                Zum Login
                            </PrimaryButton>
                        </ActionWrapper>
                    </PublicPageCard>
                </PageWrapper>
            );
        }

        const message = getInvitationLoadErrorMessage(error);
        return (
            <PageWrapper>
                <PublicPageCard>
                    <PublicPageCardTitle>
                        Einladung konnte nicht geladen werden
                    </PublicPageCardTitle>

                    <ErrorWrapper>
                        <Alert severity="error">{message}</Alert>
                    </ErrorWrapper>
                </PublicPageCard>
            </PageWrapper>
        );
    }

    return (
        <PageWrapper>
            <AcceptInvitationForm invitation={data.publicUserInvitationBeta} />
        </PageWrapper>
    );
};
