import { ApolloError, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import {
    OptionsObject,
    SnackbarKey,
    SnackbarMessage,
    useSnackbar
} from 'notistack';
import React, { useState } from 'react';
import { RestoreTimeTrackingIcon } from '../../common/components/icons/RestoreTimeTrackingIcon';
import {
    getResponseErrorMessage,
    GraphQLError,
    toApiError
} from '../../core/api/error';
import { executeSafe, OnNodeChangeFn } from '../../utils';
import { RECOVER_TIME_ENTRY_MUTATION } from '../queries';
import { getTimeEntrySummary } from '../time';
import { TimeEntry } from '../TimeEntry';
import { TimeEntryService } from '../TimeEntryService';

const StyledCircularProgress = styled(CircularProgress)`
    color: #fff;
`;

const StyledButton = styled(Button)`
    margin-left: 16px;
`;

type BlaProps = {
    timeEntry: TimeEntry;
    snackbarKey: SnackbarKey;
    onSuccess?: OnNodeChangeFn;
};

const TimeEntryDeletionSuccessActions = ({
    timeEntry,
    snackbarKey,
    onSuccess
}: BlaProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isBusy, setBusy] = useState(false);

    /* Delete */
    const [recoverTimeEntryMutation] = useMutation(RECOVER_TIME_ENTRY_MUTATION);

    function handleUndoError(error: ApolloError) {
        const apiError = toApiError(error);
        if (apiError && apiError instanceof GraphQLError) {
            if (apiError.getCode() === 'time_entry_already_recovered') {
                enqueueSnackbar(`Eintrag wiederhergestellt`, {
                    variant: 'success'
                });
                closeSnackbar(snackbarKey);
                return;
            }
        }

        const errorMessage = getResponseErrorMessage(error, apiError => {
            const code = apiError.getCode();

            if (code === 'time_entry_overlap') {
                const extensions = apiError.getExtensions();
                if (extensions.overlappedTimeEntry) {
                    return `Der Eintrag kann nicht wiederhergestellt werden, da er sich mit folgendem Eintrag überschneidet: ${getTimeEntrySummary(
                        extensions.overlappedTimeEntry
                    )}`;
                } else {
                    return `Der Eintrag kann nicht wiederhergestellt werden, da er sich mit einem Eintrag überschneidet.`;
                }
            }

            return `Eintrag konnte nicht wiederhergestellt werden. Fehler ${code}`;
        });

        enqueueSnackbar(errorMessage, { variant: 'error' });
    }

    function undoDeletion() {
        setBusy(true);
        TimeEntryService.recover(recoverTimeEntryMutation, timeEntry.id!)
            .then(payload => {
                closeSnackbar(snackbarKey);
                enqueueSnackbar(`Eintrag wiederhergestellt`, {
                    variant: 'success'
                });
                executeSafe(onSuccess, 'restore', payload.timeEntry);
            })
            .catch(error => {
                handleUndoError(error);
            })
            .finally(() => {
                setBusy(false);
            });
    }

    return (
        <>
            <StyledButton
                type="button"
                onClick={undoDeletion}
                color="primary"
                size="small"
                variant="contained"
                startIcon={
                    isBusy ? (
                        <StyledCircularProgress size="14px" />
                    ) : (
                        <RestoreTimeTrackingIcon />
                    )
                }
                aria-busy={isBusy}
                disabled={isBusy}>
                Wiederherstellen
            </StyledButton>
        </>
    );
};

export function showTimeEntryDeletionSuccessNotification(
    enqueueSnackbar: (
        message: SnackbarMessage,
        options?: OptionsObject
    ) => SnackbarKey,
    deletedTimeEntry: TimeEntry,
    onUndoSuccess?: OnNodeChangeFn
) {
    enqueueSnackbar('Eintrag gelöscht', {
        variant: 'success',
        autoHideDuration: 5000,
        action: key => (
            <TimeEntryDeletionSuccessActions
                timeEntry={deletedTimeEntry}
                snackbarKey={key}
                onSuccess={onUndoSuccess}
            />
        )
    });
}
