import styled from '@emotion/styled';
import { Colors } from '../../core/theme/Colors';

const StyledTimeDuration = styled.span`
    font-variant-numeric: tabular-nums;

    .mins ~ .colon {
        color: ${Colors.TextLight};
    }

    .secs {
        color: ${Colors.TextLight};
    }

    .zero {
        color: ${Colors.TextLighter};

        ~ .colon {
            color: ${Colors.TextLighter};
        }
    }
`;

export const TimeReportDuration = ({
    timeDuration,
    className
}: {
    timeDuration: string;
    className?: string;
}) => {
    if (!timeDuration) {
        return null;
    }

    const parts = timeDuration.split(':');
    let zeroIncludingThisPart = true;
    return (
        <StyledTimeDuration className={className}>
            {parts.map((part, index) => {
                const thisPartIsZero = !!part.match(/^0+$/);
                zeroIncludingThisPart = zeroIncludingThisPart && thisPartIsZero;
                return [
                    <span
                        key={index}
                        className={[
                            zeroIncludingThisPart ? 'zero' : '',
                            index === 0
                                ? 'hours'
                                : index === 1
                                ? 'mins'
                                : 'secs'
                        ].join(' ')}>
                        {part}
                    </span>,
                    index < parts.length - 1 ? (
                        <span className={'colon'} key={index + 'c'}>
                            :
                        </span>
                    ) : null
                ];
            })}
        </StyledTimeDuration>
    );
};
