import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';
import React from 'react';
import { Colors } from '../../core/theme/Colors';

const StyledChip = styled(Chip)`
    color: white;
    background-color: ${Colors.Orange};
    font-weight: 600;

    &.MuiChip-clickable {
        &:hover,
        &:focus,
        &:active {
            background-color: ${Colors.OrangeDark};
            color: white;
        }
    }
`;

export const ReportInsightChip = (props: ChipProps) => (
    <StyledChip size="small" {...props} />
);
