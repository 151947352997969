import { useLazyQuery } from '@apollo/client';
import GetAppIcon from '@mui/icons-material/GetApp';
import { CircularProgress } from '@mui/material';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { SecondaryButton } from '../../../common/components/button/SecondaryButton';
import { b64toBlob, promiseWait } from '../../../utils';
import { QUERY_GET_ORGANIZATION_WORK_TIME_REPORT_EXCEL_FILE } from '../queries';

export const OrganizationWorkTimeReportExport = (props: {
    month: DateTime;
    deactivated: boolean;
}) => {
    const [loading, setLoading] = useState(false);

    const [fetchExcelFileBase64, { error }] = useLazyQuery<any>(
        QUERY_GET_ORGANIZATION_WORK_TIME_REPORT_EXCEL_FILE,
        {
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'standby',
            refetchWritePolicy: 'overwrite'
        }
    );

    const { enqueueSnackbar } = useSnackbar();

    const month = props.month.toFormat('yyyy-MM');

    useEffect(() => {
        if (error) {
            enqueueSnackbar('Bericht konnte nicht exportiert werden', {
                variant: 'error',
                autoHideDuration: 5000
            });
            console.error(error);
        }
    }, [error]);

    const download = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        Promise.all([
            fetchExcelFileBase64({ variables: { month } }),
            promiseWait(1500) /* comfort wait :-) */
        ])
            .then(([data]) => {
                const excelFileBase64 =
                    data.data?.me?.organization?.workTimeReport?.asExcelFile;
                if (!excelFileBase64) {
                    throw Error('No data received');
                }

                const blob = b64toBlob(excelFileBase64, 'application/xlsx');
                saveAs(
                    blob,
                    `Dajeh-Arbeitszeitbericht-${props.month.toFormat(
                        'yyyy-LL'
                    )}.xlsx`
                );
            })
            .catch(e => {
                console.error(e);
                enqueueSnackbar('Bericht konnte nicht exportiert werden', {
                    variant: 'error',
                    autoHideDuration: 5000
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <SecondaryButton
            disabled={props.deactivated || loading}
            title="Arbeitszeit als Excel-Datei herunterladen"
            startIcon={
                loading ? <CircularProgress size={16} /> : <GetAppIcon />
            }
            onClick={download}>
            {loading ? 'Download startet' : 'Download'}
        </SecondaryButton>
    );
};
