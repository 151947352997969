import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FormControl } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { FieldConfig, useField, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { TimeEntryFormService } from './TimeEntryFormService';
import { TimeEntryFormValues } from './TimeEntryFormValues';

export const TimeField = (
    props: FieldConfig & { id?: string; disabled?: boolean }
) => {
    const { values, setValues, setFieldTouched, setFieldError } =
        useFormikContext<TimeEntryFormValues>();

    const [field, meta] = useField(props);
    const fieldName = field.name;

    const onChange = useCallback(
        (_newTime: unknown) => {
            setFieldTouched(fieldName, true, false);

            if (!_newTime) {
                return;
            }

            let newTime: DateTime | null = null;
            if (_newTime instanceof DateTime) {
                newTime = _newTime;
            }

            if (!newTime || !newTime.isValid) {
                setFieldError(fieldName, 'Bitte gültige Uhrzeit eingeben');
                return;
            }

            const updatedValues = TimeEntryFormService.update(
                values,
                fieldName === 'startTime' ? 'from' : 'to',
                newTime.toISOTime({ includeOffset: false })!
            );
            setValues(updatedValues, true);
        },
        [fieldName, values, setValues, setFieldTouched, setFieldError]
    );

    const touchedError = meta.touched && !!meta.error;

    return (
        <FormControl fullWidth>
            <TimePicker<DateTime>
                ampm={false}
                value={DateTime.fromISO(field.value)}
                onChange={onChange}
                format={'H:mm'}
                slots={{
                    openPickerIcon: props => (
                        <AccessTimeIcon {...props} sx={{ fontSize: 20 }} />
                    )
                }}
                onError={error =>
                    setFieldError(fieldName, 'Bitte gültige Uhrzeit eingeben')
                }
                slotProps={{
                    field: {
                        id: props.id,
                        disabled: props.disabled
                    },
                    textField: {
                        fullWidth: true,
                        helperText: touchedError && meta.error,
                        error: touchedError,
                        inputProps: {
                            sx: {
                                paddingRight: 0,
                                textAlign: 'right',
                                fontVariantNumeric: 'tabular-nums',
                                marginRight: '-9px'
                            }
                        }
                    },
                    openPickerButton: {
                        disableRipple: true
                    }
                }}
            />
        </FormControl>
    );
};
