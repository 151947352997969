import React, { PropsWithChildren } from 'react';
import { PublicPageCard } from '../../../common/components/page/PublicPageCard';
import { PublicPageCardTitle } from '../../../common/components/page/PublicPageCardTitle';

export const AuthBox = ({
    title,
    children
}: PropsWithChildren<{ title: string }>) => {
    return (
        <PublicPageCard>
            <PublicPageCardTitle>{title}</PublicPageCardTitle>
            {children}
        </PublicPageCard>
    );
};
