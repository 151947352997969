import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    TextField
} from '@mui/material';
import { FieldConfig, useField } from 'formik';
import React from 'react';
import { InputLabel } from '../../common/components/form/InputLabel';

export const PasswordField = (
    props: FieldConfig & {
        id?: string;
        disabled?: boolean;
        label?: string;
        placeholder?: string;
    }
) => {
    const [field, meta] = useField(props);

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(show => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const touchedError = meta.touched && !!meta.error;

    return (
        <FormControl fullWidth>
            {props.label && (
                <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            )}
            <TextField
                {...field}
                placeholder={props.placeholder}
                type={showPassword ? 'text' : 'password'}
                error={touchedError}
                size="medium"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end">
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            {touchedError && (
                <FormHelperText error>{meta.error}</FormHelperText>
            )}
        </FormControl>
    );
};
