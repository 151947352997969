import styled from '@emotion/styled';
import React from 'react';
import { Colors } from '../../theme/Colors';

const Subtitle = styled.div`
    font-size: 16px;
    line-height: 1.25;
    font-weight: 500;
    letter-spacing: 0.15px;
    color: ${Colors.TextLighter};
`;

export const PageSubHeadline = ({ children }) => (
    <Subtitle>{children}</Subtitle>
);
