import styled from '@emotion/styled';
import { Colors } from '../theme/Colors';

export const OnPageTabNavigation = styled.nav`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2px;
    margin-top: 16px;
    border-bottom: 1px solid ${Colors.LineColor};

    @media (min-width: 600px) {
        margin-top: 24px;
        gap: 4px;
    }

    @media (min-width: 960px) {
        margin-top: 32px;
    }
`;
