import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import React from 'react';

type LoadingSpinnerProps = {
    label: string | null;
    center: boolean;
    size?: number;
};

const Wrapper = styled.div<{ center: boolean }>`
    text-align: ${props => (props.center ? 'center' : 'left')};
`;

const LabelWrapper = styled.span`
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
`;

const StyledActivityIndicator = styled(CircularProgress)`
    display: inline-block;
    vertical-align: middle;
`;

export const LoadingSpinner = ({
    label,
    center,
    size = 24
}: LoadingSpinnerProps) => {
    return (
        <Wrapper center={center}>
            <StyledActivityIndicator size={`${size}px`} />
            {label && <LabelWrapper>{label}</LabelWrapper>}
        </Wrapper>
    );
};

LoadingSpinner.defaultProps = {
    label: 'Lade Daten ...',
    center: false
};
