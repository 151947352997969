import styled from '@emotion/styled';
import EmailIcon from '@mui/icons-material/Email';
import HelpIcon from '@mui/icons-material/Help';
import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactSupportLink } from '../common/components/ContactSupportLink';
import { ContentCard } from '../common/components/layout/ContentCard';
import { GridContainer } from '../common/components/layout/GridContainer';
import { GridItem } from '../common/components/layout/GridItem';
import { PageBody } from '../core/components/page/PageBody';
import { PageHeader } from '../core/components/page/PageHeader';
import { PageHeadline } from '../core/components/page/PageHeadline';
import { PageSubHeadline } from '../core/components/page/PageSubHeadline';
import { getSupportEmailAddress } from '../core/environment';
import { Colors } from '../core/theme/Colors';
import { EmployeeIcon } from '../employee/components/EmployeeIcon';
import { HelpPageBase } from './HelpPageBase';

const ClickableContentCard = styled(ContentCard)`
    &.is-clickable {
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            box-shadow: 0 0 0 4px ${Colors.Primary};
        }
    }
`;

const HelpCardCt = styled.div`
    display: flex;
    flex-direction: row;
`;

const HelpCardIconCt = styled.div`
    flex: 0;
    width: 70px;
    margin-right: 24px;
`;

const HelpCardContent = styled.div`
    flex: 1;

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
`;

const HelpCardTitle = styled.h2`
    margin-top: 0;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
`;

const HelpCard = ({
    title,
    icon,
    children,
    onClick
}: PropsWithChildren<{
    icon: React.ReactNode;
    title: string;
    onClick?: () => void;
}>) => {
    return (
        <ClickableContentCard
            onClick={onClick}
            className={onClick ? 'is-clickable' : undefined}>
            <HelpCardCt>
                <HelpCardIconCt>{icon}</HelpCardIconCt>
                <HelpCardContent>
                    <HelpCardTitle>{title}</HelpCardTitle>
                    {children}
                </HelpCardContent>
            </HelpCardCt>
        </ClickableContentCard>
    );
};

export const HelpOverviewPage = () => {
    const navigate = useNavigate();
    return (
        <HelpPageBase title={'Hilfe - Übersicht'}>
            <PageHeader>
                <div
                    style={{
                        textAlign: 'center',
                        padding: '24px 0',
                        marginBottom: '24px'
                    }}>
                    <PageHeadline>Willkommen im Hilfebereich</PageHeadline>
                    <PageSubHeadline>
                        Hier finden Sie Informationen zu den einzelnen
                        Funktionen des Systems.
                    </PageSubHeadline>
                </div>
            </PageHeader>

            <PageBody>
                <GridContainer>
                    <GridItem md={6}>
                        <HelpCard
                            title="Mitarbeiter- und Nutzerverwaltung"
                            icon={
                                <EmployeeIcon
                                    style={{
                                        display: 'block',
                                        fontSize: 60,
                                        color: Colors.Primary
                                    }}
                                />
                            }
                            onClick={() => navigate('/help/employees')}>
                            <p>
                                Alles rund um Mitarbeiter und Nutzer in Dajeh.
                            </p>
                        </HelpCard>
                    </GridItem>
                    <GridItem md={6}>
                        <HelpCard
                            title="Support kontaktieren"
                            icon={
                                <HelpIcon
                                    style={{
                                        display: 'block',
                                        fontSize: 60,
                                        color: Colors.Primary
                                    }}
                                />
                            }>
                            <p>
                                Sollten Sie Fragen haben, die hier nicht
                                beantwortet werden, wenden Sie sich bitte an den
                                Dajeh-Support: <br />
                                <br />
                                <ContactSupportLink>
                                    <EmailIcon
                                        style={{
                                            color: Colors.Primary,
                                            width: '0.7em',
                                            height: '0.7em',
                                            verticalAlign: 'top',
                                            marginRight: '0.15em',
                                            marginTop: '0.1em'
                                        }}
                                    />
                                    {getSupportEmailAddress()}
                                </ContactSupportLink>
                            </p>
                        </HelpCard>
                    </GridItem>
                </GridContainer>
            </PageBody>
        </HelpPageBase>
    );
};
