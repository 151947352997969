import styled from '@emotion/styled';

export const PageHeadlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h1 {
        margin-bottom: 0 !important;
    }

    > * + * {
        margin-left: 8px;
    }
`;
