import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { formatSecondsAsTimeDuration, getActiveTrackingSeconds } from '../time';

export type ActiveTimeTrackingCounterProps = {
    startDate?: string;
};

const formatActiveTracking = (startDate: DateTime) => {
    return formatSecondsAsTimeDuration(getActiveTrackingSeconds(startDate));
};

export const ActiveTimeTrackingCounter = ({
    startDate
}: ActiveTimeTrackingCounterProps) => {
    const [time, setTime] = useState('-:--:--');

    useEffect(() => {
        if (!startDate) {
            setTime('0:00:00');
            return;
        }

        const startDateTime = DateTime.fromISO(startDate);
        setTime(formatActiveTracking(startDateTime));

        const interval = window.setInterval(() => {
            setTime(formatActiveTracking(startDateTime));
        }, 40);

        return () => clearInterval(interval);
    }, [startDate]);

    return <>{time}</>;
};
