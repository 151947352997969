import styled from '@emotion/styled';
import { Colors } from '../../theme/Colors';

export const SubPageHeadline = styled.h2`
    color: ${Colors.Secondary};
    font-size: 20px;
    line-height: 1.25;
    font-weight: bold;
    margin: 0 0 16px;

    @media (min-width: 600px) {
        margin-bottom: 20px;
        font-size: 24px;
    }

    @media (min-width: 960px) {
        font-size: 28px;
    }
`;
