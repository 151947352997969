import styled from '@emotion/styled';
import React from 'react';
import DownloadOnTheAppStore from '../../../assets/images/Download_on_the_App_Store_Badge.svg';
import DownloadOnGooglePlay from '../../../assets/images/google-play-badge.png';

const AppDownloadButton = styled.a`
    display: block;
    text-decoration: none;
`;

const AppChoiceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const AppDownloadChoices = () => {
    return (
        <AppChoiceWrapper>
            <AppDownloadButton
                href="https://play.google.com/store/apps/details?id=de.kalaflax.dajeh&hl=de&gl=DE"
                target={'_blank'}>
                <img
                    alt={'Google Play'}
                    src={DownloadOnGooglePlay}
                    style={{
                        height: 75,
                        width: 'auto'
                    }}
                />
            </AppDownloadButton>

            <AppDownloadButton
                href="https://apps.apple.com/de/app/dajeh/id1556853951"
                target="_blank">
                <img
                    alt={'Laden im App Store'}
                    src={DownloadOnTheAppStore}
                    style={{
                        height: 60,
                        width: 'auto'
                    }}
                />
            </AppDownloadButton>
        </AppChoiceWrapper>
    );
};
