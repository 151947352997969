import { ApolloError } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InputLabel } from '../../../common/components/form/InputLabel';
import { PublicPageCardForm } from '../../../common/components/page/PublicPageCardForm';
import { PublicPageCardFormError } from '../../../common/components/page/PublicPageCardFormError';
import { PublicPageCardFormSubmitButton } from '../../../common/components/page/PublicPageCardFormSubmitButton';
import { getResponseErrorMessage } from '../../api/error';
import { GraphQLInputError } from '../../api/graphqlInputErrors';
import { AuthLocalStorage } from '../authLocalStorage';
import { useLoginMutation } from '../index';

interface Values {
    email: string;
    password: string;
}

export const LoginForm = () => {
    const [login, loading] = useLoginMutation();
    const [loginError, setLoginError] = useState<string | undefined>();

    const [searchParams] = useSearchParams();
    const emailParam = searchParams.get('email');

    function onSubmit(values, { setSubmitting }) {
        if (loading) {
            return;
        }

        const { email, password } = values;
        AuthLocalStorage.setSignInEmail(email);
        setLoginError(undefined);

        // Redirect happens in the AuthenticationContextProvider
        login(email, password).catch(e => {
            setLoginError(getLoginErrorMessage(e));
            setSubmitting(false);
        });
    }

    return (
        <>
            {!!loginError && (
                <PublicPageCardFormError>{loginError}</PublicPageCardFormError>
            )}

            <Formik
                initialValues={{
                    email: emailParam || AuthLocalStorage.getSignInEmail(),
                    password: ''
                }}
                validate={values => {
                    const errors: Partial<Values> = {};
                    if (!values.email) {
                        errors.email =
                            'Bitte geben Sie eine E-Mail Adresse ein';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            values.email
                        )
                    ) {
                        errors.email =
                            'Bitte geben Sie eine gültige E-Mail Adresse ein';
                    }

                    if (!values.password) {
                        errors.password = 'Bitte geben Sie Ihr Passwort ein';
                    }

                    return errors;
                }}
                onSubmit={onSubmit}>
                {({ submitForm, isSubmitting }) => (
                    <PublicPageCardForm style={{ maxWidth: 375 }}>
                        <InputLabel htmlFor="email">E-Mail-Adresse</InputLabel>
                        <Field
                            component={TextField}
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            disabled={isSubmitting}
                        />
                        <br />
                        <InputLabel htmlFor="password">Passwort</InputLabel>
                        <Field
                            component={TextField}
                            required
                            fullWidth
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            disabled={isSubmitting}
                        />
                        <PublicPageCardFormSubmitButton
                            disabled={isSubmitting}
                            onClick={submitForm}>
                            Anmelden
                        </PublicPageCardFormSubmitButton>

                        {isSubmitting && <LinearProgress />}
                    </PublicPageCardForm>
                )}
            </Formik>
        </>
    );
};

const getLoginErrorMessage = (error: ApolloError) => {
    return getResponseErrorMessage(error, graphQlApiError => {
        if (graphQlApiError.hasInputErrors()) {
            const inputErrors = graphQlApiError.getInputErrors();

            if (
                inputErrors.email &&
                inputErrors.email === GraphQLInputError.INVALID_EMAIL
            ) {
                return 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
            }
        }

        if (graphQlApiError.getCode() === 'auth_invalid_credentials') {
            return 'Wir konnten keinen Nutzer mit dieser E-Mail/Passwort-Kombination finden.';
        }
    });
};
