import { DateTime } from 'luxon';
import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { EmployeeOnboardingDocumentPage } from '../../employee/EmployeeOnboardingDocumentPage';
import { EmployeeProfilePage } from '../../employee/EmployeeProfilePage';
import { EmployeesPage } from '../../employee/EmployeesPage';
import { EmployeeTimeTrackingPage } from '../../employee/EmployeeTimeTrackingPage';
import { EmployeeWorkTimeReportPage } from '../../employee/EmployeeWorkTimeReportPage';
import { EmployeesHelpPage } from '../../help/EmployeesHelpPage';
import { HelpOverviewPage } from '../../help/HelpOverviewPage';
import { OrganizationActivationPage } from '../../organization/activation/OrganizationActivationPage';
import OrganizationWorkTimeReportPage from '../../timeTracking/report/organizationWorkTime/OrganizationWorkTimeReportPage';
import { TimeTrackingPage } from '../../timeTracking/TimeTrackingPage';
import { AcceptInvitationPage } from '../../user/invitation/AcceptInvitationPage';
import { UserNotificationsPage } from '../../user/notifications/UserNotificationsPage';
import { UserSettingsPage } from '../../user/settings/UserSettingsPage';
import { LoginPage } from '../auth/components/LoginPage';
import { RequestPasswordResetPage } from '../auth/components/resetPassword/RequestPasswordResetPage';
import { ResetPasswordPage } from '../auth/components/resetPassword/ResetPasswordPage';
import { FourOFourPage } from './404';
import { Paths } from './paths';

export default function AppRoutes() {
    return (
        <Routes>
            {/* Public */}
            <Route path={Paths.Login} element={<LoginPage />} />
            <Route
                path={Paths.RequestPasswordReset}
                element={<RequestPasswordResetPage />}
            />
            <Route path={Paths.ResetPassword} element={<ResetPasswordPage />} />
            <Route
                path={Paths.AcceptInvitation}
                element={<AcceptInvitationPage />}
            />
            <Route
                path={Paths.OrganizationActivationPage}
                element={<OrganizationActivationPage />}
            />

            {/* Time tracking */}
            <Route
                path={Paths.WorkTimeTracking}
                element={<TimeTrackingPage />}
            />

            {/* Organization or personal work time report */}
            <Route path={'reports/work-time'} element={<Outlet />}>
                <Route
                    index
                    element={
                        <Navigate
                            to={`/reports/work-time/${DateTime.now().toFormat(
                                'yyyy-MM'
                            )}`}
                            replace
                        />
                    }
                />
                <Route
                    path={':month'}
                    element={<OrganizationWorkTimeReportPage />}
                />
            </Route>

            {/* Organization members space */}
            <Route path={'employees'} element={<Outlet />}>
                <Route index element={<EmployeesPage />} />
                <Route path={':employeeId'} element={<Outlet />}>
                    <Route
                        index
                        element={<Navigate to={'profile'} replace />}
                    />
                    <Route path={'profile'} element={<EmployeeProfilePage />} />
                    <Route
                        path={'time-tracking'}
                        element={<EmployeeTimeTrackingPage />}
                    />
                    <Route path={'reports'}>
                        <Route
                            path={'work-time'}
                            element={<EmployeeWorkTimeReportPage />}
                        />
                        <Route
                            index
                            element={<Navigate to={'work-time'} replace />}
                        />
                    </Route>
                    <Route
                        path={'onboarding-document'}
                        element={<EmployeeOnboardingDocumentPage />}
                    />
                </Route>
            </Route>

            <Route path={Paths.UserSettings} element={<UserSettingsPage />} />
            <Route
                path={Paths.UserNotifications}
                element={<UserNotificationsPage />}
            />

            <Route path={Paths.Help} element={<Outlet />}>
                <Route index element={<HelpOverviewPage />} />
                <Route path={'employees'} element={<EmployeesHelpPage />} />
            </Route>

            {/*

                Redirects

            */}

            {/* Redirections due to non-existing overview pages */}
            <Route
                path={Paths.Home}
                element={<Navigate to={Paths.WorkTimeTracking} replace />}
            />
            <Route
                path={'/reports'}
                element={
                    <Navigate to={Paths.OrganizationWorkTimeReport} replace />
                }
            />
            <Route
                path={Paths.User}
                element={<Navigate to={Paths.UserSettings} replace />}
            />

            {/* Deprecation redirections */}
            <Route
                path={'/organization/work-time-report/:month'}
                element={
                    <Navigate to={Paths.OrganizationWorkTimeReport} replace />
                }
            />

            {/* Last but not least: 404 */}
            <Route path="*" element={<FourOFourPage />} />
        </Routes>
    );
}
